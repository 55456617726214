import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { paginate, PaginationState } from "../../common/Pagination";
import { DataContextCustomer } from "../../../context/dataContext";
import {
  CC_ALMOSTCOMPLETE,
  CC_CALLOFFOPTIONS,
  CC_EXPIRINGSOON,
  CC_LESSTHANHALF,
  CC_NOCALLS,
  CC_PREPARATION,
  CC_SORTOPTIONS,
  CC_STATUSOPTIONS,
  isContractInactive,
} from "../../../utils/customerContractUtils";
import { SortOrder } from "../../../utils/filterUtils";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import { CC_CANCELED, CC_CLOSED, CC_OPEN, CC_READY, CC_STATES } from "../../../model/customerContract.types";
import Search from "../../common/Search";
import BaseListing from "../../common/BaseListing";
import CustomerContractRow from "./CustomerContractRow";
import { PropertyType } from "../../../utils/propertyUtils";
import SortIcon from "../../common/SortIcon";
import { CustomerCustomerContract } from "../../../model/customer/customerCustomerContract.types";
import ListPlaceholder from "../../common/ListPlaceholder";

interface CustomerContractListingProps {
  context: React.ContextType<typeof DataContextCustomer>;
}

interface CustomerContractListingState extends PaginationState {
  search: string;
  status?: SelectOption;
  callStatus?: SelectOption;
  sortColumn: SelectOption;
  category?: SelectOption;
  sortOrder: SortOrder;
}

const COMPONENT_NAME = "CustomerContractListing";

class CustomerContractListing extends PureComponent<CustomerContractListingProps, CustomerContractListingState> {
  constructor(props: CustomerContractListingProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 20,
      sortColumn: CC_SORTOPTIONS[0],
      sortOrder: SortOrder.DESC,
      search: "",
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: CC_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: { value: "", label: "Best Match" },
        sortOrder: SortOrder.DESC,
      });
  };

  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangeSortColumn = (sortColumn: SelectOption) => this.setState({ sortColumn, currentPage: 1 });
  handleToggleSortOrder = () => {
    const { sortOrder } = this.state;
    this.setState({ sortOrder: sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, currentPage: 1 });
  };
  handleChangeStatus = (status?: SelectOption) => this.setState({ status, currentPage: 1 });
  handleChangeCallStatus = (callStatus?: SelectOption) => this.setState({ callStatus, currentPage: 1 });
  handleChangeCategory = (category?: SelectOption) => this.setState({ category, currentPage: 1 });

  /**
   * Filter and sort the contracts.
   * @returns {Array<CustomerCustomerContract>} Filtered and sorted contracts
   */
  getFilteredContracts = (): Array<CustomerCustomerContract> => {
    const { search, sortColumn, sortOrder, status, callStatus, category } = this.state;
    let contractsFiltered = this.props.context.customerContract.slice();

    if (search.trim()) {
      contractsFiltered = doFuseSearch(contractsFiltered, search, [
        "contractNo",
        "commodity.title.en",
        "customerReference",
        "state",
        "company.name",
      ]);
    }

    if (category)
      contractsFiltered = contractsFiltered.filter((c) =>
        c.commodity.properties.some((p) => p._id.toString() === category.value)
      );

    if (status)
      switch (status.value) {
        case CC_PREPARATION:
          contractsFiltered = contractsFiltered.filter((c) => c.state === CC_OPEN);
          break;
        case CC_READY:
          contractsFiltered = contractsFiltered.filter((c) => c.state === CC_READY);
          break;
        case CC_OPEN:
          contractsFiltered = contractsFiltered.filter((c) => c.contractInformation.restAmount > 0);
          break;
        case CC_EXPIRINGSOON:
          // filter for less than 30 days
          contractsFiltered = contractsFiltered.filter(
            (c) => c.validityPeriod.end.getTime() - new Date().getTime() <= 1000 * 60 * 60 * 24 * 30
          );
          break;
        case CC_CLOSED:
          contractsFiltered = contractsFiltered.filter((c) =>
            ([CC_CANCELED, CC_CLOSED] as Array<CC_STATES>).includes(c.state)
          );
          break;
      }

    if (callStatus)
      switch (callStatus.value) {
        case CC_NOCALLS:
          contractsFiltered = contractsFiltered.filter(
            (c) => c.contractInformation.restAmount === c.contractInformation.totalAmount
          );
          break;
        case CC_LESSTHANHALF:
          contractsFiltered = contractsFiltered.filter(
            (c) => c.contractInformation.restAmount / c.contractInformation.totalAmount > 0.5
          );
          break;
        case CC_ALMOSTCOMPLETE:
          // less than 10% left or presumably only 1 call left
          contractsFiltered = contractsFiltered.filter(
            (c) =>
              c.contractInformation.restAmount / c.contractInformation.totalAmount < 0.1 ||
              c.contractInformation.restAmount < 2 * c.contractInformation.minimumCallQuantity
          );
          break;
        case CC_CLOSED:
          contractsFiltered = contractsFiltered.filter((c) => c.contractInformation.restAmount === 0);
          break;
      }

    // Handle sorting for search
    if (!sortColumn.value && search.trim()) {
      if (sortOrder === SortOrder.DESC) return contractsFiltered.reverse();
      return contractsFiltered;
    }

    // Inactive contracts always at the end
    const active: Array<CustomerCustomerContract> = [];
    const inactive: Array<CustomerCustomerContract> = [];
    contractsFiltered.forEach((c) => (isContractInactive(c) ? inactive.push(c) : active.push(c)));
    contractsFiltered = _.orderBy(active, sortColumn.value, sortOrder).concat(
      _.orderBy(inactive, sortColumn.value, sortOrder)
    );
    return contractsFiltered;
  };

  render() {
    const { context } = this.props;
    const { property } = context;
    const { currentPage, pageSize, sortColumn, status, sortOrder, search, category, callStatus } = this.state;
    const categories = property.filter((p) => p.type === PropertyType.CATEGORY);
    const filteredContracts = this.getFilteredContracts();
    const paginatedContracts = paginate(filteredContracts, currentPage, pageSize);

    const headerDefinition = [
      { title: "Contract", style: { width: "12%" } },
      { title: "Quantity" },
      { title: "Commodity", style: { width: "30%" } },
      { title: "Call-Offs" },
      { title: "Remaining" },
      { title: "Usage", style: { width: "5%" } },
      { title: "Valid until", style: { width: "10%" } },
    ];
    const sortOptions = CC_SORTOPTIONS.slice();
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Contracts</span>
                  <Link to="/articles">
                    <button className="btn btn-light btn-text float-right">New Contract</button>
                  </Link>
                  <button className="btn btn-light btn-text float-right disabled mr-3" disabled={true}>
                    Export
                  </button>
                </h3>
                <p className="mb-15">
                  <span className="mb-3 fs-5 text-muted">
                    Showing {paginatedContracts.length} of {filteredContracts.length} matching contracts
                  </span>
                </p>
                <div className="card mb-10 bg-white border-none bg-custom-medium-gray p-5 ">
                  <div className="row g-8 mb-4">
                    <div className="col-lg-6 col-8">
                      <label className="fs-6 form-label fw-bolder text-dark">Search Query</label>
                      <Search placeholder="Search contracts..." onSearch={this.handleChangeSearch} value={search} />
                    </div>
                  </div>
                  <div className="row g-8">
                    <div className="col-lg-3">
                      <label className="fs-6 form-label fw-bolder text-dark">Status</label>
                      <CustomSelect
                        options={CC_STATUSOPTIONS}
                        placeholder="All States"
                        matchFormControl={true}
                        isClearable
                        onChange={this.handleChangeStatus}
                        value={status}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="fs-6 form-label fw-bolder text-dark">Call-Offs</label>
                      <CustomSelect
                        options={CC_CALLOFFOPTIONS}
                        placeholder="All States"
                        matchFormControl={true}
                        isClearable
                        onChange={this.handleChangeCallStatus}
                        value={callStatus}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="fs-6 form-label fw-bolder text-dark">Commodity Category</label>
                      <CustomSelect
                        options={categories.map((p) => {
                          return { value: p._id.toString(), label: p.name.en };
                        })}
                        placeholder="All Categories"
                        matchFormControl={true}
                        isClearable
                        onChange={this.handleChangeCategory}
                        value={category}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="fs-6 form-label fw-bolder text-dark">Sort</label>
                      <label className="form-label text-muted float-right cursor-pointer btn-text">
                        <SortIcon sortOrder={sortOrder} onToggleSortOrder={this.handleToggleSortOrder} />
                      </label>
                      <CustomSelect
                        options={CC_SORTOPTIONS}
                        matchFormControl={true}
                        onChange={this.handleChangeSortColumn}
                        value={sortColumn}
                      />
                    </div>
                  </div>
                </div>
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {filteredContracts.length > 0 ? (
                        paginatedContracts.map((cc) => (
                          <CustomerContractRow key={cc._id.toString()} contract={cc} context={context} />
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            <ListPlaceholder type={"contract"} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  documents={filteredContracts}
                  currentPage={currentPage}
                  baseSize={20}
                  pageSize={pageSize}
                  noHover={filteredContracts.length === 0}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerContractListing;
