import React from "react";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { formatOrderDate } from "../../../../utils/customerOrderUtils";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";
import { SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { CustomerOrderExtended } from "../../../../model/customerOrder.types";
import { ForwardingOrder } from "../../../../model/forwardingOrder.types";
import Tooltip from "../../../common/Tooltip";
import { formatDateWithType } from "../../../../utils/logisticsUtils";

interface ConfirmETAModalProps {
  orders: Array<SupplierOrderExtended | CustomerOrderExtended>;
  forwardingOrder: ForwardingOrder;
  onConfirm: (forwardingOrder: ForwardingOrder) => void;
}

const ConfirmETAModal: React.FunctionComponent<ConfirmETAModalProps> = ({ orders, forwardingOrder, onConfirm }) => {
  return (
    <SimpleConfirmationModal.SimpleConfirmationModalButton
      buttonText="Confirm ETA"
      buttonClasses="btn btn-outline btn-outline-light btn-sm ms-2 px-2 p-1"
      onConfirm={() => onConfirm(forwardingOrder)}
      modalTitle={`FW-${forwardingOrder.forwardingOrderNo} - ETAs`}
      modalDescription={
        <>
          <div className="text-white">Please make sure the following dates are correct:</div>
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table align-middle gs-0 gy-1 ">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0">Order Name</th>
                  <th className="border-bottom-0">
                    Original Target Date
                    <Tooltip tooltipText={"The original target date set by the customer"}>
                      <span className="ml-2">
                        <i className="fa fa-info-circle text-white" />
                      </span>
                    </Tooltip>
                  </th>
                  <th className="border-bottom-0">
                    Delivery Date
                    <Tooltip
                      tooltipText={"The delivery date given to the forwarder, can be set inside the forwarding order"}
                    >
                      <span className="ml-2">
                        <i className="fa fa-info-circle text-white" />
                      </span>
                    </Tooltip>
                  </th>
                  <th className="border-bottom-0">
                    ETA
                    <Tooltip
                      tooltipText={
                        "The estimated time of arrival which will be displayed to the customer, can be set in the dashboard or the order itself"
                      }
                    >
                      <span className="ml-2">
                        <i className="fa fa-info-circle text-white" />
                      </span>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ConfirmETAModalRows orders={orders} forwardingOrder={forwardingOrder} />
              </tbody>
            </table>
          </div>
        </>
      }
      cancelButtonText="Close"
      confirmButtonText="Confirm"
      size="lg"
    />
  );
};

interface ConfirmETAModalRowsProps {
  orders: Array<SupplierOrderExtended | CustomerOrderExtended>;
  forwardingOrder: ForwardingOrder;
}

const ConfirmETAModalRows: React.FunctionComponent<ConfirmETAModalRowsProps> = ({ orders, forwardingOrder }) => {
  return (
    <>
      {orders.map((order, index) => {
        const orderInformation = forwardingOrder.orderInformation[index];
        return (
          <tr key={index}>
            <td className="text-white">{getOrderNumber(order)}</td>
            <td className="text-white">{formatOrderDate(order, undefined, true)}</td>
            <td className="text-white">
              {formatDateWithType(orderInformation.deliveryDate, orderInformation.deliveryDateType)}
            </td>
            <td className="text-white">{formatOrderDate(order)}</td>
          </tr>
        );
      })}
    </>
  );
};

export default ConfirmETAModal;
