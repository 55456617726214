import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { downloadFile } from "../../utils/fileUtils";
import { ALLOWED_SEPARATORS, exportCommoditiesAsCSV, parseCSV } from "../../utils/csvUtils";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { CommodityExtended } from "../../model/commodity.types";
import { DataContextInternal } from "../../context/dataContext";
import { PropertyType } from "../../utils/propertyUtils";
import { Property } from "../../model/property.types";
import { ActiveSubstance } from "../../model/activeSubstance.types";
import {
  generateSubtitle,
  getCommodityTimelineEntry,
  insertCommoditiesAndRelatedDocuments,
  T_COMMODITYCREATED,
  updateMultipleCommodities,
} from "../../utils/commodityUtils";
import { formatDate, getCountryNameForCode } from "../../utils/baseUtils";
import userService from "../../services/userService";
import { TransportConditions, TRANSPORTCONDITIONTYPE } from "../../model/finishedProduct.types";
import { TRANSPORTCONDITIONS } from "../../utils/finishedProductUtils";
import { reduceCommodity } from "../../utils/dataTransformationUtils";

interface CommodityParsingToolProps {}

interface CommodityParsingToolState {
  content: Array<string>;
  separator: SelectOption;
  processing: boolean;
  commoditiesNew: Array<CommodityExtended>;
  commoditiesUpdated: Array<Partial<CommodityExtended>>;
  addedProps: Array<Property>;
  addedActiveSubstances: Array<ActiveSubstance>;
  stage: "initial" | "generating" | "parsing" | "summary" | "success" | "failure";
  fieldsToUpdate: {
    [key: string]: boolean;
  };
}

class CommodityParsingTool extends PureComponent<CommodityParsingToolProps, CommodityParsingToolState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;
  selectFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: CommodityParsingToolProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = this.getDefaultState();
  }

  handleDownloadCommoditiesCSV = () => {
    this.setState({ stage: "generating" });
    const csv = exportCommoditiesAsCSV(this.context.commodity, this.context);
    downloadFile(csv, `Commodities_${formatDate(new Date())}.csv`, "text/plain");
    this.setState({ stage: "initial" });
  };

  handleChangeSeparator = (separator: SelectOption) => this.setState({ separator });

  handleParseCommodityCSV = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const { separator, fieldsToUpdate } = this.state;
    const { property, activeSubstance } = this.context;
    this.setState({ processing: true });
    const file = await e.target.files[0].text();
    const content: Array<string> = file.split(/[\r\n]+/).filter((line) => line.trim() !== "");
    this.setState({ content });
    const parsedCSV = parseCSV(content, separator.value);
    const addedProps: Array<Property> = [];
    const addedActiveSubstances: Array<ActiveSubstance> = [];
    const commoditiesNew = [];
    const commoditiesUpdated = [];
    for (let i = 1; i < parsedCSV.length; i++) {
      try {
        const l = parsedCSV[i];
        const [
          acId,
          id,
          name,
          duty,
          category,
          contentPurity,
          specificRotation,
          productionExtractionSolvent,
          hsCode,
          casNumber,
          novelFood,
          cites,
          echa,
          hazardous,
          botanicalName,
          veganVegetarian,
          allergens,
          halal,
          kosher,
          bulkDensity,
          part,
          grade,
          particleSize,
          analysisMethod,
          appearance,
          odor,
          maxAllowHeavyMetals,
          maxAllowedMicrobiology,
          maxAllowedWETO,
          lossOnDry,
          ashStr,
          activeSubstances,
          contentOfActiveSubstances,
          shelfLife,
          limits,
          storageConditions,
          transportConditionTypes,
          transportCondition,
          packaging,
          totalResidualOrganicSolvents,
          aflatoxins,
          regulatoryData,
          pah4,
          benzopyrene,
          carrier,
          crossContamination,
          ratioExtract,
          country,
          organic,
          btiRefNo,
          vatPercentage,
        ] = l;
        // When there is no name we do not create the commodity
        if (!name.trim()) {
          console.error("LINE", i, "CONTAINED NO TITLE - SKIPPING");
          continue;
        }
        const dutyString = duty.split("+");
        const dutyPercentage = Number(dutyString[0].trim().replace("%", "")) || 0;
        const dumpingFee = dutyString[1] ? Number(dutyString[1].trim().replace("EUR", "")) : undefined;
        let categoryObj = property.find(
          (p) => p.type === PropertyType.CATEGORY && p.name.en.toLowerCase() === category.trim().toLowerCase()
        );
        if (!categoryObj && !["", "not specified"].includes(category.trim().toLowerCase())) {
          categoryObj = addedProps.find(
            (p) => p.type === PropertyType.CATEGORY && p.name.en.toLowerCase() === category.trim().toLowerCase()
          );
          if (!categoryObj) {
            categoryObj = {
              _id: new BSON.ObjectId(),
              name: { en: category.trim() },
              description: { en: "" },
              type: PropertyType.CATEGORY,
              disabled: false,
            };
            addedProps.push(categoryObj);
          }
        }
        let solvent = property.find(
          (p) =>
            p.type === PropertyType.SOLVENT &&
            p.name.en.toLowerCase() === productionExtractionSolvent.trim().toLowerCase()
        );
        if (!solvent && !["", "not specified"].includes(productionExtractionSolvent.trim().toLowerCase())) {
          solvent = addedProps.find(
            (p) =>
              p.type === PropertyType.SOLVENT &&
              p.name.en.toLowerCase() === productionExtractionSolvent.trim().toLowerCase()
          );
          if (!solvent) {
            solvent = {
              _id: new BSON.ObjectId(),
              name: { en: productionExtractionSolvent.trim() },
              description: { en: "" },
              type: PropertyType.SOLVENT,
              disabled: false,
            };
            addedProps.push(solvent);
          }
        }
        const agString = ["", "no", "not specified"].includes(allergens.trim().toLowerCase())
          ? []
          : allergens.split(/[|,]/).map((a) => a.trim());
        const agProp: Array<Property> = [];
        for (let j = 0; j < agString.length; j++) {
          const a = agString[j];
          let allergen = property.find(
            (p) => p.type === PropertyType.ALLERGEN && p.name.en.toLowerCase() === a.trim().toLowerCase()
          );
          if (!allergen) {
            allergen = addedProps.find(
              (p) => p.type === PropertyType.ALLERGEN && p.name.en.toLowerCase() === a.trim().toLowerCase()
            );
            if (!allergen) {
              allergen = {
                _id: new BSON.ObjectId(),
                name: { en: a.trim() },
                description: { en: "" },
                type: PropertyType.ALLERGEN,
                disabled: false,
              };
              addedProps.push(allergen);
            }
          }
          agProp.push(allergen);
        }
        const density = { min: 0, max: 0 };
        const densityString = bulkDensity.trim().replaceAll(" ", "").toLowerCase();
        let range;
        if (densityString.includes("kg/m3")) {
          range = densityString.split("kg/m3")[0].split("-");
        } else if (densityString.includes("kg/m³")) {
          range = densityString.split("kg/m³")[0].split("-");
        }
        if (range) {
          density.min = Number(range[0]);
          density.max = Number(range[1]);
        }
        const grades = grade.trim().toLowerCase();
        let analysisMethodObj = property.find(
          (p) =>
            p.type === PropertyType.ANALYSISMETHOD && p.name.en.toLowerCase() === analysisMethod.trim().toLowerCase()
        );
        if (!analysisMethodObj && !["", "not specified"].includes(analysisMethod.trim().toLowerCase())) {
          analysisMethodObj = addedProps.find(
            (p) =>
              p.type === PropertyType.ANALYSISMETHOD && p.name.en.toLowerCase() === analysisMethod.trim().toLowerCase()
          );
          if (!analysisMethodObj) {
            analysisMethodObj = {
              _id: new BSON.ObjectId(),
              name: { en: analysisMethod.trim() },
              description: { en: "" },
              type: PropertyType.ANALYSISMETHOD,
              disabled: false,
            };
            addedProps.push(analysisMethodObj);
          }
        }
        let odorObj = property.find(
          (p) => p.type === PropertyType.ODOR && p.name.en.toLowerCase() === odor.trim().toLowerCase()
        );
        if (!odorObj && !["", "not specified"].includes(odor.trim().toLowerCase())) {
          odorObj = addedProps.find(
            (p) => p.type === PropertyType.ODOR && p.name.en.toLowerCase() === odor.trim().toLowerCase()
          );
          if (!odorObj) {
            odorObj = {
              _id: new BSON.ObjectId(),
              name: { en: odor.trim() },
              description: { en: "" },
              type: PropertyType.ODOR,
              disabled: false,
            };
            addedProps.push(odorObj);
          }
        }
        let lodAmount = Number(lossOnDry.replaceAll(/\?|≤|<=|max.|%/g, ""));
        if (isNaN(lodAmount)) lodAmount = 0;
        const lossOnDrying = {
          lessThan:
            lossOnDry.trim().toLowerCase().includes("≤") ||
            lossOnDry.trim().toLowerCase().includes("<=") ||
            lossOnDry.trim().toLowerCase().includes("max"),
          amount: lodAmount,
        };
        let ashAmount = Number(ashStr.replaceAll(/\?|≤|<=|max.|%/g, ""));
        if (isNaN(ashAmount)) ashAmount = 0;
        const ash = {
          lessThan:
            ashStr.trim().toLowerCase().includes("≤") ||
            ashStr.trim().toLowerCase().includes("<=") ||
            ashStr.trim().toLowerCase().includes("max"),
          amount: ashAmount,
        };
        const aSString = ["", "no", "not specified"].includes(activeSubstances.trim().toLowerCase())
          ? []
          : activeSubstances.split(/[|,]/).map((as) => as.trim());
        const aSObj: Array<{ substance: ActiveSubstance; percentage: number }> = [];
        for (let k = 0; k < aSString.length; k++) {
          const a = aSString[k];
          let aS = activeSubstance.find((aSub) => aSub.name.en.toLowerCase() === a.trim().toLowerCase());
          if (!aS) {
            aS = addedActiveSubstances.find((aSub) => aSub.name.en.toLowerCase() === a.trim().toLowerCase());
            if (!aS) {
              aS = {
                _id: new BSON.ObjectId(),
                name: { en: a.trim() },
                nrv: 0,
                disabled: false,
              };
              addedActiveSubstances.push(aS);
            }
          }
          aSObj.push({ substance: aS, percentage: 0 });
        }
        const aSAmountString = contentOfActiveSubstances.split(/[|\-,]/);
        // aSObj intended as iterator!
        for (let l = 0; l < aSObj.length; l++) {
          let preparedValue = Number(aSAmountString[l].replaceAll(/%?/g, "").trim());
          if (isNaN(preparedValue)) preparedValue = 0;
          aSObj[l].percentage = preparedValue;
        }

        const transportConditionString = ["", "no", "not specified"].includes(transportCondition.trim().toLowerCase())
          ? []
          : transportCondition.split(/[|,]/).map((tc) => tc.trim());
        const transportConditionObj: Array<TransportConditions> = [];
        for (let j = 0; j < transportConditionString.length; j++) {
          const tc = transportConditionString[j];
          // split tc string in name and description with : as separator
          const [name, description] = tc.split(/:\s*/);
          if (name && description) {
            let transportCondition = property.find(
              (p) =>
                p.type === PropertyType.TRANSPORTCONDITIONS && p.name.en.toLowerCase() === name.trim().toLowerCase()
            );
            if (!transportCondition) {
              transportCondition = addedProps.find(
                (p) =>
                  p.type === PropertyType.TRANSPORTCONDITIONS && p.name.en.toLowerCase() === name.trim().toLowerCase()
              );
              if (!transportCondition) {
                transportCondition = {
                  _id: new BSON.ObjectId(),
                  name: { en: name.trim() },
                  description: { en: description },
                  type: PropertyType.TRANSPORTCONDITIONS,
                  disabled: false,
                };
                addedProps.push(transportCondition);
              }
            }
            transportConditionObj.push({ type: "tcTemperature", property: transportCondition._id.toString() });
          }
        }
        const transportConditionTypeString = transportConditionTypes.split(/[|,]/);
        // transportConditionObj intended as iterator!
        for (let l = 0; l < transportConditionObj.length; l++) {
          const preparedLabel = transportConditionTypeString[l].trim();
          const foundObject = TRANSPORTCONDITIONS.find((item) => item.label === preparedLabel);
          transportConditionObj[l].type = (foundObject?.value as TRANSPORTCONDITIONTYPE) ?? "tcTemperature";
        }
        let packagingObj = property.find(
          (p) => p.type === PropertyType.PACKAGING && p.name.en.toLowerCase() === packaging.trim().toLowerCase()
        );
        if (!packagingObj && ["", "not specified"].includes(packaging.trim().toLowerCase())) {
          packagingObj = addedProps.find(
            (p) => p.type === PropertyType.PACKAGING && p.name.en.toLowerCase() === packaging.trim().toLowerCase()
          );
          if (!packagingObj) {
            packagingObj = {
              _id: new BSON.ObjectId(),
              name: { en: packaging.trim() },
              description: { en: "" },
              type: PropertyType.PACKAGING,
              disabled: false,
            };
            addedProps.push(packagingObj);
          }
        }
        let carrierObj = property.find(
          (p) => p.type === PropertyType.CARRIER && p.name.en.toLowerCase() === carrier.trim().toLowerCase()
        );
        if (!carrierObj && !["", "not specified"].includes(carrier.trim().toLowerCase())) {
          carrierObj = addedProps.find(
            (p) => p.type === PropertyType.CARRIER && p.name.en.toLowerCase() === carrier.trim().toLowerCase()
          );
          if (!carrierObj) {
            carrierObj = {
              _id: new BSON.ObjectId(),
              name: { en: carrier.trim() },
              description: { en: "" },
              type: PropertyType.CARRIER,
              disabled: false,
            };
            addedProps.push(carrierObj);
          }
        }
        const countryName = getCountryNameForCode(country.trim());
        const commodity: CommodityExtended = {
          _id: BSON.ObjectId.isValid(id) ? new BSON.ObjectId(id) : new BSON.ObjectId(),
          title: { en: name.trim() },
          subtitle: { en: "" },
          suppliers: [],
          supplierEUStocks: [],
          sellingPrices: [],
          disabled: false,
          approved: false,
          articleNo: "",
          organic: organic.trim().toLowerCase() === "yes",
          documents: [],
          unit: "kg",
          country: countryName ? { code: country.trim(), name: countryName } : { code: "", name: "" },
          hsCode,
          images: [],
          note: "",
          timeline: [getCommodityTimelineEntry(T_COMMODITYCREATED)],
          purity: { en: contentPurity.trim() },
          specificRotation: { en: specificRotation.trim() },
          casNumber: casNumber.split(/[,\/]/).map((c) => c.trim()),
          botanicalName: botanicalName.trim(),
          vegetarian: veganVegetarian.trim().toLowerCase() === "yes",
          vegan: veganVegetarian.trim().toLowerCase() === "yes",
          properties: [...agProp],
          halal: halal.trim().toLowerCase() === "yes",
          kosher: kosher.trim().toLowerCase() === "yes",
          density,
          part: { en: part.trim() },
          foodGrade: grades.includes("food grade"),
          pharmaceuticalGrade: grades.includes("pharmaceutical grade") || grades.includes("pharma grade"),
          cosmeticGrade: grades.includes("cosmetic grade"),
          feedGrade: grades.includes("feed grade"),
          industrialGrade: grades.includes("industrial grade"),
          medicineGrade: grades.includes("medicine grade"),
          uspGrade: grades.includes("usp grade"),
          particleSize: { en: particleSize.trim() },
          color: { min: "0", max: "0" },
          appearance: { en: appearance },
          maxAllowedHeavyMetals: { en: maxAllowHeavyMetals.trim() },
          maxAllowedMicrobiology: { en: maxAllowedMicrobiology.trim() },
          maxAllowedWETO: { en: maxAllowedWETO.trim() },
          lossOnDrying,
          ash,
          activeSubstances: aSObj,
          shelfLife: shelfLife.toLowerCase().includes("years")
            ? Number(shelfLife.toLowerCase().replace("years", "")) * 12
            : Number(shelfLife.toLowerCase().replace("months", "")),
          limits: { en: limits.trim() },
          storageConditions: { en: storageConditions.trim() },
          transportConditions: transportConditionObj,
          totalResidualOrganicSolvents: { en: totalResidualOrganicSolvents.trim() },
          aflatoxins: { en: aflatoxins.trim() },
          regulatoryData: { en: regulatoryData.trim() },
          pah4: { en: pah4.trim() },
          benzoypyrene: { en: benzopyrene.trim() },
          possibleCrossContamination: { en: crossContamination.trim() },
          hazardMaterial: hazardous.trim().toLowerCase() === "yes",
          sampleSize: [],
          echa: echa.trim().toLowerCase() === "yes",
          novelFood: novelFood.trim().toLowerCase() === "yes",
          cites: cites.trim().toLowerCase() === "yes",
          ratioExtract: ratioExtract.trim() ? ratioExtract.trim() : undefined,
          acId: acId.trim() ? acId.trim() : undefined,
          duty: { percentage: dutyPercentage, dumpingFee },
          graduatedPrices: { air: [], sea: [] },
          lastUpdate: new Date(),
          btiRefNo,
          vatPercentage: Number(vatPercentage) || 19,
        };
        categoryObj && commodity.properties.push(categoryObj);
        solvent && commodity.properties.push(solvent);
        analysisMethodObj && commodity.properties.push(analysisMethodObj);
        odorObj && commodity.properties.push(odorObj);
        packagingObj && commodity.properties.push(packagingObj);
        commodity.subtitle.en = generateSubtitle(commodity);
        if (BSON.ObjectId.isValid(id) && this.context.commodity.some((c) => c._id.toString() === id)) {
          const omitPaths = [
            "unit",
            "documents",
            "timeline",
            "sampleSize",
            "images",
            "note",
            "country",
            "suppliers",
            "color",
            "articleNo",
            "disabled",
            "approved",
            "organic",
          ];
          for (const key in fieldsToUpdate) {
            if (key === "grade" && !fieldsToUpdate[key]) {
              omitPaths.push("cosmeticGrade");
              omitPaths.push("feedGrade");
              omitPaths.push("foodGrade");
              omitPaths.push("industrialGrade");
              omitPaths.push("uspGrade");
              omitPaths.push("medicineGrade");
              omitPaths.push("pharmaceuticalGrade");
            } else if (key === "veganVegetarian" && !fieldsToUpdate[key]) {
              omitPaths.push("vegan");
              omitPaths.push("vegetarian");
            } else if (key === "title" && !fieldsToUpdate[key]) {
              omitPaths.push("title");
              omitPaths.push("subtitle");
            } else if (!fieldsToUpdate[key]) omitPaths.push(key);
          }
          // Do not overwrite data that is not handled inside the table and data that was omitted
          const comToUpdate = _.omit(commodity, omitPaths);
          commoditiesUpdated.push(comToUpdate);
        } else commoditiesNew.push(commodity);
      } catch (e) {
        console.error("ERROR IN LINE", i, ":", e);
      }
    }
    this.setState({
      commoditiesNew,
      commoditiesUpdated,
      addedActiveSubstances: commoditiesNew.length > 0 || commoditiesUpdated.length > 0 ? addedActiveSubstances : [],
      addedProps: commoditiesNew.length > 0 || commoditiesUpdated.length > 0 ? addedProps : [],
      processing: false,
      stage: "summary",
    });
  };

  handleClickCancel = () => {
    this.setState(this.getDefaultState());
  };

  handleClickAddToDatabase = async () => {
    const { commoditiesNew, commoditiesUpdated, addedActiveSubstances, addedProps } = this.state;
    this.setState({ processing: true, stage: "parsing" });
    try {
      const res = await insertCommoditiesAndRelatedDocuments(
        addedProps,
        addedActiveSubstances,
        commoditiesNew.map((c) => reduceCommodity(c))
      );
      let resUp = commoditiesUpdated.length === 0; // Set result of updates to true if there is nothing to update
      if (commoditiesUpdated.length > 0)
        resUp = await updateMultipleCommodities(
          commoditiesUpdated.map((c) => {
            let properties = undefined;
            if (c.properties) properties = c.properties.map((p) => p._id.toString());
            let suppliers = undefined;
            if (c.suppliers) {
              suppliers = [];
              for (let i = 0; i < c.suppliers.length; i++) {
                suppliers.push({ ...c.suppliers[i], supplier: c.suppliers[i].supplier._id.toString() });
              }
            }
            let documents = undefined;
            if (c.documents) {
              documents = [];
              for (let i = 0; i < c.documents.length; i++) {
                documents.push({ ...c.documents[i], supplier: c.documents[i].supplier?._id.toString() });
              }
            }
            let activeSubstances = undefined;
            if (c.activeSubstances) {
              activeSubstances = [];
              for (let i = 0; i < c.activeSubstances.length; i++) {
                activeSubstances.push({
                  ...c.activeSubstances[i],
                  substance: c.activeSubstances[i].substance._id.toString(),
                });
              }
            }
            return { ...c, properties, suppliers, documents, activeSubstances };
          })
        );
      if (res && resUp) {
        toast.success(
          `Added ${commoditiesUpdated.length > 0 ? "and updated " : ""}commodities and related documents successfully`
        );
        this.setState({ stage: "success" });
      } else {
        toast.error(
          `Error adding ${commoditiesUpdated.length > 0 ? "and updated " : ""}commodities and related documents`
        );
        this.setState({ stage: "failure" });
      }
    } catch (e) {
      console.error("ERROR WRITING TO DB:", e);
    } finally {
      this.setState({ processing: false });
    }
  };

  handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldsToUpdate = _.cloneDeep(this.state.fieldsToUpdate);
    const { name, checked } = e.target; // Checked is containing the value after the action
    if (name === "all") {
      for (const key in fieldsToUpdate) {
        fieldsToUpdate[key] = checked;
      }
    } else {
      fieldsToUpdate[name] = checked;
    }
    this.setState({ fieldsToUpdate });
  };

  getDefaultState = (): CommodityParsingToolState => {
    return {
      content: [],
      commoditiesNew: [],
      commoditiesUpdated: [],
      addedProps: [],
      addedActiveSubstances: [],
      processing: false,
      separator: ALLOWED_SEPARATORS[0],
      stage: "initial",
      fieldsToUpdate: {
        acId: true,
        title: true,
        duty: true,
        properties: true,
        purity: true,
        specificRotation: true,
        hsCode: true,
        casNumber: true,
        novelFood: true,
        cites: true,
        echa: true,
        hazardMaterial: true,
        botanicalName: true,
        veganVegetarian: true,
        halal: true,
        kosher: true,
        density: true,
        part: true,
        grade: true,
        particleSize: true,
        appearance: true,
        maxAllowedHeavyMetals: true,
        maxAllowedMicrobiology: true,
        maxAllowedWETO: true,
        lossOnDrying: true,
        ash: true,
        activeSubstances: true,
        shelfLife: true,
        limits: true,
        storageConditions: true,
        transportConditions: true,
        totalResidualOrganicSolvents: true,
        aflatoxins: true,
        regulatoryData: true,
        pah4: true,
        benzoypyrene: true,
        possibleCrossContamination: true,
        ratioExtract: true,
        country: true,
        organic: true,
        btiRefNo: true,
      },
    };
  };

  render() {
    const {
      processing,
      content,
      separator,
      commoditiesNew,
      commoditiesUpdated,
      addedActiveSubstances,
      addedProps,
      stage,
      fieldsToUpdate,
    } = this.state;
    const fieldsToUpdateList = Object.keys(fieldsToUpdate).map((key) => {
      return { key, checked: fieldsToUpdate[key] };
    });
    if (!userService.isAdmin())
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Commodity Parsing Tool</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Commodity Parsing Tool</span>
                </h3>
                {["generating", "parsing"].includes(stage) ? (
                  <div className="row">
                    <div className="col text-center m-10">
                      <span className="h3 text-white">Processing Data - Please wait!</span>
                    </div>
                  </div>
                ) : stage === "initial" ? (
                  <>
                    <span className="text-white">Please provide a CSV in the following format:</span>
                    <div className="my-4">
                      <button
                        className="btn btn-outline btn-outline-light"
                        onClick={processing ? undefined : this.handleDownloadCommoditiesCSV}
                        disabled={processing}
                      >
                        Export Commodities CSV
                      </button>
                    </div>
                    <div>
                      <span className="text-white h3">Important Information:</span>
                      <br />
                      <span className="text-white ">- Do NOT use the selected separator inside any fields</span>
                      <br />
                      <span className="text-white ">
                        - Check names of categories, active substances, solvents, analysis methods and odor for typos.
                        Any typo leads to the creation of a new property.
                      </span>
                      <br />
                      <span className="text-white ">- Format for density: XX-YY kg/m3</span>
                      <br />
                      <span className="text-white ">- Format for active substances: AS1,AS2</span>
                      <br />
                      <span className="text-white ">- Format for active substances content: XX%,YY%</span>
                      <br />
                      <span className="text-white ">- Format for shelf life: XX Years</span>
                      <br />
                      <span className="text-white ">
                        - The fields Suitable for vegans/vegetarians, Halal and Kosher should only be filled with "Yes",
                        "No" or "Not Specified"
                      </span>
                      <br />
                      <span className="text-white ">
                        - Commodities are created as not approved - they need to be approved afterwards
                      </span>
                      <br />
                    </div>
                    <div className="border-bottom-dark-gray mt-4" />
                    <div className="my-4">
                      <span className="text-white">
                        Please provide a CSV which fits the specified format and upload it - you can still check your
                        input before any database operation is performed.
                      </span>
                      <div className="my-4 row">
                        <div className="col-4">
                          <button
                            className="btn btn-outline btn-outline-light"
                            type="button"
                            disabled={processing}
                            onClick={() => this.selectFileRef.current?.click()}
                          >
                            Upload Commodity CSV
                          </button>
                          <input
                            type="file"
                            ref={this.selectFileRef}
                            accept="text/csv"
                            style={{ display: "none" }}
                            onChange={processing ? undefined : this.handleParseCommodityCSV}
                          />
                        </div>
                        <div className="col-6 text-right align-self-center">
                          <span className="text-white">Separator:</span>
                        </div>
                        <div className="col-2">
                          <CustomSelect
                            options={ALLOWED_SEPARATORS}
                            onChange={this.handleChangeSeparator}
                            value={separator}
                          />
                        </div>
                      </div>
                      <div className="fs-4 mt-6">
                        Values to update (not applied to commodities that are newly added)
                      </div>
                      <div className="table-responsive mt-5">
                        <table className="table align-middle gs-0 gy-1 ">
                          <thead>
                            <tr className="fw-bolder text-muted">
                              <th className="border-bottom-0">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="all"
                                    checked={fieldsToUpdateList.every((f) => f.checked)}
                                    onChange={this.handleCheck}
                                  />
                                </div>
                              </th>
                              <th className="border-bottom-0">Field</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fieldsToUpdateList.map((f) => (
                              <tr key={f.key}>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={f.key}
                                      checked={f.checked}
                                      onChange={this.handleCheck}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <span className="text-white">{f.key}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : stage === "summary" ? (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        {content.length - 1} Lines were provided
                        <br />
                        {commoditiesNew.length} new Commodities were parsable
                        <br />
                        {commoditiesUpdated.length} updated Commodities were parsable
                        <br />
                        {addedProps.length} Properties need to be added
                        <br />
                        {addedActiveSubstances.length} Active Substances need to be added
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right ml-2"
                        onClick={this.handleClickAddToDatabase}
                      >
                        Add to Database
                      </button>
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : stage === "success" ? (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        {commoditiesNew.length} new Commodities were added
                        <br />
                        {commoditiesUpdated.length} Commodities were updated
                        <br />
                        {addedProps.length} Properties were added
                        <br />
                        {addedActiveSubstances.length} Active Substances were added
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 m-10">
                      <span className="h3 text-white">
                        Updating commodities failed. Please contact the IT-Department.
                      </span>
                    </div>
                    <div className="border-bottom-dark-gray" />
                    <div className="col-12 mt-4">
                      <button
                        className="btn btn-outline btn-outline-light float-right"
                        onClick={this.handleClickCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityParsingTool;
