import { BSON } from "realm-web";
import { Address } from "./commonTypes";
import { PaletteData } from "./configuration/calculationConfiguration.types";
import { CommodityTimelineEntry, TimelineEntrySupplierPrices } from "./commodity.types";
import { FinishedProductTimelineEntry } from "./finishedProduct.types";
import { UserData } from "./userData.types";

// timeline types
export const T_S_ADDPACKAGINGDIMENSIONS = "addPackagingDimensions";
export const T_S_UPDATEPACKAGINGDIMENSIONS = "updatePackagingDimensions";
export const T_S_ASSIGNPACKAGINGDIMENSION = "assignPackagingDimensions";
export const T_S_UPLOADSUPPLIERFILE = "uploadSupplierFile";
export const T_S_DELETESUPPLIERFILE = "deleteSupplierFile"; //
export const T_S_ADDUSERTOSUPPLIER = "addUserToSupplier"; // If contact was added (Create Additional Contacts)
export const T_S_ADDCOMMODITYTOSUPPLIER = "addCommodityToSupplier";
export const T_S_ADDFINISHEDPRODUCTSUPPLIER = "addFinishedProductToSupplier";
export const T_S_UPDATESUPPLIERSTATE = "updateSuppleirState"; // enable / disable supplier
export const T_S_UPDATESUPPLIERINFORMATION = "updateSupplierInformation";
export const T_S_UPDATECOMMODITYPRICE = "updateCommodityPrice"; // update prices of offered commodity
export const T_S_UPDATEFINISHEDPRODUCTPRICE = "updateFinishedProductPrice";
export const T_S_UPDATEEUSTOCKPRICE = "updateEUStockPrice";

export type S_TIMELINETYPE =
  | typeof T_S_ADDPACKAGINGDIMENSIONS
  | typeof T_S_UPDATEPACKAGINGDIMENSIONS
  | typeof T_S_ASSIGNPACKAGINGDIMENSION
  | typeof T_S_UPLOADSUPPLIERFILE
  | typeof T_S_DELETESUPPLIERFILE
  | typeof T_S_ADDUSERTOSUPPLIER
  | typeof T_S_ADDCOMMODITYTOSUPPLIER
  | typeof T_S_ADDFINISHEDPRODUCTSUPPLIER
  | typeof T_S_UPDATESUPPLIERSTATE
  | typeof T_S_UPDATESUPPLIERINFORMATION
  | typeof T_S_UPDATECOMMODITYPRICE
  | typeof T_S_UPDATEFINISHEDPRODUCTPRICE
  | typeof T_S_UPDATEEUSTOCKPRICE;

export const S_TIMELINETYPE_VALUES = [
  T_S_ADDPACKAGINGDIMENSIONS,
  T_S_UPDATEPACKAGINGDIMENSIONS,
  T_S_ASSIGNPACKAGINGDIMENSION,
  T_S_UPLOADSUPPLIERFILE,
  T_S_DELETESUPPLIERFILE,
  T_S_ADDUSERTOSUPPLIER,
  T_S_ADDCOMMODITYTOSUPPLIER,
  T_S_ADDFINISHEDPRODUCTSUPPLIER,
  T_S_UPDATESUPPLIERSTATE,
  T_S_UPDATESUPPLIERINFORMATION,
  T_S_UPDATECOMMODITYPRICE,
  T_S_UPDATEFINISHEDPRODUCTPRICE,
  T_S_UPDATEEUSTOCKPRICE,
];

export interface Supplier {
  _id: BSON.ObjectId;
  name: string; // Supplier name
  internalContact: string; // OID as string - Internal user that is the contact of the supplier
  rating: number;
  currency: string;
  vat: string; // Has to be set!
  mail: string; // Primary mail for supplier (like info@supplier.xyz)
  phone: string; // Primary phone for supplier
  address: Array<Address>;
  primaryPerson: string; // OID as string
  persons: Array<string>; // OID as string
  activated: boolean; // Set to false after self-registration
  disabled: boolean;
  notes: string;
  paymentTerms?: {
    paymentTarget: string;
    paymentTargetConditions?: string;
  };
  seaportReferences: Array<string>;
  airportReferences: Array<string>;
  transport: {
    preparationTime: number;
    land: boolean;
    water: boolean;
    air: boolean;
  };
  documents?: Array<SupplierFile>;
  packagingDimensions?: Array<PackagingDimension>;
  timeline: Array<SupplierTimelineEntry>;
  /** If set this supplier is located inside the EU which leads to different price calculation and transport **/
  euSupplier?: boolean;
}

export interface SupplierExtended extends Omit<Supplier, "internalContact" | "primaryPerson" | "persons"> {
  internalContact: UserData;
  primaryPerson: UserData;
  persons: Array<UserData>;
}

export interface PackagingDimension extends PaletteData {
  _id: BSON.ObjectId;
  description: string;
  commodities: Array<string> | "all";
  finishedProducts?: Array<string> | "all";
  transportTypes?: Array<string>;
}

export interface SupplierFile {
  _id: BSON.ObjectId;
  name: string;
  path: string;
  type: "certificate" | "other";
  customType?: string;
  date: Date;
  validUntil?: Date;
  size: number;
}

export interface SupplierTimelineEntry {
  _id: BSON.ObjectId;
  date: Date;
  type: S_TIMELINETYPE;
  person: string;
  payload?: SupplierTimelineEntryPayload | null;
  pre?: Array<TimelineEntrySupplierPrices>;
  post?: Array<TimelineEntrySupplierPrices>;
}

export interface SupplierTimelineEntryPayload {
  type?: string;
  reference?: string;
  name?: string;
}

export function isSupplierTimelineEntry(
  entry: SupplierTimelineEntry | CommodityTimelineEntry | FinishedProductTimelineEntry
): entry is SupplierTimelineEntry {
  return S_TIMELINETYPE_VALUES.includes(entry.type as S_TIMELINETYPE);
}
