import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextCustomer } from "../../../../context/dataContext";
import SampleOrderPage from "../../common/sampleOrder/SampleOrderPage";

interface SampleOrderWrapperParams {
  id: string;
}

interface SampleOrderWrapperProps extends RouteComponentProps<SampleOrderWrapperParams> {}

const SampleOrderWrapper: React.FunctionComponent<SampleOrderWrapperProps> = (props) => {
  const context = useContext(DataContextCustomer);
  return <SampleOrderPage context={context} {...props} />;
};

export default SampleOrderWrapper;
