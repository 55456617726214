import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomerSampleOrderExtended } from "../../../../../model/customer/customerSampleOrder.types";
import { SAMO_CANCELED, SAMO_REQUESTED, SAMO_T_CANCELED } from "../../../../../model/sampleOrder.types";
import { getOrderNumber } from "../../../../../utils/orderUtils";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../../../../utils/sampleOrderUtils";

interface CancelSampleOrderModalProps {
  order: CustomerSampleOrderExtended;
}

interface CancelSampleOrderModalState {
  show: boolean;
  saving: boolean;
}

class CancelSampleOrderModal extends PureComponent<CancelSampleOrderModalProps, CancelSampleOrderModalState> {
  constructor(props: CancelSampleOrderModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });

  handleCancelSampleOrder = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const res = await updateSampleOrder(
        { state: SAMO_CANCELED },
        order._id,
        getSampleOrderTimelineEntry(SAMO_T_CANCELED)
      );
      if (res && res.res && res.res.modifiedCount > 0) {
        toast.success("Sample request canceled successfully");
        this.setState({ show: false });
      } else {
        toast.error("Error canceling sample request");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean): CancelSampleOrderModalState => {
    return { show, saving: false };
  };

  render() {
    const { order } = this.props;
    const { show, saving } = this.state;

    return (
      <>
        <button
          type="button"
          disabled={order.state !== SAMO_REQUESTED}
          onClick={this.handleShow}
          className={"btn btn-outline btn-outline-white ml-2 " + (order.state !== SAMO_REQUESTED && "disabled")}
        >
          Cancel
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {`Cancel Sample Order ${getOrderNumber(order)}`}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="text-white fs-6 mt-10 mb-5">
              <div>
                Are you sure you want to cancel the sample request for{" "}
                <em>
                  {order.amount}
                  {order.unit}
                </em>{" "}
                of <em>{order.commodity.title.en}</em>?
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-sm btn-text-white " + (saving && "disabled")}
              disabled={saving}
              onClick={saving ? undefined : this.handleHide}
            >
              Close
            </button>
            <ErrorOverlayButton
              errors={[]}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Confirm"
              onClick={this.handleCancelSampleOrder}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CancelSampleOrderModal;
