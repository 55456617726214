import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import countryList from "i18n-iso-countries";
import { CO_CANCELED, CO_REQUESTEDBYCUSTOMER, CustomerOrderExtended } from "../../../model/customerOrder.types";
import { formatCurrency, formatDate, getDocFromCollection } from "../../../utils/baseUtils";
import {
  calculateArrivalInformation,
  ChangeDateAction,
  DateType,
  getForwarderFromOrder,
  getOrderNumber,
  getOrderStateDescriptions,
  getOrderType,
} from "../../../utils/orderUtils";
import { getUserName, INTERNAL } from "../../../utils/userUtils";
import userService from "../../../services/userService";
import { CustomerCustomerOrderExtended } from "../../../model/customer/customerCustomerOrder.types";
import OpenCommodityIcon from "../../common/OpenCommodityIcon";
import { DataContextCustomerType, DataContextInternalType } from "../../../context/dataContext";
import { getCW } from "../../../utils/dateUtils";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import { formatAddress } from "../../../utils/addressUtils";
import UpdateCommoditySnapshotModal from "../../common/internal/modals/UpdateCommoditySnapshotModal";
import ChangeEtaModal from "../internal/supplierOrder/modals/ChangeETAModal";
import { CustomerContract } from "../../../model/customerContract.types";
import CancelCustomerOrderModal from "../internal/customerOrder/modals/CancelCustomerOrderModal";
import ChangeCustomerReferenceModal from "../internal/customerOrder/modals/ChangeCustomerReferenceModal";
import ChangeDestinationAddressModal from "../internal/customerOrder/modals/ChangeDestinationAddressModal";
import { formatDateFromType } from "../../../utils/customerOrderUtils";
import { isActive } from "../../../utils/customerOrderUtils";

interface CustomerOrderSummaryProps {
  order: CustomerOrderExtended | CustomerCustomerOrderExtended;
  supplierOrder?: SupplierOrder;
  contract?: CustomerContract;
  context: DataContextInternalType | DataContextCustomerType;
}

class CustomerOrderSummary extends PureComponent<CustomerOrderSummaryProps> {
  render() {
    const { order, context, supplierOrder, contract } = this.props;
    const view = userService.getUserType();
    const stateDescription = getOrderStateDescriptions(order);
    const arrival = calculateArrivalInformation(order, order.changedETA);
    const splitOrders = order.splitOrders
      ? order.splitOrders.map((spO) => getDocFromCollection(context.customerOrder, spO)!)
      : [];
    let changedEtaCW;
    if (order.changedETA) {
      changedEtaCW = getCW(order.changedETA);
    }
    const targetDateCW = getCW(order.targetDate);
    const country =
      typeof order.destination === "string"
        ? order.company.address.find((a) => formatAddress(a) === order.destination)?.country
        : order.company.address.find((a) => a === order.destination)?.country;
    const fullCountry = country
      ? countryList.getName(country, "en", {
          select: "alias",
        })
      : "";
    const active = isActive(order);
    const forwarder = getForwarderFromOrder(context, order);
    return (
      <div className="card mb-5 mb-xl-8 bg-white">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <span className="fs-2 text-gray-800 fw-bolder mb-3">{getOrderNumber(order)}</span>
            {view === INTERNAL && <span className="badge badge-pill badge-success mb-3">Customer Order</span>}
            <div className="fs-6 fw-bold text-muted ">
              {order.commodity.title.en}
              <OpenCommodityIcon article={order.commodity} />
            </div>
            {view === INTERNAL && <div className="fw-bold text-muted mb-3">{order.commodity.subtitle.en}</div>}
            {order.state === CO_REQUESTEDBYCUSTOMER ? (
              <div className="mb-6 mt-3">
                <span className="badge fw-bolder badge-lg my-2 badge-warning">Requested</span>
              </div>
            ) : order.state === CO_CANCELED ? (
              <div className="mb-6 mt-3">
                <span className="badge fw-bolder badge-lg my-2 badge-danger">Canceled</span>
              </div>
            ) : (
              <div className="mb-6 mt-3">
                <span
                  className="badge fw-bolder badge-lg my-2"
                  style={{ backgroundColor: arrival.late && arrival.target ? "#f15151" : "#50cc89ff" }}
                >
                  {arrival.target ? (
                    <span className="mb-1">
                      {arrival.weekDiff} {arrival.weekDiff === 1 ? "week" : "weeks"}{" "}
                      {arrival.late ? "overdue" : "remaining"}
                    </span>
                  ) : (
                    <span className="mb-1">delivered</span>
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div className="fw-bolder">Order Details</div>
          </div>
          <div className="border-bottom-dark-gray" />
          <div className="pb-5 fs-6">
            <div className="fw-bolder mt-5">Amount</div>
            <div className="text-muted">
              {order.amount} {order.unit === "1000 pcs" ? `x ${order.unit}` : order.unit}
            </div>
            <div className="fw-bolder mt-5">Type</div>
            <div className="text-muted">{getOrderType(order)}</div>
            {order.supplier && (
              <>
                <div className="fw-bolder mt-5">Supplier</div>
                <div className="text-muted">{order.supplier.name}</div>
              </>
            )}
            {order.trackingInformation && (
              <>
                <div className="fw-bolder mt-5">Tracking</div>
                <a
                  href={order.trackingInformation.trackingLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white fw-bold custom-link"
                >{`${order.trackingInformation.deliveryCompany}-${order.trackingInformation.trackingNumber}`}</a>
              </>
            )}
            <div className="fw-bolder mt-5">Status</div>
            <div className={order.state === CO_CANCELED ? "text-danger" : "text-warning"}>{stateDescription.title}</div>
            <div className="fw-bolder mt-5">
              {arrival.target ? (arrival.dateIsCW ? "Target Week (ETA)" : "Target Date (ETA)") : "Delivery Date"}
              {active && view === INTERNAL && arrival.target && (
                <ChangeEtaModal order={order as CustomerOrderExtended} action={ChangeDateAction.CHANGEETA} />
              )}
            </div>
            <div
              className={
                changedEtaCW && changedEtaCW !== targetDateCW && changedEtaCW < getCW(new Date())
                  ? "text-danger"
                  : order.changedETA && changedEtaCW !== targetDateCW
                  ? "text-warning"
                  : "text-muted"
              }
            >
              {arrival.dateIsCW
                ? `CW ${arrival.cw}-${arrival.year}`
                : arrival.dateIsFix
                ? `${formatDate(arrival.arrivalDate)} (fixed)`
                : `${formatDate(arrival.arrivalDate)}`}
            </div>
            <div className="fw-bolder mt-5">
              Original Target {order.targetDateType === DateType.CW ? "Week" : "Date"}
              {view === INTERNAL && (
                <ChangeEtaModal order={order as CustomerOrderExtended} action={ChangeDateAction.CHANGETARGETWEEK} />
              )}
            </div>
            <div className="text-muted">{formatDateFromType(order.targetDate, order.targetDateType)}</div>
            <div className="fw-bolder mt-5">Last Update</div>
            <div className="text-muted">
              {formatDate(order.timeline.length > 0 ? order.timeline[order.timeline.length - 1].date : order.createdAt)}
            </div>
            {view === INTERNAL && (
              <>
                <div className="fw-bolder mt-5">Customer</div>
                <div>
                  <Link className="text-muted custom-link" to={"/customer/" + order.company._id.toString()}>
                    {order.company.name}
                  </Link>
                </div>
              </>
            )}
            <div className="fw-bolder mt-5">{view === INTERNAL ? "Turnover" : "Total Price"}</div>
            <div className="text-muted">
              {formatCurrency(order.totalPrice * (1 - (order.discount || 0) / 100), order.currency)}
            </div>
            {order.terms && (
              <>
                <div className="fw-bolder mt-5">Payment Terms</div>
                <div className="text-muted">
                  {order.terms.paymentTerms} {order.terms.paymentTermConditions}
                </div>
                <div className="fw-bolder mt-5">Delivery Terms</div>
                <div className="text-muted">
                  {order.terms.deliveryTerms} {order.terms.deliveryCity}
                </div>
              </>
            )}
            {forwarder && (
              <>
                <div className="fw-bolder mt-5">Forwarder</div>
                <div>
                  <Link className="text-muted custom-link" to={"/forwarder/" + forwarder._id.toString()}>
                    {forwarder.name}
                  </Link>
                </div>
              </>
            )}
            <div className="fw-bolder mt-5">Created by</div>
            <div className="text-muted">{getUserName(order.person)}</div>
            <div className="fw-bolder mt-5">
              Reference number
              {active && view === INTERNAL && <ChangeCustomerReferenceModal order={order as CustomerOrderExtended} />}
            </div>
            <div className="text-muted">{order.customerReference ? order.customerReference : "-"}</div>
            <div className="fw-bolder mt-5">
              Destination Address
              {active && view === INTERNAL && <ChangeDestinationAddressModal order={order as CustomerOrderExtended} />}
            </div>
            <div className="text-muted">
              {typeof order.destination === "string" ? order.destination : formatAddress(order.destination)}
              <br />
              {fullCountry}
            </div>
            {splitOrders.length > 0 && (
              <>
                <div className="fw-bolder mt-5">Related Orders</div>
                {splitOrders.map((spO) => (
                  <div key={spO._id.toString()}>
                    <Link className="text-muted fs-6 custom-link" to={`/customerOrder/${spO._id.toString()}`}>
                      {getOrderNumber(spO)}
                    </Link>
                  </div>
                ))}
              </>
            )}
            {active && view === INTERNAL && (
              <>
                <UpdateCommoditySnapshotModal
                  order={order as CustomerOrderExtended}
                  context={context as DataContextInternalType}
                />
                <br />
                <CancelCustomerOrderModal
                  order={order as CustomerOrderExtended}
                  context={context as DataContextInternalType}
                  supplierOrder={supplierOrder}
                  contract={contract}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerOrderSummary;
