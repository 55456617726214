import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { Input } from "../../common/Input";
import { DataContextInternalType, DataContextSupplierType } from "../../../context/dataContext";
import { CommodityExtended, ReferencePrice } from "../../../model/commodity.types";
import { pluralize, round } from "../../../utils/baseUtils";
import { BASE_CURRENCY, SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import { C_UNITS, updateCommodity } from "../../../utils/commodityUtils";

interface CommodityReferencePricesProps {
  context: DataContextInternalType | DataContextSupplierType;
  commodity: CommodityExtended;
}

interface CommodityReferencePricesState {
  referencePrices: Array<ReferencePrice>;
  saving: boolean;
}

class CommodityReferencePrices extends PureComponent<CommodityReferencePricesProps, CommodityReferencePricesState> {
  constructor(props: CommodityReferencePricesProps) {
    super(props);
    this.state = { referencePrices: this.prepareReferencePrices(props), saving: false };
  }

  componentDidUpdate(prevProps: Readonly<CommodityReferencePricesProps>) {
    if (!_.isEqual(prevProps.commodity.referencePrices, this.props.commodity.referencePrices)) {
      this.setState({ referencePrices: this.prepareReferencePrices(this.props) });
    }
  }

  handleAddReferencePrice = () => {
    const referencePrices = _.cloneDeep(this.state.referencePrices);
    referencePrices.push({ moq: 0, price: 0, fromAc: false });
    this.setState({ referencePrices });
  };

  handleChangeReferencePrice = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const referencePrices = _.cloneDeep(this.state.referencePrices);
    const name = e.target.name;
    // @ts-ignore
    referencePrices[idx][name] = Number(e.target.value);
    this.setState({ referencePrices });
  };

  handleRemoveReferencePrice = (idx: number) => {
    const referencePrices = _.cloneDeep(this.state.referencePrices);
    referencePrices.splice(idx, 1);
    this.setState({ referencePrices });
  };

  handleSaveReferencePrices = async () => {
    const { commodity } = this.props;
    const referencePrices = _.cloneDeep(this.state.referencePrices).filter((rp) => rp.price > 0 && rp.moq >= 0);
    this.setState({ saving: true });
    try {
      const result = await updateCommodity({ referencePrices }, commodity._id);
      if (result && result.modifiedCount > 0) {
        toast.success("Reference prices successfully updated");
      } else {
        toast.error("Error updating reference prices");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  prepareReferencePrices = (props: CommodityReferencePricesProps) => {
    const referencePrices = _.cloneDeep(props.commodity.referencePrices);
    return referencePrices ? _.orderBy(referencePrices, "moq") : [];
  };

  render() {
    const { commodity } = this.props;
    const { referencePrices, saving } = this.state;

    return (
      <div className="card bg-white mt-5">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Reference Prices</span>
            <span className="text-muted fw-bold fs-7">{pluralize(referencePrices.length, "Price")}</span>
          </h3>
        </div>
        <div className="card-body p-4 pt-8">
          <div className="bg-light2 rounded p-5 mb-7">
            <div className="table-responsive mt-5">
              <table className="table align-middle gs-0 gy-1 ">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="border-bottom-0">MOQ</th>
                    <th className="border-bottom-0">Price</th>
                    <th className="border-bottom-0">from AC</th>
                    <th className="border-bottom-0 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {referencePrices.map((price, idx) => (
                    <tr key={idx}>
                      <td className="align-middle">
                        <div className="input-group">
                          <Input
                            type="number"
                            value={price.moq}
                            disabled={price.fromAc}
                            className={
                              "form-control custom-form-control pt-0 pb-0 bg-dark " + (price.fromAc && "disabled")
                            }
                            name="moq"
                            min={0}
                            onBlur={price.fromAc ? undefined : (e) => this.handleChangeReferencePrice(e, idx)}
                          />
                          <div className="input-group-append rounded-end">
                            <select
                              className="form-control custom-form-control bg-dark pt-0 pb-0"
                              disabled={true}
                              value={commodity.unit}
                            >
                              {C_UNITS.map((u) => (
                                <option key={u} value={u}>
                                  {u}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="input-group">
                          <Input
                            type="number"
                            value={round(price.price, 2)}
                            disabled={price.fromAc}
                            className={"form-control custom-form-control py-0 bg-dark " + (price.fromAc && "disabled")}
                            name="price"
                            min={0}
                            onBlur={price.fromAc ? undefined : (e) => this.handleChangeReferencePrice(e, idx)}
                          />
                          <div
                            className="input-group-append rounded-end"
                            style={price.fromAc ? { opacity: 0.7 } : undefined}
                          >
                            <select
                              className="form-control custom-form-control bg-dark py-0"
                              name="currency"
                              value={BASE_CURRENCY}
                              disabled={true}
                            >
                              {SUPPORTED_CURRENCIES.map((c) => (
                                <option key={c} value={c}>
                                  {c}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <span className="text-white text-nowrap">{price.fromAc ? "Yes" : "No"}</span>
                      </td>
                      <td className="align-middle text-center">
                        <button
                          className={"btn btn-text-danger btn-sm p-0 " + (saving && "disabled")}
                          onClick={saving ? undefined : () => this.handleRemoveReferencePrice(idx)}
                          disabled={saving}
                        >
                          <i className="fa fa-trash text-danger" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="align-middle" colSpan={3} />
                    <td className="align-middle text-center">
                      <button
                        className={"btn btn-text btn-sm p-0 " + (saving && "disabled")}
                        onClick={saving ? undefined : this.handleAddReferencePrice}
                        disabled={saving}
                      >
                        <i className="fa fa-plus text-success" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                className={"btn btn-text-success btn-sm float-right " + (saving && "disabled")}
                onClick={saving ? undefined : this.handleSaveReferencePrices}
                disabled={saving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityReferencePrices;
