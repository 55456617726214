import _ from "lodash";
import React, { useContext, useMemo, useState } from "react";
import Search from "../../../common/Search";
import Tooltip from "../../../common/Tooltip";
import CostTile from "./CostTile";
import { DataContextInternal } from "../../../../context/dataContext";
import { SO_CANCELED, SupplierOrderExtended } from "../../../../model/supplierOrder.types";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { extendSupplierOrder } from "../../../../utils/dataTransformationUtils";
import { doFuseSearch, formatCurrency, formatDate, pluralize } from "../../../../utils/baseUtils";
import { getOrderNumber, getOrderStateDescriptions, getOrderTypeName } from "../../../../utils/orderUtils";
import AdjustCalculationModal from "./AdjustCalculationModal";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { convertCurrency, EURO } from "../../../../utils/currencyUtils";
import { getCalculationDelta } from "../../../../utils/controllingDashboardUtils";
import { ADJUSTABLE_CALCULATION_VALUES } from "../../../../utils/supplierOrderUtils";

interface OrderRentabilityCardState {
  visualizeDelta: boolean;
  orderBy: SelectOption;
  search: string;
  selectedOrders: Array<string>;
}

interface ModalState {
  order?: SupplierOrderExtended;
  field?: ADJUSTABLE_CALCULATION_VALUES;
}

const SortByOptions: Array<SelectOption> = [
  { value: "createdAt", label: "Date" },
  { value: "totalTurnover", label: "Turnover" },
  { value: "totalMargin", label: "Margin" },
];

const getDefaultState = (): OrderRentabilityCardState => {
  return { visualizeDelta: false, search: "", orderBy: SortByOptions[0], selectedOrders: [] };
};

const OrderRentabilityCard: React.FC = () => {
  const context = useContext(DataContextInternal);

  const [state, setState] = useState<OrderRentabilityCardState>(getDefaultState());
  const [modalState, setModalState] = useState<ModalState>({});
  const { visualizeDelta, orderBy, search, selectedOrders } = state;

  const handleCloseModal = () => {
    setModalState({ order: undefined, field: undefined });
  };

  const handleToggleSelectedOrder = (id: string) => {
    setState((prevState) => {
      const selectedOrders = Array.from(prevState.selectedOrders);
      const idx = selectedOrders.indexOf(id);
      if (idx === -1) selectedOrders.push(id);
      else selectedOrders.splice(idx, 1);
      return { ...prevState, selectedOrders };
    });
  };

  const handleToggleSelectAllOrders = () => {
    setState((prevState) => {
      let selectedOrders: Array<string> = [];
      if (prevState.selectedOrders.length !== relevantOrders.length) {
        selectedOrders = relevantOrders.map((rO) => rO._id.toString());
      }
      return { ...prevState, selectedOrders };
    });
  };

  const handleTileClick = (order: SupplierOrderExtended, field: ADJUSTABLE_CALCULATION_VALUES) => {
    setModalState({ order, field });
  };

  const handleChangeOrderBy = (e: SelectOption) => {
    setState((prevState) => ({ ...prevState, orderBy: e, selectedOrders: [] }));
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value, selectedOrders: [] }));
  };

  const toggleVisualizeData = () => {
    setState((prevState) => ({ ...prevState, visualizeDelta: !visualizeDelta }));
  };

  const relevantOrders = useMemo(() => {
    let filteredOrders = search.trim()
      ? doFuseSearch(context.supplierOrder, search, [
          "orderNo",
          "commodity.title.en",
          "customerReference",
          "state",
          "supplier.name",
          "company.name",
        ])
      : context.supplierOrder;
    filteredOrders = _.orderBy(
      filteredOrders.filter((sO) => sO.state !== SO_CANCELED),
      [orderBy.value],
      ["desc"]
    ).slice(0, 16);
    return filteredOrders.map((fO) => extendSupplierOrder(fO, context));
  }, [search, orderBy, context.supplierOrder]);

  const {
    totalTurnover,
    totalPriceCommodities,
    totalPriceTransport,
    totalPriceInsurance,
    totalPriceCustoms,
    totalWarehouseCost,
    totalB2BFollowUpCost,
    totalMargin,
  } = useMemo(() => {
    let totalTurnover = 0;
    let totalPriceCommodities = 0;
    let totalPriceTransport = 0;
    let totalPriceInsurance = 0;
    let totalPriceCustoms = 0;
    let totalWarehouseCost = 0;
    let totalB2BFollowUpCost = 0;
    let totalMargin = 0;
    // Retrieve the values and convert them to euro
    for (let i = 0; i < relevantOrders.length; i++) {
      const rO = relevantOrders[i];
      totalTurnover += convertCurrency(rO.totalTurnover, rO.currency, EURO, rO.exchangeRates ?? context.currencies);
      totalPriceCommodities += convertCurrency(
        rO.priceCommodities,
        rO.currency,
        EURO,
        rO.exchangeRates ?? context.currencies
      );
      totalPriceTransport += convertCurrency(
        rO.priceTransport,
        rO.currency,
        EURO,
        rO.exchangeRates ?? context.currencies
      );
      if (rO.calculationDetails) {
        totalPriceInsurance += convertCurrency(
          "totalInsuranceCost" in rO.calculationDetails.details.finalValues
            ? rO.calculationDetails.details.finalValues.totalInsuranceCost || 0
            : 0,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        totalWarehouseCost += convertCurrency(
          rO.calculationDetails.details.finalValues.totalWarehouseCost || 0,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        totalB2BFollowUpCost += convertCurrency(
          rO.calculationDetails.details.finalValues.totalB2bFollowUpCost,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
      }
      totalPriceCustoms += convertCurrency(rO.priceCustoms, rO.currency, EURO, rO.exchangeRates ?? context.currencies);
      totalMargin += convertCurrency(rO.totalMargin, rO.currency, EURO, rO.exchangeRates ?? context.currencies);
    }
    return {
      totalTurnover,
      totalPriceCommodities,
      totalPriceTransport,
      totalPriceInsurance,
      totalPriceCustoms,
      totalWarehouseCost,
      totalB2BFollowUpCost,
      totalMargin,
    };
  }, [relevantOrders]);

  const {
    selectedTurnover,
    selectedPriceCommodities,
    selectedPriceTransport,
    selectedPriceInsurance,
    selectedPriceCustoms,
    selectedWarehouseCost,
    selectedB2BFollowUpCost,
    selectedMargin,
  } = useMemo(() => {
    let selectedTurnover = 0;
    let selectedPriceCommodities = 0;
    let selectedPriceTransport = 0;
    let selectedPriceInsurance = 0;
    let selectedPriceCustoms = 0;
    let selectedWarehouseCost = 0;
    let selectedB2BFollowUpCost = 0;
    let selectedMargin = 0;
    for (let i = 0; i < relevantOrders.length; i++) {
      const rO = relevantOrders[i];
      if (selectedOrders.includes(rO._id.toString())) {
        selectedTurnover += convertCurrency(
          rO.totalTurnover,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        selectedPriceCommodities += convertCurrency(
          rO.priceCommodities,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        selectedPriceTransport += convertCurrency(
          rO.priceTransport,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        if (rO.calculationDetails) {
          selectedPriceInsurance += convertCurrency(
            "totalInsuranceCost" in rO.calculationDetails.details.finalValues
              ? rO.calculationDetails.details.finalValues.totalInsuranceCost || 0
              : 0,
            rO.currency,
            EURO,
            rO.exchangeRates ?? context.currencies
          );
          selectedWarehouseCost += convertCurrency(
            rO.calculationDetails.details.finalValues.totalWarehouseCost || 0,
            rO.currency,
            EURO,
            rO.exchangeRates ?? context.currencies
          );
          selectedB2BFollowUpCost += convertCurrency(
            rO.calculationDetails.details.finalValues.totalB2bFollowUpCost,
            rO.currency,
            EURO,
            rO.exchangeRates ?? context.currencies
          );
        }
        selectedPriceCustoms += convertCurrency(
          rO.priceCustoms,
          rO.currency,
          EURO,
          rO.exchangeRates ?? context.currencies
        );
        selectedMargin += convertCurrency(rO.totalMargin, rO.currency, EURO, rO.exchangeRates ?? context.currencies);
      }
    }
    return {
      selectedTurnover,
      selectedPriceCommodities,
      selectedPriceTransport,
      selectedPriceInsurance,
      selectedPriceCustoms,
      selectedWarehouseCost,
      selectedB2BFollowUpCost,
      selectedMargin,
    };
  }, [relevantOrders, selectedOrders]);

  return (
    <>
      <AdjustCalculationModal onCloseModal={handleCloseModal} order={modalState.order} field={modalState.field} />
      <div className="card bg-white rounded mb-3">
        <div className="card-header border-0">
          <div className="card-title d-flex justify-content-between align-items-center w-100">
            <h2 className="mb-0">Order Rentability</h2>
            <label className="form-check form-switch form-check-custom form-check-solid ml-auto mr-3">
              <small className="form-check-label fw-bold text-muted mr-1">Visualize Delta</small>
              <input
                className="form-check-input position-static"
                type="checkbox"
                checked={visualizeDelta}
                onChange={toggleVisualizeData}
                style={{ height: 20, width: 40 }}
              />
            </label>
            <div style={{ width: "150px" }}>
              <CustomSelect
                additionalClasses="mr-2"
                options={SortByOptions}
                onChange={handleChangeOrderBy}
                value={orderBy}
                matchFormControl={true}
              />
            </div>
            <div style={{ width: 250 }}>
              <Search
                onSearch={handleChangeSearch}
                placeholder="Search..."
                inputClasses="form-control custom-form-control"
                value={search}
              />
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <table className="table text-white table-row-dashed data-table" style={{ borderTop: "3px solid #131315" }}>
            <tbody className="table-row-extra-small-padding border-top-0">
              <tr className="w-100 font-weight-bold" style={{ backgroundColor: "#2a2a2a" }}>
                <td>
                  <div className="form-check form-check-xs form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      style={{ backgroundColor: "#444446" }}
                      type="checkbox"
                      onChange={handleToggleSelectAllOrders}
                      checked={relevantOrders.length > 0 && relevantOrders.length === selectedOrders.length}
                    />
                  </div>
                </td>
                <td>Order</td>
                <td className="d-none d-xl-table-cell">Type</td>
                <td>Turnover</td>
                <td>Articles</td>
                <td>Transport</td>
                <td>Insurance</td>
                <td>Customs</td>
                <td>Warehouse</td>
                <td>Followup</td>
                <td>Margin</td>
                <td>Delta</td>
              </tr>
              {relevantOrders.map((sO) => (
                <OrderRentabilityRow
                  key={sO._id.toString()}
                  supplierOrder={sO}
                  onClickCheckbox={handleToggleSelectedOrder}
                  onTileClick={handleTileClick}
                  selected={selectedOrders.includes(sO._id.toString())}
                  visualizeDelta={visualizeDelta}
                />
              ))}
              {Array(16 - relevantOrders.length)
                .fill("")
                .map((_, idx) => (
                  <tr className="w-100" key={idx}>
                    <td colSpan={12}>&nbsp;</td>
                  </tr>
                ))}
              <tr className="w-100" style={{ backgroundColor: "#212121" }}>
                <td>&nbsp;</td>
                <td>Selection</td>
                <td className="d-none d-xl-table-cell" />
                <td>
                  <span>{formatCurrency(selectedTurnover, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedPriceCommodities, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedPriceTransport, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedPriceInsurance, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedPriceCustoms, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedWarehouseCost, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedB2BFollowUpCost, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(selectedMargin, EURO)}</span>
                </td>
                <td />
              </tr>
              <tr className="w-100" style={{ backgroundColor: "#2a2a2a" }}>
                <td>&nbsp;</td>
                <td>Total</td>
                <td className="d-none d-xl-table-cell" />
                <td>
                  <span>{formatCurrency(totalTurnover, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalPriceCommodities, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalPriceTransport, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalPriceInsurance, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalPriceCustoms, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalWarehouseCost, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalB2BFollowUpCost, EURO)}</span>
                </td>
                <td>
                  <span>{formatCurrency(totalMargin, EURO)}</span>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

interface OrderRentabilityRowProps {
  supplierOrder: SupplierOrderExtended;
  visualizeDelta: boolean;
  selected: boolean;
  onClickCheckbox: (id: string) => void;
  onTileClick: (order: SupplierOrderExtended, field: ADJUSTABLE_CALCULATION_VALUES) => void;
}

const OrderRentabilityRow: React.FC<OrderRentabilityRowProps> = ({
  supplierOrder,
  visualizeDelta,
  selected,
  onTileClick,
  onClickCheckbox,
}) => {
  const context = useContext(DataContextInternal);

  const {
    unit,
    commodity,
    supplier,
    timeline,
    transport,
    totalTurnover,
    priceCommodities,
    priceCustoms,
    priceTransport,
    calculationDetails,
    totalMargin,
    customerOrders,
    currency,
    exchangeRates,
  } = supplierOrder;
  const totalInsuranceCost = useMemo(() => {
    return calculationDetails && "totalInsuranceCost" in calculationDetails.details.finalValues
      ? calculationDetails.details.finalValues.totalInsuranceCost || 0
      : 0;
  }, [calculationDetails?.details.finalValues]);
  const totalWarehouseCost = useMemo(
    () => calculationDetails?.details.finalValues.totalWarehouseCost || 0,
    [calculationDetails?.details.finalValues.totalWarehouseCost]
  );
  const totalB2bFollowUpCost = useMemo(
    () => calculationDetails?.details.finalValues.totalB2bFollowUpCost || 0,
    [calculationDetails?.details.finalValues.totalB2bFollowUpCost]
  );

  const handleClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onClickCheckbox(supplierOrder._id.toString());
  };

  const turnoverConverted = useMemo(() => {
    return convertCurrency(totalTurnover, currency, EURO, exchangeRates ?? context.currencies);
  }, [totalTurnover]);
  const commoditiesConverted = useMemo(() => {
    return convertCurrency(priceCommodities, currency, EURO, exchangeRates ?? context.currencies);
  }, [priceCommodities]);
  const [transportConverted, transportAdjusted] = useMemo(() => {
    const adjustedValue = supplierOrder.calculationDetails?.adjustedCalculation?.totalTransportationCost;
    return [
      convertCurrency(priceTransport, currency, EURO, exchangeRates ?? context.currencies),
      adjustedValue
        ? convertCurrency(
            adjustedValue.reduce((sum, aV) => sum + aV.value, 0),
            currency,
            EURO,
            exchangeRates ?? context.currencies
          )
        : undefined,
    ];
  }, [priceTransport, supplierOrder.calculationDetails?.adjustedCalculation?.totalTransportationCost]);
  const [insuranceConverted, insuranceAdjusted] = useMemo(() => {
    const adjustedValue = supplierOrder.calculationDetails?.adjustedCalculation?.totalInsuranceCost;
    return [
      convertCurrency(totalInsuranceCost, currency, EURO, exchangeRates ?? context.currencies),
      adjustedValue
        ? convertCurrency(
            adjustedValue.reduce((sum, aV) => sum + aV.value, 0),
            currency,
            EURO,
            exchangeRates ?? context.currencies
          )
        : undefined,
    ];
  }, [totalInsuranceCost, supplierOrder.calculationDetails?.adjustedCalculation?.totalInsuranceCost]);
  const [customsConverted, customsAdjusted] = useMemo(() => {
    const adjustedValue = supplierOrder.calculationDetails?.adjustedCalculation?.totalCustomsCost;
    return [
      convertCurrency(priceCustoms, currency, EURO, exchangeRates ?? context.currencies),
      adjustedValue
        ? convertCurrency(
            adjustedValue.reduce((sum, aV) => sum + aV.value, 0),
            currency,
            EURO,
            exchangeRates ?? context.currencies
          )
        : undefined,
    ];
  }, [priceCustoms, supplierOrder.calculationDetails?.adjustedCalculation?.totalCustomsCost]);
  const [warehouseConverted, warehouseAdjusted] = useMemo(() => {
    const adjustedValue = supplierOrder.calculationDetails?.adjustedCalculation?.totalWarehouseCost;
    return [
      convertCurrency(totalWarehouseCost, currency, EURO, exchangeRates ?? context.currencies),
      adjustedValue
        ? convertCurrency(
            adjustedValue.reduce((sum, aV) => sum + aV.value, 0),
            currency,
            EURO,
            exchangeRates ?? context.currencies
          )
        : undefined,
    ];
  }, [totalWarehouseCost, supplierOrder.calculationDetails?.adjustedCalculation?.totalWarehouseCost]);
  const [b2bFollowUpCostConverted, b2bFollowUpAdjusted] = useMemo(() => {
    const adjustedValue = supplierOrder.calculationDetails?.adjustedCalculation?.totalFollowUpCost;
    return [
      convertCurrency(totalB2bFollowUpCost, currency, EURO, exchangeRates ?? context.currencies),
      adjustedValue
        ? convertCurrency(
            adjustedValue.reduce((sum, aV) => sum + aV.value, 0),
            currency,
            EURO,
            exchangeRates ?? context.currencies
          )
        : undefined,
    ];
  }, [totalB2bFollowUpCost, supplierOrder.calculationDetails?.adjustedCalculation?.totalFollowUpCost]);
  const marginConverted = useMemo(() => {
    return convertCurrency(totalMargin, currency, EURO, exchangeRates ?? context.currencies);
  }, [totalMargin]);

  const delta = getCalculationDelta(supplierOrder);

  return (
    <tr className="w-100 cursor-pointer-row">
      <td>
        <div className="form-check form-check-xs form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" onChange={handleClickCheckbox} checked={selected} />
        </div>
      </td>
      <td>
        <Tooltip
          tooltipText={
            <div className="text-white text-left">
              <b>Article: </b>
              {formatArticleUnit(unit, commodity)} {commodity.title.en}
              <br />
              <b>From: </b>
              {supplier.name}
              <br />
              <b>For: </b>
              {pluralize(new Set(customerOrders.map((cO) => cO.company)).size, "Customer")}
              <br />
              <b>Status: </b>
              {getOrderStateDescriptions(supplierOrder).title} ({formatDate(timeline[timeline.length - 1].date)})
            </div>
          }
        >
          <div>{getOrderNumber(supplierOrder)}</div>
        </Tooltip>
      </td>
      <td className="d-none d-xl-table-cell">{getOrderTypeName(transport)}</td>
      <td>
        <span>{formatCurrency(turnoverConverted, EURO)}</span>
      </td>
      <td>
        <span>{formatCurrency(commoditiesConverted, EURO)}</span>
      </td>
      <CostTile
        value={transportConverted}
        adjustedValue={transportAdjusted}
        order={supplierOrder}
        field={ADJUSTABLE_CALCULATION_VALUES.TRANSPORT}
        onClick={onTileClick}
        visualizeDelta={visualizeDelta}
      />
      <CostTile
        value={insuranceConverted}
        adjustedValue={insuranceAdjusted}
        order={supplierOrder}
        field={ADJUSTABLE_CALCULATION_VALUES.INSURANCE}
        onClick={onTileClick}
        visualizeDelta={visualizeDelta}
      />
      <CostTile
        value={customsConverted}
        adjustedValue={customsAdjusted}
        order={supplierOrder}
        field={ADJUSTABLE_CALCULATION_VALUES.CUSTOMS}
        onClick={onTileClick}
        visualizeDelta={visualizeDelta}
      />
      <CostTile
        value={warehouseConverted}
        adjustedValue={warehouseAdjusted}
        order={supplierOrder}
        field={ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE}
        onClick={onTileClick}
        visualizeDelta={visualizeDelta}
      />
      <CostTile
        value={b2bFollowUpCostConverted}
        adjustedValue={b2bFollowUpAdjusted}
        order={supplierOrder}
        field={ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP}
        onClick={onTileClick}
        visualizeDelta={visualizeDelta}
      />
      <td>
        <span>{formatCurrency(marginConverted, EURO)}</span>
      </td>
      <td>
        <span className={delta > 0 ? "text-danger" : delta < 0 ? "text-success" : ""}>{Math.abs(delta)}%</span>
      </td>
    </tr>
  );
};

export default OrderRentabilityCard;
