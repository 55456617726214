import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { CustomerCustomerContractExtended } from "../../../../model/customer/customerCustomerContract.types";
import { CustomerCustomerOrder } from "../../../../model/customer/customerCustomerOrder.types";
import { formatCurrency, formatDate, toAbsoluteUrl, truncateString } from "../../../../utils/baseUtils";
import { getCW, getDaysUntil, getWeeksBetween } from "../../../../utils/dateUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { getContractStateDescriptions } from "../../../../utils/customerContractUtils";
import { collectFilesForContract } from "../../../../utils/contractUtils";
import CustomerContractUpdates from "../CustomerContractUpdates";
import AddReferenceModal from "../../../common/AddReferenceModal";

interface CustomerContractDetailsProps {
  contract: CustomerCustomerContractExtended;
  calls?: Array<CustomerCustomerOrder>;
}

const CustomerContractDetails: React.FunctionComponent<CustomerContractDetailsProps> = ({ contract, calls }) => {
  const { commodity, contractInformation, priceInformation } = contract;
  const { minimumCallQuantity, totalAmount, restAmount } = contractInformation;
  const files = collectFilesForContract(contract);
  const stateDesc = getContractStateDescriptions(contract);
  const series = [totalAmount - restAmount, restAmount];

  const options: ApexOptions = {
    chart: { width: 96, type: "pie" },
    colors: ["#969696", "#343434"],
    labels: ["Already used", "Remaining"],
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, w }) {
        return `<div style="background: #303030; color: #fff; padding: 5px; box-shadow: none">
                <small>${w.globals.labels[seriesIndex]}:  <strong>${series[seriesIndex]}kg</strong></small>
              </div>`;
      },
    },
    dataLabels: { enabled: false },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
  };
  return (
    <>
      <div className="card card-bottom-radius-none bg-white">
        <div className="d-flex flex-column px-9 bg-light2 border-bottom-0">
          <div className="d-flex align-items-center justify-content-between mb-1 w-100">
            <div className="d-flex flex-column  py-4">
              <span className="fs-1 text-gray-800 fw-bolder">Contract RBC-{contract.contractNo}</span>
              <div className="fs-4 text-muted">{contract.commodity.title.en}</div>
            </div>
            <div className="d-flex flex-column">
              <div id="chart">
                <ReactApexChart options={options} series={series} type="pie" width={90} height={90} />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-9 pt-4">
          <div className="d-flex py-3 align-items-center w-100 justify-content-between">
            <div className="me-3">
              <div className="text-white fs-3 fw-bolder">Contract Information</div>
            </div>
          </div>
          <div className="fs-6">
            <div className="d-flex flex-wrap py-5">
              <table className="table fw-bold gy-1">
                <tbody>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Reference</td>
                    <td className="text-muted w-25">
                      {contract.customerReference ? (
                        truncateString(contract.customerReference, 20)
                      ) : (
                        <AddReferenceModal order={contract} />
                      )}
                    </td>
                    <td className="text-white w-25 min-w-125px">Commodity</td>
                    <td className="text-muted w-25">{truncateString(commodity.title.en, 20)}</td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Status</td>
                    <td className="text-warning w-25">{stateDesc.title}</td>
                    <td className="text-white w-25 min-w-125px">Subtitle</td>
                    <td className="text-muted w-25">{truncateString(commodity.subtitle.en, 20) || "-"}</td>
                  </tr>{" "}
                  <tr>
                    <td className="text-white w-25 min-w-125px">Type</td>
                    <td className="text-muted w-25">Variable Calls</td>
                    <td className="text-white w-25 min-w-125px">Supplier</td>
                    <td className="text-muted w-25">{contract.supplier?.name || "Selected by RAWBIDS"}</td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Order Date</td>
                    <td className="text-muted w-25">
                      {formatDate(contract.createdAt) + " (Week " + getCW(contract.createdAt) + ")"}
                    </td>
                    <td className="text-white w-25 min-w-125px">Total Quantity</td>
                    <td className="text-muted w-25">
                      {totalAmount.toLocaleString()} {commodity.unit}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Availability</td>
                    <td className="text-muted w-25">
                      {contract.arrivalDate
                        ? "Available"
                        : contract.targetDate
                        ? "Week " + getCW(contract.targetDate)
                        : "Pending"}
                    </td>
                    <td className="text-white w-25 min-w-125px">Minimum Call-Off Quantity</td>
                    <td className="text-muted w-25">
                      {minimumCallQuantity.toLocaleString()} {commodity.unit}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Period</td>
                    <td className="text-muted w-25">
                      {Math.round(getWeeksBetween(contract.validityPeriod.start, contract.validityPeriod.end) / 4) +
                        " Months"}
                    </td>
                    <td className="text-white w-25 min-w-125px">Price per {commodity.unit}</td>
                    <td className="text-muted w-25">
                      {formatCurrency(priceInformation.totalPrice / totalAmount, priceInformation.currency)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">First Call</td>
                    <td className="text-muted w-25">{formatDate(contract.validityPeriod.start)}</td>
                    <td className="text-white w-25 min-w-125px">Total Price</td>
                    <td className="text-muted w-25">
                      {formatCurrency(priceInformation.totalPrice, priceInformation.currency)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 min-w-125px">Last Call</td>
                    <td className="text-muted w-25">{formatDate(contract.validityPeriod.end)}</td>
                    <td className="text-white w-25 min-w-125px">Note</td>
                    <td className="text-muted w-25">{contract.noteCustomer.trim() ? contract.noteCustomer : "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex py-3 align-items-center w-100">
            <div className="mr-3">
              <div className="text-white fs-5 fw-bolder">Files &amp; Documents</div>
              <div className="text-muted">
                {files.length === 1 ? `1 file` : `${files.length} files`} for this contract
              </div>
            </div>
          </div>
          <div className="card mt-2 bg-light2 rounded">
            <div className="card-body pt-8">
              <div className="d-flex flex-column">
                {files.length === 0 ? (
                  <div className={"d-flex align-items-center mx-auto text-muted"}>No files available</div>
                ) : (
                  files.map((f, idx) => {
                    const days = Math.abs(getDaysUntil(f.date, true));
                    return (
                      <div
                        key={f._id.toString()}
                        className={"d-flex align-items-center " + (idx < files.length - 1 && "mb-5")}
                      >
                        <div className="symbol symbol-30px mr-4">
                          <img alt="pdf" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                        </div>
                        <div className="fw-bold">
                          <a
                            className="fs-6 fw-bolder custom-link"
                            href={resolveFilePath(f.link)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {f.title}
                          </a>
                          <div className="text-gray-400">
                            {days === 0 ? "Today" : days === 1 ? `${days} day ago` : `${days} days ago`}
                          </div>
                        </div>
                        <button
                          type="button"
                          disabled={true}
                          className=" d-none btn btn-sm btn-icon btn-active-light-primary ml-auto"
                        >
                          <i className="fa fa-download" />
                        </button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <CustomerContractUpdates contract={contract} calls={calls} />
      </div>
    </>
  );
};

export default CustomerContractDetails;
