import { BSON } from "realm-web";
import { OrderFile } from "./commonTypes";
import { DateTypeLogistics } from "../utils/logisticsUtils";

// states
export enum STO_STATES {
  STO_CREATED = "storageOrderCreated",
  STO_CANCELED = "storageOrderCanceled",
}

export interface StorageOrder {
  _id: BSON.ObjectId;
  storageOrderNo: string;
  orderInformation: StorageOrderInformation;
  supplierRef?: string;
  takeOver?: {
    startIncoterm?: string;
    startingFrom?: string;
    destinationIncoterm?: string;
    destinationTo?: string;
  };
  state?: STO_STATES;
  sender?: string;
  recipient?: string;
  warehouse: string;
  customsState: boolean;
  deliveryDate?: Date;
  deliveryDateType?: DateTypeLogistics;
  remarks: string;
  files: Array<OrderFile>;
  createdAt: Date;
  timeline?: Array<StorageOrderTimelineEntry>;
}

export interface StorageOrderInformation {
  orderId: string; // id of the supplier or customer stock order
  orderNo: string;
  packaging: string;
  netWeight: number;
  grossWeight: number;
}

// timeline
export enum STO_TIMELINE {
  STO_T_CREATED = "storageOrderCreated",
  STO_T_CANCELED = "storageOrderCanceled",
}

export interface StorageOrderTimelineEntryPayload {}

export interface StorageOrderTimelineEntry {
  // Might be incomplete
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: STO_TIMELINE; // What happened?
  person: string;
  payload: StorageOrderTimelineEntryPayload | null; // Additional data for certain types, tbd
}
