import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import DateInput from "../../common/DateInput";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import OwnAddressSelector from "../../common/OwnAddressSelector";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { CustomerCommodityExtended } from "../../../model/customer/customerCommodity.types";
import { CustomerSampleOrder } from "../../../model/customer/customerSampleOrder.types";
import { SAMO_REQUESTED, SAMO_T_CREATED } from "../../../model/sampleOrder.types";
import userService from "../../../services/userService";
import { formatCurrency, formatUnit } from "../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";
import { getSampleOrderTimelineEntry, placeSampleOrder } from "../../../utils/sampleOrderUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../services/slackService";
import { Textarea } from "../../common/Textarea";
import { AddressSelectOption } from "../../../utils/addressUtils";
import { CommoditySnapshot } from "../../../model/commodity.types";
import { getArticleSnapshot } from "../../../utils/productArticleUtils";
import { SampleSize } from "../../../model/commodity.types";

interface CreateSampleOrderModalProps extends RouteComponentProps {
  commodity: CustomerCommodityExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
  buttonClass?: string;
  buttonText?: string;
}

interface CreateSampleOrderModalState {
  sampleSize?: SelectOption<SampleSize>;
  shippingAddress?: AddressSelectOption;
  targetDate: Date;
  noteCustomer: string;
  customerReference: string;
  tosAgreed: boolean;
  step: number;
  show: boolean;
  processing: boolean;
}

class CreateSampleOrderModal extends PureComponent<CreateSampleOrderModalProps, CreateSampleOrderModalState> {
  constructor(props: CreateSampleOrderModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });

  handleSwitchStep = () => this.setState({ step: this.state.step === 0 ? 1 : 0 });

  handleChangeAmount = (sampleSize: SelectOption<SampleSize>) => this.setState({ sampleSize });
  handleChangeAddress = (shippingAddress: AddressSelectOption) => this.setState({ shippingAddress });

  handleChangeStringValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeTargetData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    this.setState({ targetDate });
  };

  handleToggleTosAgreed = () => this.setState({ tosAgreed: !this.state.tosAgreed });

  handleConfirmSampleOrder = async () => {
    const { context, commodity, history } = this.props;
    const { sampleSize, shippingAddress, noteCustomer, customerReference, targetDate } = this.state;
    // TODO Add finished products properly RB-776
    const commoditySnapshot = getArticleSnapshot(commodity) as CommoditySnapshot;
    const companyId = userService.getCompany();
    const company = context.company.find((c) => c._id.toString() === companyId);
    if (!company || !sampleSize || !sampleSize.object || !shippingAddress) return;
    this.setState({ processing: true });
    const sS = sampleSize.object as { amount: number; price: number };
    try {
      const sampleOrder: CustomerSampleOrder = {
        _id: new BSON.ObjectId(),
        amount: sS.amount,
        commodity: commoditySnapshot,
        createdAt: new Date(),
        currency: BASE_CURRENCY,
        company: company._id.toString(),
        destination: shippingAddress.address,
        files: [],
        noteCustomer,
        customerReference,
        orderNo: "-1",
        person: userService.getUserId(),
        state: SAMO_REQUESTED,
        targetDate,
        timeline: [getSampleOrderTimelineEntry(SAMO_T_CREATED)],
        totalPrice: sS.price,
        unit: commodity.unit,
      };
      const res = await placeSampleOrder(sampleOrder);
      if (res && res.res && res.res.insertedId) {
        toast.success("Successfully placed sample order");
        history.push("/sampleOrder/" + res.res.insertedId.toString());

        // Create information slack message for sample order creation
        const message = ` <https://${process.env.REACT_APP_BASE_URL || ""}/customer/${company._id.toString()}|*${
          company.name || ""
        }*> requested a sample (${formatUnit(sS.amount, commodity.unit)}) of <https://${
          process.env.REACT_APP_BASE_URL || ""
        }/commodity/${commodity._id.toString()}|*${commodity.title.en || ""}*> for *${formatCurrency(
          sS.price,
          sampleOrder.currency
        )}*`;
        sendMessage(getDefaultSlackChannel(true, NotificationType.ORDER), message);
      } else {
        toast.error("Error placing sample order");
      }
    } finally {
      this.setState({ processing: false });
    }
  };

  getDefaultState = (show: boolean): CreateSampleOrderModalState => {
    const sampleSize = this.props.commodity.sampleSize ? this.props.commodity.sampleSize[0] : undefined;
    return {
      show,
      step: 0,
      tosAgreed: false,
      processing: false,
      sampleSize: sampleSize
        ? { value: "0", label: sampleSize.amount + " " + this.props.commodity.unit, object: sampleSize }
        : undefined,
      targetDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
      noteCustomer: "",
      customerReference: "",
    };
  };

  validateData = () => {
    const { sampleSize, shippingAddress, step, tosAgreed } = this.state;
    const errors = [];
    if (!sampleSize) errors.push("Sample size has to be selected");
    if (!shippingAddress) errors.push("Address has to be selected");
    if (step === 1 && !tosAgreed) errors.push("You need to agree our terms of service");
    return errors;
  };

  render() {
    const { commodity, context, buttonClass, buttonText } = this.props;
    const { sampleSize, show, step, shippingAddress, tosAgreed, targetDate, noteCustomer, customerReference } =
      this.state;
    const errors = this.validateData();

    return (
      <>
        <ErrorOverlayButton
          errors={
            commodity.sampleSize && commodity.sampleSize.length > 0 && commodity.approved && !commodity.disabled
              ? []
              : ["Currently we can not provide a sample for this commodity."]
          }
          disabled={isAnonymousContext(context)}
          className={buttonClass ?? "btn btn-outline btn-outline-light"}
          buttonText={buttonText ?? "Sample Request"}
          onClick={this.handleShow}
        />
        {commodity.sampleSize && (
          <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
            <Modal.Header className="border-0 pb-0">
              <Modal.Title>
                <h1 className="fw-bolder d-flex align-items-center text-white">New Sample Request</h1>
              </Modal.Title>
              <CloseButton variant="white" onClick={this.handleHide} />
            </Modal.Header>
            <Modal.Body>
              <div className="card card-xl-stretch border-0 bg-white">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">
                      {step === 0 ? "Request Settings" : step === 1 ? "Request Overview" : ""}
                    </span>
                  </h3>
                  <h3 className="card-title flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">Sample Request</span>
                    {commodity && <span className="text-muted fw-bold fs-7">{commodity.title.en}</span>}
                  </h3>
                </div>
                <div className="card-body d-flex flex-column">
                  {step === 0 ? (
                    <>
                      <div className="mb-4">
                        <label className="align-items-center fs-6 fw-bold mb-2">
                          <span className="required">Amount</span>
                        </label>
                        <CustomSelect
                          options={
                            commodity.sampleSize.map((s, idx) => {
                              return { value: idx.toString(), label: s.amount + " " + commodity.unit, object: s };
                            }) ?? []
                          }
                          onChange={this.handleChangeAmount}
                          matchFormControl={true}
                          value={sampleSize}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="align-items-center fs-6 fw-bold mb-2">
                          <span className="required">Destination Address</span>
                        </label>
                        <OwnAddressSelector
                          context={context}
                          onChange={this.handleChangeAddress}
                          value={shippingAddress}
                          placeholder="Shipping Address..."
                          matchFormControl={true}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="required fs-5 fw-bold mb-2">Target Date</label>
                        <DateInput
                          classes="form-control custom-form-control"
                          value={targetDate}
                          onBlur={this.handleChangeTargetData}
                          name="targetDate"
                          min={new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7)}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="fs-5 fw-bold mb-2">Note</label>
                        <Textarea
                          className="form-control custom-form-control"
                          value={noteCustomer}
                          onChange={this.handleChangeStringValue}
                          name="noteCustomer"
                          placeholder="Any information you want us to know"
                        />
                      </div>
                      <div className="mb-4">
                        <label className="fs-5 fw-bold mb-2">Reference</label>
                        <Input
                          className="form-control custom-form-control"
                          value={customerReference}
                          onChange={this.handleChangeStringValue}
                          name="customerReference"
                          placeholder="e.g. AT-12345"
                        />
                      </div>
                      {sampleSize?.object && (
                        <div
                          className="text-center mb-4 p-2 background-secondary flex-grow-1"
                          style={{ borderRadius: 10 }}
                        >
                          <span className="text-muted fw-bold mt-0 mb-2">
                            Total Price:{" "}
                            <span className="text-bold text-white fs-5">
                              {formatCurrency(sampleSize.object.price, BASE_CURRENCY)}
                            </span>
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="flex-grow-1">
                        <div className="mb-4">
                          <div className="row">
                            <div className="col-3 my-auto">
                              <label className="fs-7 text-white fw-bold my-auto">Amount</label>
                            </div>
                            <div className="col">
                              <Input
                                className="form-control custom-form-control"
                                type="text"
                                name="amount"
                                value={sampleSize?.object?.amount + commodity.unit}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-3 my-auto">
                              <label className="fs-7 text-white fw-bold my-auto">Shipping Address</label>
                            </div>
                            <div className="col">
                              <textarea
                                className="form-control custom-form-control"
                                value={shippingAddress?.value}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-3 my-auto">
                              <label className="fs-7 text-white fw-bold my-auto">Your Target Date</label>
                            </div>
                            <div className="col">
                              <DateInput
                                classes="form-control custom-form-control"
                                value={targetDate}
                                disabled={true}
                                onBlur={() => true}
                                name="targetDate"
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-3 my-auto">
                              <label className="fs-5 fw-bold mb-2">Note</label>
                            </div>
                            <div className="col">
                              <Textarea
                                className="form-control custom-form-control"
                                value={noteCustomer}
                                disabled={true}
                                name="noteCustomer"
                                placeholder="Any information you want us to know"
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-3 my-auto">
                              <label className="fs-5 fw-bold mb-2">Reference</label>
                            </div>
                            <div className="col">
                              <Input
                                className="form-control custom-form-control"
                                value={customerReference}
                                disabled={true}
                                name="customerReference"
                                placeholder="e.g. AT-12345"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column text-center mb-4 p-2 background-secondary"
                        style={{ borderRadius: 10 }}
                      >
                        {sampleSize?.object && (
                          <span className="text-muted fw-bold mt-0 mb-2">
                            Total Price:{" "}
                            <span className="text-bold text-white fs-5">
                              {formatCurrency(sampleSize?.object?.price, BASE_CURRENCY)}
                            </span>
                          </span>
                        )}
                        <span className="text-success">
                          The delivery date will be communicated as soon as possible.
                        </span>
                      </div>
                      <div className="mb-5">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input position-static"
                            checked={tosAgreed}
                            type="checkbox"
                            onChange={this.handleToggleTosAgreed}
                          />
                          <label className="form-check-label fs-5">
                            <span onClick={this.handleToggleTosAgreed}>I have read and agreed the </span>
                            <a href="/tos" target="_blank" rel="noopener noreferrer">
                              terms of service
                            </a>
                            <span onClick={this.handleToggleTosAgreed}>
                              . I understand that a placed sample requests might not be cancelable anymore.
                            </span>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-sm btn-text-white"
                onClick={step === 0 ? this.handleHide : this.handleSwitchStep}
              >
                {step === 0 ? "Close" : "Back"}
              </button>
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-sm btn-outline btn-outline-light"
                buttonText={step === 0 ? "Continue" : "Confirm"}
                onClick={step === 0 ? this.handleSwitchStep : this.handleConfirmSampleOrder}
              />
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default withRouter(CreateSampleOrderModal);
