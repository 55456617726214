import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import WorkflowUploadDocumentModal from "../workflowTabPanels/modals/WorkflowUploadDocumentModal";
import SimpleConfirmationModal from "../../../../../common/SimpleConfirmationModal";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { CO_HANDLEDATWAREHOUSE, CO_T_SHIPPEDWAREHOUSE, T_EUSTOCK } from "../../../../../../model/customerOrder.types";
import {
  SO_HANDLEDATWAREHOUSE,
  SO_T_DOCUMENTREMOVED,
  SO_T_SHIPPEDWAREHOUSE,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../../../../../model/supplierOrder.types";
import { formatDate } from "../../../../../../utils/baseUtils";
import { getCustomerOrderTimelineEntry } from "../../../../../../utils/customerOrderUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";
import {
  getShipmentFiles,
  getSupplierOrderTimelineEntry,
  SO_BOL,
  SO_COA,
  SO_CUSTOMSINVOICE,
  SO_FILETYPES,
  SO_PACKAGINGLIST,
  updateSupplierOrder,
  updateSupplierOrderAndSwitchCustomerStates,
} from "../../../../../../utils/supplierOrderUtils";
import { CC_T_READY, getCustomerContractTimelineEntry } from "../../../../../../utils/customerContractUtils";
import { CC_READY } from "../../../../../../model/customerContract.types";

interface WorkflowShippingInformationCardProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
  done: boolean;
}

interface WorkflowShippingInformationCardState {
  saving: boolean;
}

class WorkflowShippingInformationCard extends PureComponent<
  WorkflowShippingInformationCardProps,
  WorkflowShippingInformationCardState
> {
  fileUploadRef?: WorkflowUploadDocumentModal;

  constructor(props: WorkflowShippingInformationCardProps) {
    super(props);
    this.state = { saving: false };
  }

  setRef = (ref: WorkflowUploadDocumentModal | null) => {
    if (ref) this.fileUploadRef = ref;
  };

  /**
   * Handles removing the customs invoice.
   */
  handleRemoveCustomsInvoice = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const files = order.files.filter((f) => f.type !== SO_CUSTOMSINVOICE);
      const update: Partial<SupplierOrder> = { files };
      const sOTimeline = getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, { type: SO_CUSTOMSINVOICE });
      const res = await updateSupplierOrder(update, order._id, sOTimeline);
      if (res && res.modifiedCount > 0) {
        toast.success("Customs Invoice removed successfully");
      } else {
        toast.error("Error removing Customs Invoice");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Handles switching order states and updates the customer orders.
   */
  handleOrderStateSwitch = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const update: Partial<SupplierOrder> = { _id: order._id, state: SO_HANDLEDATWAREHOUSE };
      if (order.shipment.length === 1) {
        update.shipment = order.shipment;
        update.shipment[0].state = SO_HANDLEDATWAREHOUSE;
      }
      const cOs = order.customerOrders.map((cO) => cO._id);
      const cCs = order.customerContracts?.map((cC) => cC._id) ?? [];
      const sOTimeline = getSupplierOrderTimelineEntry(SO_T_SHIPPEDWAREHOUSE);
      const cOTimeline = getCustomerOrderTimelineEntry(CO_T_SHIPPEDWAREHOUSE);
      const cCTimeline = getCustomerContractTimelineEntry(CC_T_READY);
      const res = await updateSupplierOrderAndSwitchCustomerStates(
        update,
        cOs,
        cCs,
        CO_HANDLEDATWAREHOUSE,
        CC_READY,
        sOTimeline,
        cOTimeline,
        cCTimeline
      );
      if (res) {
        toast.success("Order updated successfully");
      } else {
        toast.error("Error updating order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Handles the removal of a document.
   * @param type Type of the document that should be removed
   */
  handleRemoveDocument = async (type: string) => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const files = order.files.filter((f) => f.type !== type);
      const update: Partial<SupplierOrder> = { files };
      const sOTimeline = getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, { type });
      const res = await updateSupplierOrder(update, order._id, sOTimeline);
      if (res && res.modifiedCount > 0) {
        toast.success("File removed successfully");
      } else {
        toast.error("Error removing file");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleClickMissingDocument = (fileType: string) => {
    const fileSelectOption = SO_FILETYPES.find((f) => f.value === fileType);
    this.fileUploadRef?.handleShow(fileSelectOption);
  };

  render() {
    const { order, context, done } = this.props;
    const { saving } = this.state;
    const { bol, packagingList, customsInvoice } = getShipmentFiles(order, order.shipment[0]);
    const shipment = order.shipment[0];

    return (
      <div className="opacity-100-hover" style={{ opacity: done ? 0.3 : 1 }}>
        <div className="fw-bolder text-white fs-3 my-5">
          Shipping Documents{" "}
          {done ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        <div className="table-responsive mt-5 pt-2 bg-light2">
          <table className="table table-row-gray-100 align-middle">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="border-bottom-0" style={{ width: "30%" }}>
                  Type
                </th>
                <th className="border-bottom-0" style={{ width: "40%" }}>
                  Title
                </th>
                <th className="border-bottom-0" style={{ width: "15%" }}>
                  Uploaded
                </th>
                <th className="border-bottom-0 text-right" style={{ width: "15%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {order.transport !== T_EUSTOCK && (
                <tr>
                  <td className="align-middle">
                    <span className="text-white fw-bolder">BOL</span>
                  </td>
                  <td className="align-middle">
                    {bol ? (
                      <a href={resolveFilePath(bol.path)} target="_blank" rel="noopener noreferrer">
                        <span className="text-white fw-bold">{shortenAlias(bol.path)}</span>
                      </a>
                    ) : (
                      <span
                        className="text-danger fw-bold cursor-pointer"
                        onClick={() => this.handleClickMissingDocument(SO_BOL)}
                      >
                        missing
                      </span>
                    )}
                  </td>
                  <td className="align-middle">
                    <span className="text-success">{bol && formatDate(bol.date)}</span>
                  </td>
                  <td className="align-middle text-right">
                    {bol && (
                      <SimpleConfirmationModal.SimpleConfirmationModalButton
                        size="md"
                        modalTitle="Remove BOL"
                        onConfirm={() => this.handleRemoveDocument(SO_BOL)}
                        confirmButtonText="Confirm"
                        buttonText="x"
                        buttonClasses="btn btn-text text-danger btn-sm p-2"
                        cancelButtonText="Close"
                        modalDescription={
                          <span className="text-white">
                            Do you really want to remove the BOL? This can not be undone.
                          </span>
                        }
                      />
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td className="align-middle">
                  <span className="text-white fw-bolder">Packaging List</span>
                </td>
                <td className="align-middle">
                  {packagingList ? (
                    <a href={resolveFilePath(packagingList.path)} target="_blank" rel="noopener noreferrer">
                      <span className="text-white fw-bold">{shortenAlias(packagingList.path)}</span>
                    </a>
                  ) : (
                    <span
                      className="text-danger fw-bold cursor-pointer"
                      onClick={() => this.handleClickMissingDocument(SO_PACKAGINGLIST)}
                    >
                      missing
                    </span>
                  )}
                </td>
                <td className="align-middle">
                  <span className="text-success">{packagingList && formatDate(packagingList.date)}</span>
                </td>
                <td className="align-middle text-right">
                  {packagingList && (
                    <SimpleConfirmationModal.SimpleConfirmationModalButton
                      size="md"
                      modalTitle="Remove Packaging List"
                      onConfirm={() => this.handleRemoveDocument(SO_PACKAGINGLIST)}
                      confirmButtonText="Confirm"
                      buttonText="x"
                      buttonClasses="btn btn-text text-danger btn-sm p-2"
                      cancelButtonText="Close"
                      modalDescription={
                        <span className="text-white">
                          Do you really want to remove the packaging list? This can not be undone.
                        </span>
                      }
                    />
                  )}
                </td>
              </tr>
              {order.transport !== T_EUSTOCK && (
                <tr>
                  <td className="align-middle">
                    <span className="text-white fw-bolder">Customs Invoice</span>
                  </td>
                  <td className="align-middle">
                    {customsInvoice ? (
                      <a href={resolveFilePath(customsInvoice.path)} target="_blank" rel="noopener noreferrer">
                        <span className="text-white fw-bold">{shortenAlias(customsInvoice.path)}</span>
                      </a>
                    ) : (
                      <span
                        className="text-danger fw-bold cursor-pointer"
                        onClick={() => this.handleClickMissingDocument(SO_CUSTOMSINVOICE)}
                      >
                        missing
                      </span>
                    )}
                  </td>
                  <td className="align-middle">
                    <span className="text-success">{customsInvoice && formatDate(customsInvoice.date)}</span>
                  </td>
                  <td className="align-middle text-right">
                    {customsInvoice && (
                      <SimpleConfirmationModal.SimpleConfirmationModalButton
                        size="md"
                        modalTitle="Remove Customs Invoice"
                        onConfirm={this.handleRemoveCustomsInvoice}
                        confirmButtonText="Confirm"
                        buttonText="x"
                        buttonClasses="btn btn-text text-danger btn-sm p-2"
                        cancelButtonText="Close"
                        modalDescription={
                          <span className="text-white">
                            Do you really want to remove the BOL? This can not be undone.
                          </span>
                        }
                      />
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={4} className="align-middle text-right">
                  <WorkflowUploadDocumentModal
                    order={order}
                    context={context}
                    fixedTypes={SO_FILETYPES.filter((ft) =>
                      [SO_BOL, SO_COA, SO_PACKAGINGLIST, SO_CUSTOMSINVOICE].includes(ft.value)
                    )}
                    shipment={shipment}
                    disabled={done}
                    ref={this.setRef}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {!done && (
          <div className="pt-3">
            <div className="d-flex pt-3 align-items-center text-right w-100">
              <button
                className={"btn btn-outline btn-outline-white btn-sm ml-auto float-right" + (saving ? " disabled" : "")}
                onClick={saving ? undefined : this.handleOrderStateSwitch}
                disabled={saving}
              >
                {saving ? "Saving..." : "Arrived at Warehouse"}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WorkflowShippingInformationCard;
