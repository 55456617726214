import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BaseListing from "../../common/BaseListing";
import CreateSupplierModal from "./modals/CreateSupplierModal";
import { DataContextInternalType } from "../../../context/dataContext";
import {
  doFuseSearch,
  formatCurrency,
  getComponentState,
  getDocFromCollection,
  pluralize,
} from "../../../utils/baseUtils";
import {
  getSupplierActivity,
  getSupplierArticleStatus,
  getSupplierCommodityStats,
  getSupplierOpenOrders,
  S_SORTOPTIONS,
  updateSupplier,
} from "../../../utils/supplierUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import { Supplier } from "../../../model/supplier.types";
import SupplierListingFilter from "./SupplierListingFilter";
import { SelectOption } from "../../common/CustomSelect";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import { COR_INREVIEW, COR_REQUESTED } from "../../../model/commodityOfferRequest.types";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import ReactStarsWrapper from "../../common/ReactStarsWrapper";
import SupplierEUWidget from "../../common/SupplierEUWidget";

interface SupplierListingProps {
  context: DataContextInternalType;
}

interface SupplierListingState extends PaginationState {
  search: string;
  generalFilter?: SelectOption;
  sortBy: SelectOption;
  sortOrder: SelectOption;
  offeringStatus?: SelectOption;
}

const COMPONENT_NAME = "SupplierListing";

class SupplierListing extends PureComponent<SupplierListingProps, SupplierListingState> {
  constructor(props: SupplierListingProps) {
    super(props);
    this.state = {
      pageSize: 25,
      currentPage: 1,
      search: "",
      sortBy: S_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: S_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: { value: "", label: "Best Match" },
        sortOrder: SORTORDEROPTIONS[0],
      });
  };

  handleChangeSelect = (name: string, entry: SelectOption | undefined) => {
    //@ts-ignore
    if (["sortBy", "sortOrder"].includes(name)) this.setState({ [name]: entry });
    //@ts-ignore
    else this.setState({ [name]: entry, currentPage: 1 });
  };

  getFilteredSuppliers = () => {
    const { context } = this.props;
    const { supplier } = context;
    const { search, sortBy, sortOrder, generalFilter, offeringStatus } = this.state;
    let filteredSuppliers = _.cloneDeep(supplier);
    if (search.trim()) filteredSuppliers = doFuseSearch(filteredSuppliers, search, ["name"]);
    //TODO Some Filters are not defined yet: 1.'Filter results'. 2.'Sort Results': commodities,activity & open orders Issue: RB-180
    if (generalFilter) {
      if (generalFilter.value === "goodRating") {
        filteredSuppliers = filteredSuppliers.filter((s) => s.rating >= 3);
      } else if (generalFilter.value === "badRating") {
        filteredSuppliers = filteredSuppliers.filter((s) => s.rating < 3);
      }
    }
    if (offeringStatus) {
      const { commodityOfferRequest } = context;
      switch (offeringStatus.value) {
        case "openRequests": {
          const openRequestsSupIDs = commodityOfferRequest
            .filter((cor) => cor.state === COR_REQUESTED)
            .map((corF) => corF.supplier);
          filteredSuppliers = filteredSuppliers.filter((s) => openRequestsSupIDs.includes(s._id.toString()));
          break;
        }
        case "requestInReview": {
          const inReviewRequestsSupIDs = commodityOfferRequest
            .filter((cor) => cor.state === COR_INREVIEW)
            .map((corF) => corF.supplier);
          filteredSuppliers = filteredSuppliers.filter((s) => inReviewRequestsSupIDs.includes(s._id.toString()));
          break;
        }
      }
    }
    // Handle sorting for search
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return filteredSuppliers.reverse();
      return filteredSuppliers;
    }
    return _.orderBy(filteredSuppliers, sortBy.value, sortOrder.value as "asc" | "desc");
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, search, generalFilter, sortBy, sortOrder, offeringStatus } = this.state;
    const filteredSuppliers = this.getFilteredSuppliers();
    const headerDefinition = [
      { title: "Supplier" },
      { title: "Articles" },
      { title: "Open Orders" },
      { title: "Activity" },
      { title: "Country", className: "text-center px-0" },
      { title: "Access", className: "text-center px-0" },
      { title: "Rating", className: "text-center px-0" },
    ];
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Suppliers</span>
                  <CreateSupplierModal />
                </h3>
                <SupplierListingFilter
                  search={search}
                  generalFilter={generalFilter}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  offeringStatus={offeringStatus}
                  onSearch={this.handleSearch}
                  onGeneralFilterChange={(e) => this.handleChangeSelect("generalFilter", e)}
                  onSortByChange={(e) => this.handleChangeSelect("sortBy", e)}
                  onSortOrderChange={(e) => this.handleChangeSelect("sortOrder", e)}
                  onOfferingStatusChange={(e) => this.handleChangeSelect("offeringStatus", e)}
                />
                <BaseListing
                  headerDefinition={headerDefinition}
                  documents={filteredSuppliers}
                  bodyContent={
                    <>
                      {paginate(filteredSuppliers, currentPage, pageSize).map((s) => (
                        <SupplierListingRowRouter key={s._id.toString()} supplier={s} context={context} />
                      ))}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface SupplierListingRowProps extends RouteComponentProps {
  supplier: Supplier;
  context: DataContextInternalType;
}

const SupplierListingRow: React.FunctionComponent<SupplierListingRowProps> = ({ supplier, context, history }) => {
  const { commodity, finishedProduct, supplierOrder: orders, currencies } = context;

  const commodityStats = getSupplierCommodityStats(supplier._id.toString(), commodity);
  const { validArticles, invalidArticles } = getSupplierArticleStatus(supplier, commodity, finishedProduct);
  const handleRatingChange = async (rating: number) => {
    if (rating < 0 || rating > 5) return;
    const result = await updateSupplier({ rating }, supplier._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Supplier rating updated successfully");
    } else {
      toast.error("Error updating supplier rating");
    }
  };

  const [openOrders, openValue] = getSupplierOpenOrders(supplier, orders, currencies);
  const activity = getSupplierActivity(openOrders, commodityStats[1]);
  const systemAccess = supplier.persons.some((p) => {
    const person = getDocFromCollection(context.userData, p);
    return person?.userId.trim() !== "";
  });

  const forwardSupplier = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    history.push(`/supplier/${supplier._id.toString()}`);
  };

  return (
    <tr className="cursor-pointer-row" onClick={forwardSupplier}>
      <td className="align-middle">
        <Link
          onClick={(e) => e.stopPropagation()}
          className="text-white fs-5 mb-1 custom-link"
          to={`/supplier/${supplier._id.toString()}`}
        >
          {supplier.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
          {!supplier.activated && !supplier.disabled && <span className="text-warning mr-2">[PENDING]</span>}
          {supplier.name}
          {supplier.euSupplier && <SupplierEUWidget />}
        </Link>
        <ContactPersonWidget
          person={getDocFromCollection(context.userData, supplier.primaryPerson)}
          spanClasses="text-muted fw-bold d-block"
        />
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className={validArticles > 0 ? "text-success" : "text-muted"}>{validArticles} valid</div>
          <span className={invalidArticles > 0 ? "text-danger" : "text-muted"}>{invalidArticles} invalid</span>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className={openOrders.length > 0 ? "text-success" : "text-muted"}>
            {pluralize(openOrders.length, "open order")}
          </div>
          <span className="text-muted">{formatCurrency(openValue, supplier.currency)}</span>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className="progress" style={{ backgroundColor: "#232323", height: 5 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: activity + "%",
                color: "red",
                backgroundColor: "#6d6d6d",
              }}
            />
          </div>
        </div>
      </td>
      <td className="align-middle text-center">
        <div className="fs-6">
          <div className="d-flex flex-center flex-column py-1">
            <CompanyCountryWidget company={supplier} wrapperClasses={"fw-bold text-muted"} />
          </div>
        </div>
      </td>
      <td className="align-middle text-center">
        <div className={"fs-6 " + (systemAccess ? "text-success" : "text-muted")}>
          {systemAccess ? "active" : "inactive"}
        </div>
      </td>
      <td className="align-middle text-center">
        <div
          className="fs-6"
          style={{ minWidth: "70px" }}
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
          {supplier.rating < 0 ? (
            "-"
          ) : (
            <ReactStarsWrapper
              classNames="mx-auto"
              value={supplier.rating}
              count={5}
              size={14}
              onChange={handleRatingChange}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

const SupplierListingRowRouter = withRouter(SupplierListingRow);

export default SupplierListing;
