import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import ErrorOverlayButton from "../../../../../common/ErrorOverlayButton";
import { Input } from "../../../../../common/Input";
import {
  CO_ARRIVEDATSTARTINGPORT,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_SHIPPED,
  CO_T_TRACKINGNOCHANGED,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_SEAFREIGHT,
} from "../../../../../../model/customerOrder.types";
import {
  SO_ARCHIVED,
  SO_ARRIVEDATSTARTINGPORT,
  SO_CANCELED,
  SO_ORDERCONFIRMED,
  SO_REQUESTED,
  SO_SHIPPEDFROMSUPPLIER,
  SO_T_ARRIVEDATSTARTINGPORT,
  SO_T_SHIPPED,
  SO_T_TRACKINGNOCHANGED,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../../../../../model/supplierOrder.types";
import { getCustomerOrderTimelineEntry } from "../../../../../../utils/customerOrderUtils";
import {
  getPortNameOrAddress,
  getSupplierOrderTimelineEntry,
  shipSupplierOrder,
  updateSupplierOrderAndSwitchCustomerStates,
} from "../../../../../../utils/supplierOrderUtils";
import CustomSelect, { SelectOption } from "../../../../../common/CustomSelect";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { Seaport } from "../../../../../../model/seaport.types";
import {
  getDestinationSeaports,
  getSeaportName,
  getStartingSeaports,
  isSeaport,
} from "../../../../../../utils/seaportUtils";
import CreateSeaportModal from "../../../../../settings/internal/seaports/CreateSeaportModal";
import { AIRPORT, getDocumentDB, SEAPORT } from "../../../../../../services/dbService";
import { getDocFromCollection } from "../../../../../../utils/baseUtils";
import { Airport } from "../../../../../../model/airport.types";
import {
  getAirportName,
  getDestinationAirports,
  getStartingAirports,
  isAirport,
} from "../../../../../../utils/airportUtils";
import CreateAirportModal from "../../../../../settings/internal/airports/CreateAirportModal";
import CalendarWeekSelector from "../../../../../common/CalendarWeekSelector";
import { getCW } from "../../../../../../utils/dateUtils";
import { AddressSelectOption, formatAddress, isAddress } from "../../../../../../utils/addressUtils";
import { Address } from "../../../../../../model/commonTypes";

interface WorkflowShipmentCardProps {
  order: SupplierOrderExtended;
  done: boolean;
  context: DataContextInternalType;
}

interface WorkflowShipmentCardState {
  trackingNumber: string;
  eta: Date;
  startingPoint?: PortSelectOption | AddressSelectOption;
  destination?: PortSelectOption | AddressSelectOption;
  saving: boolean;
  createNew?: "startingPoint" | "destination";
  loading?: "startingPoint" | "destination";
}

interface PortSelectOption extends SelectOption {
  port: Seaport | Airport;
}

class WorkflowShipmentCard extends PureComponent<WorkflowShipmentCardProps, WorkflowShipmentCardState> {
  _isMounted = false;

  constructor(props: WorkflowShipmentCardProps) {
    super(props);
    const orderTransportMethod = props.order.transport;
    const shipping = props.order.shipment[0] ? props.order.shipment[0].shipping : undefined;
    const startingPointFromOrder =
      shipping?.startingPoint ??
      (props.order.purchaseOrderInformation
        ? orderTransportMethod === T_AIRFREIGHT
          ? props.order.purchaseOrderInformation.startingAirport
          : orderTransportMethod === T_EUSTOCK
          ? props.order.purchaseOrderInformation.startingEUWarehouse
          : props.order.purchaseOrderInformation.startingSeaport
        : undefined);
    this.state = {
      trackingNumber: shipping ? shipping.trackingNumber : "",
      eta: shipping?.eta ?? new Date(),
      startingPoint:
        startingPointFromOrder && (isAirport(startingPointFromOrder) || isSeaport(startingPointFromOrder)) // air- or seafreight (port)
          ? this.getPortSelectOption(startingPointFromOrder)
          : startingPointFromOrder && isAddress(startingPointFromOrder) // EU stock (address)
          ? {
              value: formatAddress(startingPointFromOrder),
              label: formatAddress(startingPointFromOrder),
              address: startingPointFromOrder,
            }
          : startingPointFromOrder && (orderTransportMethod === T_AIRFREIGHT || orderTransportMethod === T_SEAFREIGHT) // air- or seafreight (string)
          ? this.getPortSelectOption(startingPointFromOrder)
          : startingPointFromOrder && orderTransportMethod === T_EUSTOCK // EU stock (string)
          ? {
              value: "",
              label: startingPointFromOrder,
              address: {} as Address,
            }
          : undefined,
      destination:
        shipping?.destination && (isAirport(shipping.destination) || isSeaport(shipping.destination)) // air- or seafreight (port)
          ? this.getPortSelectOption(shipping.destination)
          : shipping?.destination && isAddress(shipping.destination) // EU stock (address)
          ? {
              value: formatAddress(shipping.destination),
              label: formatAddress(shipping.destination),
              address: shipping.destination,
            }
          : shipping?.destination && (orderTransportMethod === T_AIRFREIGHT || orderTransportMethod === T_SEAFREIGHT) // air- or seafreight (string)
          ? this.getPortSelectOption(shipping.destination as string)
          : shipping?.destination && orderTransportMethod === T_EUSTOCK // EU stock (string)
          ? {
              value: "",
              label: shipping.destination as string,
              address: {} as Address,
            }
          : undefined,
      saving: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Get a custom select option for destination or start ports
   * @param port a seaport object or string for backwards compatibility
   * @returns {PortSelectOption} a port select option
   */
  getPortSelectOption = (port: Seaport | Airport | string): PortSelectOption => {
    if (typeof port === "object") {
      if (isSeaport(port)) return { value: port._id.toString(), label: getSeaportName(port), port };
      else if (isAirport(port)) return { value: port._id.toString(), label: getAirportName(port), port };
    }
    return { value: "", label: port, port: {} as Seaport };
  };

  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const t = e.target;
    // @ts-ignore
    this.setState({ [t.name]: t.type === "number" ? Number(t.value) : t.value });
  };

  handleChangeETA = (date: Date) => this.setState({ eta: date });

  handleChangePort = (e: PortSelectOption, type: "startingPoint" | "destination") => {
    if (e.value === "new") {
      // Handle Create New option
      this.setState({ createNew: type });
      return;
    }
    // @ts-ignore
    this.setState({ [type]: e });
  };

  handleChangeStartingPointEU = (e: AddressSelectOption) => {
    this.setState({ startingPoint: e });
  };

  handleChangeDestinationEU = (e: AddressSelectOption) => {
    this.setState({ destination: e });
  };

  handleHideSeaportCreationModal = async (seaportId?: string | BSON.ObjectId) => {
    const { context } = this.props;
    const { createNew } = this.state;
    if (seaportId) {
      this.setState({ loading: this.state.createNew });
      try {
        // Select created seaport
        let seaport = getDocFromCollection(context.seaport, seaportId);
        if (!seaport) seaport = await getDocumentDB<Seaport>(SEAPORT, seaportId);
        if (!seaport) {
          console.error("No seaport could be loaded for id", seaportId);
          toast.error("The created seaport could not be loaded. Please reload the page");
          return;
        }
        if (
          seaport &&
          ((createNew === "startingPoint" && seaport.cost === undefined) ||
            (createNew === "destination" && seaport.cost !== undefined))
        ) {
          console.error(
            "Created seaport is not feasible as" +
              (createNew === "startingPoint" ? "starting point" : "destination port")
          );
          toast.error(
            "Created seaport is not feasible as" +
              (createNew === "startingPoint" ? "starting point" : "destination port")
          );
          return;
        }
        context.addDocuments(SEAPORT, [seaport]);
        if (this._isMounted && createNew) {
          // @ts-ignore
          this.setState({
            [createNew]: this.getPortSelectOption(seaport),
            createNew: undefined,
            loading: undefined,
          });
        }
      } finally {
        if (this._isMounted) this.setState({ loading: undefined });
      }
    } else this.setState({ createNew: undefined });
  };

  handleHideAirportCreationModal = async (airportId?: string | BSON.ObjectId) => {
    const { context } = this.props;
    const { createNew } = this.state;
    if (airportId) {
      this.setState({ loading: this.state.createNew });
      try {
        // Select created airport
        let airport = getDocFromCollection(context.airport, airportId);
        if (!airport) airport = await getDocumentDB<Airport>(AIRPORT, airportId);
        if (!airport) {
          console.error("No airport could be loaded for id", airportId);
          toast.error("The created airport could not be loaded. Please reload the page");
          return;
        }
        if (
          airport &&
          ((createNew === "startingPoint" && airport.cost === undefined) ||
            (createNew === "destination" && airport.cost !== undefined))
        ) {
          console.error(
            "Created airport is not feasible as" +
              (createNew === "startingPoint" ? "starting point" : "destination port")
          );
          toast.error(
            "Created airport is not feasible as" +
              (createNew === "startingPoint" ? "starting point" : "destination port")
          );
          return;
        }
        context.addDocuments(AIRPORT, [airport]);
        if (this._isMounted && createNew) {
          // @ts-ignore
          this.setState({
            [createNew]: this.getPortSelectOption(airport),
            createNew: undefined,
            loading: undefined,
          });
        }
      } finally {
        if (this._isMounted) this.setState({ loading: undefined });
      }
    } else this.setState({ createNew: undefined });
  };

  /**
   * Switches the state of the shipment to "arrivedAtStartingPort" and updates the related customer orders / updates tracking number
   */
  handleSaveShipment = async () => {
    const { order } = this.props;
    const { trackingNumber, startingPoint, destination, eta } = this.state;
    if (!startingPoint) return;
    const start = "address" in startingPoint ? (startingPoint.address as Address) : startingPoint.port;
    const dest =
      destination && "address" in destination
        ? (destination.address as Address)
        : destination
        ? destination.port
        : undefined;
    this.setState({ saving: true });
    try {
      const update: Partial<SupplierOrder> = {
        _id: order._id,
      };
      let sOTimeline;
      let cOTimeline;
      if (order.shipment.length === 0) {
        let shipping: {
          trackingNumber: string;
          arrivedAtStartingPort?: Date;
          shipped?: Date;
          eta?: Date;
          startingPoint: Seaport | Airport | Address;
          destination?: Seaport | Airport | Address;
        } = {
          arrivedAtStartingPort: new Date(),
          eta: eta ?? undefined,
          startingPoint: start,
          trackingNumber,
        };
        if (dest) {
          shipping = { ...shipping, destination: dest };
        }
        update.shipment = [
          {
            _id: new BSON.ObjectId(),
            state: SO_ARRIVEDATSTARTINGPORT,
            amount: order.amount,
            timeline: [],
            shipping,
          },
        ];
        sOTimeline = getSupplierOrderTimelineEntry(SO_T_ARRIVEDATSTARTINGPORT);
        cOTimeline = getCustomerOrderTimelineEntry(CO_T_ARRIVEDATSTARTINGPORT);
      } else {
        const shipment = _.cloneDeep(order.shipment);
        if (shipment) {
          shipment[0].shipping.trackingNumber = trackingNumber;
          update.shipment = shipment;
        }
        sOTimeline = getSupplierOrderTimelineEntry(SO_T_TRACKINGNOCHANGED);
        cOTimeline = getCustomerOrderTimelineEntry(CO_T_TRACKINGNOCHANGED);
      }
      const cOs = order.customerOrders.map((cO) => cO._id);
      const res = await updateSupplierOrderAndSwitchCustomerStates(
        update,
        cOs,
        [],
        CO_ARRIVEDATSTARTINGPORT,
        "",
        sOTimeline,
        cOTimeline
      );
      if (res) {
        toast.success("Order updated successfully");
      } else {
        toast.error("Error updating order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  /**
   * Switches the state of the supplier order to "shipped" and updates the related customer orders
   */
  handleClickOrderShipped = async () => {
    const { order } = this.props;
    const { trackingNumber, startingPoint, destination, eta } = this.state;
    if (!startingPoint) return;
    this.setState({ saving: true });
    const start = "address" in startingPoint ? (startingPoint.address as Address) : startingPoint.port;
    const dest =
      destination && "address" in destination
        ? (destination.address as Address)
        : destination
        ? destination.port
        : undefined;
    try {
      const update: Partial<SupplierOrder> = {
        _id: order._id,
        state: SO_SHIPPEDFROMSUPPLIER,
        targetDate: eta,
        changedETA: eta,
      };
      if (order.shipment.length === 0) {
        let shipping: {
          trackingNumber: string;
          arrivedAtStartingPort?: Date;
          shipped?: Date;
          eta?: Date;
          startingPoint: Seaport | Airport | Address;
          destination?: Seaport | Airport | Address;
        } = {
          shipped: new Date(),
          eta: eta ?? undefined,
          startingPoint: start,
          trackingNumber,
        };
        if (dest) {
          shipping = { ...shipping, destination: dest };
        }
        update.shipment = [
          {
            _id: new BSON.ObjectId(),
            state: SO_SHIPPEDFROMSUPPLIER,
            amount: order.amount,
            timeline: [],
            shipping,
          },
        ];
      } else {
        const shipment = order.shipment[0];
        if (shipment) {
          shipment.state = SO_SHIPPEDFROMSUPPLIER;
          shipment.shipping = {
            shipped: new Date(),
            arrivedAtStartingPort: shipment.shipping.arrivedAtStartingPort || undefined,
            eta: eta ?? undefined,
            startingPoint: start,
            trackingNumber,
          };
          if (dest) {
            shipment.shipping = { ...shipment.shipping, destination: dest };
          }
          update.shipment = order.shipment;
        }
      }
      const cOs = order.customerOrders.map((cO) => cO._id);
      const sOTimeline = getSupplierOrderTimelineEntry(SO_T_SHIPPED);
      const cOTimeline = getCustomerOrderTimelineEntry(CO_T_SHIPPED);
      const res = await shipSupplierOrder(update, cOs, sOTimeline, cOTimeline);
      if (res) {
        toast.success("Order shipped successfully");
      } else {
        toast.error("Error shipping order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { order } = this.props;
    const { eta, startingPoint, destination } = this.state;
    if (order.state === SO_ARCHIVED) return ["Order already finished"];
    if (order.state === SO_CANCELED) return ["Order was canceled"];
    const errors = [];
    if (!eta || eta < new Date()) errors.push("ETA has to be in the future");
    // .value check is to make sure to not allow to safe invalid ports from old orders
    if (!startingPoint || !startingPoint.value) errors.push("Starting Point has to be set");
    if (order.transport !== T_EUSTOCK && (!destination || !destination.value)) errors.push("Destination has to be set");
    return errors;
  };

  render() {
    const { order, done, context } = this.props;
    const { trackingNumber, eta, destination, startingPoint, saving, createNew, loading } = this.state;
    const { airport, seaport } = context;
    const editable = _.includes([SO_REQUESTED, SO_ORDERCONFIRMED], order.state) && order.shipment.length === 0;
    const editableTracking =
      _.includes([SO_REQUESTED, SO_ORDERCONFIRMED], order.state) ||
      (order.shipment[0] && order.shipment[0].state === SO_ARRIVEDATSTARTINGPORT);
    const errors = this.validateData();
    const newSeaportOption = { value: "new", label: "Create New" };
    const isAirfreight = order.transport === T_AIRFREIGHT;
    const relevantStartingPorts = isAirfreight ? getStartingAirports(airport) : getStartingSeaports(seaport);
    const relevantDestinationPorts = isAirfreight ? getDestinationAirports(airport) : getDestinationSeaports(seaport);
    const startingPorts = [newSeaportOption].concat(
      relevantStartingPorts.map((p) => {
        return { label: getPortNameOrAddress(p), value: p._id.toString(), port: p };
      })
    );
    const destinationPorts = [newSeaportOption].concat(
      relevantDestinationPorts.map((p) => {
        return { label: getPortNameOrAddress(p), value: p._id.toString(), port: p };
      })
    );
    const notifies: Array<AddressSelectOption> = [];
    for (let i = 0; i < context.notify.length; i++) {
      const n = context.notify[i];
      notifies.push({
        value: n._id.toString(),
        label: `${n.companyName}, ${formatAddress(n.address, { withComma: true })}`,
        address: n.address,
      });
    }
    const supplierAddresses: Array<AddressSelectOption> = [];
    for (let i = 0; i < order.supplier.address.length; i++) {
      const sa = order.supplier.address[i];
      const address = sa.name ? formatAddress(sa) : `${order.supplier.name} ${formatAddress(sa)}`;
      supplierAddresses.push({
        value: address,
        label: address,
        address: sa,
      });
    }
    return (
      <div className="opacity-100-hover" style={{ opacity: done ? 0.3 : 1 }}>
        {isAirfreight ? (
          <CreateAirportModal onlyModal={true} show={!!createNew} onHide={this.handleHideAirportCreationModal} />
        ) : (
          <CreateSeaportModal onlyModal={true} show={!!createNew} onHide={this.handleHideSeaportCreationModal} />
        )}
        <div className="fw-bolder text-white fs-3 my-5">
          Shipping Information{" "}
          {done ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : (
            <span className="text-warning">[Current Task]</span>
          )}
        </div>
        <div className="row py-5">
          <div className="col-md-6 mt-2">
            <label className="fs-6 fw-bold mb-1">Tracking Number (opt.)</label>
            <Input
              type="text"
              name="trackingNumber"
              value={trackingNumber}
              onChange={this.handleChangeInput}
              disabled={!editableTracking}
              className="form-control custom-form-control"
              placeholder="Enter tracking number..."
            />
          </div>
          <div className="col-md-6 mt-2">
            <label className="fs-6 fw-bold mb-1 required">ETA</label>
            {editableTracking ? (
              <CalendarWeekSelector value={eta} onSelectCalendarWeek={this.handleChangeETA} />
            ) : (
              <Input
                type="text"
                name="eta"
                value={`CW ${getCW(eta)}-${eta.getFullYear()}`}
                disabled={true}
                className="form-control custom-form-control"
              />
            )}
          </div>
          {order.transport === T_EUSTOCK ? (
            <>
              <div className="col-md-6 mt-2">
                <label className="fs-6 fw-bold mb-1 required">Starting Point</label>
                <CustomSelect
                  options={supplierAddresses}
                  disabled={loading === "startingPoint" || !editable}
                  placeholder={"Select the supplier warehouse address"}
                  onChange={(e: AddressSelectOption) => this.handleChangeStartingPointEU(e)}
                  value={
                    loading === "startingPoint"
                      ? { value: "", label: "Loading...", address: undefined }
                      : startingPoint ?? undefined
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="fs-6 fw-bold mb-1">Destination</label>
                <CustomSelect
                  options={notifies}
                  disabled={loading === "destination" || !editable}
                  placeholder={"Select the destination"}
                  onChange={(e: AddressSelectOption) => this.handleChangeDestinationEU(e)}
                  value={loading === "destination" ? { value: "", label: "Loading..." } : destination ?? undefined}
                  matchFormControl={true}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 mt-2">
                <label className="fs-6 fw-bold mb-1 required">Starting Point</label>
                <CustomSelect
                  options={startingPorts}
                  disabled={loading === "startingPoint" || !editable}
                  placeholder={"Select the start port"}
                  onChange={(e: PortSelectOption) => this.handleChangePort(e, "startingPoint")}
                  value={loading === "startingPoint" ? { value: "", label: "Loading..." } : startingPoint ?? undefined}
                />
              </div>
              <div className="col-md-6 mt-2">
                <label className="fs-6 fw-bold mb-1 required">Destination</label>
                <CustomSelect
                  options={destinationPorts}
                  disabled={loading === "destination" || !editable}
                  placeholder={"Select the destination port"}
                  onChange={(e: PortSelectOption) => this.handleChangePort(e, "destination")}
                  value={loading === "destination" ? { value: "", label: "Loading..." } : destination ?? undefined}
                />
              </div>
            </>
          )}
        </div>
        {!done && (
          <div className="pt-3">
            <div className="d-flex pt-3 align-items-center text-right w-100">
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-light btn-text btn-sm ml-auto float-right"
                buttonText="Shipped"
                saving={saving}
                onClick={this.handleClickOrderShipped}
              />
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-light btn-text btn-sm ml-2 float-right"
                buttonText={
                  order.shipment.length === 0 && order.transport !== T_EUSTOCK
                    ? "Arrived at starting location"
                    : "Save Shipment"
                }
                saving={saving}
                onClick={this.handleSaveShipment}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WorkflowShipmentCard;
