import React from "react";
import _ from "lodash";
import { Input } from "./Input";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { I_PAYMENTTARGETS } from "../../utils/invoiceUtils";
import { CO_DELIVERYTERMS } from "../../utils/customerOrderUtils";
import { Textarea } from "./Textarea";

export interface CustomerTermOptions {
  id: string;
  title?: string;
  paymentTerm: SelectOption;
  deliveryTerm: SelectOption;
  deliveryCity: string;
  customPaymentTerm: string;
  customPaymentTermCondition: string;
  note: string;
}

interface CustomerTermsProps {
  heading?: string;
  customerTerm: CustomerTermOptions;
  onChangePaymentTerms: (e: SelectOption, id: string) => void;
  onChangeCustomPaymentTerm: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onChangeCustomPaymentTermCondition: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onChangeDeliveryTerms: (e: SelectOption, id: string) => void;
  onChangeDeliveryCity: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onChangeComment: (e: React.ChangeEvent<HTMLTextAreaElement>, id: string) => void;
}

const CustomerTerms: React.FunctionComponent<CustomerTermsProps> = ({
  heading,
  customerTerm,
  onChangePaymentTerms,
  onChangeCustomPaymentTerm,
  onChangeCustomPaymentTermCondition,
  onChangeDeliveryTerms,
  onChangeDeliveryCity,
  onChangeComment,
}) => {
  const { id, title, paymentTerm, deliveryTerm, deliveryCity, customPaymentTerm, customPaymentTermCondition, note } =
    customerTerm;
  const paymentTermArray: Array<SelectOption> = _.cloneDeep(I_PAYMENTTARGETS);
  if (!paymentTermArray.some((pT) => pT.value === "custom")) {
    paymentTermArray.push({ value: "custom", label: "Custom" });
  }
  return (
    <div>
      <div className="fw-bolder text-white fs-3 my-5">{heading ?? `Customer Order Terms - ${title || ""}`}</div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Payment Terms</span>
        <div className="col-8">
          <CustomSelect
            options={paymentTermArray}
            onChange={(e: SelectOption) => onChangePaymentTerms(e, id)}
            matchFormControl={true}
            placeholder={"Please select payment terms"}
            value={paymentTerm}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      {paymentTerm.value === "custom" && (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Custom payment term</span>
          <div className="col-8">
            <div className="row">
              <div className="col-5">
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type="number"
                    value={customPaymentTerm}
                    onChange={(e) => onChangeCustomPaymentTerm(e, id)}
                  />
                  <div className="input-group-append disabled" style={{ opacity: "0.7" }}>
                    <span className="input-group-text form-control custom-form-control">days</span>
                  </div>
                </div>
              </div>
              <div className="col-7 pl-0">
                <Input
                  className="form-control custom-form-control"
                  value={customPaymentTermCondition}
                  onChange={(e) => onChangeCustomPaymentTermCondition(e, id)}
                />
              </div>
            </div>
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      )}
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Terms of Delivery</span>
        <div className="col-8">
          <div className="row">
            <div className="col-4">
              <CustomSelect
                options={CO_DELIVERYTERMS}
                onChange={(e: SelectOption) => onChangeDeliveryTerms(e, id)}
                matchFormControl={true}
                placeholder="Please select delivery terms"
                value={deliveryTerm}
              />
              <small className="mt-1">&nbsp;</small>
            </div>
            <div className="col-8 pl-0">
              <Input
                className="form-control custom-form-control"
                value={deliveryCity}
                placeholder="City of delivery"
                onChange={(e) => onChangeDeliveryCity(e, id)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Additional Notes</span>
        <div className="col-8">
          <Textarea
            className="form-control custom-form-control"
            rows={2}
            value={note}
            placeholder="Additional notes regarding payment or delivery terms..."
            onChange={(e) => onChangeComment(e, id)}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
    </div>
  );
};

export default CustomerTerms;
