import { BSON } from "realm-web";
import { CommoditySnapshot, Duty } from "./commodity.types";
import { Address, Note, OrderFile } from "./commonTypes";
import { CO_TRANSPORT, CustomerOrder } from "./customerOrder.types";
import { Seaport } from "./seaport.types";
import { ContainerValues, LCL_CHARGES } from "../utils/priceCalculationUtils";
import { Currencies } from "../utils/currencyUtils";
import { Airport } from "./airport.types";
import {
  AirFreightCostDefinition,
  AirFreightInsurance,
  PaletteData,
  SeaFreightCostDefinition,
  SeaFreightInsurance,
  SeaportFreightCost,
  WarehouseCostDefinition,
} from "./configuration/calculationConfiguration.types";
import { FinishedProductSnapshot } from "./finishedProduct.types";
import { CustomerContract } from "./customerContract.types";
import { UserData } from "./userData.types";
import { Supplier } from "./supplier.types";

export const SO_REQUESTED = "requested" as const;
export const SO_ORDERCONFIRMED = "orderConfirmed" as const;
export const SO_ARRIVEDATSTARTINGPORT = "supplierOrderArrivedAtStartingPort" as const;
export const SO_SHIPPEDFROMSUPPLIER = "shippedFromSupplier" as const;
export const SO_HANDLEDATCUSTOMS = "handledAtCustoms" as const;
export const SO_SHIPPEDTOWAREHOUSE = "shippedToWarehouse" as const;
export const SO_HANDLEDATWAREHOUSE = "handledAtWarehouse" as const;
export const SO_ARCHIVED = "archived" as const;
export const SO_CANCELED = "canceled" as const;

export type SO_STATES =
  | typeof SO_REQUESTED
  | typeof SO_ORDERCONFIRMED
  | typeof SO_ARRIVEDATSTARTINGPORT
  | typeof SO_SHIPPEDFROMSUPPLIER
  | typeof SO_HANDLEDATCUSTOMS
  | typeof SO_SHIPPEDTOWAREHOUSE
  | typeof SO_HANDLEDATWAREHOUSE
  | typeof SO_ARCHIVED
  | typeof SO_CANCELED;

export const CALC_LCLBASE = "lclBase";
export const CALC_AIRFREIGHT = "airFreight";
export const CALC_SEAFREIGHT = "seaFreight";
export const CALC_EUSTOCK = "euStock";

export interface SupplierOrder {
  _id: BSON.ObjectId;
  orderNo: string; // Auto-incrementing number to identify the order
  createdAt: Date; // Date when order was created
  targetDate: Date; // Date the order should arrive, set when order is created
  etd?: Date; // Estimated time of departure, Date when shipment from supplier starts
  changedETA?: Date | null; // Date that is set when order is delayed
  deliveryDate?: Date; // Date when order arrived at distribution center
  noteInternal: Array<Note>; // Note by internal
  noteSupplier: string; // Note by supplier
  state: SO_STATES;
  supplier: string;
  person: string;
  commodity: CommoditySnapshot | FinishedProductSnapshot; // Commodity snapshot at moment of ordering
  amount: number; // Amount ordered in unit including warehouse amount
  unit: "kg" | "ltr" | "1000 pcs";
  warehouseAmount: number;
  totalTurnover: number; // Total turnover of all customer orders on creation
  priceCommodities: number; // Total price for the commodities
  priceTransport: number; // Total price for transport
  priceCustoms: number; // Total price for customs
  totalWarehouse?: number; // Total price for the warehouse amount
  totalBuffer: number; // Total amount of safety buffer
  totalPrice: number; // Total price
  totalMargin: number; // Total margin
  calculationDetails?: CalculationDetails;
  exchangeRates?: Currencies;
  currency: string;
  customerOrders: Array<string>;
  customerContracts?: Array<string>;
  timeline: Array<SupplierOrderTimelineEntry>;
  files: Array<OrderFile>;
  transport: CO_TRANSPORT;
  shipment: Array<SupplierOrderShipment>;
  purchaseOrderInformation?: PurchaseOrderInformation;
  terms?: SupplierOrderTerms;
}

export interface AdditionalSOInvoiceFileValues {
  totalPrice: number;
}

export interface SupplierOrderExtended
  extends Omit<SupplierOrder, "supplier" | "person" | "customerOrders" | "customerContracts"> {
  supplier: Supplier;
  person: UserData;
  customerOrders: Array<CustomerOrder>;
  customerContracts?: Array<CustomerContract>;
}

export interface SupplierOrderTerms {
  paymentTerms: string;
  paymentTermConditions?: string;
  deliveryTerms: string;
  notify?: string;
}

export interface PurchaseOrderInformation {
  startingSeaport?: Seaport;
  startingAirport?: Airport;
  startingEUWarehouse?: Address;
  shippingInstruction: string;
}

export interface CalculationDetails {
  type: typeof CALC_LCLBASE | typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof CALC_EUSTOCK; // We will probably have different types of calculations in the future
  details:
    | LCLBaseCalculationDetails
    | AirFreightCalculationDetails
    | SeaFreightCalculationDetails
    | EUStockCalculationDetails;
  adjustedCalculation?: AdjustedCalculationDetails;
}

export interface AdjustedCalculationEntry {
  _id: BSON.ObjectId;
  value: number;
  description: string;
  document?: OrderFile;
}

export interface AdjustedCalculationDetails {
  totalTransportationCost?: Array<AdjustedCalculationEntry>;
  totalInsuranceCost?: Array<AdjustedCalculationEntry>;
  totalCustomsCost?: Array<AdjustedCalculationEntry>;
  totalWarehouseCost?: Array<AdjustedCalculationEntry>;
  totalFollowUpCost?: Array<AdjustedCalculationEntry>;
}

export interface LCLBaseCalculationDetails {
  // Total values resulting from the calculation
  finalValues: {
    totalCommodityPrice: number;
    totalWarehouseCost: number; // cost of commodity amount to be put on stock
    totalWarehouseHandlingCost?: number; // handling cost at the glomm warehouse
    totalDockage: number;
    totalB2bFollowUpCost: number;
    totalCustomsCost: { duty: number; flatRate: number; additionalFee: number; total: number };
    totalSeaFreight: { cost: number; cbm: boolean };
    totalLclCharges: { cost: number; cbm: boolean };
    totalCost: number;
    totalPricePerUnit: number;
    totalInsuranceCost?: number;
  };
  // Base vales the calculation was based on
  baseValues: {
    cbm: number;
    weight: number;
    palettes: number;
    lclCharges: typeof LCL_CHARGES;
    dockage: number;
    customsFlatRate: number;
    customsFeeAgency: number;
    seaFreightCost: number;
    b2bFollowUpCost: number;
    warehouseCost: WarehouseCostDefinition;
    paletteData: PaletteData;
    duty: Duty;
  };
}

export interface AirFreightCalculationDetails {
  // Total values resulting from the calculation
  finalValues: {
    totalCommodityPrice: number;
    totalWarehouseCost?: number; // cost of commodity amount to be put on stock
    totalWarehouseHandlingCost?: number; // handling cost at the glomm warehouse
    totalAirFreightCost: number;
    totalAirportCost: number;
    totalFollowUpCost: number;
    totalB2bFollowUpCost: number;
    totalAdditionalFee: number;
    totalDuty: number;
    totalCost: number;
    totalPricePerUnit: number;
    totalInsuranceCost?: number;
  };
  // Base vales the calculation was based on
  baseValues: {
    cbm: number;
    weight: number;
    palettes: number;
    otherCost: AirFreightCostDefinition;
    airportStorageTime: number;
    customsFeeAgency: number;
    customsFreightCoefficient: number;
    airFreightCost: { cost: number; currency: string };
    b2bFollowUpCost: number;
    warehouseCost: WarehouseCostDefinition;
    paletteData: PaletteData;
    duty: Duty;
    insurance: AirFreightInsurance; // Percentage of commodity value
  };
}
export interface SeaFreightCalculationDetails {
  // Total values resulting from the calculation
  finalValues: {
    totalCommodityPrice: number;
    totalWarehouseCost?: number; // cost of commodity amount to be put on stock
    totalWarehouseHandlingCost?: number; // handling cost at the glomm warehouse
    totalFCLCost: { freight: number; followUp: number; total: number };
    totalLCLCost: { freight: number; followUp: number; total: number };
    totalCustomsCost: { duty: number; flatRate: number; additionalFee: number; total: number };
    totalB2bFollowUpCost: number;
    totalCost: number;
    totalPricePerUnit: number;
    totalInsuranceCost?: number;
  };
  // Base vales the calculation was based on
  baseValues: {
    cbm: number;
    weight: number;
    palettes: number;
    seaFreightCost: SeaportFreightCost;
    otherCost: SeaFreightCostDefinition;
    containerValues: ContainerValues;
    breakEvenPoints: BreakEvenPoints;
    customsFlatRate: number;
    customsFeeAgency: number;
    b2bFollowUpCost: number;
    warehouseCost: WarehouseCostDefinition;
    paletteData: PaletteData;
    duty: Duty;
    insurance: SeaFreightInsurance; // Percentage of commodity value
  };
}

// null === Infinity
export interface BreakEvenPoints {
  "20": number | null;
  "40": number | null;
  "40HC": number | null;
}

export interface EUStockCalculationDetails {
  // Total values resulting from the calculation
  finalValues: {
    totalCommodityPrice: number;
    totalWarehouseCost?: number; // cost of commodity amount to be put on stock
    totalWarehouseHandlingCost: number; // handling cost at the glomm warehouse
    totalFollowUpCost: number;
    totalB2bFollowUpCost: number;
    totalCost: number;
    totalPricePerUnit: number;
  };
  // Base vales the calculation was based on
  baseValues: {
    cbm: number;
    weight: number;
    palettes: number;
    b2bFollowUpCost: number;
    paletteData: PaletteData;
    warehouseCost: WarehouseCostDefinition;
  };
}

// Exclusive partial states
export const SO_PARTIAL_DONE = "done" as const;

export type SO_PARTIAL_STATES =
  | typeof SO_ARRIVEDATSTARTINGPORT
  | typeof SO_SHIPPEDFROMSUPPLIER
  | typeof SO_HANDLEDATCUSTOMS
  | typeof SO_SHIPPEDTOWAREHOUSE
  | typeof SO_HANDLEDATWAREHOUSE
  | typeof SO_PARTIAL_DONE;

export interface SupplierOrderShipment {
  _id: BSON.ObjectId;
  state: SO_PARTIAL_STATES;
  deliveryDate?: Date;
  amount: number;
  timeline: Array<SupplierOrderTimelineEntry>;
  shipping: {
    trackingNumber: string;
    arrivedAtStartingPort?: Date;
    shipped?: Date;
    eta?: Date;
    startingPoint: Seaport | Airport | Address | string;
    destination?: Seaport | Airport | Address | string;
  };
  processing?: { inspected: boolean; retentionSampleTaken: boolean; bolChecked: boolean };
}

// State updated
export const SO_T_CREATED = "created" as const;
export const SO_T_CONFIRMED = "orderConfirmed" as const;
export const SO_T_ARRIVEDATSTARTINGPORT = "supplierOrderTimelineArrivedAtStartingPort" as const;
export const SO_T_TRACKINGNOCHANGED = "trackingNoChanged" as const;
export const SO_T_SHIPPED = "orderShipped" as const;
export const SO_T_CUSTOMS = "orderArrivedAtCustoms" as const;
export const SO_T_SHIPPEDWAREHOUSE = "orderShippedToWarehouse" as const;
export const SO_T_ARRIVEDWAREHOUSE = "orderArrivedAtWarehouse" as const;
export const SO_T_ARCHIVED = "orderArchived" as const;
export const SO_T_CANCELED = "orderCanceled" as const;

export const SO_TIMELINE_STATE_UPDATES: Array<SO_TIMELINETYPE> = [
  SO_T_CREATED,
  SO_T_CONFIRMED,
  SO_T_ARRIVEDATSTARTINGPORT,
  SO_T_TRACKINGNOCHANGED,
  SO_T_SHIPPED,
  SO_T_CUSTOMS,
  SO_T_SHIPPEDWAREHOUSE,
  SO_T_ARRIVEDWAREHOUSE,
  SO_T_ARCHIVED,
  SO_T_CANCELED,
];

// Document updates
export const SO_T_DOCUMENTUPLOADED = "documentUploaded" as const;
export const SO_T_DOCUMENTREPLACED = "documentReplaced" as const;
export const SO_T_DOCUMENTREMOVED = "documentRemoved" as const;
export const SO_T_BOLUPLOADED = "bolUploaded" as const;
export const SO_T_PACKLISTUPLOADED = "packagingListUploaded" as const;
export const SO_T_COAUPLOADED = "coaUploaded" as const;
export const SO_T_CUSTOMSINVOICEUPLOADED = "customsInvoiceUploaded" as const;

// Processing updates
export const SO_T_BATCHCREATED = "batchCreated" as const;
export const SO_T_BATCHATTACHED = "batchAttached" as const;
export const SO_T_GOODSINSPECTED = "incomingGoodsInspected" as const;
export const SO_T_RETENTIONSAMPLE = "retentionSampleTaken" as const;
export const SO_T_BOLCHECK = "bolChecked" as const;
export const SO_T_TERMSUPDATED = "termsUpdated" as const;
export const SO_T_CO_CANCELED = "coCanceled" as const;

// Partial Orders
export const SO_T_PARTIALSHIPMENTCREATED = "partialShipmentCreated" as const;
export const SO_T_PARTIALSHIPMENTFINISHED = "partialShipmentFinished" as const;
export const SO_T_CHANGEDETA = "changeETA" as const;
export const SO_T_CHANGEDETD = "changeETD" as const;

// Actions
export const SO_T_UPDATEDCOMMODITYSNAPSHOT = "updateSOCommoditySnapshot" as const;
export const SO_T_EDITED = "supplierOrderEdited" as const;
export const SO_T_CALCULATIONUPDATED = "supplierOrderCalculationUpdated" as const;
export const SO_T_COCALCULATIONUPDATED = "supplierOrderCOCalculationUpdated" as const;
export const SO_T_CALCULATION_ADJUSTED = "supplierOrderCalculationAdjusted" as const;
export const SO_T_CALCULATION_RESET = "supplierOrderCalculationReset" as const;

export type SO_TIMELINETYPE =
  | typeof SO_T_CREATED
  | typeof SO_T_EDITED
  | typeof SO_T_CALCULATIONUPDATED
  | typeof SO_T_CALCULATION_ADJUSTED
  | typeof SO_T_CALCULATION_RESET
  | typeof SO_T_COCALCULATIONUPDATED
  | typeof SO_T_CONFIRMED
  | typeof SO_T_ARRIVEDATSTARTINGPORT
  | typeof SO_T_SHIPPED
  | typeof SO_T_CUSTOMS
  | typeof SO_T_SHIPPEDWAREHOUSE
  | typeof SO_T_ARRIVEDWAREHOUSE
  | typeof SO_T_TRACKINGNOCHANGED
  | typeof SO_T_ARCHIVED
  | typeof SO_T_CANCELED
  | typeof SO_T_DOCUMENTUPLOADED
  | typeof SO_T_DOCUMENTREPLACED
  | typeof SO_T_DOCUMENTREMOVED
  | typeof SO_T_BATCHCREATED
  | typeof SO_T_BATCHATTACHED
  | typeof SO_T_GOODSINSPECTED
  | typeof SO_T_RETENTIONSAMPLE
  | typeof SO_T_BOLCHECK
  | typeof SO_T_TERMSUPDATED
  | typeof SO_T_PARTIALSHIPMENTCREATED
  | typeof SO_T_PARTIALSHIPMENTFINISHED
  | typeof SO_T_BOLUPLOADED
  | typeof SO_T_PACKLISTUPLOADED
  | typeof SO_T_COAUPLOADED
  | typeof SO_T_CUSTOMSINVOICEUPLOADED
  | typeof SO_T_CHANGEDETA
  | typeof SO_T_CHANGEDETD
  | typeof SO_T_UPDATEDCOMMODITYSNAPSHOT
  | typeof SO_T_CO_CANCELED;

export interface SupplierOrderTimelineEntryPayload {
  type?: string;
  checked?: boolean;
  reference?: string;
  name?: string;
  reason?: string;
  calculation?: string;
}

export interface SupplierOrderTimelineEntry {
  // Might be incomplete
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: SO_TIMELINETYPE; // What happened?
  person: string;
  payload: SupplierOrderTimelineEntryPayload | null; // Additional data for certain types, tbd
}
