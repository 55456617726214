import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

interface SettingProps {
  type: "userData" | "supplier" | "notification" | "packagingDimension";
}

class Setting extends PureComponent<SettingProps> {
  /**
   * Resolves the header of the setting.
   * @returns { string } Header text
   */
  resolveHeader = () => {
    const { type } = this.props;
    switch (type) {
      case "userData":
        return "User Profile";
      case "supplier":
        return "Company";
      case "notification":
        return "Notifications";
      case "packagingDimension":
        return "Palette Data";
      default:
        return "Unknown";
    }
  };

  /**
   * Resolves the description of the setting.
   * @returns { string } Description text
   */
  resolveBody = () => {
    const { type } = this.props;
    switch (type) {
      case "userData":
        return "View your personal data and adjust information like your position, emails or phone numbers.";
      case "supplier":
        return (
          "View the data of your company like name, address and contact information. If you are the responsible " +
          "of your company you can also adjust this data."
        );
      case "notification":
        return "Adjust your notification settings. Configure when and how often you receive notifications.";
      case "packagingDimension":
        return "Create and manage palette data which you use for shipping your ingredients.";
      default:
        return "Unknown";
    }
  };

  /**
   * Resolves the link to configure the setting.
   * @returns { string } Link to setting configuration
   */
  resolveLink = () => {
    const { type } = this.props;
    switch (type) {
      case "userData":
        return "/profile";
      case "supplier":
        return "/supplier";
      case "notification":
        return "/notificationSettings";
      case "packagingDimension":
        return "/packagingDimensions";
      default:
        return "/";
    }
  };

  render() {
    return (
      <div className="card h-100" style={{ backgroundColor: "#232323", border: "none" }}>
        <div className="card-body">
          <h1>{this.resolveHeader()}</h1>
          <p className="fs-5 text-white">{this.resolveBody()}</p>
        </div>
        <div className="card-footer" style={{ border: "none" }}>
          <Link className="btn btn-outline btn-outline-light float-right" to={this.resolveLink()}>
            Configure
          </Link>
        </div>
      </div>
    );
  }
}

export default Setting;
