import _ from "lodash";
import React, { PureComponent, useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import BaseListing from "../../common/BaseListing";
import { Company } from "../../../model/company.types";
import CreateCustomerModal from "./modals/CreateCustomerModal";
import CustomerListingFilter from "../../customers/internal/CustomerListingFilter";
import { DataContextInternalType } from "../../../context/dataContext";
import {
  doFuseSearch,
  formatCurrency,
  formatDate,
  formatDateTime,
  getComponentState,
  getDocFromCollection,
  pluralize,
} from "../../../utils/baseUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import { SelectOption } from "../../common/CustomSelect";
import {
  C_SORTOPTIONS,
  calculateReliability,
  getCustomerStatistics,
  getOpenInvoices,
  updateCompany,
  getCustomerOrders,
} from "../../../utils/companyUtils";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";
import { I_PAYMENTTARGETS } from "../../../utils/invoiceUtils";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import { CustomerStatistics } from "../../../model/statistics/customerStatistics.types";
import Tooltip from "../../common/Tooltip";
import ReactStarsWrapper from "../../common/ReactStarsWrapper";
import { UserData } from "../../../model/userData.types";

interface CustomerListingProps {
  context: DataContextInternalType;
}

interface CustomerListingState extends PaginationState {
  search: string;
  generalFilter?: SelectOption;
  sortBy: SelectOption;
  sortOrder: SelectOption;
}

const COMPONENT_NAME = "CustomerListing";

class CustomerListing extends PureComponent<CustomerListingProps, CustomerListingState> {
  constructor(props: CustomerListingProps) {
    super(props);
    this.state = {
      pageSize: 25,
      currentPage: 1,
      search: "",
      sortBy: C_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: "",
        currentPage: 1,
        sortBy: C_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: val,
        currentPage: 1,
        sortBy: { value: "", label: "Best Match" },
        sortOrder: SORTORDEROPTIONS[0],
      });
  };
  handleChangeSelect = (name: string, entry: SelectOption | undefined) => {
    //@ts-ignore
    if (["sortBy", "sortOrder"].includes(name)) this.setState({ [name]: entry });
    //@ts-ignore
    this.setState({ [name]: entry, currentPage: 1 });
  };

  getFilteredCompanies = () => {
    const { context } = this.props;
    const { company } = context;
    const { search, sortBy, sortOrder, generalFilter } = this.state;
    let filteredCompanies = _.cloneDeep(company);
    if (search.trim()) filteredCompanies = doFuseSearch(filteredCompanies, search, ["name"]);
    //TODO Some Filters are not defined yet: 1.'Filter results'. 2.'Sort Results': commodities,activity & open/late orders Issue: RB-180
    if (generalFilter) {
      if (generalFilter.value === "goodRating") {
        filteredCompanies = filteredCompanies.filter((c) => c.rating >= 3);
      } else if (generalFilter.value === "badRating") {
        filteredCompanies = filteredCompanies.filter((c) => c.rating < 3);
      }
    }
    // Handle sorting for search
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return filteredCompanies.reverse();
      return filteredCompanies;
    }
    return _.orderBy(filteredCompanies, sortBy.value, sortOrder.value as "asc" | "desc");
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, search, generalFilter, sortBy, sortOrder } = this.state;
    const filteredCompanies = this.getFilteredCompanies();
    const headerDefinition = [
      { title: "Customer" },
      { title: "Orders" },
      { title: "Invoices" },
      { title: "Payment" },
      { title: "Reliability", className: "text-center" },
      { title: "Country", className: "text-center" },
      { title: "Rating" },
      { title: "YTY Quantity", className: "text-right text-nowrap" },
    ];
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Customers</span>
                  <CreateCustomerModal />
                </h3>
                <CustomerListingFilter
                  search={search}
                  generalFilter={generalFilter}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  onSearch={this.handleSearch}
                  onGeneralFilterChange={(e) => this.handleChangeSelect("generalFilter", e)}
                  onSortByChange={(e) => this.handleChangeSelect("sortBy", e)}
                  onSortOrderChange={(e) => this.handleChangeSelect("sortOrder", e)}
                />
                <BaseListing
                  headerDefinition={headerDefinition}
                  documents={filteredCompanies}
                  bodyContent={
                    <>
                      {filteredCompanies.length > 0 ? (
                        paginate(filteredCompanies, currentPage, pageSize).map((c) => (
                          <CustomerListingRowRouter key={c._id.toString()} company={c} context={context} />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center">
                            <span>No Customers found</span>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handlePageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface CustomerListingRowProps extends RouteComponentProps {
  company: Company;
  context: DataContextInternalType;
}

const CustomerListingRow: React.FunctionComponent<CustomerListingRowProps> = ({ company, context, history }) => {
  const { currencies, invoice, customerOrder: orders } = context;

  const handleRatingChange = async (rating: number) => {
    if (rating < 0 || rating > 5) return;
    const result = await updateCompany({ rating }, company._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Company rating updated successfully");
    } else {
      toast.error("Error updating company rating");
    }
  };

  const [statistics, setStatistics] = useState<Partial<CustomerStatistics> | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const openOrders = getCustomerOrders(company._id, orders, true);
  const [openInvoices, openAmount] = getOpenInvoices(company._id, invoice, BASE_CURRENCY, currencies);
  const reliability = calculateReliability(invoice.filter((i) => i.company === company._id.toString()));

  const forwardCustomer = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    history.push(`/customer/${company._id.toString()}`);
  };

  useEffect(() => {
    getCustomerStatistics(company._id, ["ytyQuantity"]).then((res) => {
      setStatistics(res);
      setLoading(false);
    });
  }, []);

  return (
    <tr className="cursor-pointer-row" onClick={forwardCustomer}>
      <td className="align-middle">
        <Link
          onClick={(e) => e.stopPropagation()}
          className="text-white fs-5 mb-1 custom-link"
          to={`/customer/${company._id.toString()}`}
        >
          {company.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
          {!company.activated && !company.disabled && <span className="text-warning mr-2">[PENDING]</span>}
          {company.name}
        </Link>
        <ContactPersonWidget
          person={getDocFromCollection(context.userData, company.primaryPerson) as UserData}
          spanClasses="text-muted fw-bold d-block"
        />
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className={openOrders[1].length > 0 ? "text-success" : "text-muted"}>{openOrders[1].length} in time</div>
          <div className={openOrders[2].length > 0 ? "text-danger" : "text-muted"}>{openOrders[2].length} late</div>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <span className={openInvoices.length > 0 ? "text-white" : "text-muted"}>
            {pluralize(openInvoices.length, "invoice")}
          </span>
          <br />
          <span className={openAmount > 0 ? "text-danger" : "text-muted"}>
            {formatCurrency(openAmount, BASE_CURRENCY)}
          </span>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <span className="text-white">
            {company.paymentTerms
              ? company.paymentTerms.paymentTarget + " " + company.paymentTerms.paymentTargetConditions
              : company.paymentTarget !== null && company.paymentTarget !== undefined
              ? I_PAYMENTTARGETS.find((pt) => pt.value === company.paymentTarget?.toString())?.label
              : "-"}
          </span>
          <br />
          <span className="text-muted">
            Limit: {company.paymentTarget === -1 ? "-" : formatCurrency(company.creditLimit, BASE_CURRENCY)}
          </span>
        </div>
      </td>
      <td className="align-middle text-center">
        <div className="fs-6">
          <div className="progress" style={{ backgroundColor: "#232323", height: 5 }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: reliability + "%",
                color: "red",
                backgroundColor: reliability > 95 ? "#50cd89" : reliability > 80 ? "#ffc700" : "#f1416c",
              }}
            />
          </div>
          <span className="text-muted">{reliability ? reliability.toFixed(2) + "% " : "-"}</span>
        </div>
      </td>
      <td className="align-middle text-center py-4">
        <div className="fs-6">
          <div className="d-flex flex-center flex-column">
            <CompanyCountryWidget company={company} wrapperClasses={"fw-bold text-muted"} />
          </div>
        </div>
      </td>
      <td className="align-middle text-center">
        <div
          className="fs-6"
          style={{ minWidth: "70px" }}
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
          {company.rating < 0 ? (
            "-"
          ) : (
            <ReactStarsWrapper value={company.rating} count={5} size={14} onChange={handleRatingChange} />
          )}
        </div>
      </td>
      <td className="align-middle text-right">
        <div className="fs-6">
          {loading ? (
            <div className="text-muted">Loading...</div>
          ) : statistics && statistics.ytyQuantity ? (
            <Tooltip
              tooltipText={
                <>
                  <div className="text-white text-left">
                    Period:{" "}
                    {`${formatDate(statistics.ytyQuantity.startDate)} - ${formatDate(statistics.ytyQuantity.endDate)}`}
                  </div>
                  <div className="text-white text-left">
                    Last Update: {formatDateTime(statistics.ytyQuantity.lastUpdate)}
                  </div>
                </>
              }
            >
              <div>
                {Object.entries(statistics.ytyQuantity.quantity).map(([unit, amount], idx) => (
                  <div key={idx} className="text-white text-nowrap">{`${amount.toLocaleString()}${unit}`}</div>
                ))}
                <div className="text-muted fs-7 text-nowrap">
                  {pluralize(statistics.ytyQuantity.orderCount, "order")}
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className="text-muted">N/A</div>
          )}
        </div>
      </td>
    </tr>
  );
};

const CustomerListingRowRouter = withRouter(CustomerListingRow);

export default CustomerListing;
