import { BSON } from "realm-web";
import userService from "./userService";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  DataContextSupplierType,
} from "../context/dataContext";
import {
  buildAnonymousContext,
  buildCustomerContext,
  buildInternalContext,
  buildSupplierContext,
} from "../utils/dataContextUtils";

// Collections
export const ACTIVESUBSTANCE = "activeSubstance";
export const AIRPORT = "airport";
export const BATCH = "batch";
export const COMMODITY = "commodity";
export const COMMODITYSTATISTICS = "commodityStatistics";
export const COMPANY = "company";
export const CONFIGURATION = "configuration";
export const CUSTOMERCONTRACT = "customerContract";
export const CUSTOMERORDER = "customerOrder";
export const CUSTOMERREQUEST = "customerRequest";
export const CUSTOMERSTATISTICS = "customerStatistics";
export const FINISHEDPRODUCT = "finishedProduct";
export const FORWARDINGORDER = "forwardingOrder";
export const STORAGEORDER = "storageOrder";
export const GENERAL = "general";
export const INVOICE = "invoice";
export const NEWS = "news";
export const NOTIFICATION = "notification";
export const NOTIFY = "notify";
export const PRICEHISTORY = "priceHistory";
export const PRICEGRAPH = "priceGraph";
export const PROPERTY = "property";
export const SERVICE = "service";
export const SUPPLIERORDER = "supplierOrder";
export const SAMPLEORDER = "sampleOrder";
export const SEAPORT = "seaport";
export const SUPPLIER = "supplier";
export const SUPPLIERSTATISTICS = "supplierStatistics";
export const USERDATA = "userData";
export const FAVORITES = "favorites";
export const COMMODITYOFFERREQUEST = "commodityOfferRequest";
export const VERSIONHISTORY = "versionHistory";
export const WATCHLIST = "watchlist";
export const CONTROLLINGSTATISTICS = "controllingStatistics";
export const SYSTEMNOTIFICATION = "systemNotification";
export const CONFIRMATIONCODE = "confirmationCode";
export const FORWARDER = "forwarder";

// Functions
export const INSERTMULTIPLEDOCUMENTS = "insertMultipleDocuments";
export const TRANSACTION = "transaction";
export const DELETEUSER = "deleteUser";
export const CHECKTOKENVALIDITY = "checkTokenValidity";

export type Action = UpdateAction | InsertAction;

export const UPDATE = "UPDATE";
export const INSERT = "INSERT";

export interface UpdateAction {
  /** Optional, in case of undefined the action defaults to update */
  action?: typeof UPDATE;
  collection: string;
  filter: object;
  update?: object;
  push?: object;
  pull?: object;
  replace?: object;
  inc?: object;
  unset?: object;
  arrayFilters?: object;
}

export interface InsertAction {
  action: typeof INSERT;
  collection: string;
  object: object;
}

export function getDb() {
  return userService
    .getUser()
    ?.mongoClient("mongodb-atlas")
    .db(process.env.REACT_APP_DATABASE || "");
}

/**
 * Calls the given function with the given args in backend.
 * @param functionName Name of the function that should be called
 * @param args Arguments that should be passed to the function
 * @returns { Promise<any> } Return value of the function
 */
export async function callFunction<T>(functionName: string, args: Array<any>): Promise<T> {
  return userService.getUser()!.callFunction<T>(functionName, [...args]);
}

/**
 * Calls the insert multiple documents function in the backend.
 * @param documents List of documents that should be inserted
 * @param collection Name of the collection
 * @returns { Promise<boolean> } Indicating success of the call
 */
export async function insertMultipleDocuments<T>(documents: Array<T>, collection: string): Promise<boolean> {
  return callFunction(INSERTMULTIPLEDOCUMENTS, [documents, collection]);
}

/**
 * Wrapper to query a whole database collection.
 * @param collection Name of the collection that should be queried
 * @returns { Promise<Array<T>> } Contains all documents of the queried collection
 */
export async function getCollectionDB<T>(collection: string): Promise<Array<T>> {
  let result = [];
  try {
    result = await getDb()!.collection(collection).find();
  } catch (e) {
    console.error("ERROR IN GET CDB:", e);
  }
  return result as Array<T>;
}

/**
 * Wrapper to query a whole database collection.
 * @param collection Name of the collection that should be queried
 * @param query Filter for the find
 * @returns { Promise<Array<T>> } Contains all documents of the queried collection
 */
export async function getCollectionDBWithQuery<T>(
  collection: string,
  query: Record<string, unknown>
): Promise<Array<T>> {
  let result = [];
  try {
    result = await getDb()!.collection(collection).find(query);
  } catch (e) {
    console.error("ERROR IN GET CDB:", e);
  }
  return result as Array<T>;
}

/**
 * Retrieve the document with the referenced ID from the referenced collection.
 * @param collection Name of the collection from which the document should be retrieved
 * @param _id ID of the document
 * @returns { Promise<any | boolean> } Document or false if not found
 */
export async function getDocumentDB<T>(collection: string, _id: BSON.ObjectId | string): Promise<T> {
  return (await getDb()!
    .collection(collection)
    .findOne({ _id: new BSON.ObjectId(_id.toString()) })) as T;
}

/**
 * Wrapper for proper typing of the context for internals.
 * @returns { Promise<DataContextInternalType> } DataContext for internal user.
 */
export async function getInternalContext(): Promise<DataContextInternalType> {
  return buildInternalContext();
}

/**
 * Wrapper for proper typing of the context for customers.
 * @returns { Promise<DataContextCustomerType> } DataContext for customer user.
 */
export async function getCustomerContext(): Promise<DataContextCustomerType> {
  return buildCustomerContext();
}

/**
 * Wrapper for proper typing of the context for anonymous users.
 * @returns { Promise<DataContextSupplierType> } DataContext for anonymous user.
 */
export async function getAnonymousContext(): Promise<DataContextAnonymousType> {
  return buildAnonymousContext();
}

/**
 * Wrapper for proper typing of the context for suppliers.
 * @returns { Promise<DataContextSupplierType> } DataContext for supplier user.
 */
export async function getSupplierContext(): Promise<DataContextSupplierType> {
  return buildSupplierContext();
}

/**
 * Performs multiple database actions as a transaction.
 * @param actions Actions that should be performed as transaction
 * @returns { Promise<boolean> } True if the transaction was successful, False if not
 */
export async function transaction(actions: Array<Action>): Promise<boolean> {
  return callFunction(TRANSACTION, [actions]);
}

/**
 * Receive a change stream iterator for the given collection.
 * @param collection Name of the collection
 * @param options Contains a filter or ids
 * @returns ChangeStreamIterator of the collection
 */
export function receiveStreamIterator(collection: string, options?: unknown) {
  const dbCol = getDb()?.collection(collection);
  if (!dbCol) {
    return;
  }
  return dbCol.watch(options);
}

/**
 * Listen to updated on db collection and call function to update the context
 * @param collection the collection to listen to
 * @param updateDatabase function called on receiving database change to update context
 */
async function listen(
  collection: string,
  updateDatabase: (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => void
) {
  const stream = receiveStreamIterator(collection);
  if (!stream) return;
  try {
    for await (const change of stream) {
      updateDatabase(change);
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * Register all listener for the internal collections.
 * @param updateDatabase Function to update the collection
 */
export function listenerInternal(
  updateDatabase: (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => void
) {
  listen(ACTIVESUBSTANCE, updateDatabase);
  listen(AIRPORT, updateDatabase);
  listen(BATCH, updateDatabase);
  listen(CONFIGURATION, updateDatabase);
  listen(COMMODITY, updateDatabase);
  listen(COMPANY, updateDatabase);
  listen(CUSTOMERORDER, updateDatabase);
  listen(CUSTOMERCONTRACT, updateDatabase);
  listen(CUSTOMERREQUEST, updateDatabase);
  listen(FINISHEDPRODUCT, updateDatabase);
  listen(FORWARDER, updateDatabase);
  listen(FORWARDINGORDER, updateDatabase);
  listen(INVOICE, updateDatabase);
  listen(NEWS, updateDatabase);
  listen(NOTIFICATION, updateDatabase);
  listen(NOTIFY, updateDatabase);
  listen(PRICEHISTORY, updateDatabase);
  listen(PRICEGRAPH, updateDatabase);
  listen(PROPERTY, updateDatabase);
  listen(SERVICE, updateDatabase);
  listen(STORAGEORDER, updateDatabase);
  listen(SUPPLIERORDER, updateDatabase);
  listen(SAMPLEORDER, updateDatabase);
  listen(SEAPORT, updateDatabase);
  listen(SUPPLIER, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(GENERAL, updateDatabase);
  listen(COMMODITYOFFERREQUEST, updateDatabase);
  listen(VERSIONHISTORY, updateDatabase);
  listen(CONTROLLINGSTATISTICS, updateDatabase);
  listen(SYSTEMNOTIFICATION, updateDatabase);
}

/**
 * Register all listener for the customer collections.
 * @param updateDatabase Function to update the collection
 */
export function listenerCustomer(
  updateDatabase: (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => void
) {
  listen(ACTIVESUBSTANCE, updateDatabase);
  listen(COMMODITY, updateDatabase);
  listen(COMPANY, updateDatabase);
  listen(CUSTOMERORDER, updateDatabase);
  listen(CUSTOMERCONTRACT, updateDatabase);
  listen(CUSTOMERREQUEST, updateDatabase);
  listen(FINISHEDPRODUCT, updateDatabase);
  listen(SAMPLEORDER, updateDatabase);
  listen(INVOICE, updateDatabase);
  listen(NEWS, updateDatabase);
  listen(NOTIFICATION, updateDatabase);
  listen(PRICEHISTORY, updateDatabase);
  listen(PRICEGRAPH, updateDatabase);
  listen(PROPERTY, updateDatabase);
  listen(SERVICE, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(FAVORITES, updateDatabase);
  listen(VERSIONHISTORY, updateDatabase);
  listen(SYSTEMNOTIFICATION, updateDatabase);
}

/**
 * Register all listener for the anonymous user collections.
 * @param updateDatabase Function to update the collection
 */
export function listenerAnonymous(
  updateDatabase: (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => void
) {
  listen(ACTIVESUBSTANCE, updateDatabase);
  listen(COMMODITY, updateDatabase);
  listen(COMPANY, updateDatabase);
  listen(CUSTOMERORDER, updateDatabase);
  listen(CUSTOMERCONTRACT, updateDatabase);
  listen(CUSTOMERREQUEST, updateDatabase);
  listen(FINISHEDPRODUCT, updateDatabase);
  listen(SAMPLEORDER, updateDatabase);
  listen(NEWS, updateDatabase);
  listen(NOTIFICATION, updateDatabase);
  listen(PRICEHISTORY, updateDatabase);
  listen(PRICEGRAPH, updateDatabase);
  listen(PROPERTY, updateDatabase);
  listen(SERVICE, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(FAVORITES, updateDatabase);
  listen(VERSIONHISTORY, updateDatabase);
  listen(SYSTEMNOTIFICATION, updateDatabase);
}

/**
 * Register all listener for the supplier collections.
 * @param updateDatabase Function to update the collection
 */
export function listenerSupplier(
  updateDatabase: (change: Realm.Services.MongoDB.ChangeEvent<Realm.Services.MongoDB.Document>) => void
) {
  listen(ACTIVESUBSTANCE, updateDatabase);
  listen(AIRPORT, updateDatabase);
  listen(COMMODITY, updateDatabase);
  listen(FINISHEDPRODUCT, updateDatabase);
  listen(INVOICE, updateDatabase);
  listen(SEAPORT, updateDatabase);
  listen(NOTIFICATION, updateDatabase);
  listen(PRICEHISTORY, updateDatabase);
  listen(PRICEGRAPH, updateDatabase);
  listen(PROPERTY, updateDatabase);
  listen(SERVICE, updateDatabase);
  listen(SUPPLIERORDER, updateDatabase);
  listen(SUPPLIER, updateDatabase);
  listen(USERDATA, updateDatabase);
  listen(FAVORITES, updateDatabase);
  listen(COMMODITYOFFERREQUEST, updateDatabase);
  listen(VERSIONHISTORY, updateDatabase);
  listen(SYSTEMNOTIFICATION, updateDatabase);
}
