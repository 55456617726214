import React from "react";
import { SupplierExtended } from "../../model/supplier.types";
import { CompanyExtended } from "../../model/company.types";
import PersonSummaryWidget from "./PersonSummaryWidget";
import { SupplierSupplierExtended } from "../../model/supplier/supplierSupplier.types";
import { ForwarderExtended } from "../../model/forwarder.types";

interface ContactsCardProps {
  company: CompanyExtended | SupplierExtended | SupplierSupplierExtended | ForwarderExtended;
  type: "company" | "supplier" | "forwarder";
}

const ContactsCard: React.FunctionComponent<ContactsCardProps> = ({ company, type }) => {
  return (
    <div className="card bg-white">
      <div className="card-header border-0 mt-5">
        <div className="card-title flex-column">
          <h2 className="mb-1">Contact Persons</h2>
          <div className="fs-6 fw-bold text-muted">
            {company.persons.length + 1} {company.persons.length === 0 ? "contact person" : "contact persons"} for this{" "}
            {type}
          </div>
        </div>
      </div>
      <div className="card-body p-9 pt-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <PersonSummaryWidget
              company={company}
              type={type}
              description={"Primary Contact"}
              person={company.primaryPerson}
            />
          </div>
          {company.persons.map((p, idx) => (
            <div key={p._id.toString()} className="col-12 col-md-6">
              <PersonSummaryWidget
                company={company}
                type={type}
                description={`Contact #${idx + 1}`}
                person={p}
                isRemovable
                isAsPrimaryAssignable
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactsCard;
