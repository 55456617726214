import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import Setting from "./Setting";
import { CustomToggle } from "../../common/CustomToggle";
import { SettingEntry, SettingType } from "../../../utils/settingsUtils";
import { ADMIN, BOM, SCM } from "../../../utils/userUtils";

interface SettingsProps {}

interface SettingsState {
  activeKey?: SettingType;
}

class Settings extends PureComponent<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {};
  }

  handleClickSettingsType = (activeKey: SettingType) => {
    this.setState({ activeKey: activeKey === this.state.activeKey ? undefined : activeKey });
  };

  render() {
    const { activeKey } = this.state;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Settings</span>
                </h3>
                <div className="col-12 px-0 mb-6">
                  <h3 className="mb-0">Personal</h3>
                </div>
                <div className="row">
                  <div className="col-6 mb-6">
                    <Setting type={SettingEntry.USERDATA} />
                  </div>
                </div>
                <div className="border-bottom-dark-gray" />
                <Accordion>
                  <CustomSettingToggle
                    activeKey={activeKey}
                    settingsKey={SettingType.TRANSPORT}
                    onClickSettingsType={this.handleClickSettingsType}
                  />
                  <Accordion.Collapse eventKey={SettingType.TRANSPORT}>
                    <div className="row">
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.SEAPORTS} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.AIRPORTS} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.NOTIFIES} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.DELIVERY_TIMES} />
                      </div>
                    </div>
                  </Accordion.Collapse>
                  <div className="border-bottom-dark-gray" />
                  <CustomSettingToggle
                    activeKey={activeKey}
                    settingsKey={SettingType.PROPERTIES}
                    onClickSettingsType={this.handleClickSettingsType}
                  />
                  <Accordion.Collapse eventKey={SettingType.PROPERTIES}>
                    <div className="row">
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.PROPERTIES} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.ACTIVE_SUBSTANCES} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.SERVICES} />
                      </div>
                    </div>
                  </Accordion.Collapse>
                  <div className="border-bottom-dark-gray" />
                  <CustomSettingToggle
                    activeKey={activeKey}
                    settingsKey={SettingType.CONFIGURATIONS}
                    onClickSettingsType={this.handleClickSettingsType}
                  />
                  <Accordion.Collapse eventKey={SettingType.CONFIGURATIONS}>
                    <div className="row">
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.SYSTEM_NOTIFICATION} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.PRICE_GRADUATION} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.VERSION_HISTORY} requiresAdmin={true} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.ANONYMOUS_VIEW_ARTICLES} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.RESPONSIBLE} />
                      </div>
                    </div>
                  </Accordion.Collapse>
                  <div className="border-bottom-dark-gray" />
                  <CustomSettingToggle
                    activeKey={activeKey}
                    settingsKey={SettingType.VALUES}
                    onClickSettingsType={this.handleClickSettingsType}
                  />
                  <Accordion.Collapse eventKey={SettingType.VALUES}>
                    <div className="row">
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.GENERAL_VALUES} requiresAdmin={true} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.CALCULATION_VALUES} requiredRole={[ADMIN, SCM]} />
                      </div>
                      <div className="col-6 mb-6">
                        <Setting type={SettingEntry.EXPENSE_VALUES} requiredRole={[ADMIN, BOM]} />
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;

interface CustomSettingToggleProps {
  settingsKey: SettingType;
  onClickSettingsType: (key: SettingType) => void;
  activeKey?: SettingType;
}

const CustomSettingToggle: React.FunctionComponent<CustomSettingToggleProps> = ({
  settingsKey,
  onClickSettingsType,
  activeKey,
}) => {
  const handleClickSettingsType = () => onClickSettingsType(settingsKey);

  return (
    <CustomToggle eventKey={settingsKey}>
      <div className="col-auto px-0 mt-6 mb-6" onClick={handleClickSettingsType}>
        <h3 className="mb-0 cursor-pointer">
          {_.upperFirst(settingsKey)}
          <i className={`custom-accordion-toggle fa fa-chevron-up mx-2 ${activeKey === settingsKey ? "show" : ""}`} />
        </h3>
      </div>
    </CustomToggle>
  );
};
