import React, { useContext } from "react";
import { DataContextCustomer } from "../../../context/dataContext";
import CustomerRequestListing from "./CustomerRequestListing";

interface CustomerRequestListingWrapperProps {}

const CustomerRequestListingWrapper: React.FunctionComponent<CustomerRequestListingWrapperProps> = () => {
  const context = useContext(DataContextCustomer);
  return <CustomerRequestListing context={context} />;
};

export default CustomerRequestListingWrapper;
