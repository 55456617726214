import React, { useContext } from "react";
import { DataContextCustomer } from "../../../context/dataContext";
import CustomerContractListing from "./CustomerContractListing";

const CustomerContractListingWrapper: React.FunctionComponent = () => {
  const context = useContext(DataContextCustomer);
  return <CustomerContractListing context={context} />;
};

export default CustomerContractListingWrapper;
