import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Commodity } from "../../../model/commodity.types";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { DataContextInternalType } from "../../../context/dataContext";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { getFullArticleName } from "../../../utils/productArticleUtils";
import { Action, CONFIGURATION, transaction } from "../../../services/dbService";
import { CONFIG } from "../../../utils/configurationUtils";
import userService from "../../../services/userService";

interface AddArticleToAnonymousViewModalProps {
  context: DataContextInternalType;
  anonymousViewArticles: Array<{ article: Commodity | FinishedProduct; withPrice: boolean }>;
  onSuccess: () => void;
}

interface AddArticleToAnonymousViewModalState {
  commodities: Array<SelectOption>;
  finishedProducts: Array<SelectOption>;
  commodityOptions: Array<SelectOption>;
  finishedProductOptions: Array<SelectOption>;
  saving: boolean;
  show: boolean;
}

class AddArticleToAnonymousViewModal extends PureComponent<
  AddArticleToAnonymousViewModalProps,
  AddArticleToAnonymousViewModalState
> {
  constructor(props: AddArticleToAnonymousViewModalProps) {
    super(props);
    this.state = this.getDefaultState(props, false);
  }

  componentDidUpdate(prevProps: Readonly<AddArticleToAnonymousViewModalProps>) {
    if (
      !_.isEqual(prevProps.context.commodity, this.props.context.commodity) ||
      !_.isEqual(prevProps.context.finishedProduct, this.props.context.finishedProduct) ||
      !_.isEqual(prevProps.anonymousViewArticles, this.props.anonymousViewArticles)
    ) {
      const { commodityOptions, finishedProductOptions } = this.generateOptions(this.props);
      this.setState({ commodityOptions, finishedProductOptions });
    }
  }

  handleShow = () => this.setState(this.getDefaultState(this.props, true));
  handleHide = () => this.setState({ show: false });

  handleChangeArticle = (e: Array<SelectOption>, type: "commodity" | "finishedProduct") => {
    switch (type) {
      case "commodity":
        this.setState({ commodities: e });
        break;
      case "finishedProduct":
        this.setState({ finishedProducts: e });
        break;
    }
  };

  handleSave = async () => {
    const { onSuccess } = this.props;
    const { commodities, finishedProducts } = this.state;
    this.setState({ saving: true });
    try {
      const addedCommodities = commodities.map((c) => {
        return { id: c.value, withPrice: false };
      });
      const addedFinishedProducts = finishedProducts.map((fp) => {
        return { id: fp.value, withPrice: false };
      });
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre: {},
        post: { commodities: addedCommodities, finishedProducts: addedFinishedProducts },
      };
      const action: Action = {
        collection: CONFIGURATION,
        filter: { key: CONFIG.ANONYMOUS },
        update: { lastUpdate: new Date() },
        push: {
          "values.commodities": { $each: addedCommodities },
          "values.finishedProducts": { $each: addedFinishedProducts },
          timeline: timelineEntry,
        },
      };
      const result = await transaction([action]);
      if (result) {
        onSuccess();
        toast.success("Articles successfully added to Anonymous View.");
        this.handleHide();
      } else {
        toast.error("Articles could not be added to Anonymous View. Please try again later.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (
    props: AddArticleToAnonymousViewModalProps,
    show: boolean
  ): AddArticleToAnonymousViewModalState => {
    const { commodityOptions, finishedProductOptions } = this.generateOptions(props);
    return {
      show,
      saving: false,
      commodities: [],
      finishedProducts: [],
      finishedProductOptions,
      commodityOptions,
    };
  };

  generateOptions = (props: AddArticleToAnonymousViewModalProps) => {
    const { context, anonymousViewArticles } = props;
    const commodityOptions: Array<SelectOption> = [];
    const finishedProductOptions: Array<SelectOption> = [];
    const alreadyUsedCommodities = [];
    const alreadyUsedFinishedProducts = [];
    for (let i = 0; i < anonymousViewArticles.length; i++) {
      const aVA = anonymousViewArticles[i].article;
      if (isFinishedProduct(aVA)) alreadyUsedFinishedProducts.push(aVA._id.toString());
      else alreadyUsedCommodities.push(aVA._id.toString());
    }
    for (let i = 0; i < context.commodity.length; i++) {
      const c = context.commodity[i];
      if (!alreadyUsedCommodities.includes(c._id.toString())) {
        commodityOptions.push({ value: c._id.toString(), label: getFullArticleName(c) });
      }
    }
    for (let i = 0; i < context.finishedProduct.length; i++) {
      const fp = context.finishedProduct[i];
      if (!alreadyUsedFinishedProducts.includes(fp._id.toString())) {
        finishedProductOptions.push({ value: fp._id.toString(), label: getFullArticleName(fp) });
      }
    }
    return { commodityOptions, finishedProductOptions };
  };

  render() {
    const { commodities, finishedProducts, commodityOptions, finishedProductOptions, show, saving } = this.state;

    return (
      <>
        <button className="btn btn-sm btn-outline btn-outline-light" onClick={this.handleShow}>
          Add Articles
        </button>
        <Modal show={show} size="lg" contentClassName={"bg-dark"} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Add Articles</h1>
            </Modal.Title>
            <CloseButton variant="white" disabled={saving} onClick={saving ? undefined : this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-12 mt-5">
                <label className="fs-5 fw-bold mb-2">Commodities</label>
                <CustomSelect
                  value={commodities}
                  onChange={(e: Array<SelectOption>) => this.handleChangeArticle(e ?? [], "commodity")}
                  options={commodityOptions}
                  isMulti={true}
                />
              </div>
              <div className="col-12 mt-5">
                <label className="fs-5 fw-bold mb-2">Finished Products</label>
                <CustomSelect
                  value={finishedProducts}
                  onChange={(e: Array<SelectOption>) => this.handleChangeArticle(e ?? [], "finishedProduct")}
                  options={finishedProductOptions}
                  isMulti={true}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={saving}
              className={"btn btn-light btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : this.handleHide}
            >
              Close
            </button>
            <ErrorOverlayButton
              errors={[]}
              className="btn btn-light btn-sm "
              saving={saving}
              buttonText="Save"
              onClick={this.handleSave}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddArticleToAnonymousViewModal;
