import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import {
  SAMO_CONFIRMED,
  SAMO_REJECTED,
  SAMO_REQUESTED,
  SAMO_T_CONFIRMED,
  SAMO_T_REJECTED,
  SampleOrderExtended,
} from "../../../../../../../model/sampleOrder.types";
import { getOrderNumber } from "../../../../../../../utils/orderUtils";
import { Textarea } from "../../../../../../common/Textarea";
import ErrorOverlayButton from "../../../../../../common/ErrorOverlayButton";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../../../../../../utils/sampleOrderUtils";
import { createPDF } from "../../../../../../../utils/pdfUtils";
import { createSampleOrderConfirmationHTML } from "../../../../../../../utils/pdf/sampleOrderCofirmationGenerationUtils";
import { STANDARDTEXTSHORT } from "../../../../../../../utils/pdf/templateUtils";
import { SAMO_CONFIRMATION } from "../../../../../../../utils/sampleOrderUtils";
import { SAMPLEORDER, Action, transaction } from "../../../../../../../services/dbService";
import { formatAddress } from "../../../../../../../utils/addressUtils";
import userService from "../../../../../../../services/userService";

interface RejectSampleRequestModalProps {
  order: SampleOrderExtended;
}

interface RejectSampleRequestModalState {
  reason: string;
  type: "approve" | "reject";
  saving: boolean;
  show: boolean;
}

class HandleSampleRequestModal extends PureComponent<RejectSampleRequestModalProps, RejectSampleRequestModalState> {
  constructor(props: RejectSampleRequestModalProps) {
    super(props);
    this.state = this.getDefaultState(false, "approve");
  }

  handleShow = (type: "approve" | "reject") => this.setState(this.getDefaultState(true, type));
  handleHide = () => this.setState({ show: false });
  handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ reason: e.target.value });

  handleSampleRequest = async () => {
    const { order } = this.props;
    const { reason, type } = this.state;
    this.setState({ saving: true });

    try {
      const timelineEntry = getSampleOrderTimelineEntry(
        type === "approve" ? SAMO_T_CONFIRMED : SAMO_T_REJECTED,
        type === "reject" ? { reason } : undefined
      );
      const targetState = type === "approve" ? SAMO_CONFIRMED : SAMO_REJECTED;
      let res;

      if (type !== "reject") {
        const address = formatAddress(order.company.address[0]);
        const path = await createPDF(
          createSampleOrderConfirmationHTML(order, order.company.name, address, order.company.vat, STANDARDTEXTSHORT),
          "Sample-Order-Confirmation-" + order.orderNo,
          order.company._id.toString(),
          { marginLeft: "2cm" }
        );
        if (!path) {
          console.error("Sample order confirmation could not be created. Please try again later.");
          return;
        }

        const fileEntry = {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: SAMO_CONFIRMATION,
          uploadedBy: userService.getUserId(),
        };
        const action: Action = {
          collection: SAMPLEORDER,
          filter: { _id: order._id },
          update: { state: targetState },
          push: { timeline: timelineEntry, files: fileEntry },
        };
        res = await transaction([action]);
      } else {
        res = await updateSampleOrder({ state: targetState }, order._id, timelineEntry);
      }

      if (res === true || (res && res.res && res.res.modifiedCount > 0)) {
        toast.success("Successfully " + (type === "approve" ? "approved" : "rejected") + " sample order");
        this.setState({ show: false });
      } else {
        toast.error("Error " + (type === "approve" ? "approving" : "rejecting") + " sample order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean, type: "approve" | "reject"): RejectSampleRequestModalState => {
    return { show, saving: false, type, reason: "" };
  };

  validateData = () => {
    const { reason, type } = this.state;
    const errors = [];
    if (type === "reject" && !reason.trim()) errors.push("Please provide a reason");
    return errors;
  };

  render() {
    const { order } = this.props;
    const { reason, saving, show, type } = this.state;
    const errors = this.validateData();

    return (
      <>
        <button
          type="button"
          disabled={order.state !== SAMO_REQUESTED}
          onClick={() => this.handleShow("reject")}
          className={"btn btn-text-white " + (order.state !== SAMO_REQUESTED && "disabled")}
        >
          Reject
        </button>
        <button
          type="button"
          disabled={order.state !== SAMO_REQUESTED}
          onClick={() => this.handleShow("approve")}
          className={"btn btn-outline btn-outline-white ml-2 " + (order.state !== SAMO_REQUESTED && "disabled")}
        >
          Approve
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {type === "approve"
                  ? `Approve Sample Order ${getOrderNumber(order)}`
                  : `Reject Sample Order ${getOrderNumber(order)}`}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="text-white fs-6 mt-10 mb-5">
              <div>
                Are you sure you want to{" "}
                {type === "approve" ? (
                  <span className="text-success">approve</span>
                ) : (
                  <span className="text-danger">reject</span>
                )}{" "}
                the sample request for{" "}
                <em>
                  {order.amount}
                  {order.unit}
                </em>{" "}
                of <em>{order.commodity.title.en}</em>?{type === "reject" && <span> Please provide a reason:</span>}
              </div>
              {type === "reject" && (
                <div className="mt-10">
                  <Textarea
                    className="form-control custom-form-control"
                    rows={2}
                    name="reason"
                    value={reason}
                    onChange={this.handleChangeReason}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-sm btn-text-white " + (saving && "disabled")}
              disabled={saving}
              onClick={saving ? undefined : this.handleHide}
            >
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Confirm"
              onClick={this.handleSampleRequest}
              saving={saving}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default HandleSampleRequestModal;
