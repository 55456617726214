import _ from "lodash";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  SO_ARCHIVED,
  SO_CANCELED,
  SO_T_DOCUMENTREPLACED,
  SO_T_DOCUMENTUPLOADED,
  SupplierOrder,
} from "../../../../model/supplierOrder.types";
import { DataContextInternal } from "../../../../context/dataContext";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { STO_STATES, STO_TIMELINE, StorageOrder, StorageOrderInformation } from "../../../../model/storageOrder.types";
import { formatAddress, getStandardWarehouseAddress } from "../../../../utils/addressUtils";
import { DateType, getOrderNumber, getOrderType } from "../../../../utils/orderUtils";
import {
  getSupplierOrderStartString,
  getSupplierOrderTimelineEntry,
  SO_DELIVERYTERMS,
  SO_ORDERCONFIRMATION,
  SO_STORAGEORDER,
} from "../../../../utils/supplierOrderUtils";
import { createPDF, FOOTER_WITHOUT_FINANCIAL_HTML } from "../../../../utils/pdfUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { Action, SUPPLIERORDER, transaction } from "../../../../services/dbService";
import userService from "../../../../services/userService";
import { createStorageOrder } from "../../../../utils/pdf/storageOrderGenerationUtils";
import { Input } from "../../../common/Input";
import { Textarea } from "../../../common/Textarea";
import DateInput from "../../../common/DateInput";
import CalendarWeekSelector from "../../../common/CalendarWeekSelector";
import WysiwygEditor from "../../../common/WYSIWYGEditor";
import { getLinkForOrderOrContract } from "../../../../utils/contractAndOrderUtils";
import {
  getStorageOrderFile,
  getStorageOrderTimelineEntry,
  insertStorageOrder,
  STO_REMARKS,
  STO_STORAGEORDER,
  updateStorageOrder,
} from "../../../../utils/storageOrderUtils";
import { getBatchPackagingInfo } from "../../../../utils/batchUtils";
import { getEditorStateAsHTML, getStringAsEditorState } from "../../../../utils/wysiwygUtils";
import { ADMIN, SCM } from "../../../../utils/userUtils";
import { CalculationConfiguration } from "../../../../model/configuration/calculationConfiguration.types";
import { CONFIG, getConfiguration } from "../../../../utils/configurationUtils";
import { calculateCBMAndGrossWeight } from "../../../../utils/priceCalculationUtils";
import { AdditionalDateTypeLogistics, DateTypeLogistics } from "../../../../utils/logisticsUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { extendSupplierOrder } from "../../../../utils/dataTransformationUtils";

interface StorageOrderParams {
  id?: string;
}

interface CreateStorageOrderProps extends RouteComponentProps<StorageOrderParams> {
  context: React.ContextType<typeof DataContextInternal>;
}

interface CreateStorageOrderState {
  edit: boolean;
  saving: "draft" | "storageOrder" | false;
  selectedOrder: SelectOption<SupplierOrder>;
  orderInfo: StorageOrderInformation;
  supplierRef: string;
  startIncoterm: SelectOption | undefined;
  startingFrom: string;
  destinationIncoterm: SelectOption | undefined;
  destinationTo: string;
  sender: string;
  recipient: string;
  customsState: boolean;
  delivery: Date;
  deliveryDateType: DateTypeLogistics | undefined;
  remarks: EditorState;
}

class CreateStorageOrder extends PureComponent<CreateStorageOrderProps, CreateStorageOrderState> {
  constructor(props: CreateStorageOrderProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  async componentDidMount() {
    await this.populateData();
  }

  handleChangeOrder = async (e: SelectOption<SupplierOrder>) => {
    const order = e.object;
    const startIncoterm = order?.terms
      ? SO_DELIVERYTERMS.find((t) => t.value === order.terms?.deliveryTerms)
      : undefined;
    const startingFrom = getSupplierOrderStartString(order);
    this.setState({
      orderInfo: await this.getStorageOrderInfo(order),
      selectedOrder: e,
      startIncoterm,
      startingFrom,
    });
  };

  handleStartIncoterm = (e: SelectOption) => {
    this.setState({ startIncoterm: e });
  };

  handleDestinationIncoterm = (e: SelectOption) => {
    this.setState({ destinationIncoterm: e });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangePacking = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const info = _.cloneDeep(this.state.orderInfo);
    info.packaging = e.currentTarget.value;
    this.setState({ orderInfo: info });
  };

  handleChangeWeights = (e: React.ChangeEvent<HTMLInputElement>) => {
    const info = _.cloneDeep(this.state.orderInfo);
    _.set(info, e.target.name, +e.target.value);
    this.setState({ orderInfo: info });
  };

  handleChangeTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.currentTarget.value });
  };

  handleChangeCustomsState = () => {
    this.setState({ customsState: !this.state.customsState });
  };

  handleChangeDelivery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const deliveryDate = new Date(e.target.value);
    if (!deliveryDate) return;
    this.setState({ delivery: deliveryDate });
  };

  handleChangeCWDelivery = (e: Date) => {
    this.setState({ delivery: e });
  };

  handleChangeAsap = () => {
    const { deliveryDateType, selectedOrder, delivery } = this.state;
    if (!selectedOrder.object) return; // Should never happen
    let newDeliveryDate = delivery;
    if (deliveryDateType === AdditionalDateTypeLogistics.ASAP) {
      newDeliveryDate = selectedOrder.object.changedETA ?? selectedOrder.object.targetDate;
    }
    this.setState({
      deliveryDateType:
        deliveryDateType === AdditionalDateTypeLogistics.ASAP ? DateType.CW : AdditionalDateTypeLogistics.ASAP,
      delivery: newDeliveryDate,
    });
  };

  handleChangeCW = () => {
    this.setState({ deliveryDateType: this.state.deliveryDateType === DateType.CW ? undefined : DateType.CW });
  };

  handleChangeRemarks = (e: EditorState) => {
    this.setState({ remarks: e });
  };

  handleCreateDraft = async () => {
    const { context } = this.props;
    const { selectedOrder } = this.state;
    if (!selectedOrder.object) return; // Should never happen
    this.setState({ saving: "draft" });
    try {
      const path = await createPDF(
        createStorageOrder(context, selectedOrder.object, this.getStorageOrder(), true),
        "DRAFT-StorageOrder",
        selectedOrder.object._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
        }
      );
      if (!path) {
        console.error("Storage order draft could not be created. Please try again later");
        return;
      } else {
        window.open(resolveFilePath(path));
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleConfirm = async () => {
    const { context } = this.props;
    const { edit, selectedOrder } = this.state;
    if (!selectedOrder.object) return; // Should never happen
    this.setState({ saving: "storageOrder" });
    const storageOrder = this.getStorageOrder();
    try {
      if (edit) {
        const path = await createPDF(
          createStorageOrder(context, selectedOrder.object, storageOrder),
          "StorageOrder" + storageOrder.storageOrderNo,
          selectedOrder.object._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
          }
        );
        if (!path) {
          console.error("Storage order PDF could not be created. Please try again later");
          return;
        } else {
          const storageOrderFile = getStorageOrderFile(path, STO_STORAGEORDER);
          storageOrder.files.push(storageOrderFile);
          const res = await updateStorageOrder(storageOrder, storageOrder._id);
          if (res && res.res.modifiedCount) {
            let actions: Array<Action> = [];
            const order = selectedOrder.object;
            // replace storage order pdf in order
            const file = order.files.find((f) => f.type === SO_STORAGEORDER);
            if (file) {
              actions.push({
                collection: SUPPLIERORDER,
                filter: { _id: order._id },
                update: {
                  "files.$[f].path": path,
                  "files.$[f].date": new Date(),
                  "files.$[f].uploadedBy": userService.getUserId(),
                },
                arrayFilters: [{ "f._id": file._id }],
                push: {
                  timeline: {
                    $each: [getSupplierOrderTimelineEntry(SO_T_DOCUMENTREPLACED, { type: SO_STORAGEORDER })],
                  },
                },
              });
            } else {
              actions = this.getActionsForUploadOrderFile(path, storageOrder._id.toString());
            }
            await this.redirectAndOpen(actions, path);
          } else {
            toast.error("Error creating storage order PDF");
          }
        }
      } else {
        const stO = await insertStorageOrder(storageOrder); // insert storage order into DB
        if (stO) {
          storageOrder.storageOrderNo = stO.storageOrderNo; // Create PDF with storage number generated by backend
          const path = await createPDF(
            createStorageOrder(context, selectedOrder.object, storageOrder),
            "StorageOrder" + storageOrder.storageOrderNo,
            selectedOrder.object._id.toString(),
            {
              marginLeft: "2cm",
              marginBottom: "4.2cm",
              footerHtml: FOOTER_WITHOUT_FINANCIAL_HTML,
            }
          );
          if (!path) {
            console.error("Storage order PDF could not be created. Please try again later");
            return;
          } else {
            const res = await updateStorageOrder(
              { files: [getStorageOrderFile(path, STO_STORAGEORDER)] },
              stO.res.insertedId
            );
            if (res && res.res.modifiedCount) {
              const actions: Array<Action> = this.getActionsForUploadOrderFile(path, storageOrder._id.toString());
              await this.redirectAndOpen(actions, path);
            } else {
              toast.error("Error creating storage order PDF");
            }
          }
        } else {
          toast.error("Error creating storage order");
        }
      }
    } catch (e) {
      toast.error("An error has occurred on creating the storage order: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  redirectAndOpen = async (actions: Array<Action>, path: string) => {
    const { location, history } = this.props;
    const result = await transaction(actions);
    if (result) {
      window.open(resolveFilePath(path));
      toast.success("Successfully updated related orders");
      const query = new URLSearchParams(location.search);
      if (query.has("redirect")) {
        // Go back to page before
        history.goBack();
      } else history.push("/logistics");
    } else {
      toast.error("Error updating related orders");
    }
  };

  getGrossWeight = async (order: SupplierOrder) => {
    if (order.calculationDetails) {
      return order.calculationDetails.details.baseValues.weight;
    } else {
      // default palette is used
      const config: CalculationConfiguration = await getConfiguration(CONFIG.CALCULATION);
      const defaultPalette = config.values.generalValues.defaultPalette;
      return calculateCBMAndGrossWeight(order.amount, defaultPalette)[1]; // 1 is the grossWeight
    }
  };

  getStorageOrderInfo = async (newOrder?: SupplierOrder) => {
    const { context } = this.props;
    const order = newOrder ?? (this.state.selectedOrder.object as SupplierOrder);
    let packaging = "";
    const bWithPackages = context.batch.filter(
      (b) => b.supplierOrder === order._id.toString() && b.packages.length > 0
    );
    packaging += bWithPackages
      ?.map((b) => {
        return getBatchPackagingInfo(b);
      })
      .join(", \n");
    packaging += "\nPallet type: \nsee also attached Packaging list";
    const grossWeight = (await this.getGrossWeight(order)) || 0;
    return {
      orderId: order._id.toString(),
      orderNo: order.orderNo,
      packaging: packaging,
      netWeight: order.amount,
      grossWeight,
    };
  };

  getActionsForUploadOrderFile = (path: string, storageOrderId: string) => {
    const { selectedOrder } = this.state;
    const order = selectedOrder.object as SupplierOrder;
    const actions: Array<Action> = [];
    // update orders and upload storage order pdf
    const fileEntry = {
      _id: new BSON.ObjectId(),
      date: new Date(),
      path,
      type: SO_STORAGEORDER,
      uploadedBy: userService.getUserId(),
      reference: storageOrderId,
    };
    actions.push({
      collection: SUPPLIERORDER,
      filter: { _id: order._id },
      push: {
        files: fileEntry,
        timeline: {
          $each: [getSupplierOrderTimelineEntry(SO_T_DOCUMENTUPLOADED, { type: SO_STORAGEORDER })],
        },
      },
    });
    return actions;
  };

  getRemarks = () => {
    const blocksFromHTML = convertFromHTML(STO_REMARKS);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  getStorageOrder = () => {
    const { context, match } = this.props;
    const {
      orderInfo,
      supplierRef,
      startIncoterm,
      startingFrom,
      destinationIncoterm,
      destinationTo,
      recipient,
      sender,
      delivery,
      deliveryDateType,
      remarks,
      customsState,
      edit,
    } = this.state;
    const id = match.params.id;
    const stO = context.storageOrder.find((stO) => stO._id.toString() === id);
    const htmlRemarks = getEditorStateAsHTML(remarks);
    const glommAddress = getStandardWarehouseAddress(context, true); // Glomm should always be there, so default only for safety
    const storageOrder: StorageOrder = {
      _id: edit && stO ? stO._id : new BSON.ObjectId(),
      storageOrderNo: edit && stO ? stO.storageOrderNo : "",
      orderInformation: orderInfo,
      state: STO_STATES.STO_CREATED,
      warehouse: glommAddress?.addressString || "",
      customsState: customsState,
      remarks: htmlRemarks,
      files: edit && stO ? stO.files : [],
      createdAt: edit && stO ? stO.createdAt : new Date(),
      timeline: [getStorageOrderTimelineEntry(STO_TIMELINE.STO_T_CREATED)],
    };
    if (supplierRef.trim() !== "") storageOrder.supplierRef = supplierRef;
    if (
      startIncoterm !== undefined ||
      destinationIncoterm !== undefined ||
      startingFrom.trim() !== "" ||
      destinationTo.trim() !== ""
    )
      storageOrder.takeOver = {
        startIncoterm: startIncoterm !== undefined ? startIncoterm.value : undefined,
        startingFrom: startingFrom.trim() !== "" ? startingFrom : undefined,
        destinationIncoterm: destinationIncoterm !== undefined ? destinationIncoterm.value : undefined,
        destinationTo: destinationTo.trim() !== "" ? destinationTo : undefined,
      };
    if (sender.trim() !== "") storageOrder.sender = sender;
    if (recipient.trim() !== "") storageOrder.recipient = recipient;
    if (sender.trim() !== "") storageOrder.sender = sender;
    if (deliveryDateType) storageOrder.deliveryDateType = deliveryDateType;
    if (deliveryDateType !== AdditionalDateTypeLogistics.ASAP) storageOrder.deliveryDate = delivery;

    return storageOrder;
  };

  getAvailableOrdersSelect = () => {
    const { context } = this.props;
    let availableOrders: Array<SelectOption<SupplierOrder>> = [];
    const supplierOrders = context.supplierOrder.filter((sO) => sO.state !== SO_CANCELED && sO.state !== SO_ARCHIVED);
    for (let i = 0; i < supplierOrders.length; i++) {
      if (supplierOrders[i].files.some((f) => f.type === SO_ORDERCONFIRMATION)) {
        availableOrders.push({
          value: supplierOrders[i]._id.toString(),
          label: getOrderNumber(supplierOrders[i]),
          object: supplierOrders[i],
        });
      }
    }
    // filter orders that are already used in storage orders
    availableOrders = availableOrders.filter(
      (aO) =>
        !context.storageOrder.some(
          (stO) => stO.state !== STO_STATES.STO_CANCELED && stO.orderInformation.orderId === aO.object?._id.toString()
        )
    );
    return availableOrders;
  };

  getDefaultState = (props: CreateStorageOrderProps): CreateStorageOrderState => {
    const { context, match, location } = props;
    const id = match.params.id;
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("orderId"); // orderId will be passed on STO creation from SCM dashboard
    const stO = context.storageOrder.find((stO) => stO._id.toString() === id);
    const availableOrders = this.getAvailableOrdersSelect();
    let orderSelected: SelectOption<SupplierOrder> = availableOrders[0];
    // state if storage order is edited
    if (id && stO) {
      const order = context.supplierOrder.find((sO) => sO._id.toString() === stO.orderInformation.orderId);
      if (order) {
        orderSelected = { value: order._id.toString(), label: getOrderNumber(order), object: order };
      }
      return {
        edit: true,
        saving: false,
        selectedOrder: orderSelected,
        supplierRef: stO.supplierRef ? stO.supplierRef : "",
        orderInfo: stO.orderInformation,
        startIncoterm:
          stO.takeOver && stO.takeOver.startIncoterm
            ? SO_DELIVERYTERMS.find((t) => t.value === stO.takeOver?.startIncoterm)
            : undefined,
        startingFrom: stO.takeOver && stO.takeOver.startingFrom ? stO.takeOver.startingFrom : "",
        destinationIncoterm:
          stO.takeOver && stO.takeOver.destinationIncoterm
            ? SO_DELIVERYTERMS.find((t) => t.value === stO.takeOver?.destinationIncoterm)
            : undefined,
        destinationTo: stO.takeOver && stO.takeOver.destinationTo ? stO.takeOver.destinationTo : "",
        sender: stO.sender || "",
        recipient: stO.recipient || "",
        customsState: stO.customsState,
        delivery: stO.deliveryDate || order?.targetDate || new Date(),
        deliveryDateType: stO.deliveryDateType,
        remarks: getStringAsEditorState(stO.remarks),
      };
      // state if storage order is created
    } else {
      // use passed id if storage order is created from an order from the SCM dashboard
      if (orderId) {
        const order = context.supplierOrder.find((sO) => sO._id.toString() === orderId);
        if (order) {
          orderSelected = { value: order._id.toString(), label: getOrderNumber(order), object: order };
        }
      }
      const oSO = orderSelected.object as SupplierOrder;
      return {
        edit: false,
        saving: false,
        selectedOrder: orderSelected,
        supplierRef: "",
        orderInfo: {
          orderId: oSO._id.toString(),
          orderNo: oSO.orderNo,
          packaging: "",
          netWeight: oSO.amount,
          grossWeight: 0,
        },
        startIncoterm: undefined,
        startingFrom: "",
        destinationIncoterm: undefined,
        destinationTo: "",
        sender: "",
        recipient: "",
        customsState: true,
        delivery: oSO.changedETA ?? oSO.targetDate,
        deliveryDateType: AdditionalDateTypeLogistics.ASAP,
        remarks: this.getRemarks(),
      };
    }
  };

  populateData = async () => {
    if (!this.state.edit) {
      const storageOrderInfo = await this.getStorageOrderInfo(this.state.selectedOrder.object);
      this.setState({ orderInfo: storageOrderInfo });
    }
  };

  render() {
    const { context, match, history } = this.props;
    const {
      edit,
      orderInfo,
      selectedOrder,
      supplierRef,
      recipient,
      sender,
      delivery,
      deliveryDateType,
      remarks,
      saving,
      startIncoterm,
      startingFrom,
      destinationIncoterm,
      destinationTo,
      customsState,
    } = this.state;
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);
    const id = match.params.id;
    const stToEdit = context.storageOrder.find((stO) => stO._id.toString() === id);
    const order = extendSupplierOrder(selectedOrder.object as SupplierOrder, context);
    const notesWithLineBreaks = order.noteInternal
      .map((n) => {
        return n.note;
      })
      .join(";\n");
    const asap = deliveryDateType === AdditionalDateTypeLogistics.ASAP;
    const cw = deliveryDateType === DateType.CW;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        {isSCMOrAdmin ? (
          <div className="container-xxl">
            <div className="ms-lg-15">
              <div className="card bg-white">
                <div className="card-header mt-6 border-none">
                  <div className="card-title flex-column">
                    <h2 className="mb-1">
                      {edit && stToEdit ? `Edit Storage Order ST-${stToEdit.storageOrderNo}` : "Create Storage Order"}
                    </h2>
                  </div>
                </div>
                <div className="card-body p-9 pt-0">
                  <div className=" pt-0">
                    <div className="border-bottom-dark-gray pt-5 my-2" />
                    <div className="row mb-2">
                      <div className="fw-bolder text-white fs-3 my-5">
                        <Link className="fw-bolder text-white my-2 custom-link" to={getLinkForOrderOrContract(order)}>
                          {getOrderNumber(order)}
                        </Link>{" "}
                        - Supplier Order
                      </div>
                      <div className="col-4 modal-content-scroll">
                        <div className="bg-light2 p-4 pt-2 m-0" style={{ marginTop: "35px" }}>
                          {order.noteInternal.length > 0 && (
                            <>
                              <div className="text-white fs-4 mb-4">
                                <b>Order Notes</b>
                              </div>
                              <div className="text-white fs-5 my-2" style={{ whiteSpace: "pre-wrap" }}>
                                {notesWithLineBreaks}
                              </div>
                            </>
                          )}
                          <div className="text-white fs-4 mb-4">
                            <b>Order Information</b>
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Supplier: </b>
                            {order.supplier.name || "-"}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Commodity: </b>
                            {order.commodity.title.en + order.commodity.subtitle.en || "-"}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Amount: </b>
                            {order.amount + formatArticleUnit(order.unit) || "-"}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Customer Orders: </b>
                            {order.customerOrders.map((cO) => getOrderNumber(cO)).join(", ") || "-"}
                          </div>
                          {order.customerContracts && (
                            <div className="text-white fs-5 my-2">
                              <b>Customer Contracts: </b>
                              {order.customerContracts.map((cC) => "RBC-" + cC.contractNo).join(", ") || "-"}
                            </div>
                          )}
                          <div className="text-white fs-5 my-2">
                            <b>Customer Order Addresses: </b>
                            {order.customerOrders
                              .map((cO) =>
                                typeof cO.destination === "string"
                                  ? cO.destination
                                  : formatAddress(cO.destination, { withoutBreak: true, withComma: true })
                              )
                              .join(", ") || "-"}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Transport: </b>
                            {getOrderType(order)}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Incoterm: </b>
                            {`${order.terms?.deliveryTerms || "-"} ${getSupplierOrderStartString(order)}
                      `}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Startingpoint: </b>
                            {getSupplierOrderStartString(order) || "-"}
                          </div>
                          <div className="text-white fs-5 my-2">
                            <b>Payment Terms: </b>
                            {`${order.terms?.paymentTerms || ""} ${order.terms?.paymentTermConditions || ""}`}
                          </div>
                          {!order.calculationDetails && (
                            <div className="text-white fs-5 my-2">
                              Grossweight is calculated with standard palette data!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="fw-bolder text-white fs-5 mb-2">Purchase Order</div>
                        <CustomSelect
                          options={this.getAvailableOrdersSelect()}
                          value={selectedOrder}
                          matchFormControl={true}
                          onChange={(e: SelectOption<SupplierOrder>) => this.handleChangeOrder(e)}
                        />
                        <div className="fw-bolder text-white fs-5 my-2">Packing</div>
                        <Textarea
                          className="form-control custom-form-control"
                          value={orderInfo.packaging}
                          rows={4}
                          placeholder="Insert Packing information"
                          onChange={(e) => this.handleChangePacking(e)}
                        />
                        <div className="fw-bolder text-white fs-5 my-2">Net Weight</div>
                        <div className="input-group">
                          <Input
                            className="form-control custom-form-control"
                            name={"netWeight"}
                            value={orderInfo.netWeight}
                            type="number"
                            placeholder="Insert Net Weight"
                            onChange={(e) => this.handleChangeWeights(e)}
                          />
                          <div className="input-group-append rounded-end">
                            <div className="input-group-text form-control custom-form-control">kg</div>
                          </div>
                        </div>
                        <div className="fw-bolder text-white fs-5 my-2">Gross Weight</div>
                        <div className="input-group">
                          <Input
                            className="form-control custom-form-control"
                            name={"grossWeight"}
                            value={orderInfo.grossWeight}
                            type="number"
                            placeholder="Insert Gross Weight"
                            onChange={(e) => this.handleChangeWeights(e)}
                          />
                          <div className="input-group-append rounded-end">
                            <div className="input-group-text form-control custom-form-control">kg</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom-dark-gray pt-5 my-2" />
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Supplier Reference</span>
                      <div className="col-8">
                        <div className="row fw-bolder text-white fs-5 my-2">
                          <div>
                            <Input
                              className="form-control custom-form-control"
                              name={"supplierRef"}
                              value={supplierRef}
                              onChange={this.handleInputChange}
                              placeholder="Insert deliverer reference if given"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Starting Incoterms</span>
                      <div className="col-8">
                        <div className="row fw-bolder text-white fs-5 my-2">
                          <div>
                            <CustomSelect
                              options={SO_DELIVERYTERMS}
                              value={startIncoterm}
                              matchFormControl={true}
                              onChange={this.handleStartIncoterm}
                              placeholder={"Select starting incoterm"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <span className="col-4 col-form-label fs-6 fw-bold">Starting From</span>
                      <div className="col-8">
                        <div className="fw-bolder text-white fs-5 my-2">
                          <Input
                            className="form-control custom-form-control"
                            name={"startingFrom"}
                            value={startingFrom}
                            onChange={this.handleInputChange}
                            placeholder="Insert starting from"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Destination Incoterm</span>
                      <div className="col-8">
                        <div className="row fw-bolder text-white fs-5 my-2">
                          <div>
                            <CustomSelect
                              options={SO_DELIVERYTERMS}
                              value={destinationIncoterm}
                              matchFormControl={true}
                              onChange={this.handleDestinationIncoterm}
                              placeholder={"Select destination incoterm"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <span className="col-4 col-form-label fs-6 fw-bold">Destination To</span>
                      <div className="col-8">
                        <div className="fw-bolder text-white fs-5 my-2">
                          <Input
                            className="form-control custom-form-control"
                            name={"destinationTo"}
                            value={destinationTo}
                            placeholder="Insert destination to"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Sender</span>
                      <div className="col-8">
                        <div className="row fw-bolder text-white fs-5 my-2">
                          <div>
                            <Textarea
                              className="form-control custom-form-control"
                              name={"sender"}
                              value={sender}
                              rows={2}
                              placeholder="Insert Address"
                              onChange={this.handleChangeTextarea}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Recipient</span>
                      <div className="col-8">
                        <div className="row fw-bolder text-white fs-5 my-2">
                          <div>
                            <Textarea
                              className="form-control custom-form-control"
                              name={"recipient"}
                              value={recipient}
                              rows={2}
                              placeholder="Insert Address"
                              onChange={this.handleChangeTextarea}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">State</span>
                      <div className="col-4">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input position-static"
                            checked={customsState}
                            type="checkbox"
                            onChange={this.handleChangeCustomsState}
                          />
                          <label className="form-check-label fs-5">Customs Cleared</label>
                        </div>
                      </div>
                    </div>
                    {!asap && !cw && (
                      <div className="row mb-2">
                        <span className="col-4 col-form-label fs-6 fw-bold">Delivery Date</span>
                        <div className="col-8">
                          <DateInput
                            classes="form-control custom-form-control"
                            name="delivery"
                            value={delivery}
                            onBlur={this.handleChangeDelivery}
                          />
                        </div>
                      </div>
                    )}
                    {!asap && cw && (
                      <div className="row mb-2">
                        <span className="col-4 col-form-label fs-6 fw-bold">Delivery Date</span>
                        <div className="col-8">
                          <CalendarWeekSelector value={delivery} onSelectCalendarWeek={this.handleChangeCWDelivery} />
                        </div>
                      </div>
                    )}
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">{asap ? "Delivery Date" : ""}</span>
                      <div className="col-8">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input position-static"
                            checked={asap}
                            type="checkbox"
                            onChange={this.handleChangeAsap}
                          />
                          <label className="form-check-label fs-5">Asap</label>
                        </div>
                      </div>
                    </div>
                    {!asap && (
                      <div className="row mb-2">
                        <span className="col-4 col-form-label fs-6 fw-bold"></span>
                        <div className="col-8">
                          <div className="form-check form-check-sm form-check-custom form-check-solid mb-2">
                            <input
                              className="form-check-input position-static"
                              checked={cw}
                              type="checkbox"
                              onChange={this.handleChangeCW}
                            />
                            <label className="form-check-label fs-5">Calendar Week</label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-2">
                      <span className="col-4 col-form-label fs-6 fw-bold">Remarks</span>
                      <div className="col-8">
                        <WysiwygEditor content={remarks} onChange={this.handleChangeRemarks} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-0 border-0">
                  <div className="border-bottom-dark-gray pt-5" />
                  <div className="pt-3">
                    <div className="d-flex pt-3 align-items-center w-100">
                      <button
                        className="btn btn-light btn-sm float-right"
                        onClick={() => {
                          history.push("/logistics");
                        }}
                      >
                        Back to Listing
                      </button>
                      <button
                        className="btn btn-light btn-sm float-right ml-4"
                        onClick={() => {
                          history.push("/scm");
                        }}
                      >
                        Back to Dashboard
                      </button>
                      <button
                        className="btn btn-link btn-sm btn-text-white ml-auto"
                        disabled={!!saving}
                        onClick={this.handleCreateDraft}
                      >
                        {saving === "draft" ? "Generating" : "Preview"}
                      </button>
                      <button
                        className={"btn btn-light btn-sm ml-4 float-right"}
                        disabled={!!saving}
                        onClick={this.handleConfirm}
                      >
                        {saving === "storageOrder"
                          ? "Generating"
                          : edit
                          ? "Update Storage Order"
                          : "Create Storage Order"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    {edit && stToEdit ? `Edit Storage Order ST-${stToEdit.storageOrderNo}` : "Create Storage Order"}
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CreateStorageOrder);
