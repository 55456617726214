import _ from "lodash";
import React, { PureComponent } from "react";
import CommodityOfferRequestRow from "./CommodityOfferRequestRow";
import CommodityOfferRequestFilter from "./CommodityOfferRequestFilter";
import BaseListing from "../../common/BaseListing";
import { SelectOption } from "../../common/CustomSelect";
import { paginate, PaginationState } from "../../common/Pagination";
import Search from "../../common/Search";
import { DataContextInternalType } from "../../../context/dataContext";
import {
  CommodityOfferRequestExtended,
  COR_APPROVED,
  COR_ARTICLE_TYPE,
  COR_CANCELED,
  COR_INREVIEW,
  COR_REJECTED,
  COR_REQUESTED,
  CORE_ARTICLE,
} from "../../../model/commodityOfferRequest.types";
import { doFuseSearch, getComponentState, getDocFromCollection } from "../../../utils/baseUtils";
import { COR_FILTEROPTIONS, COR_SORTOPTIONS } from "../../../utils/commodityOfferRequestUtils";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";

interface CommodityOfferRequestListProps {
  context: DataContextInternalType;
}

interface CommodityOfferRequestListState extends PaginationState {
  search: string;
  sortBy: SelectOption;
  sortOrder: { value: "asc" | "desc"; label: string };
  filterBy: SelectOption;
}

const COMPONENT_NAME = "CommodityOfferRequestList";

class CommodityOfferRequestList extends PureComponent<CommodityOfferRequestListProps, CommodityOfferRequestListState> {
  constructor(props: CommodityOfferRequestListProps) {
    super(props);
    this.state = {
      search: "",
      filterBy: COR_FILTEROPTIONS[0],
      sortBy: COR_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangeFilterBy = (filterBy: SelectOption) => this.setState({ filterBy });
  handleChangeSortOrder = (sortOrder: { value: "asc" | "desc"; label: string }) => this.setState({ sortOrder });
  handleChangeSortBy = (sortBy: SelectOption) => this.setState({ sortBy });

  filterCommodityOfferRequests = () => {
    const { commodityOfferRequest, commodity, supplier, finishedProduct } = this.props.context;
    const { filterBy, sortBy, sortOrder, search } = this.state;

    let corFiltered = commodityOfferRequest;

    if (filterBy.value !== "all") {
      if (filterBy.value === "open") {
        corFiltered = corFiltered.filter((cor) => ![COR_REJECTED, COR_CANCELED, COR_APPROVED].includes(cor.state));
      } else if (filterBy.value === "closed") {
        corFiltered = corFiltered.filter((cor) => [COR_REJECTED, COR_CANCELED, COR_APPROVED].includes(cor.state));
      } else if (filterBy.value === "requested") {
        corFiltered = corFiltered.filter((cor) => cor.state === COR_REQUESTED);
      } else if (filterBy.value === "inReview") {
        corFiltered = corFiltered.filter((cor) => cor.state === COR_INREVIEW);
      } else if (filterBy.value === "approved") {
        corFiltered = corFiltered.filter((cor) => cor.state === COR_APPROVED);
      }
    }

    let corExtended: Array<CommodityOfferRequestExtended> = [];
    for (let i = 0; i < corFiltered.length; i++) {
      const cor = corFiltered[i];
      let article;
      let articleCORE: CORE_ARTICLE | undefined;
      if (cor.article.type === COR_ARTICLE_TYPE.COMMODITY) {
        article = getDocFromCollection(commodity, cor.article.id);
        if (article) articleCORE = { type: COR_ARTICLE_TYPE.COMMODITY, document: article };
      } else {
        article = getDocFromCollection(finishedProduct, cor.article.id);
        if (article) articleCORE = { type: COR_ARTICLE_TYPE.FINISHEDPRODUCT, document: article };
      }
      const sup = supplier.find((s) => s._id.toString() === cor.supplier);
      if (!article || !sup || !articleCORE) continue;
      const corE: CommodityOfferRequestExtended = {
        _id: cor._id,
        coa: cor.coa,
        created: cor.created,
        note: cor.note,
        timeline: cor.timeline,
        state: cor.state,
        specification: cor.specification,
        article: articleCORE,
        supplier: sup,
      };
      corExtended.push(corE);
    }

    if (search.trim()) {
      corExtended = doFuseSearch(corExtended, search, ["commodity.title.en", "supplier.name"]);
    }

    return _.orderBy(corExtended, sortBy.value, sortOrder.value);
  };

  render() {
    const { search, pageSize, currentPage, sortBy, sortOrder, filterBy } = this.state;

    const commodityOfferRequests = this.filterCommodityOfferRequests();
    const corPaginated = paginate(commodityOfferRequests, currentPage, pageSize);

    const headerDefinition = [
      { title: "Supplier & Article", style: { width: "45%" } },
      { title: "Status", style: { width: "15%" } },
      { title: "Created", style: { width: "15%" } },
      { title: "Progress", style: { width: "25%" } },
    ];

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white" style={{ minHeight: "100%" }}>
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Article Offer Requests</span>
                </h3>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Search
                      placeholder="Search for article order requests..."
                      onSearch={this.handleChangeSearch}
                      value={search}
                    />
                  </div>
                </div>
                <CommodityOfferRequestFilter
                  sortBy={sortBy}
                  filterBy={filterBy}
                  sortOrder={sortOrder}
                  onChangeFilterBy={this.handleChangeFilterBy}
                  onChangeSortOrder={this.handleChangeSortOrder}
                  onChangeSortBy={this.handleChangeSortBy}
                />
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {corPaginated.length > 0 ? (
                        corPaginated.map((cor) => (
                          <CommodityOfferRequestRow key={cor._id.toString()} commodityOfferRequest={cor} />
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={5}>
                            No orders found
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  documents={commodityOfferRequests}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityOfferRequestList;
