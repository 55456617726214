import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import userService from "../../services/userService";
import DashboardSearch from "../dashboard/common/DashboardSearch";
import { ADMIN, INTERNAL, SCM, SUPPLIER } from "../../utils/userUtils";
import { DataContextType, isAnonymousContext, isInternalContext, isSupplierContext } from "../../context/dataContext";
import CreateCustomerAccountModal from "../userData/anonymous/CreateCustomerAccountModal";
import PriceTicker from "./headerContent/PriceTicker";

interface HeaderProps extends RouteComponentProps {
  context: DataContextType;
  onChangeToggleView?: () => void;
}

interface HeaderState {
  seconds: number;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      seconds: 3,
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (this.state.seconds > 0) this.setState({ seconds: this.state.seconds - 1 });
      else clearInterval(interval);
    }, 1000);
  }
  render() {
    const { history, context, onChangeToggleView } = this.props;
    const { seconds } = this.state;
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);
    const disabled = !userService.getUserData()?.onboardingDone || userService.getUserType() === SUPPLIER;
    const isSC = isSupplierContext(context);

    return (
      <>
        <div className="indeterminate-progress-bar" style={{ opacity: seconds ? 1 : 0 }}>
          {seconds && <div className="indeterminate-progress-bar__progress" />}
        </div>
        <div className="header align-items-stretch" style={{ height: isSC ? 0 : 40 }}>
          {!isSC && <PriceTicker context={context} />}
        </div>
        <div
          className="header"
          style={{ animationDuration: "0.3s", height: 75, boxShadow: "none", marginTop: isSC ? 0 : 40 }}
        >
          <div className="container-xxl">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3" />
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <Link to={isInternalContext(context) && context.currentView === SCM ? "/scm" : "/dashboard"}>
                <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="h-40px h-lg-40px" />
              </Link>
              <div className="header-search w-xl-50" style={{ minWidth: "620px" }}>
                <div className="d-flex align-items-center show menu-dropdown">
                  <div data-kt-search-element="form" className="w-100 position-relative me-3 search">
                    <input type="hidden" />
                    <span className="svg-icon svg-icon-2 search-icon position-absolute top-50 translate-middle-y ms-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path
                          d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <div className="w-100 position-relative">
                      <DashboardSearch />
                    </div>
                    <span
                      className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                      data-kt-search-element="spinner"
                    >
                      <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
                    </span>
                    <span
                      className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
                      data-kt-search-element="clear"
                    >
                      <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                          <rect
                            opacity="0.5"
                            x={6}
                            y="17.3137"
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(-45 6 17.3137)"
                            fill="black"
                          />
                          <rect
                            x="7.41422"
                            y={6}
                            width={16}
                            height={2}
                            rx={1}
                            transform="rotate(45 7.41422 6)"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <button
                className={"btn btn-outline btn-outline-light" + (disabled ? " disabled" : "")}
                disabled={disabled}
                onClick={disabled ? undefined : () => history.push("/orders")}
              >
                Orders
              </button>
              {isAnonymousContext(context) && <CreateCustomerAccountModal context={context} />}
              {isSCMOrAdmin && (
                <div className="d-flex" style={{ minWidth: "120px" }}>
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input position-static"
                      type="checkbox"
                      checked={isInternalContext(context) && context.currentView === INTERNAL}
                      onChange={onChangeToggleView}
                    />
                    <span className="form-check-label fw-bold text-muted">
                      {isInternalContext(context) && context.currentView === INTERNAL ? "Internal" : "SCM"}
                    </span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
