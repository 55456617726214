import { BSON } from "realm-web";
import { SelectOption } from "../components/common/CustomSelect";
import {
  FIRSTDUNNING,
  I_CREDITNOTECUSTOMER,
  I_CREDITNOTESAMPLE,
  I_CUSTOMERINVOICE,
  I_SAMPLEINVOICE,
  I_STATE,
  I_SUPPLIERINVOICE,
  Invoice,
  InvoiceExtended,
  Payment,
  Position,
  REMINDER,
  SECONDDUNNING,
} from "../model/invoice.types";
import { callFunction } from "../services/dbService";
import { CustomerOrder } from "../model/customerOrder.types";
import { SampleOrder } from "../model/sampleOrder.types";
import { EXTENDED_ORDER_TYPES, isCustomerOrder, isSampleOrder } from "./orderUtils";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
  isCustomerContext,
  isInternalContext,
} from "../context/dataContext";
import { getDocFromCollection } from "./baseUtils";
import { Supplier } from "../model/supplier.types";
import { Company } from "../model/company.types";
import { extendCustomerSampleOrder, extendSampleOrder } from "./sampleOrderUtils";
import { extendCustomerCustomerOrder, extendCustomerOrder, extendSupplierOrder } from "./dataTransformationUtils";

// 9% + EZB base interest rate
export const I_INTEREST = 9 + -0.88;

export const I_P_DUNNING = "Dunning";
export const I_P_PAYMENT = "Payment";

export const I_TYPES: Array<SelectOption> = [
  { value: "invoice", label: "Invoice" },
  { value: "reminder", label: "Reminder" },
  { value: "cancelation", label: "Cancelation" },
  { value: "creditNote", label: "Credit Note" },
];

export const I_CURRENCIES: Array<SelectOption> = [
  { value: "EUR", label: "Euro" },
  { value: "USD", label: "US Dollar" },
  { value: "AFN", label: "Afghani" },
  { value: "DZD", label: "Algerian Dinar" },
  { value: "ARS", label: "Argentine Peso" },
  { value: "AMD", label: "Armenian Dram" },
  { value: "AWG", label: "Aruban Florin" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "AZN", label: "Azerbaijanian Manat" },
  { value: "BSD", label: "Bahamian Dollar" },
  { value: "BHD", label: "Bahraini Dinar" },
  { value: "THB", label: "Baht" },
  { value: "PAB", label: "Balboa" },
  { value: "BBD", label: "Barbados Dollar" },
  { value: "BYR", label: "Belarussian Ruble" },
  { value: "BZD", label: "Belize Dollar" },
  { value: "BMD", label: "Bermudian Dollar" },
  { value: "VEF", label: "Bolivar" },
  { value: "BOB", label: "Boliviano" },
  { value: "BRL", label: "Brazilian Real" },
  { value: "BND", label: "Brunei Dollar" },
  { value: "BGN", label: "Bulgarian Lev" },
  { value: "BIF", label: "Burundi Franc" },
  { value: "CVE", label: "Cabo Verde Escudo" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "KYD", label: "Cayman Islands Dollar" },
  { value: "XOF", label: "CFA Franc BCEAO" },
  { value: "XAF", label: "CFA Franc BEAC" },
  { value: "XPF", label: "CFP Franc" },
  { value: "CLP", label: "Chilean Peso" },
  { value: "COP", label: "Colombian Peso" },
  { value: "KMF", label: "Comoro Franc" },
  { value: "CDF", label: "Congolese Franc" },
  { value: "BAM", label: "Convertible Mark" },
  { value: "NIO", label: "Cordoba Oro" },
  { value: "CRC", label: "Costa Rican Colon" },
  { value: "HRK", label: "Croatian Kuna" },
  { value: "CUP", label: "Cuban Peso" },
  { value: "CZK", label: "Czech Koruna" },
  { value: "GMD", label: "Dalasi" },
  { value: "DKK", label: "Danish Krone" },
  { value: "MKD", label: "Denar" },
  { value: "DJF", label: "Djibouti Franc" },
  { value: "STD", label: "Dobra" },
  { value: "DOP", label: "Dominican Peso" },
  { value: "VND", label: "Dong" },
  { value: "EGP", label: "Egyptian Pound" },
  { value: "SVC", label: "El Salvador Colon" },
  { value: "ETB", label: "Ethiopian Birr" },
  { value: "FKP", label: "Falkland Islands Pound" },
  { value: "FJD", label: "Fiji Dollar" },
  { value: "HUF", label: "Forint" },
  { value: "GHS", label: "Ghana Cedi" },
  { value: "GIP", label: "Gibraltar Pound" },
  { value: "HTG", label: "Gourde" },
  { value: "PYG", label: "Guarani" },
  { value: "GNF", label: "Guinea Franc" },
  { value: "GYD", label: "Guyana Dollar" },
  { value: "HKD", label: "Hong Kong Dollar" },
  { value: "UAH", label: "Hryvnia" },
  { value: "ISK", label: "Iceland Krona" },
  { value: "INR", label: "Indian Rupee" },
  { value: "IRR", label: "Iranian Rial" },
  { value: "IQD", label: "Iraqi Dinar" },
  { value: "JMD", label: "Jamaican Dollar" },
  { value: "JOD", label: "Jordanian Dinar" },
  { value: "KES", label: "Kenyan Shilling" },
  { value: "PGK", label: "Kina" },
  { value: "LAK", label: "Kip" },
  { value: "KWD", label: "Kuwaiti Dinar" },
  { value: "MWK", label: "Kwacha" },
  { value: "AOA", label: "Kwanza" },
  { value: "MMK", label: "Kyat" },
  { value: "GEL", label: "Lari" },
  { value: "LBP", label: "Lebanese Pound" },
  { value: "ALL", label: "Lek" },
  { value: "HNL", label: "Lempira" },
  { value: "SLL", label: "Leone" },
  { value: "LRD", label: "Liberian Dollar" },
  { value: "LYD", label: "Libyan Dinar" },
  { value: "SZL", label: "Lilangeni" },
  { value: "LSL", label: "Loti" },
  { value: "MGA", label: "Malagasy Ariary" },
  { value: "MYR", label: "Malaysian Ringgit" },
  { value: "MUR", label: "Mauritius Rupee" },
  { value: "MXN", label: "Mexican Peso" },
  { value: "MXV", label: "Mexican Unidad de Inversion (UDI)" },
  { value: "MDL", label: "Moldovan Leu" },
  { value: "MAD", label: "Moroccan Dirham" },
  { value: "MZN", label: "Mozambique Metical" },
  { value: "BOV", label: "Mvdol" },
  { value: "NGN", label: "Naira" },
  { value: "ERN", label: "Nakfa" },
  { value: "NAD", label: "Namibia Dollar" },
  { value: "NPR", label: "Nepalese Rupee" },
  { value: "ANG", label: "Netherlands Antillean Guilder" },
  { value: "ILS", label: "New Israeli Sheqel" },
  { value: "RON", label: "New Romanian Leu" },
  { value: "TWD", label: "New Taiwan Dollar" },
  { value: "NZD", label: "New Zealand Dollar" },
  { value: "BTN", label: "Ngultrum" },
  { value: "KPW", label: "North Korean Won" },
  { value: "NOK", label: "Norwegian Krone" },
  { value: "PEN", label: "Nuevo Sol" },
  { value: "MRO", label: "Ouguiya" },
  { value: "TOP", label: "Pa’anga" },
  { value: "PKR", label: "Pakistan Rupee" },
  { value: "MOP", label: "Pataca" },
  { value: "CUC", label: "Peso Convertible" },
  { value: "UYU", label: "Peso Uruguayo" },
  { value: "PHP", label: "Philippine Peso" },
  { value: "GBP", label: "Pound Sterling" },
  { value: "BWP", label: "Pula" },
  { value: "QAR", label: "Qatari Rial" },
  { value: "GTQ", label: "Quetzal" },
  { value: "ZAR", label: "Rand" },
  { value: "OMR", label: "Rial Omani" },
  { value: "KHR", label: "Riel" },
  { value: "MVR", label: "Rufiyaa" },
  { value: "IDR", label: "Rupiah" },
  { value: "RUB", label: "Russian Ruble" },
  { value: "RWF", label: "Rwanda Franc" },
  { value: "SHP", label: "Saint Helena Pound" },
  { value: "SAR", label: "Saudi Riyal" },
  { value: "RSD", label: "Serbian Dinar" },
  { value: "SCR", label: "Seychelles Rupee" },
  { value: "SGD", label: "Singapore Dollar" },
  { value: "SBD", label: "Solomon Islands Dollar" },
  { value: "KGS", label: "Som" },
  { value: "SOS", label: "Somali Shilling" },
  { value: "TJS", label: "Somoni" },
  { value: "SSP", label: "South Sudanese Pound" },
  { value: "LKR", label: "Sri Lanka Rupee" },
  { value: "XSU", label: "Sucre" },
  { value: "SDG", label: "Sudanese Pound" },
  { value: "SRD", label: "Surinam Dollar" },
  { value: "SEK", label: "Swedish Krona" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "SYP", label: "Syrian Pound" },
  { value: "BDT", label: "Taka" },
  { value: "WST", label: "Tala" },
  { value: "TZS", label: "Tanzanian Shilling" },
  { value: "KZT", label: "Tenge" },
  { value: "TTD", label: "Trinidad and Tobago Dollar" },
  { value: "MNT", label: "Tugrik" },
  { value: "TND", label: "Tunisian Dinar" },
  { value: "TRY", label: "Turkish Lira" },
  { value: "TMT", label: "Turkmenistan New Manat" },
  { value: "AED", label: "UAE Dirham" },
  { value: "UGX", label: "Uganda Shilling" },
  { value: "CLF", label: "Unidad de Fomento" },
  { value: "COU", label: "Unidad de Valor Real" },
  { value: "UYI", label: "Uruguay Peso en Unidades Indexadas (URUIURUI)" },
  { value: "UZS", label: "Uzbekistan Sum" },
  { value: "VUV", label: "Vatu" },
  { value: "CHE", label: "WIR Euro" },
  { value: "CHW", label: "WIR Franc" },
  { value: "KRW", label: "Won" },
  { value: "YER", label: "Yemeni Rial" },
  { value: "JPY", label: "Yen" },
  { value: "CNY", label: "Yuan Renminbi" },
  { value: "ZMW", label: "Zambian Kwacha" },
  { value: "ZWL", label: "Zimbabwe Dollar" },
  { value: "PLN", label: "Zloty" },
];

export const I_DUEOPTIONS: Array<SelectOption> = [
  { value: "0", label: "In Time" },
  { value: "7", label: "Overdue since 7 days" },
  { value: "14", label: "Overdue since 14 days" },
  { value: "28", label: "Overdue since 28 days" },
  { value: "-1", label: "With Reminder" },
  { value: "-2", label: "In Dunning" },
];

export const I_SORTOPTIONS: Array<SelectOption> = [
  { value: "invoiceNumber", label: "Invoice Number" },
  { value: "invoiceDate", label: "Invoice Date" },
  { value: "total", label: "Order Volume" },
  { value: "overdue", label: "Time in Overdue" },
];

export const I_ACTIONOPTIONS: Array<SelectOption> = [
  { value: "exportAsZIP", label: "Export as ZIP" },
  { value: "exportAsCSV", label: "Export as CSV" },
];

export const I_STATEDESCRIPTION = [
  { value: I_STATE.OPEN, label: "Open" },
  { value: I_STATE.PARTLY_PAID, label: "Partly Paid" },
  { value: I_STATE.PAID, label: "Paid" },
  { value: I_STATE.CANCELED, label: "Canceled" },
];

export const I_REMINDERDESCRIPTION: Array<SelectOption> = [
  { value: REMINDER, label: "Reminder" },
  { value: FIRSTDUNNING, label: "First Dunning" },
  { value: SECONDDUNNING, label: "Second Dunning" },
];

export const I_PAYMENTTARGETS: Array<SelectOption> = [
  { value: "-1", label: "In Advance" },
  { value: "0", label: "Due Immediately" },
  { value: "7", label: "7 days" },
  { value: "14", label: "14 days" },
  { value: "30", label: "30 days" },
  { value: "60", label: "60 days" },
  { value: "90", label: "90 days" },
  { value: "120", label: "120 days" },
];

export const I_PAYMENTTARGETS_WITH_CUSTOM_OPTION: Array<SelectOption> = [
  ...I_PAYMENTTARGETS,
  { value: "custom", label: "Custom" },
];

// Backend functions relating to property
const UPSERTINVOICE = "upsertInvoice";
const PAYINVOICE = "payInvoice";

/**
 * Inserts a new invoice into the database.
 * @param invoice Invoice that should be inserted into the database
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; invoiceNumber: string } | false>} Result of the function
 */
export async function insertInvoice(
  invoice: Invoice
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; invoiceNumber: string } | false> {
  return (await callUpsertInvoice(invoice, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; invoiceNumber: string }
    | false;
}

/**
 * Updates an existing invoice inside the database.
 * @param invoice invoice that should be updated inside the database
 * @param invoiceId Optional id of invoice if it is not contained in invoice object
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; invoiceNumber: string } | false>} Result of the function
 */
export async function updateInvoice(
  invoice: Partial<Invoice>,
  invoiceId?: BSON.ObjectId
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; invoiceNumber: string } | false> {
  if (invoiceId) invoice._id = invoiceId;
  return (await callUpsertInvoice(invoice, false)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; invoiceNumber: string }
    | false;
}

/**
 * Calls the upsert invoice function in backend.
 * @param invoice Invoice that should be upsert
 * @param insert True for insert, else update
 * @returns {Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; invoiceNumber: string } | false>} Result of the function
 */
async function callUpsertInvoice(
  invoice: Partial<Invoice>,
  insert: boolean
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; invoiceNumber: string } | false> {
  return callFunction(UPSERTINVOICE, [invoice, insert]);
}

/**
 * Calls the pay invoice function in backend.
 * @param invoice ID of the invoice that should be paid
 * @param payment Payment that should be added to list of payments
 * @param newState New state of the invoice, either partly or fully paid
 * @returns {Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>} Result of the update
 */
export async function callPayInvoice(
  invoice: BSON.ObjectId,
  payment: Payment,
  newState: I_STATE.PAID | I_STATE.PARTLY_PAID
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  return await callFunction<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>>(PAYINVOICE, [
    invoice,
    payment,
    newState,
  ]);
}

/**
 * Retrieve the invoice state description for the given state
 * @param state State whose description should be resolved
 * @returns {string} State description
 */
export function getInvoiceStateDescription(state: I_STATE): string {
  const desc = I_STATEDESCRIPTION.find((item) => item.value === state);
  return desc ? desc.label : "";
}

/**
 * Get a default invoice position
 * @returns {Position} a default invoice position
 */
export function getDefaultPosition(): Position {
  return {
    _id: new BSON.ObjectId(),
    title: "",
    description: "",
    quantity: 0,
    unit: "kg",
    price: 0,
    vatPercentage: 19,
    total: 0,
  };
}

/**
 * Get a description for a payment target
 * @param paymentTarget the payment target as number or numeri string
 * @returns {string} description of payment target, e.g., "Due Immediately"
 */
export const getPaymentTargetDescription = (paymentTarget: number | string): string => {
  return (
    I_PAYMENTTARGETS.find((pt) => pt.value === paymentTarget.toString())?.label ?? `${paymentTarget.toString()} days`
  );
};

/**
 * Generate positions from the given order.
 * @param order Order whose positions should be generated
 * @param vat VAT amount
 * @returns { Array<Position> } Position for the commodity and for services of the order
 */
export function getPositionsFromOrder(order: EXTENDED_ORDER_TYPES, vat: number): Array<Position> {
  const isSample = isSampleOrder(order);
  const isCO = isCustomerOrder(order);
  const lots: Array<string> = [];
  if (isCO && order.usedBatches) {
    for (let i = 0; i < order.usedBatches.length; i++) {
      const b = order.usedBatches[i];
      if (b.supplierLot) lots.push(b.supplierLot);
    }
  }
  const positions: Array<Position> = [
    {
      _id: new BSON.ObjectId(),
      title: order.commodity.title.en,
      price: isSample ? order.totalPrice : order.priceCommodities / order.amount,
      unit: isSample ? "Sample" : order.unit,
      quantity: isSample ? 1 : order.amount,
      vatPercentage: vat === 0 || !order.commodity.vatPercentage ? vat : order.commodity.vatPercentage,
      description: isSample ? `Sample á ${order.amount}${order.unit}` : lots.length > 0 ? `LOT: ${lots.join()}` : "",
      total: isSample ? order.totalPrice : order.priceCommodities,
      discount: isCO ? order.discount : undefined,
    },
  ];
  if (isCO) {
    for (let i = 0; i < order.services.length; i++) {
      const s = order.services[i];
      positions.push({
        _id: new BSON.ObjectId(),
        title: s.service.title.en,
        price: s.priceOrderCurrency,
        unit: "Service",
        quantity: 1,
        vatPercentage: vat,
        description: s.service.description.en,
        total: s.priceOrderCurrency,
      });
    }
  }
  return positions;
}

/**
 * Calculate the total open sum to pay for the given invoice.
 * @param invoice Invoice whose sum to pay should be resolved
 * @param withoutTax Optional flag, when set subtotal is used instead of total
 * @returns {number} Open sum
 */
export function getTotalOpenSumToPay(invoice: Invoice, withoutTax?: boolean): number {
  let sum = withoutTax ? invoice.subtotal : invoice.total;
  for (let i = 0; i < invoice.reminders.length; i++) {
    sum += invoice.reminders[i].dunningFee;
  }
  for (let j = 0; j < invoice.payments.length; j++) {
    sum -= invoice.payments[j].amount;
  }
  return sum;
}

/**
 * Negates the values of the invoice. Needed for cancelations.
 * @param invoice Invoice that should be negated
 * @returns {Invoice} Invoice with negated values
 */
export function negateInvoice(invoice: Invoice): Invoice {
  invoice.subtotal = -invoice.subtotal;
  invoice.total = -invoice.total;
  return invoice;
}

/**
 * Negates the given positions. Needed for cancelations.
 * @param positions Positions that should be negated
 * @returns {Array<Position>} Negated positions
 */
export function negatePositions(positions: Array<Position>): Array<Position> {
  for (let i = 0; i < positions.length; i++) {
    const p = positions[i];
    p.price = -p.price;
    p.total = -p.total;
  }
  return positions;
}

/**
 * Retrieve the subtotal of the invoice without regarding discounts
 * @param invoice Invoice whose subtotal shall be calculated
 * @returns {number} Subtotal without discount
 */
export function getSubtotalWithoutDiscount(invoice: Invoice): number {
  return invoice.positions.reduce((sum, pos) => sum + pos.price * pos.quantity, 0);
}

export function extendInvoice(
  invoice: Invoice,
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType
): InvoiceExtended {
  const company = (
    invoice.type === I_SUPPLIERINVOICE
      ? getDocFromCollection(context.supplier, invoice.company)
      : getDocFromCollection(context.company, invoice.company)
  ) as Company | Supplier;
  let relatedOrder = undefined;
  if (invoice.relatedOrder) {
    if ([I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(invoice.type)) {
      const order = getDocFromCollection(context.customerOrder, invoice.relatedOrder);
      if (order)
        relatedOrder = isInternalContext(context)
          ? extendCustomerOrder(order as CustomerOrder, context)
          : extendCustomerCustomerOrder(order, context);
    } else if ([I_SAMPLEINVOICE, I_CREDITNOTESAMPLE].includes(invoice.type)) {
      const order = getDocFromCollection(context.sampleOrder, invoice.relatedOrder);
      if (order)
        relatedOrder = isInternalContext(context)
          ? extendSampleOrder(order as SampleOrder, context)
          : extendCustomerSampleOrder(order, context);
    } else if (!isCustomerContext(context) && !isAnonymousContext(context) && invoice.type === I_SUPPLIERINVOICE) {
      const order = getDocFromCollection(context.supplierOrder, invoice.relatedOrder);
      if (order) relatedOrder = extendSupplierOrder(order, context);
    }
  }
  return { ...invoice, company, relatedOrder };
}
