import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommodityOfferRequest, COR_REJECTED, COR_T_REJECTED } from "../../../../model/commodityOfferRequest.types";
import { Action, COMMODITYOFFERREQUEST, transaction } from "../../../../services/dbService";
import { getCORTimelineEntry } from "../../../../utils/commodityOfferRequestUtils";
import { Textarea } from "../../../common/Textarea";

interface RejectCommodityOfferRequestModalProps {
  commodityOfferRequest: CommodityOfferRequest;
}

interface RejectCommodityOfferRequestModalState {
  reason: string;
  saving: boolean;
  show: boolean;
}

class RejectCommodityOfferRequestModal extends PureComponent<
  RejectCommodityOfferRequestModalProps,
  RejectCommodityOfferRequestModalState
> {
  constructor(props: RejectCommodityOfferRequestModalProps) {
    super(props);
    this.state = { reason: "", show: false, saving: false };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleReject = async () => {
    const { commodityOfferRequest } = this.props;
    const { reason } = this.state;
    this.setState({ saving: true });
    try {
      const actions: Array<Action> = [
        {
          collection: COMMODITYOFFERREQUEST,
          filter: { _id: commodityOfferRequest._id },
          push: {
            timeline: getCORTimelineEntry(COR_T_REJECTED, reason),
          },
          update: { state: COR_REJECTED },
        },
      ];
      const res = await transaction(actions);
      if (res) {
        toast.success("Commodity Offer Request successfully rejected");
      } else {
        toast.error("Error rejecting Commodity Offer Request");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ reason: e.target.value });

  render() {
    const { reason, show, saving } = this.state;
    return (
      <>
        <button className="btn btn-sm btn-text-muted float-right" onClick={this.handleShow}>
          Reject
        </button>
        <Modal show={show} contentClassName="bg-dark" onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Reject Commodity Offer Request</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <span className="text-white">
              Do you really want to reject the Commodity Offer Request? Please provide a reason.
            </span>
            <div className="mt-5">
              <label className="fs-5 fw-bold mb-2">Reason</label>
              <Textarea
                className="form-control custom-form-control"
                placeholder="Reason for rejecting the Commodity Offer Request"
                value={reason}
                onChange={this.handleChangeReason}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={saving}
              className={"btn btn-text-light btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : this.handleHide}
            >
              Cancel
            </button>
            <button
              disabled={saving}
              className={"btn btn-outline btn-outline-light btn-sm " + (saving && "disabled")}
              onClick={saving ? undefined : this.handleReject}
            >
              Reject
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default RejectCommodityOfferRequestModal;
