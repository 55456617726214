import React from "react";
import { CustomerCustomerOrderExtended } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerOrderExtended } from "../../../model/customerOrder.types";
import { doFuseSearch, formatCurrency, formatDate, formatUnit, truncateString } from "../../../utils/baseUtils";
import AddReferenceModal from "../../common/AddReferenceModal";
import {
  calculateArrivalInformation,
  DateType,
  getCOPaymentTermsToDisplay,
  getOrderStateDescriptions,
  O_ORDERMETHODS,
} from "../../../utils/orderUtils";
import { getCW } from "../../../utils/dateUtils";
import CountryWidget from "../../common/CountryWidget";
import { formatAddress } from "../../../utils/addressUtils";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { formatDateFromType } from "../../../utils/customerOrderUtils";
import { formatArticleUnit } from "../../../utils/productArticleUtils";

export function getOrderInformation(
  order: CustomerOrderExtended | CustomerCustomerOrderExtended,
  query: string,
  blurPrices?: boolean
) {
  const { commodity } = order;
  const arrival = calculateArrivalInformation(order);
  const stateDesc = getOrderStateDescriptions(order);
  const entries: Array<{ key: string; additionalClasses?: string; entry: string | JSX.Element }> = [];
  entries.push({
    key: "Reference",
    entry: order.customerReference.trim() ? order.customerReference : <AddReferenceModal order={order} />,
  });
  entries.push({
    key: "Article",
    entry: truncateString(commodity.title.en, 20),
  });
  entries.push({
    key: "Status",
    entry: stateDesc.title,
  });
  entries.push({
    key: "Subtitle",
    entry: truncateString(commodity.subtitle.en, 20) || "-",
  });
  entries.push({
    key: "Order Date",
    entry: `CW ${getCW(order.createdAt)} (${formatDate(order.createdAt)})`,
  });
  entries.push({
    key: "Supplier",
    entry: order.supplier?.name || "Selected by RAWBIDS",
  });
  entries.push({
    key: `Original Target ${order.targetDateType === DateType.CW ? "Week" : "Date"}`,
    entry: `${formatDateFromType(order.targetDate, order.targetDateType)}`,
  });
  entries.push({
    key: "Amount",
    entry: `${order.amount} ${formatArticleUnit(order.unit, order.commodity)}`,
  });
  entries.push({
    key: `Target ${
      (order.changedETA && order.changedETAType === DateType.CW) || order.targetDateType === DateType.CW
        ? "Week"
        : "Date"
    } (ETA)`,
    entry: `${
      order.changedETA
        ? formatDateFromType(order.changedETA, order.changedETAType)
        : formatDateFromType(order.targetDate, order.targetDateType)
    }`,
  });
  entries.push({
    key: "Packaging",
    entry: order.shippingInformation?.grossWeight
      ? formatUnit(order.shippingInformation?.grossWeight, order.shippingInformation.unitLoad)
      : "-",
  });
  entries.push({
    key: "Delivery Week",
    additionalClasses: !arrival.target ? (arrival.late ? "text-danger" : "text-success") : undefined,
    entry: !arrival.target ? `CW ${arrival.cw}-${arrival.year}` : "Pending",
  });
  entries.push({
    key: "Transport Mode",
    entry: O_ORDERMETHODS.find((o) => o.value === order.transport)?.label || "-",
  });
  entries.push({
    key: `Price per ${order.unit}`,
    entry: blurPrices ? (
      <AnonymousBlurText>{formatCurrency(order.priceCommodities / order.amount, order.currency)}</AnonymousBlurText>
    ) : (
      formatCurrency((order.priceCommodities / order.amount) * (1 - (order.discount || 0) / 100), order.currency)
    ),
  });
  entries.push({
    key: "Origin",
    entry: commodity?.country ? <CountryWidget countryCode={commodity.country.code} /> : "-",
  });
  entries.push({
    key: "Total Price",
    entry: blurPrices ? (
      <AnonymousBlurText>{formatCurrency(order.priceCommodities, order.currency)}</AnonymousBlurText>
    ) : (
      formatCurrency(order.priceCommodities * (1 - (order.discount || 0) / 100), order.currency)
    ),
  });
  entries.push({
    key: "Tracking-Number",
    entry: order.trackingInformation?.trackingNumber || "Pending",
  });
  entries.push({
    key: "Payment Terms",
    entry: order.terms ? getCOPaymentTermsToDisplay(order.terms) : "TBD",
  });
  entries.push({
    key: "Incoterm",
    entry: `DDP ${truncateString(
      order.terms?.deliveryCity ??
        (typeof order.destination === "string" ? order.destination : formatAddress(order.destination)),
      16
    )}`,
  });
  entries.push({
    key: "Last Update",
    entry:
      order.timeline.length > 0
        ? formatDate(order.timeline.sort((t1, t2) => t1.date.getTime() - t2.date.getTime())[0].date)
        : "-",
  });
  const entriesFiltered = query.trim() ? doFuseSearch(entries, query, ["key"]) : entries;

  const entriesAsComponents = [];
  for (let i = 0; i < entriesFiltered.length; i += 2) {
    const e1 = entriesFiltered[i];
    const e2 = entriesFiltered[i + 1];
    entriesAsComponents.push(
      <tr>
        <td className="text-white w-25 min-w-125px">{e1.key}</td>
        <td className={"text-muted w-25 " + (e1.additionalClasses ?? "")}>{e1.entry}</td>
        <td className="text-white w-25 min-w-125px">{e2?.key ?? ""}</td>
        <td className={"text-muted w-25 " + (e2?.additionalClasses ?? "")}>{e2?.entry ?? ""}</td>
      </tr>
    );
  }
  return entriesAsComponents;
}
