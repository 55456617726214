import React, { PureComponent } from "react";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";

interface TooltipProps {
  show?: boolean;
  defaultShow?: boolean;
  tooltipText: string | JSX.Element;
  tooltipClass?: string;
  tooltipId?: string;
  children: JSX.Element;
  delay?: { show: number; hide: number };
}

class Tooltip extends PureComponent<TooltipProps> {
  render() {
    const { tooltipText, tooltipClass, tooltipId, children, show, defaultShow, delay } = this.props;
    return (
      <OverlayTrigger
        defaultShow={defaultShow}
        show={show}
        delay={delay ? delay : { show: 0, hide: 0 }}
        overlay={
          <ReactTooltip className={tooltipClass ?? "custom-tooltip"} id={tooltipId}>
            {typeof tooltipText === "string" ? <span className="text-white">{tooltipText}</span> : tooltipText}
          </ReactTooltip>
        }
      >
        {children}
      </OverlayTrigger>
    );
  }
}

export default Tooltip;
