import { BSON } from "realm-web";
import { Note, OrderFile } from "./commonTypes";
import { CommoditySnapshot } from "./commodity.types";
import { CustomerOrder } from "./customerOrder.types";
import { CC_TIMELINETYPE } from "../utils/customerContractUtils";
import { FinishedProductSnapshot } from "./finishedProduct.types";
import { UserData } from "./userData.types";
import { Supplier } from "./supplier.types";
import { Company } from "./company.types";

export const CC_OPEN = "customerContractOpen" as const;
export const CC_ORDERED = "customerContractOrdered" as const;
export const CC_READY = "customerContractReady" as const;
export const CC_CLOSED = "customerContractClosed" as const;
export const CC_CANCELED = "customerContractCanceled" as const;

export type CC_STATES = typeof CC_OPEN | typeof CC_READY | typeof CC_ORDERED | typeof CC_CLOSED | typeof CC_CANCELED;

export interface CustomerContract {
  _id: BSON.ObjectId;
  contractNo: string; // Auto-incrementing number to identify the contract
  createdAt: Date; // Date when order was created
  targetDate?: Date | null; // Date the order should be available at our warehouse
  changedETA?: Date | null; // Updated target date
  arrivalDate?: Date | null; // Date when the order arrived at the warehouse and is available
  validityPeriod: {
    start: Date;
    end: Date;
  };
  noteCustomer: string; // Note left by customer (set on creation)
  customerReference: string; // the customers own reference if required
  noteInternal: Array<Note>; // Notes left by internal
  state: CC_STATES;
  company: string;
  supplier?: string | null;
  person: string;
  commodity: CommoditySnapshot | FinishedProductSnapshot; // Commodity or finished product snapshot at moment of ordering
  contractInformation: ContractInformation;
  priceInformation: ContractPriceInformation;
  timeline: Array<ContractTimelineEntry>;
  files: Array<OrderFile>;
  terms: CustomerContractTerms;
  calls: Array<Call>;
  requestId?: string; // reference to request this contract originated from
}

export interface Call
  extends Pick<
    CustomerOrder,
    "_id" | "orderNo" | "amount" | "unit" | "createdAt" | "customerReference" | "transport"
  > {}

export interface CustomerContractExtended extends Omit<CustomerContract, "company" | "person" | "supplier"> {
  company: Company;
  supplier?: Supplier | null;
  person: UserData;
}

export interface ContractPriceInformation {
  purchasePriceCommodities: number; // Total purchase price of commodities
  totalPrice: number; // Total price customer has to pay
  discount?: number; // In %
  currency: string;
}

export interface ContractInformation {
  totalAmount: number;
  restAmount: number;
  minimumCallQuantity: number;
}

export interface ContractTimelineEntry {
  _id: BSON.ObjectId;
  date: Date;
  type: CC_TIMELINETYPE;
  person: string;
  payload: ContractTimelineEntryPayload | null; // Additional data for certain types
}

export interface ContractTimelineEntryPayload {
  type?: string;
  reference?: string;
  name?: string;
  reason?: string;
}

export interface CustomerContractTerms {
  paymentTerms: string;
  paymentTermConditions?: string;
  deliveryTerms: string;
  deliveryCity?: string;
  note?: string;
}
