import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { paginate, PaginationState } from "../../common/Pagination";
import Search from "../../common/Search";
import BaseListing from "../../common/BaseListing";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import OrderRow from "./OrderRow";
import { SelectOption } from "../../common/CustomSelect";
import { isCustomerOrder, isOrderCanceled, isOrderInactive, O_SORTOPTIONS } from "../../../utils/orderUtils";
import { SortOrder } from "../../../utils/filterUtils";
import OrderFilter from "./OrderFilter";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerSampleOrder } from "../../../model/customer/customerSampleOrder.types";
import { CO_TRANSPORT, T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE } from "../../../model/customerOrder.types";
import ListPlaceholder from "../../common/ListPlaceholder";

interface OrdersListingProps extends RouteComponentProps {
  context: DataContextCustomerType | DataContextAnonymousType;
  orderType: "customer" | "sample";
}

interface OrdersListingState extends PaginationState {
  search: string;
  status?: SelectOption;
  deliveryStatus?: SelectOption;
  transport?: SelectOption;
  category?: SelectOption;
  sortColumn: SelectOption;
  sortOrder: SortOrder;
}

const COMPONENT_NAME = "OrdersListing";

class OrdersListing extends PureComponent<OrdersListingProps, OrdersListingState> {
  constructor(props: OrdersListingProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentDidUpdate(prevProps: Readonly<OrdersListingProps>) {
    if (prevProps.orderType !== this.props.orderType) {
      this.setState(this.getDefaultState());
    }
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: O_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortColumn: { value: "", label: "Best Match" },
        sortOrder: SortOrder.DESC,
      });
  };

  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangeSelect = (option: SelectOption | undefined, name: string) =>
    // @ts-ignore
    this.setState({ [name]: option, currentPage: 1 });
  handleToggleSortOrder = () => {
    const { sortOrder } = this.state;
    this.setState({ sortOrder: sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC, currentPage: 1 });
  };

  getDefaultState = (): OrdersListingState => {
    return {
      currentPage: 1,
      pageSize: 20,
      search: "",
      sortColumn: O_SORTOPTIONS[0],
      sortOrder: SortOrder.DESC,
    };
  };

  getFilteredOrders = () => {
    const { orderType, context } = this.props;
    const { customerOrder, sampleOrder } = context;
    const { search, sortOrder, sortColumn, deliveryStatus, status, transport, category } = this.state;
    let ordersFiltered: Array<CustomerCustomerOrder | CustomerSampleOrder> =
      orderType === "sample" ? sampleOrder.slice() : customerOrder.slice();

    if (search.trim())
      ordersFiltered = doFuseSearch(ordersFiltered, search, [
        "orderNo",
        "commodity.title.en",
        "customerReference",
        "noteCustomer",
        "country.name",
      ]);

    if (status) ordersFiltered = ordersFiltered.filter((o) => o.state === status.value);
    if (deliveryStatus) {
      const now = new Date();
      if (deliveryStatus.value === "0") {
        ordersFiltered = ordersFiltered.filter((o) => o.deliveryDate && !isOrderCanceled(o));
      } else if (deliveryStatus.value === "-1") {
        const comparisonDate = new Date(new Date().setDate(now.getDate() + 7));
        ordersFiltered = ordersFiltered.filter((o) => o.targetDate < comparisonDate && !isOrderCanceled(o));
      } else if (deliveryStatus.value === "8+") {
        const comparisonDate = new Date(new Date().setDate(now.getDate() + 7 * 8));
        ordersFiltered = ordersFiltered.filter((o) => comparisonDate < o.targetDate && !isOrderCanceled(o));
      } else if (deliveryStatus.value.match(/^[0-9]+-[0-9]+$/)) {
        const [start, end] = deliveryStatus.value.split("-");
        const startDate = new Date(new Date().setDate(now.getDate() + 7 * +start));
        const endDate = new Date(new Date().setDate(now.getDate() + 7 * +end));
        ordersFiltered = ordersFiltered.filter(
          (o) => startDate < o.targetDate && o.targetDate < endDate && !isOrderCanceled(o)
        );
      } else {
        console.error("Invalid delivery status value:", deliveryStatus.value);
      }
    }

    if (transport) {
      if (transport.value === "misc") {
        ordersFiltered = ordersFiltered.filter(
          (o) =>
            isCustomerOrder(o) &&
            !([T_WAREHOUSE, T_SEAFREIGHT, T_AIRFREIGHT] as Array<CO_TRANSPORT>).includes(o.transport)
        );
      } else {
        ordersFiltered = ordersFiltered.filter((o) => isCustomerOrder(o) && o.transport === transport.value);
      }
    }

    if (category)
      ordersFiltered = ordersFiltered.filter((o) =>
        o.commodity.properties.some((p) => p._id.toString() === category.value)
      );

    if (!sortColumn.value && search.trim()) {
      if (sortOrder === SortOrder.DESC) return ordersFiltered;
      return ordersFiltered;
    }

    // Inactive orders always at the end
    const active: Array<CustomerCustomerOrder | CustomerSampleOrder> = [];
    const inactive: Array<CustomerCustomerOrder | CustomerSampleOrder> = [];
    ordersFiltered.forEach((o) => (isOrderInactive(o) ? inactive.push(o) : active.push(o)));
    ordersFiltered = _.orderBy(active, sortColumn.value, sortOrder).concat(
      _.orderBy(inactive, sortColumn.value, sortOrder)
    );

    return ordersFiltered;
  };

  render() {
    const { orderType, context } = this.props;
    const { pageSize, currentPage, search, sortColumn, sortOrder, status, deliveryStatus, transport } = this.state;
    const orders = this.getFilteredOrders();
    const paginatedOrders = paginate(orders, currentPage, pageSize);
    const COHeaderDefinition = [
      { title: "Order" },
      { title: "Quantity" },
      { title: "Article", style: { width: "30%" } },
      { title: "Reference", style: { width: "15%" } },
      { title: "Origin" },
      { title: "Status" },
      { title: "Schedule" },
    ];
    const SAMOHeaderDefinition = [
      { title: "Request" },
      { title: "Quantity" },
      { title: "Commodity", style: { width: "30%" } },
      { title: "Note", style: { width: "20%" } },
      { title: "Origin" },
      { title: "Status" },
      { title: "Schedule" },
    ];

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder mb-3 fs-3rem">
                    {orderType === "customer" ? "Article Orders" : "Samples"}
                  </span>
                  <Link to="/articles">
                    <button className="btn btn-light btn-text float-right">New Order</button>
                  </Link>
                  <button className="btn btn-light btn-text float-right disabled mr-3" disabled={true}>
                    Export
                  </button>
                </h3>
                <p className="mb-15">
                  <span className="mb-3 fs-5 text-muted">
                    Showing {paginatedOrders.length} of {orders.length} matching orders
                  </span>
                </p>
                <div className="card mb-10 bg-white border-none bg-custom-medium-gray p-5 ">
                  <div className="row g-8 mb-4">
                    <div className="col-lg-6 col-8">
                      <label className="fs-6 form-label fw-bolder text-dark">Search Query</label>
                      <Search placeholder="Search orders..." onSearch={this.handleChangeSearch} value={search} />
                    </div>
                  </div>
                  <OrderFilter
                    search={search}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    deliveryStatus={deliveryStatus}
                    status={status}
                    transport={transport}
                    orderType={orderType}
                    context={context}
                    onChangeSelect={this.handleChangeSelect}
                    onToggleSortOrder={this.handleToggleSortOrder}
                  />
                </div>
                <BaseListing
                  headerDefinition={orderType === "customer" ? COHeaderDefinition : SAMOHeaderDefinition}
                  bodyContent={
                    <>
                      {orders.length > 0 ? (
                        paginatedOrders.map((cO) => <OrderRow key={cO._id.toString()} order={cO} {...this.props} />)
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            <ListPlaceholder type={"order"} />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  baseSize={20}
                  documents={orders}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  noHover={orders.length === 0}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrdersListing;
