import { SupplierOrder, SupplierOrderExtended } from "../../model/supplierOrder.types";
import { CustomerOrder, CustomerOrderExtended, T_EUSTOCK } from "../../model/customerOrder.types";
import { getOrderNumber, isCustomerOrder, isSupplierOrder, O_TRANSPORTTYPES_FORWARDINGORDER } from "../orderUtils";
import { ForwardingOrder, ForwardingOrderInformation } from "../../model/forwardingOrder.types";
import { DataContextInternalType } from "../../context/dataContext";
import { getFormattedHTML } from "../wysiwygUtils";
import { getCustomsClearanceBody } from "./customsClearanceInstructionsGenerationUtils";
import { isCommoditySnapshot } from "../productArticleUtils";
import { formatDateWithType } from "../logisticsUtils";

/**
 * Create a forwarding order html string for pdf generation
 * @param context the internal context
 * @param orders the supplier orders or customer orders for the forwarding order
 * @param forwardingOrder the forwarding order
 * @param draft optional, indicates if pdf is a draft
 * @returns {string} a html string for pdf generation
 */
export function createForwardingOrder(
  context: DataContextInternalType,
  orders: Array<SupplierOrderExtended | CustomerOrderExtended>,
  forwardingOrder: ForwardingOrder,
  draft?: boolean
): string {
  const totalGrossWeight = forwardingOrder.orderInformation.reduce((totalGross, oi) => totalGross + oi.grossWeight, 0);
  const type =
    O_TRANSPORTTYPES_FORWARDINGORDER.find((t) => t.value === forwardingOrder.transportType)?.label ||
    forwardingOrder.transportType; // fallback, should not happen
  let html = `
  <head>
    <link href="https://fonts.googleapis.com/css?family=Signika&display=swap" rel="stylesheet">
      <style>tr:nth-child(even) {
          background-color: white;
      }</style>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
  </head>
  <body style="font-family: Signika,serif">
    <img src="https://rawbids.com/images/logo-dark.png" alt="Logo" width="180" style="float:right" />
    <span style="font-size: 30px; font-weight: bold"> ${draft ? "DRAFT - " : ""}Forwarding Order ${
    forwardingOrder.transportType === T_EUSTOCK ? "" : "Import "
  }by ${type} ${forwardingOrder.returnOrder ? "- Return Shipment" : ""}</span><br /><br /><br /> 
    <div><b>Forwarding order No: </b>RB-${forwardingOrder.forwardingOrderNo}</div><br />
    <div><b>Our Reference: </b>${getAllOrderNumbers(orders)}</div><br />
    <table style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px">
      <thead>
        <tr style="background-color:#333333; color: #ffffff">
          <th style="text-align: left"><b style="white-space:nowrap">Marks &amp; numbers</b></th>
          <th style="text-align: left"><b>Packing</b></th>
          <th style="min-width: 150px; text-align: left"><b>Goods</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Net (kg)</b></th>
          <th style="text-align: left"><b style="white-space:nowrap;">Gross (kg)</b></th>
        </tr>
      </thead>
      <tbody>
       ${getOrderTableRows(orders, forwardingOrder.orderInformation, context).join("")}
       <tr>
        <td colspan="5"><br/></td>
       </tr>
       <tr>
          <td colspan="2"/>
          <td><b>Total: </b></td>
          <td>${forwardingOrder.orderInformation
            .reduce((totalNet, oi) => totalNet + oi.netWeight, 0)
            .toLocaleString()}kg</td>
          <td>${totalGrossWeight === 0 ? "-" : Math.ceil(totalGrossWeight).toLocaleString() + "kg"}</td>
        </tr>
      </tbody>
    </table>
    <br /><br />
    <table style="font-size:16px; width: 100%; display: block; border-spacing: 0 2em;">
      <tbody>
        ${getOrderRows(orders, forwardingOrder)}
      </tbody>
    </table>
    <table style="font-size:16px; width: 100%; display: block; border-spacing: 0 2em;">
      <tbody>
        <tr><td style="vertical-align: top; width: 30%">  <b>Remarks: </b>
          </td><td>${forwardingOrder.remarks}</td>
        </tr>
      </tbody>
    </table> <p style="page-break-after: always;">&nbsp;</p>`;
  if (forwardingOrder.orderInformation.some((oi) => oi.customsCleared !== undefined && !oi.customsCleared)) {
    html += `<p style="page-break-after: auto;">&nbsp;</p>
     ${addCustomsClearanceInstruction(context, forwardingOrder).join("")}`;
  }
  html += `</body>`;
  return html;
}

/**
 * Get all the information for the single customer or supplier orders as html string
 * @param orders The supplier orders or customer orders for the forwarding order
 * @param forwardingOrder The forwarding order
 * @returns
 */
function getOrderRows(
  orders: Array<SupplierOrder | CustomerOrder | SupplierOrderExtended | CustomerOrderExtended>,
  forwardingOrder: ForwardingOrder
): string {
  const infos = forwardingOrder.orderInformation;
  const orderRows: Array<string> = [];
  for (let i = 0; i < infos.length; i++) {
    const info = infos[i];
    const deliveryDate = formatDateWithType(info.deliveryDate, info.deliveryDateType);
    const order = orders.find((o) => o._id.toString() === info.orderId);
    if (order) {
      const orderRow = `<span style="font-size: 24px; font-weight: bold">Order ${getOrderNumber(order)}</span>
 <table style="font-size:16px; width: 100%; display: block; border-spacing: 0 2em;">
      <tbody>
    ${
      isCustomerOrder(order) && order.customerReference !== ""
        ? `
     <tr><td style="vertical-align: top; width: 30%">
        <b>Customer Reference: </b>
    </td><td>${isCustomerOrder(order) ? order.customerReference : ""}</td>
    </tr>`
        : ""
    }
    <tr><td style="vertical-align: top; width: 30%">
        <b>Incoterm: </b>
    </td><td>${forwardingOrder.takeOver.startIncoterm} ${forwardingOrder.takeOver.startingFrom} - ${
        info.destinationIncoterm || ""
      } ${info.destinationTo || ""}</td>
    </tr>
    <tr>
    <td style="vertical-align: top"> <b>Sender: </b>
    </td><td><span>${getFormattedHTML(
      forwardingOrder.returnOrder ? info.recipient || "" : info.sender || ""
    )}</span></td>
    </tr>       
    <tr><td style="vertical-align: top">
        <b>Transport: </b></td><td><span>${getFormattedHTML(
          forwardingOrder.returnOrder ? info.sender || "" : info.recipient || ""
        )}</span></td>
    </tr>
    ${
      info.recipientInformation
        ? `<tr><td style="vertical-align: top">
        <b>Additional Information: </b></td><td><span>${getFormattedHTML(info.recipientInformation)}</span></td>
    </tr>`
        : ""
    }
    <tr><td style="vertical-align: top">
        <b>State: </b></td><td><span>${info.customsCleared ? "Customs cleared" : "Customs not cleared"}</span></td>
    </tr>
    <tr><td style="vertical-align: top">   <b>Delivery Date: </b></td><td>${deliveryDate}</td>
    </tr>
  </tbody>
</table>${
        (i + 1 === infos.length && !forwardingOrder.orderInformation.some((oi) => oi.customsCleared)) || i % 2 !== 0
          ? ""
          : `<p style="page-break-after: always;">&nbsp;</p>`
      }
`;
      orderRows.push(orderRow);
    }
  }
  return orderRows.join("");
}

/**
 * Get the content for the table per order
 * @param orders all orders of this forwarding order
 * @param forwardingOrderOrderInfos Array of ForwardingOrderInformation, all information for the tablerows
 * @param context the internal context
 * @returns {Array<string>} the table rows as string array
 */
function getOrderTableRows(
  orders: Array<SupplierOrderExtended | CustomerOrderExtended>,
  forwardingOrderOrderInfos: Array<ForwardingOrderInformation>,
  context: DataContextInternalType
): Array<string> {
  const orderRows = [];
  for (let i = 0; i < forwardingOrderOrderInfos.length; i++) {
    const order = orders.find((o) => o._id.toString() === forwardingOrderOrderInfos[i].orderId);
    if (order) {
      const { commodity } = order;
      const batches = isSupplierOrder(order)
        ? context.batch
            .filter((b) => b.supplierOrder === order._id.toString())
            .map((b) => {
              return b.lot;
            })
        : order.usedBatches?.map((uB) => {
            return uB.supplierLot;
          });
      orderRows.push(`<tr style="background-color: ${i % 2 === 0 ? "#ffffff" : "#eeeeee"}"><td>${getOrderNumber(
        order
      )}</td>
          <td>${forwardingOrderOrderInfos[i].packaging}</td>
          <td><b>${commodity.title.en}</b><br/>Country of origin: ${commodity.country.name} <br/> ${
        isCommoditySnapshot(commodity) && commodity.particleSize ? commodity.particleSize.en + "<br/>" : ""
      } Article No.: ${commodity.articleNo}<br/>LOT: ${
        batches && batches.length > 0 ? batches.join(", ") : "see attached certificates of analysis"
      }<br/></td>
          <td>${forwardingOrderOrderInfos[i].netWeight.toLocaleString()}kg</td>
          <td>${
            forwardingOrderOrderInfos[i].grossWeight > 0
              ? `${Math.ceil(forwardingOrderOrderInfos[i].grossWeight).toLocaleString()}kg`
              : "-"
          }</td></tr>`);
    }
  }
  return orderRows;
}

/**
 * Get all order numbers separated by comma
 * @param orders all orders in this forwarding order
 * @returns {string} the order numbers as string
 */
function getAllOrderNumbers(
  orders: Array<SupplierOrder | CustomerOrder | SupplierOrderExtended | CustomerOrderExtended>
): string {
  return orders
    .map((o) => {
      return getOrderNumber(o);
    })
    .join(", ");
}

/**
 * Get the customs clearance instructions pdf part for forwarding order
 * @param context the internal context to get the orders from
 * @param forwardingOrder forwarding order for which customs clearances have to be added
 * @returns {Array<string>} customs clearance instructions as string array
 */
function addCustomsClearanceInstruction(
  context: DataContextInternalType,
  forwardingOrder: ForwardingOrder
): Array<string> {
  const customsInstructions: Array<string> = [];
  let counter = 0;
  for (let i = 0; i < forwardingOrder.orderInformation.length; i++) {
    const orderInfo = forwardingOrder.orderInformation[i];
    if (orderInfo.customsCleared !== undefined && !orderInfo.customsCleared) {
      let order: SupplierOrder | CustomerOrder | undefined = context.supplierOrder.find(
        (sO) => sO._id.toString() === orderInfo.orderId
      );
      if (order === undefined) {
        order = context.customerOrder.find((cO) => cO._id.toString() === orderInfo.orderId);
      }
      if (order) {
        counter++;
        const commodity = order.commodity;
        let html =
          `<span style="font-size: 24px; font-weight: bold">Customs Clearance Instructions RB-${order.orderNo}</span><br /><br/>
` +
          getCustomsClearanceBody(
            order,
            commodity.country.name,
            "Per commercial invoice",
            commodity.hsCode,
            commodity.btiRefNo || "",
            forwardingOrder.takeOver.startingFrom,
            forwardingOrder.takeOver.startIncoterm
          ) +
          `<br/><br/>`;
        if (counter !== 0 && counter % 2 === 0 && i + 1 !== forwardingOrder.orderInformation.length) {
          html += `<p style="page-break-after: auto;">&nbsp;</p>`;
        }
        customsInstructions.push(html);
      }
    }
  }
  return customsInstructions;
}
