import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { getEarliestDeliveryDate, validateAmount } from "../../../utils/customerOrderUtils";
import { formatCurrency, formatUnit, getNumericValue, SMALL_SPACE_UNI } from "../../../utils/baseUtils";
import {
  CO_TRANSPORT,
  CO_TYPES,
  T_AIRFREIGHT,
  T_CONTRACT,
  T_SEAFREIGHT,
  T_SPECIALREQUEST,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import { CC_DEFAULT_PERIOD_OPTION, CC_PERIOD_OPTIONS, ContractRequestData } from "../../../utils/customerContractUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { Input } from "../../common/Input";
import DateInput from "../../common/DateInput";
import Tooltip from "../../common/Tooltip";
import { Textarea } from "../../common/Textarea";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { CustomerArticleExtended } from "../../../utils/productArticleUtils";

interface CreateCustomerOrderAnonymousProps extends RouteComponentProps {
  article?: CustomerArticleExtended;
  onBlurAmount?: (amount: number) => void;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CreateCustomerOrderAnonymousState {
  amount: number;
  reference: string;
  method: CO_TYPES;
  contractRequestData: ContractRequestData;
  targetDate: Date;
  userSetTargetDate: boolean;
  earliestExpiry?: Date;
  note: string;
  earliestDeliveryDate: Date;
  loading: boolean;
}

const COMPONENT_NAME = "CreateCustomerOrderAnonymous";

class CreateCustomerOrderAnonymous extends PureComponent<
  CreateCustomerOrderAnonymousProps,
  CreateCustomerOrderAnonymousState
> {
  constructor(props: CreateCustomerOrderAnonymousProps) {
    super(props);
    this.state = {
      method: T_SEAFREIGHT,
      amount: 1000,
      reference: "",
      targetDate: new Date(),
      userSetTargetDate: false,
      contractRequestData: {
        period: CC_DEFAULT_PERIOD_OPTION,
        minimumCallQuantity: 500,
      },
      note: "",
      earliestDeliveryDate: new Date(),
      loading: false,
    };
  }

  componentDidMount() {
    this.props.context.saveRef(COMPONENT_NAME, this);
  }

  componentWillUnmount() {
    this.props.context.deleteRef(COMPONENT_NAME);
  }

  handleClickOnBlur = () => {
    (this.props.context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeContractData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const contractRequestData = _.cloneDeep(this.state.contractRequestData);
    if (e.target.name in contractRequestData) {
      const numericValue = getNumericValue(e);
      if (!numericValue) return;
      _.set(contractRequestData, e.target.name, +numericValue);
      this.setState({ contractRequestData });
    }
  };

  handleChangeContractPeriod = (e: SelectOption) => {
    const contractRequestData = _.cloneDeep(this.state.contractRequestData);
    contractRequestData.period = e;
    this.setState({ contractRequestData });
  };

  handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    targetDate.setHours(12, 0, 0, 0);
    this.setState({ targetDate, userSetTargetDate: true });
  };

  handleChangeMethod = (method: CO_TYPES) => {
    const { article } = this.props;
    const { amount, targetDate, userSetTargetDate } = this.state;
    if (!article || method === this.state.method) return;
    if (method === T_CONTRACT || method === T_SPECIALREQUEST) {
      // No calculation required here
      this.setState({ method });
      return;
    }
    this.setState({ loading: true });
    try {
      this.setState({ method }, async () => {
        const earliestDeliveryDate = await getEarliestDeliveryDate(method as CO_TRANSPORT, undefined, article, amount);
        this.setState({
          targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate,
          earliestDeliveryDate: earliestDeliveryDate,
        });
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleBlurAmount = async (e: React.ChangeEvent<HTMLInputElement> | number) => {
    const { article, onBlurAmount } = this.props;
    const { method, userSetTargetDate, targetDate } = this.state;
    if (!article) return;
    let amount;
    if (typeof e === "number") amount = e;
    else amount = Number(getNumericValue(e));
    this.setState({ loading: true });
    try {
      const earliestDeliveryDate = await getEarliestDeliveryDate(method as CO_TRANSPORT, undefined, article, amount);
      this.setState({ amount, targetDate: userSetTargetDate ? targetDate : earliestDeliveryDate });
    } finally {
      this.setState({ loading: false });
    }
    if (onBlurAmount) onBlurAmount(amount);
  };

  render() {
    const { article } = this.props;
    const { method, amount, reference, contractRequestData, targetDate, earliestDeliveryDate, note } = this.state;

    if (!article) return;
    const suitableAmount = validateAmount(article, amount, method);
    if (article.disabled || !article.approved)
      return (
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <h3 className="card-title flex-column">
              <span className="card-label fw-bolder fs-3 ">Order Settings</span>
              <span className="text-muted fw-normal" style={{ fontSize: "13px" }}>
                Specify the desired amount, destination and other preferences
              </span>
            </h3>
          </div>
          <div className="card-body d-flex flex-column">
            <h6 className="text-muted text-center py-20">This commodity is currently not available</h6>
          </div>
        </div>
      );
    return (
      <div className="card bg-white">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title flex-column">
            <span className="card-label fw-bolder fs-2rem mb-1">Order Settings</span>
            <span className="fs-6 text-muted fw-normal">
              Specify the desired amount, destination and other preferences
            </span>
          </h3>
        </div>
        <div className="card-body d-flex flex-column" style={{ minHeight: "600px" }}>
          <div className="row">
            <div className={method === T_CONTRACT ? "col-12" : "col-6"}>
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Amount</span>
              </label>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  type="number"
                  name="amount"
                  min={0}
                  value={amount}
                  onBlur={this.handleBlurAmount}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {article.unit}
                  </div>
                </div>
              </div>
              <div className="text-danger float-right " style={{ height: "1rem" }}>
                {suitableAmount && `Recommended amount: ${suitableAmount.upper}${article.unit}`}
              </div>
            </div>
            <div className={method === T_CONTRACT ? "d-none" : "col-6"}>
              <label className="required fs-6 fw-bold mb-2">Target Date</label>
              <div>
                <DateInput
                  classes={
                    "form-control custom-form-control " +
                    (targetDate < earliestDeliveryDate
                      ? method === T_SPECIALREQUEST
                        ? "custom-form-control-warning"
                        : "custom-form-control-invalid"
                      : "")
                  }
                  value={targetDate}
                  onBlur={this.handleChangeTargetDate}
                  name={"targetDate"}
                  min={new Date()}
                />
              </div>
              <div className="text-danger float-right " style={{ height: "1rem" }}>
                {targetDate < earliestDeliveryDate &&
                  `Earliest arrival on ${earliestDeliveryDate.toLocaleDateString()}`}{" "}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span className="required">Destination Address</span>
              </label>
              <div className="row">
                <div className="col-10 pr-0">
                  <CustomSelect options={[]} matchFormControl={true} placeholder="Address..." disabled />
                </div>
                <div className="col-2 text-right">
                  <button className="btn btn-light btn-sm disabled" disabled>
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6">
              <label className="align-items-center fs-6 fw-bold mb-2">
                <span>Order Reference</span>
              </label>
              <Input
                type="text"
                className="form-control custom-form-control"
                placeholder="Your internal order reference..."
                name="reference"
                value={reference}
                onBlur={this.handleChangeInput}
              />
            </div>
          </div>
          {method !== T_SPECIALREQUEST && (
            <div className="row mb-4">
              <div className="col-12">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span>Supplier</span>
                </label>
                <CustomSelect
                  options={[]}
                  disabled={true}
                  matchFormControl={true}
                  placeholder={"Select a preferred supplier if desired..."}
                  value={{ value: "Rawbids", label: "Selected by RAWBIDS" }}
                  additionalClasses="py-0"
                />
              </div>
            </div>
          )}
          <div className="mb-6">
            <label className="align-items-center fs-6 fw-bold mb-2">
              <span className="required">Ordering Type</span>
            </label>
            <div>
              <div className="btn-group w-100">
                <label
                  className={"btn btn-light " + (method === T_WAREHOUSE ? " active" : "")}
                  onClick={() => this.handleChangeMethod(T_WAREHOUSE)}
                >
                  Warehouse
                  <small className="text-success ml-2">
                    <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
                    <Tooltip
                      tooltipText={`Only ${formatUnit(2000, article?.unit || "kg")} currently available`}
                      tooltipClass={"custom-tooltip mb-8"}
                    >
                      <i className="fa fa-exclamation-circle text-muted opacity-04 ml-1" />
                    </Tooltip>
                  </small>
                </label>
                <label
                  className={"btn btn-light " + (method === T_SEAFREIGHT && "active")}
                  onClick={() => this.handleChangeMethod(T_SEAFREIGHT)}
                >
                  Seafreight
                  <small className="text-success ml-2">
                    <small className="text-success ml-2">
                      <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
                    </small>
                  </small>
                </label>
                <label
                  className={"btn btn-light " + (method === T_AIRFREIGHT && "active")}
                  onClick={() => this.handleChangeMethod(T_AIRFREIGHT)}
                >
                  Airfreight
                  <small className="text-success ml-2">
                    <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
                  </small>
                </label>
                <label
                  className={"btn btn-light " + (method === T_CONTRACT && "active")}
                  onClick={() => this.handleChangeMethod(T_CONTRACT)}
                >
                  Contract
                </label>
                <label
                  className={"btn btn-light " + (method === T_SPECIALREQUEST && "active")}
                  onClick={() => this.handleChangeMethod(T_SPECIALREQUEST)}
                >
                  Special Request
                </label>
              </div>
            </div>
          </div>
          <div className="text-center mb-4 p-2">
            <div className="bg-light2 rounded p-6">
              <div className="d-flex flex-stack flex-grow-1 ">
                <div className="text-center w-100">
                  <div className="text-white fw-bolder" style={{ fontSize: "2.2rem" }}>
                    <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
                    {SMALL_SPACE_UNI}/{SMALL_SPACE_UNI}
                    {article ? article.unit : "kg"}
                  </div>
                  <span className="text-muted fw-bold mt-0 mb-2">
                    Total Price:
                    <span className="text-bold text-white fs-5 ml-1">
                      <AnonymousBlurText>{formatCurrency(1.23 * amount, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
                    </span>
                    <div className="text-success">
                      Delivery in <AnonymousBlurText>4-5 Weeks</AnonymousBlurText>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            {method === T_SPECIALREQUEST && (
              <>
                <div className="row flex-grow-1 mb-4">
                  <div className=" mt-2 mb-2">
                    <span className="text-warning">
                      For special needs you can make a request here. Our team will get back to you shortly after. The
                      request is used, for example, if you need to reach a certain target price, have a particularly
                      high purchase quantity, or you are unclear about something and need information to order.
                    </span>
                  </div>
                  <div className="d-flex flex-column p-2 col-12">
                    <div className="d-flex flex-column mb-2">
                      <label className="required fs-5 fw-bold mb-2">Message</label>
                      <div className="input-group">
                        <Textarea
                          value={note}
                          onChange={this.handleChangeInput}
                          name="note"
                          className="form-control custom-form-control"
                          placeholder="For example, a specific target price, or you want a price for a very high purchase quantity for which we can renegotiate again with our suppliers, or queries about the raw material, anything you want to tell us."
                          rows={5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {method === T_CONTRACT && (
              <>
                <div className="row px-2">
                  <div className="col-6">
                    <label className="required fs-5 fw-bold mb-2">Validity Period</label>
                    <div>
                      <CustomSelect
                        options={CC_PERIOD_OPTIONS}
                        matchFormControl={true}
                        value={contractRequestData.period}
                        onChange={this.handleChangeContractPeriod}
                      />
                    </div>
                    <div className="text-success float-right fs-8" style={{ height: "1rem" }}>
                      {`Earliest estimated start date: ${earliestDeliveryDate.toLocaleDateString()}`}
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">MOQ</label>
                    <div className="input-group">
                      <Input
                        className="form-control custom-form-control"
                        type="number"
                        name="minimumCallQuantity"
                        min={0}
                        value={contractRequestData.minimumCallQuantity}
                        onChange={this.handleChangeContractData}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          {article?.unit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mb-4 p-2 flex-grow-1">
                  <div className="bg-light2 rounded p-6">
                    <div className="d-flex flex-stack flex-grow-1 ">
                      <div className="text-center w-100">
                        <span className="fw-bold fs-3">Price on request</span>
                        <div className="fs-6 text-muted">
                          <div className="text-muted">Our team will contact you shortly.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <button className="btn w-100 btn-success" onClick={this.handleClickOnBlur}>
              Continue
            </button>
            <div className="text-center mt-2">
              <span className="text-muted">Next Step: Summary</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCustomerOrderAnonymous;
