import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import FinanceFilter from "../FinanceFilter";
import { Input } from "../../common/Input";
import Search from "../../common/Search";
import {
  I_CREDIT_NOTE,
  I_CREDITNOTECUSTOMER,
  I_CREDITNOTESAMPLE,
  I_INVOICE,
  I_STATE,
  REMINDER,
} from "../../../model/invoice.types";
import { I_SORTOPTIONS } from "../../../utils/invoiceUtils";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import InvoiceRow from "./InvoiceRow";
import BaseListing from "../../common/BaseListing";
import { paginate, PaginationState } from "../../common/Pagination";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import { zipDocuments } from "../../../utils/zipUtils";
import { downloadFile } from "../../../utils/fileUtils";
import { exportInvoicesAsCSV } from "../../../utils/csvUtils";
import { OrderSelectOption, SelectOption } from "../../common/CustomSelect";
import { DataContextInternal } from "../../../context/dataContext";

interface InvoiceListingProps extends RouteComponentProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface InvoiceListingState extends PaginationState {
  search: string;
  dueOption?: SelectOption;
  fileType: SelectOption;
  sortOption: SelectOption;
  sortOrderOption: OrderSelectOption;
  actionOption?: SelectOption;
  showClosed: boolean;
  elementsSelected: Array<string>; // IDs of the selected invoices
  generating: boolean;
}

const COMPONENT_NAME = "InvoiceListing";

class InvoiceListing extends PureComponent<InvoiceListingProps, InvoiceListingState> {
  constructor(props: InvoiceListingProps) {
    super(props);
    this.state = {
      search: "",
      sortOption: I_SORTOPTIONS[0],
      fileType: new URLSearchParams(props.location.search).get("creditNotes")
        ? { value: I_CREDIT_NOTE, label: "Credit Note" }
        : { value: I_INVOICE, label: "Invoice" },
      sortOrderOption: SORTORDEROPTIONS[0],
      showClosed: false,
      currentPage: 1,
      pageSize: 25,
      elementsSelected: [],
      generating: false,
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ search: e.target.value, currentPage: 1 });
  handleChangeDueOption = (dueOption: SelectOption) => this.setState({ dueOption });
  handleChangeFileTypeOption = (fileType: SelectOption) => this.setState({ fileType });
  handleChangeSortOption = (sortOption: SelectOption) => this.setState({ sortOption });
  handleChangeSortOrderOption = (sortOrderOption: { value: "asc" | "desc"; label: string }) =>
    this.setState({ sortOrderOption });
  handleChangeActionOption = (actionOption?: SelectOption) => this.setState({ actionOption });
  handleChangeShowPaid = () => this.setState({ showClosed: !this.state.showClosed });
  handleClickAction = () => {
    const { actionOption } = this.state;
    if (!actionOption) return;
    if (actionOption.value === "exportAsZIP") {
      this.handleDownloadInvoices();
    } else if (actionOption.value === "exportAsCSV") {
      this.handleExportAsCSV();
    }
  };

  /**
   * Handle clicking the header checkbox
   * @param ids All ids that are currently shown in the filtered invoices
   */
  handleClickHeaderCheckbox = (ids: Array<string>) => {
    const elementsSelected = _.clone(this.state.elementsSelected);
    if (elementsSelected.length > 0) this.setState({ elementsSelected: [] });
    else this.setState({ elementsSelected: ids });
  };

  /**
   * Handle clicking the checkbox of an invoice
   * @param _id ID of the invoice
   */
  handleClickCheckbox = (_id: string) => {
    const elementsSelected = _.clone(this.state.elementsSelected);
    const idx = elementsSelected.indexOf(_id);
    if (idx !== -1) elementsSelected.splice(idx, 1);
    else elementsSelected.push(_id);
    this.setState({ elementsSelected });
  };

  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleCurrentPageChange = (currentPage: number) => this.setState({ currentPage });

  /**
   * Handles compressing all selected invoices into a ZIP and downloads them.
   */
  handleDownloadInvoices = () => {
    if (this.state.generating) return;
    const { invoice } = this.props.context;
    const { elementsSelected, fileType } = this.state;
    this.setState({ generating: true });
    try {
      const invoicesSelected = invoice.filter((i) => elementsSelected.includes(i._id.toString()));
      const invoiceDocuments = invoicesSelected.map((iS) => {
        if (iS.reminders.length === 0) return iS.file;
        const files = [iS.file];
        for (let i = 0; i < iS.reminders.length; i++) {
          files.push(iS.reminders[i].reminderFile);
        }
        return files;
      });
      // Need to flatten the array since we might have pushed arrays to it
      const invoiceDocumentsFlat = _.flatten(invoiceDocuments);
      const fileName = `Export-${
        fileType.value === "invoice" ? "Invoices" : "CreditNotes"
      }-${new Date().toISOString()}`;
      zipDocuments(fileName, invoiceDocumentsFlat);
    } finally {
      this.setState({ generating: false });
    }
  };

  handleExportAsCSV = () => {
    const { invoice } = this.props.context;
    const { elementsSelected, fileType } = this.state;
    this.setState({ generating: true });
    try {
      const invoicesSelected = invoice.filter((i) => elementsSelected.includes(i._id.toString()));
      const csv = exportInvoicesAsCSV(invoicesSelected, this.props.context);
      const fileName = `Export-${
        fileType.value === "invoice" ? "Invoices" : "CreditNotes"
      }-${new Date().toISOString()}.csv`;
      downloadFile(csv, fileName, "text/plain");
    } finally {
      this.setState({ generating: false });
    }
  };

  /**
   * Filters the invoices by the given parameters
   * @returns { Array<Invoice> } Filtered invoices
   */
  getFilteredInvoices = () => {
    const { invoice } = this.props.context;
    const { dueOption, sortOption, fileType, sortOrderOption, search, showClosed } = this.state;
    let invoicesFiltered = _.cloneDeep(invoice);

    if (fileType.value === I_CREDIT_NOTE)
      invoicesFiltered = invoicesFiltered.filter(
        (i) => i.type === I_CREDITNOTECUSTOMER || i.type === I_CREDITNOTESAMPLE
      );
    if (fileType.value === I_INVOICE) {
      invoicesFiltered = invoicesFiltered.filter(
        (i) => i.type !== I_CREDITNOTECUSTOMER && i.type !== I_CREDITNOTESAMPLE
      );
      // Check show paid flag
      if (!showClosed)
        invoicesFiltered = invoicesFiltered.filter((i) => ![I_STATE.PAID, I_STATE.CANCELED].includes(i.state));

      // Check due options filter
      if (dueOption) {
        invoicesFiltered = invoicesFiltered.filter((i) => {
          const due = new Date(i.invoiceDate);
          due.setDate(due.getDate() + i.paymentTarget);
          const now = new Date().getTime();
          if (dueOption.value === "-1") return i.reminders.length > 0;
          if (dueOption.value === "-2") return i.reminders.some((r) => r.type !== REMINDER);
          if (dueOption.value === "0") return due.getTime() > now;
          if (dueOption.value === "7") return now - due.getTime() > 1000 * 60 * 60 * 24 * 7;
          if (dueOption.value === "14") return now - due.getTime() > 1000 * 60 * 60 * 24 * 14;
          if (dueOption.value === "28") return now - due.getTime() > 1000 * 60 * 60 * 24 * 28;
        });
      }
    }

    // Check sort options filter
    if (sortOption) {
      if (sortOption.value === "overdue") {
        invoicesFiltered = invoicesFiltered.sort((i1, i2) => {
          const dD1 = new Date(i1.invoiceDate);
          if (i1.paymentTarget !== -1) dD1.setDate(dD1.getDate() + i1.paymentTarget);
          const dD2 = new Date(i2.invoiceDate);
          if (i2.paymentTarget !== -1) dD2.setDate(dD2.getDate() + i2.paymentTarget);
          return dD2.getTime() - dD1.getTime();
        });
      } else {
        invoicesFiltered = _.orderBy(invoicesFiltered, sortOption.value, sortOrderOption.value);
      }
    }

    // Perform search
    if (search) invoicesFiltered = doFuseSearch(invoicesFiltered, search, ["invoiceNumber", "company.name"]);

    return invoicesFiltered;
  };

  render() {
    const { context } = this.props;
    const {
      sortOption,
      sortOrderOption,
      fileType,
      dueOption,
      actionOption,
      showClosed,
      elementsSelected,
      pageSize,
      currentPage,
      generating,
    } = this.state;

    const invoicesFiltered = this.getFilteredInvoices();
    const invoicesPaginated = paginate(invoicesFiltered, currentPage, pageSize);
    const ids = invoicesFiltered.map((iF) => iF._id.toString());

    const headerDefinitionInvoices = [
      {
        title: (
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <Input
              type="checkbox"
              className="form-check-input"
              onClick={() => this.handleClickHeaderCheckbox(ids)}
              checked={ids.length > 0 && ids.length === elementsSelected.length}
            />
          </div>
        ),
        style: { width: "5%" },
      },
      { title: "Invoice", style: { width: "10%" } },
      { title: "Amount", style: { width: "13%" } },
      { title: "Customer", style: { width: "18%" } },
      { title: "Reference", style: { width: "10%" } },
      { title: "Date", style: { width: "10%" } },
      { title: "Payment", style: { width: "9%" } },
      { title: "Remaining", style: { width: "10%" } },
      { title: "File", style: { width: "6%" } },
      { title: "Action", className: "text-right", style: { width: "10%" } },
    ];

    const headerDefinitionCreditNote = [
      {
        title: (
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <Input
              type="checkbox"
              className="form-check-input"
              onClick={() => this.handleClickHeaderCheckbox(ids)}
              checked={ids.length > 0 && ids.length === elementsSelected.length}
            />
          </div>
        ),
        style: { width: "5%" },
      },
      { title: "Credit Note", style: { width: "10%" } },
      { title: "Amount", style: { width: "15%" } },
      { title: "Customer", style: { width: "20%" } },
      { title: "Reference", style: { width: "24%" } },
      { title: "Date", style: { width: "16%" } },
      { title: "File", style: { width: "10%" } },
    ];

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card bg-white h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  {fileType.value !== I_CREDIT_NOTE ? (
                    <>
                      <span className="card-label fw-bolder mb-3 fs-3rem">Invoices</span>
                      <Link className="btn btn-outline btn-outline-light float-right" to="/createInvoice">
                        New Invoice
                      </Link>
                    </>
                  ) : (
                    <>
                      <span className="card-label fw-bolder mb-3 fs-3rem">Credit Notes</span>
                      <Link className="btn btn-outline btn-outline-light float-right" to="/createCreditNote">
                        New Credit Note
                      </Link>
                    </>
                  )}
                </h3>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Search placeholder="Search for invoices..." onSearch={this.handleChangeSearch} />
                  </div>
                  {fileType.value !== I_CREDIT_NOTE && (
                    <div className="col-6 col-md-3 align-self-center">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input position-static"
                          checked={showClosed}
                          onChange={this.handleChangeShowPaid}
                          type="checkbox"
                        />
                        <label className="form-check-label text-muted">Include closed invoices</label>
                      </div>
                    </div>
                  )}
                </div>
                <FinanceFilter
                  additionalWrapperClasses={"mb-10 mt-6"}
                  action={actionOption}
                  due={dueOption}
                  fileType={fileType}
                  sortBy={sortOption}
                  sortOrder={sortOrderOption}
                  onChangeFileType={this.handleChangeFileTypeOption}
                  onChangeDue={this.handleChangeDueOption}
                  onChangeSortBy={this.handleChangeSortOption}
                  onChangeSortOrder={this.handleChangeSortOrderOption}
                  onChangeAction={this.handleChangeActionOption}
                  onClickAction={this.handleClickAction}
                  actionDisabled={generating}
                />
                <BaseListing
                  headerDefinition={
                    fileType.value !== I_CREDIT_NOTE ? headerDefinitionInvoices : headerDefinitionCreditNote
                  }
                  bodyContent={
                    <>
                      {invoicesPaginated.length > 0 ? (
                        invoicesPaginated.map((i) => (
                          <InvoiceRow
                            key={i._id.toString()}
                            isCreditNote={fileType.value === I_CREDIT_NOTE}
                            invoice={i}
                            checked={elementsSelected.some((eS) => eS === i._id.toString())}
                            onClickCheckbox={this.handleClickCheckbox}
                            context={context}
                          />
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={10}>
                            No invoices found
                          </td>
                        </tr>
                      )}
                    </>
                  }
                  baseSize={25}
                  noHover={true}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  documents={invoicesFiltered}
                  onPageChange={this.handleCurrentPageChange}
                  onPageSizeChange={this.handlePageSizeChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvoiceListing);
