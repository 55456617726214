import _ from "lodash";
import { round } from "./baseUtils";
import { Commodity, CommoditySnapshot, Duty } from "../model/commodity.types";
import { BASE_CURRENCY, convertCurrency, Currencies, EURO, USD } from "./currencyUtils";
import { callFunction } from "../services/dbService";
import { SupplierOrderCalculation } from "./orderCalculationUtils";
import { ContainerCost, Seaport } from "../model/seaport.types";
import { Airport } from "../model/airport.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { DataContextInternal, DataContextInternalType } from "../context/dataContext";
import { CONFIG, getConfiguration } from "./configurationUtils";
import {
  AirFreightCostDefinition,
  AirFreightInsurance,
  B2BFollowUpCost,
  CalculationConfiguration,
  ContainerData,
  FCLCostDefinition,
  LCLCostDefinition,
  PaletteData,
  SeaFreightCostDefinition,
  SeaFreightInsurance,
  SeaportFreightCost,
  SingleContainerData,
  WarehouseCostDefinition,
} from "../model/configuration/calculationConfiguration.types";
import { getBatchAmount } from "./batchUtils";
import { getOrderNumber } from "./orderUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { SupplierOrder } from "../model/supplierOrder.types";
import { FinishedProduct, FinishedProductSnapshot } from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";
import { isFinishedProduct } from "./finishedProductUtils";
import { Batch } from "../model/batch.types";

export interface CalculationPrice {
  price: number;
  currency: string;
}

export interface LCLSeaFreightPriceCalculation {
  totalCommodityPrice: number;
  totalWarehouseCost?: number;
  dockage: number;
  b2bFollowUpCost: number;
  customsCost: { duty: number; flatRate: number; additionalFee: number; total: number };
  seaFreight: { cost: number; cbm: boolean };
  lclCharges: { cost: number; cbm: boolean };
  warehouseHandlingCost?: number;
  totalCost: number;
  totalPricePerUnit: number;
  exchangeRate: number;
  insurance: SeaFreightInsurance;
  baseValues: CalculationBaseValues;
}

export interface AirFreightPriceCalculation {
  totalCommodityPrice: number;
  totalWarehouseCost?: number;
  totalInsuranceCost?: number;
  airFreightCost: number;
  airportCost: number;
  followUpCost: number;
  b2bFollowUpCost: number;
  additionalFee: number;
  duty: number;
  warehouseHandlingCost?: number;
  totalCost: number;
  totalPricePerUnit: number;
  exchangeRate: number;
  baseValues: CalculationBaseValues;
}

export interface SeaFreightPriceCalculation {
  totalCommodityPrice: number;
  totalWarehouseCost?: number;
  totalInsuranceCost?: number;
  fclCost: { freight: number; followUp: number; total: number };
  lclCost: { freight: number; followUp: number; total: number };
  customsCost: { duty: number; flatRate: number; additionalFee: number; total: number };
  b2bFollowUpCost: number;
  warehouseHandlingCost?: number;
  totalCost: number;
  totalPricePerUnit: number;
  baseValues: CalculationBaseValues;
}

export interface EUStockPriceCalculation {
  totalCommodityPrice: number;
  totalWarehouseCost?: number;
  b2bFollowUpCost: number;
  warehouseHandlingCost: number;
  followUpCost: number;
  totalCost: number;
  totalPricePerUnit: number;
  baseValues: CalculationBaseValues;
}

export interface WarehousePriceCalculation {
  totalCommodityPrice: number;
  totalLogisticCost: number;
  totalIncludedMargin: number;
  totalCost: number;
  totalPricePerUnit: number;
}

export interface CalculationBaseValues {
  cbm: number;
  weight: number;
  palettes: number;
}

export interface ContainerInformation extends CalculationBaseValues, SingleContainerData {
  amount: number;
}

export interface ExtendedContainerData {
  "20": ContainerInformation;
  "40": ContainerInformation;
  "40HC": ContainerInformation;
}

export interface ContainerValues {
  containers: ExtendedContainerData;
  rest: CalculationBaseValues;
}

export interface CommonCalculationValues {
  customsFeeAgency: number;
  warehouseCost: WarehouseCostDefinition;
  b2bFollowUpCost: number;
  paletteData: PaletteData;
  duty: Duty;
  minimumAbsoluteMargin: { value: number; currency: string };
  baseValues: CalculationBaseValues;
}

export interface SeaFreightCalculationValues extends CommonCalculationValues {
  seaFreightCost: SeaportFreightCost; // cost related to selected seaport
  customsFlatRate: number;
  insurance: SeaFreightInsurance;
  otherCost: SeaFreightCostDefinition; // all other costs related to sea freight
  containerValues: ContainerValues;
  breakEvenPoints: BreakEvenPoints;
}

export interface LCLSeaFreightCalculationValues {
  lclCharges: typeof LCL_CHARGES;
  dockage: number;
  customsFlatRate: number;
  customsFeeAgency: number;
  warehouseCost: WarehouseCostDefinition;
  seaFreightCost: number;
  b2bFollowUpCost: number;
  paletteData: PaletteData;
  duty: Duty;
  baseValues: CalculationBaseValues;
}

export interface EUStockCalculationValues {
  baseValues: CalculationBaseValues;
  b2bFollowUpCost: number;
  paletteData: PaletteData;
  warehouseCost: WarehouseCostDefinition;
}

export interface AirFreightCalculationValues extends CommonCalculationValues {
  airFreightCost: { cost: number; currency: string }; // air freight cost for selected airport
  airportStorageTime: number;
  customsFreightCoefficient: number;
  insurance: AirFreightInsurance;
  otherCost: AirFreightCostDefinition; // all other costs related to air freight
}

export interface CostForAmountValue {
  cost: number;
  currency: string;
  amount: number;
  unit: "kg";
}

export interface WarehouseCalculationRelatedObjects {
  batches: Array<Batch>;
  supplierOrder: SupplierOrder;
  commodity: Commodity;
}

export interface WarehouseCalculationValues {
  totalLogisticCost: CostForAmountValue;
  minimumAbsoluteMargin: { value: number; currency: string };
}

interface AllocationData {
  type: "container" | "lcl";
  size?: "20" | "40" | "40HC"; // only with container
  lclPalettes?: number; // only with lcl
  cost: number;
  container?: ContainerInformation; // only with container
}

export interface BreakEvenPoints {
  "20": number;
  "40": number;
  "40HC": number;
}

export const DEFAULT_PALETTE: PaletteData = {
  cbm: 1.2, // length * width * total height
  length: 120,
  width: 80,
  height: 125, // total height of packaged palette
  netWeight: 450, // only commodities
  grossWeight: 500, // total weight including packaging and palette
};

export const DOCKAGE = 60; // € per ton

// € per ton or cbm
export const LCL_CHARGES = {
  weight: 30,
  cbm: 15,
};

export const P_CALC_WAREHOUSE = "priceCalculatorWarehouse";

/**
 * Get the b2b follow-up cost for the amount of palettes
 * @param palettes amount of palettes
 * @param costDefinition optional, b2b followup cost definition
 * @returns {number} the total cost for the palettes in €
 */
export const getB2BFollowUpCost = (palettes: number, costDefinition?: B2BFollowUpCost): number => {
  const ceiledPalettes = Math.ceil(palettes);
  if (costDefinition) {
    let cost = costDefinition.paletteCost.find((pC) => pC.palettes === ceiledPalettes)?.cost;
    if (cost === undefined) cost = costDefinition.fallbackPerPalette.cost * palettes;
    return cost;
  }

  // € per palette
  switch (ceiledPalettes) {
    case 1:
      return 100;
    case 2:
      return 150;
    case 3:
      return 200;
    case 4:
      return 240;
    case 5:
      return 300;
    case 6:
      return 340;
    case 7:
      return 370;
    case 8:
      return 410;
    case 9:
      return 450;
    case 10:
      return 500;
    default:
      return palettes * 50;
  }
};

/**
 * Calculate the cbm and gross weight for given net weight and palette information
 * @param amount the net weight of the commodities or the amount of palettes
 * @param paletteData the palette data
 * @param isPaletteAmount optional, flag indicating that the amount is already the palette amount
 * @returns {[CBM: number, weight: number, paletteAmount: number, restPalette: number]} quadruple with cbm, gross weight and amount of palettes and palette rest
 */
export const calculateCBMAndGrossWeight = (
  amount: number,
  paletteData: PaletteData,
  isPaletteAmount?: boolean
): [CBM: number, weight: number, paletteAmount: number, restPalette: number] => {
  const paletteAmount = isPaletteAmount ? amount : amount / paletteData.netWeight;
  const totalPalettes = Math.ceil(paletteAmount);
  return [
    round(paletteData.cbm * paletteAmount, 2),
    round(paletteData.grossWeight * paletteAmount, 2),
    totalPalettes,
    paletteAmount % 1, // 0.5 for 5.5 palettes
  ] as [CBM: number, weight: number, paletteAmount: number, restPalette: number];
};

/**
 * @deprecated LCL calculation is not maintained anymore
 * Calculate the final DDP price
 * @param totalCommodityAmount total commodity amount in kg
 * @param warehouseAmount amount of commodity bought for warehouse
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<LCLSeaFreightPriceCalculation>} object with price calculation details
 */
export const calculateLCLPrice = async (
  totalCommodityAmount: number,
  warehouseAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: LCLSeaFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<LCLSeaFreightPriceCalculation> => {
  const {
    lclCharges,
    dockage,
    customsFlatRate,
    customsFeeAgency,
    b2bFollowUpCost,
    baseValues,
    duty,
    seaFreightCost,
    warehouseCost,
  } = calculationValues;
  const { cbm, weight, palettes } = baseValues;
  return callFunction("getLCLPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    seaFreightCost,
    duty,
    cbm,
    weight,
    palettes,
    dockage,
    lclCharges,
    customsFlatRate,
    customsFeeAgency,
    b2bFollowUpCost,
    warehouseCost,
    currency ?? BASE_CURRENCY,
    currencies,
    warehouseAmount,
  ]);
};

/**
 * Calculate the final air freight DDP price
 * @param totalCommodityAmount total commodity amount in kg
 * @param warehouseAmount amount of commodity bought for warehouse
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for air freight calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<AirFreightPriceCalculation>} object with price calculation details
 */
export const calculateAirFreightPrice = async (
  totalCommodityAmount: number,
  warehouseAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: AirFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<AirFreightPriceCalculation> => {
  return callFunction("getAirFreightPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
    warehouseAmount,
  ]);
};

/**
 * Calculate the final sea freight DDP price
 * @param totalCommodityAmount total commodity amount in kg
 * @param warehouseAmount amount of commodity bought for warehouse
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for sea freight calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<SeaFreightPriceCalculation>} object with price calculation details
 */
export const calculateSeaFreightPrice = async (
  totalCommodityAmount: number,
  warehouseAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: SeaFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<SeaFreightPriceCalculation> => {
  return callFunction("getSeaFreightPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
    warehouseAmount,
  ]);
};

/**
 * Calculate the final EU stock price
 * @param totalCommodityAmount total commodity amount in kg
 * @param warehouseAmount amount of commodity bought for warehouse
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for EU stock calculation
 * @param currencies currency exchange rates
 * @param followUpCost transport costs from supplier to our warehouse
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<EUStockPriceCalculation>} object with price calculation details
 */
export const calculateEUStockPrice = async (
  totalCommodityAmount: number,
  warehouseAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: EUStockCalculationValues,
  currencies: Currencies,
  followUpCost: number,
  currency?: string
): Promise<EUStockPriceCalculation> => {
  return callFunction("getEUStockPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
    followUpCost,
    warehouseAmount,
  ]);
};

/**
 * Calculate the final warehouse DDP price
 * @param totalCommodityAmount total commodity amount in kg
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for warehouse calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<WarehousePriceCalculation>} object with price calculation details
 */
export const calculateWarehousePrice = async (
  totalCommodityAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: WarehouseCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<WarehousePriceCalculation> => {
  return callFunction("getWarehousePriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
  ]);
};

/**
 * Calculate the FOB price from a given DDP price for air freight
 * @param totalCommodityAmount total commodity amount in kg
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for air freight calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<AirFreightPriceCalculation>} object with price calculation details
 */
export const reverseCalculateAirFreightPrice = async (
  totalCommodityAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: AirFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<AirFreightPriceCalculation> => {
  return callFunction("getReverseAirFreightPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
  ]);
};

/**
 * @deprecated LCL calculation is not maintained anymore
 * Calculate the FOB price from a given DDP price
 * @param totalCommodityAmount total commodity amount in kg
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<LCLSeaFreightPriceCalculation>} object with price calculation details
 */
export const reverseCalculateLCLPrice = async (
  totalCommodityAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: LCLSeaFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<LCLSeaFreightPriceCalculation> => {
  const {
    lclCharges,
    dockage,
    customsFlatRate,
    customsFeeAgency,
    b2bFollowUpCost,
    baseValues,
    duty,
    seaFreightCost,
    warehouseCost,
  } = calculationValues;
  const { cbm, weight, palettes } = baseValues;
  return callFunction("getReverseLCLPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    seaFreightCost,
    duty,
    cbm,
    weight,
    palettes,
    dockage,
    lclCharges,
    customsFlatRate,
    customsFeeAgency,
    b2bFollowUpCost,
    warehouseCost,
    currency ?? BASE_CURRENCY,
    currencies,
  ]);
};

/**
 * Calculate the FOB price from a given DDP price for sea freight
 * @param totalCommodityAmount total commodity amount in kg
 * @param pricePerUnit purchase price of commodity per kg
 * @param calculationValues object containing all values relevant for sea freight calculation
 * @param currencies currency exchange rates
 * @param currency optional, currency for values to be converted to
 * @returns {Promise<SeaFreightPriceCalculation>} object with price calculation details
 */
export const reverseCalculateSeaFreightPrice = async (
  totalCommodityAmount: number,
  pricePerUnit: CalculationPrice,
  calculationValues: SeaFreightCalculationValues,
  currencies: Currencies,
  currency?: string
): Promise<SeaFreightPriceCalculation> => {
  return callFunction("getReverseSeaFreightPriceCalculation", [
    totalCommodityAmount,
    pricePerUnit,
    calculationValues,
    currency ?? BASE_CURRENCY,
    currencies,
  ]);
};

/**
 * @deprecated LCL calculation is not maintained anymore
 * Get default sea freight calculation values for commodity and amount or 1 palette
 * @param commodity optional, selected commodity for duty data
 * @param amount optional, amount to calculate base values for
 * @returns {LCLSeaFreightCalculationValues} default calculation values object
 */
export const getDefaultLCLSeaFreightCalculationsValues = async (
  commodity?: Commodity | CustomerCommodity,
  amount?: number
): Promise<LCLSeaFreightCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues, seaFreightValues } = calculationConfiguration.values;
  const { defaultPalette, b2bFollowUpCost, customsFeeAgency, customsFlatRate, warehouseCost } = generalValues;
  const { defaultSeaportFreightCost } = seaFreightValues;
  let cbm = 1.2;
  let weight = 500;
  let palettes = 1;
  if (amount) [cbm, weight, palettes] = calculateCBMAndGrossWeight(amount, defaultPalette);

  return {
    warehouseCost,
    lclCharges: LCL_CHARGES,
    dockage: DOCKAGE,
    customsFlatRate: customsFlatRate.cost,
    customsFeeAgency: customsFeeAgency,
    seaFreightCost: defaultSeaportFreightCost.cost.cost,
    paletteData: defaultPalette,
    duty: commodity ? commodity.duty : { percentage: 0 },
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues: { cbm, weight, palettes },
  };
};

/**
 * Get default sea freight calculation values for commodity and amount or 1 palette
 * @param context data context with ports and currencies
 * @param article optional, selected commodity or finished product for duty data
 * @param amount optional, amount to calculate base values for
 * @param weightPerUnit optional, if it is a finished product, amount needs to be set to kg
 * @param preselectedSeaport optional, if set the selected seaport is used
 * @returns {Promise<SeaFreightCalculationValues>} default calculation values object
 */
export const getDefaultSeaFreightCalculationsValues = async (
  context: DataContextInternalType,
  article?:
    | Commodity
    | CustomerCommodity
    | FinishedProduct
    | CustomerFinishedProduct
    | CommoditySnapshot
    | FinishedProductSnapshot,
  amount?: number,
  weightPerUnit?: number,
  preselectedSeaport?: Seaport
): Promise<SeaFreightCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues, seaFreightValues } = calculationConfiguration.values;
  const { containerData, defaultPalette, b2bFollowUpCost, customsFeeAgency, customsFlatRate, warehouseCost } =
    generalValues;
  const { seaFreightCost, defaultSeaportFreightCost, minimumMargin, insurance } = seaFreightValues;
  let cbm = 1.2;
  let weight = 500;
  let palettes = 1;
  if (amount)
    [cbm, weight, palettes] = calculateCBMAndGrossWeight(
      amount * (weightPerUnit && weightPerUnit > 0 ? weightPerUnit : 1),
      defaultPalette
    );

  // Default values of no particular importance. Container cost will be updated with selected port
  // Default values are realistic values for ports as of 08.08.2023
  const seaport =
    preselectedSeaport ?? context?.seaport.find((s) => s.cost !== undefined && s.containerCost !== undefined);
  const seaportFreightCost =
    seaport && seaport.cost && seaport.containerCost
      ? {
          cost: { containerCost: seaport.containerCost, cost: seaport.cost },
          currency: seaport?.currency || USD,
        }
      : defaultSeaportFreightCost;

  const extendedContainerData = getExtendedContainerData(defaultPalette, containerData);
  const breakEvenPoints = getLCLFCLBreakEven(
    seaportFreightCost,
    extendedContainerData,
    seaFreightCost,
    defaultPalette,
    context.currencies
  );

  const containerAllocation = findOptimalContainerAllocation(
    palettes,
    extendedContainerData,
    breakEvenPoints,
    defaultPalette,
    seaportFreightCost,
    seaFreightCost,
    context.currencies
  );

  const baseValues = { cbm, weight, palettes };
  const containerValues = getContainerValuesForAllocation(
    containerAllocation,
    defaultPalette,
    extendedContainerData,
    baseValues
  );

  return {
    customsFlatRate: customsFlatRate.cost,
    customsFeeAgency,
    containerValues,
    seaFreightCost: seaportFreightCost,
    otherCost: seaFreightCost,
    paletteData: defaultPalette,
    duty: article ? article.duty : { percentage: 0 },
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues,
    breakEvenPoints,
    warehouseCost,
    insurance,
    minimumAbsoluteMargin: minimumMargin ?? { value: 250, currency: EURO },
  };
};

/**
 * Get default air freight calculation values for commodity and amount or 1 palette
 * @param article optional, selected commodity or finished product for duty data
 * @param amount optional, amount to calculate base values for
 * @param weightPerUnit optional, if it is a finished product, amount needs to be set to kg
 * @param preselectedAirport optional, if set the selected airport is used
 * @returns {Promise<AirFreightCalculationValues>} default calculation values object
 */
export const getDefaultAirFreightCalculationsValues = async (
  article?: Commodity | FinishedProduct | CommoditySnapshot | FinishedProductSnapshot,
  amount?: number,
  weightPerUnit?: number,
  preselectedAirport?: Airport
): Promise<AirFreightCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues, airFreightValues } = calculationConfiguration.values;
  const { defaultPalette, b2bFollowUpCost, customsFeeAgency, warehouseCost } = generalValues;
  const {
    airFreightCost,
    airportStorageTime,
    customsAirFreightCoefficient,
    defaultAirportFreightCost,
    minimumMargin,
    insurance,
  } = airFreightValues;

  let cbm = 1.2;
  let weight = 500;
  let palettes = 1;
  if (amount)
    [cbm, weight, palettes] = calculateCBMAndGrossWeight(
      amount * (weightPerUnit && weightPerUnit > 0 ? weightPerUnit : 1),
      defaultPalette
    );

  return {
    airFreightCost:
      preselectedAirport?.cost && preselectedAirport?.currency
        ? { cost: preselectedAirport.cost, currency: preselectedAirport.currency }
        : defaultAirportFreightCost,
    airportStorageTime,
    customsFreightCoefficient: customsAirFreightCoefficient,
    customsFeeAgency,
    otherCost: airFreightCost,
    paletteData: defaultPalette,
    duty: article ? article.duty : { percentage: 0 },
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues: { cbm, weight, palettes },
    warehouseCost,
    insurance,
    minimumAbsoluteMargin: minimumMargin ?? { value: 250, currency: EURO },
  };
};

/**
 * Get default EU stock calculation values for commodity and amount or 1 palette
 * @param amount optional, amount to calculate base values for, in kg, adjust for finished products!
 * @param weightPerUnit optional, if its a finished product, amount needs to be set to kg
 * @returns {Promise<EUStockCalculationValues>} default calculation values object
 */
export const getDefaultEUStockCalculationsValues = async (
  amount?: number,
  weightPerUnit?: number
): Promise<EUStockCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues } = calculationConfiguration.values;
  const { defaultPalette, b2bFollowUpCost, warehouseCost } = generalValues;

  let cbm = 1.2;
  let weight = 500;
  let palettes = 1;
  if (amount)
    [cbm, weight, palettes] = calculateCBMAndGrossWeight(
      amount * (weightPerUnit && weightPerUnit > 0 ? weightPerUnit : 1),
      defaultPalette
    );

  return {
    paletteData: defaultPalette,
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues: { cbm, weight, palettes },
    warehouseCost,
  };
};

/**
 * Get default warehouse calculation values
 * @returns {Promise<WarehouseCalculationValues>} default calculation values object
 */
export const getDefaultWarehouseCalculationsValues = async (
  supplierOrder?: SupplierOrder,
  context?: React.ContextType<typeof DataContextInternal>
): Promise<WarehouseCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { warehouseValues } = calculationConfiguration.values;
  const { minimumMargin } = warehouseValues;

  if (!supplierOrder || !context)
    return {
      totalLogisticCost: { cost: 0, currency: USD, amount: 0, unit: "kg" },
      minimumAbsoluteMargin: minimumMargin ?? { value: 100, currency: EURO },
    };

  const logisticCost = supplierOrder.totalPrice - supplierOrder.priceCommodities;

  return {
    totalLogisticCost: {
      cost: round(logisticCost, 2),
      currency: supplierOrder.currency,
      amount: supplierOrder.amount,
      unit: "kg",
    },
    minimumAbsoluteMargin: minimumMargin ?? { value: 100, currency: EURO },
  };
};

/**
 * Get a complete supplier order calculation object
 * @param totalTurnover the total turnover of the customer orders
 * @param priceCalculation price calculation including transport, customs, etc.
 * @returns {SupplierOrderCalculation} supplier order calculation object
 */
export const getSupplierOrderCalculation = (
  totalTurnover: number,
  priceCalculation:
    | SeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
    | null
): SupplierOrderCalculation => {
  if (priceCalculation && isSeaFreightCalculation(priceCalculation)) {
    const priceCommodities = priceCalculation.totalCommodityPrice;
    const warehouseCost = priceCalculation.totalWarehouseCost ? priceCalculation.totalWarehouseCost : 0;
    const warehouseHandlingCost = priceCalculation.warehouseHandlingCost ? priceCalculation.warehouseHandlingCost : 0;
    const priceTransport =
      priceCalculation.b2bFollowUpCost + priceCalculation.fclCost.total + priceCalculation.lclCost.total;
    const priceCustoms = priceCalculation.customsCost.total;
    const totalPrice = priceCalculation.totalCost;
    const sOCalculation: SupplierOrderCalculation = {
      totalTurnover,
      commodityCost: priceCommodities,
      transportCost: priceTransport,
      customsCost: priceCustoms,
      totalMargin: totalTurnover - totalPrice + warehouseCost,
      totalPrice,
      totalInsuranceCost: priceCalculation.totalInsuranceCost,
    };
    if (warehouseCost) sOCalculation.warehouseCost = warehouseCost;
    if (warehouseHandlingCost) sOCalculation.warehouseHandlingCost = warehouseHandlingCost;

    return sOCalculation;
  } else if (priceCalculation && isAirFreightCalculation(priceCalculation)) {
    const priceCommodities = priceCalculation.totalCommodityPrice;
    const warehouseCost = priceCalculation.totalWarehouseCost ? priceCalculation.totalWarehouseCost : 0;
    const warehouseHandlingCost = priceCalculation.warehouseHandlingCost ? priceCalculation.warehouseHandlingCost : 0;
    const priceTransport =
      priceCalculation.b2bFollowUpCost +
      priceCalculation.followUpCost +
      priceCalculation.airFreightCost +
      priceCalculation.airportCost;

    const priceCustoms = priceCalculation.duty + priceCalculation.additionalFee;
    const totalPrice = priceCalculation.totalCost;
    const sOCalculation: SupplierOrderCalculation = {
      totalTurnover,
      commodityCost: priceCommodities,
      transportCost: priceTransport,
      customsCost: priceCustoms,
      totalMargin: totalTurnover - totalPrice + warehouseCost,
      totalPrice,
      totalInsuranceCost: priceCalculation.totalInsuranceCost,
    };
    if (warehouseCost) sOCalculation.warehouseCost = warehouseCost;
    if (warehouseHandlingCost) sOCalculation.warehouseHandlingCost = warehouseHandlingCost;

    return sOCalculation;
  } else if (priceCalculation && isEUStockCalculation(priceCalculation)) {
    const priceCommodities = priceCalculation.totalCommodityPrice;
    const warehouseCost = priceCalculation.totalWarehouseCost ? priceCalculation.totalWarehouseCost : 0;
    const warehouseHandlingCost = priceCalculation.warehouseHandlingCost ? priceCalculation.warehouseHandlingCost : 0;
    const priceTransport = priceCalculation.b2bFollowUpCost + priceCalculation.followUpCost;

    const totalPrice = priceCalculation.totalCost;
    const sOCalculation: SupplierOrderCalculation = {
      totalTurnover,
      commodityCost: priceCommodities,
      transportCost: priceTransport,
      totalMargin: totalTurnover - totalPrice + warehouseCost,
      totalPrice,
    };
    if (warehouseCost) sOCalculation.warehouseCost = warehouseCost;
    if (warehouseHandlingCost) sOCalculation.warehouseHandlingCost = warehouseHandlingCost;

    return sOCalculation;
  }
  return {
    totalTurnover: 0,
    commodityCost: 0,
    transportCost: 0,
    customsCost: 0,
    totalPrice: 0,
    totalMargin: 0,
    totalInsuranceCost: 0,
  };
};

/**
 * Get calculation values for supplier order data
 * @param article the commodity or finished product document
 * @param paletteData the palette data
 * @param articleAmount the amount of commodities or finished products to be ordered in their unit
 * @param currencies current exchange rates
 * @param seaport the selected seaport
 * @returns {LCLSeaFreightCalculationValues} calculation values object
 */
export const getSeaFreightCalculationValuesForSupplierOrder = async (
  article: Commodity | FinishedProduct,
  paletteData: PaletteData,
  articleAmount: number,
  currencies: Currencies,
  seaport?: Seaport
): Promise<SeaFreightCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues, seaFreightValues } = calculationConfiguration.values;
  const { containerData, b2bFollowUpCost, customsFeeAgency, customsFlatRate, warehouseCost } = generalValues;
  const { seaFreightCost, defaultSeaportFreightCost, minimumMargin, insurance } = seaFreightValues;
  const [cbm, weight, palettes] = calculateCBMAndGrossWeight(
    isFinishedProduct(article)
      ? articleAmount * (article.weightPerUnit <= 0 ? 1 : article.weightPerUnit)
      : articleAmount,
    paletteData
  );
  const baseValues = { cbm, weight, palettes };

  const seaportFreightCost =
    seaport && seaport.cost && seaport.containerCost
      ? {
          cost: { containerCost: seaport.containerCost, cost: seaport.cost },
          currency: seaport?.currency || USD,
        }
      : defaultSeaportFreightCost;

  const extendedContainerData = getExtendedContainerData(paletteData, containerData);
  const breakEvenPoints = getLCLFCLBreakEven(
    seaportFreightCost,
    extendedContainerData,
    seaFreightCost,
    paletteData,
    currencies
  );

  const containerAllocation = findOptimalContainerAllocation(
    palettes,
    extendedContainerData,
    breakEvenPoints,
    paletteData,
    seaportFreightCost,
    seaFreightCost,
    currencies
  );

  const containerValues = getContainerValuesForAllocation(
    containerAllocation,
    paletteData,
    extendedContainerData,
    baseValues
  );

  return {
    customsFlatRate: customsFlatRate.cost,
    customsFeeAgency: customsFeeAgency,
    containerValues,
    seaFreightCost: seaportFreightCost,
    otherCost: seaFreightCost,
    paletteData,
    duty: article ? article.duty : { percentage: 0 },
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues,
    breakEvenPoints,
    warehouseCost,
    insurance,
    minimumAbsoluteMargin: minimumMargin ?? { value: 250, currency: EURO },
  };
};

/**
 * Get air freight calculation values for supplier order data
 * @param article the commodity or finished product document
 * @param paletteData the palette data
 * @param articleAmount the amount of commodities or finished products to be ordered
 * @param airport the selected airport
 * @returns {AirFreightCalculationValues} calculation values object
 */
export const getAirFreightCalculationValuesForSupplierOrder = async (
  article: Commodity | FinishedProduct,
  paletteData: PaletteData,
  articleAmount: number,
  airport?: Airport
): Promise<AirFreightCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues, airFreightValues } = calculationConfiguration.values;
  const { b2bFollowUpCost, customsFeeAgency, warehouseCost } = generalValues;
  const {
    airFreightCost,
    airportStorageTime,
    customsAirFreightCoefficient,
    defaultAirportFreightCost,
    minimumMargin,
    insurance,
  } = airFreightValues;

  const [cbm, weight, palettes] = calculateCBMAndGrossWeight(
    isFinishedProduct(article)
      ? articleAmount * (article.weightPerUnit <= 0 ? 1 : article.weightPerUnit)
      : articleAmount,
    paletteData
  );

  return {
    airFreightCost:
      airport?.cost && airport?.currency
        ? { cost: airport.cost, currency: airport.currency }
        : defaultAirportFreightCost,
    airportStorageTime,
    customsFreightCoefficient: customsAirFreightCoefficient,
    customsFeeAgency: customsFeeAgency,
    otherCost: airFreightCost,
    paletteData,
    duty: article ? article.duty : { percentage: 0 },
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues: { cbm, weight, palettes },
    warehouseCost,
    insurance,
    minimumAbsoluteMargin: minimumMargin ?? { value: 250, currency: EURO },
  };
};

/**
 * Get EU stock calculation values for supplier order data
 * @param paletteData the palette data
 * @param articleAmount the amount of commodities to be ordered
 * @param weightPerUnit optional, if its a finished product, amount needs to be set to kg
 * @returns {EUStockCalculationValues} calculation values object
 */
export const getEUStockCalculationValuesForSupplierOrder = async (
  paletteData: PaletteData,
  articleAmount: number,
  weightPerUnit?: number
): Promise<EUStockCalculationValues> => {
  const calculationConfiguration = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
  const { generalValues } = calculationConfiguration.values;
  const { b2bFollowUpCost, warehouseCost } = generalValues;
  const [cbm, weight, palettes] = calculateCBMAndGrossWeight(
    articleAmount * (weightPerUnit && weightPerUnit > 0 ? weightPerUnit : 1),
    paletteData
  );
  return {
    paletteData,
    b2bFollowUpCost: getB2BFollowUpCost(palettes, b2bFollowUpCost),
    baseValues: { cbm, weight, palettes },
    warehouseCost,
  };
};

/**
 * Calculate the maximum amount of palettes per container
 * @param paletteData palette measurements
 * @param containerData container measurements
 * @returns {number} the total amount of possible palettes fitting into the container
 */
export const getPalettesPerContainer = (paletteData: PaletteData, containerData: SingleContainerData): number => {
  const uniformLoading =
    Math.floor(containerData.length / paletteData.length) * Math.floor(containerData.width / paletteData.width);
  const uniformLoadingRotated =
    Math.floor(containerData.length / paletteData.width) * Math.floor(containerData.width / paletteData.length);
  let maxPaletteBays = uniformLoading > uniformLoadingRotated ? uniformLoading : uniformLoadingRotated;
  if (paletteData.length + paletteData.width < containerData.width) {
    const offsetLoading =
      Math.floor(containerData.length / paletteData.length) + Math.floor(containerData.length / paletteData.width);
    if (offsetLoading > maxPaletteBays) maxPaletteBays = offsetLoading;
  }
  // Stacking
  const heightFactor = paletteData.stackable ? Math.floor(containerData.height / paletteData.height) : 1;
  maxPaletteBays = maxPaletteBays * heightFactor;
  // Check weight limit
  const maxPalettesByWeight = Math.floor(containerData.maxPayloadWeight / paletteData.grossWeight);
  if (maxPalettesByWeight < maxPaletteBays) maxPaletteBays = maxPalettesByWeight;
  return maxPaletteBays;
};

/**
 * Calculates the amount of containers and the cbm, weight and palettes of the rest based on the given container allocation
 * @param containerAllocation the container allocation
 * @param palette palette dimensions
 * @param containerData extended container data
 * @param baseValues total base values
 * @returns { {containers: ExtendedContainerData, rest: CalculationBaseValues} } exact information about the containers as well as a possible rest which is shipped via lcl
 */
export const getContainerValuesForAllocation = (
  containerAllocation: Array<AllocationData>,
  palette: PaletteData,
  containerData: ExtendedContainerData,
  baseValues: CalculationBaseValues
): { containers: ExtendedContainerData; rest: CalculationBaseValues } => {
  const finalContainerData = _.cloneDeep(containerData);
  // Total cbm and weight
  let { cbm, weight } = _.cloneDeep(baseValues);
  // Reset amount
  Object.keys(finalContainerData).forEach((key) => (finalContainerData[key as keyof ExtendedContainerData].amount = 0));

  let lclRest = { cbm: 0, weight: 0, palettes: 0 };
  for (const alloc of containerAllocation) {
    if (alloc.type === "container" && alloc.size && alloc.size in finalContainerData) {
      const data = finalContainerData[alloc.size];
      data.amount += 1;
      cbm -= data.palettes * palette.cbm;
      weight -= data.palettes * palette.grossWeight;
    } else if (alloc.type === "lcl" && alloc.lclPalettes !== undefined) {
      lclRest = {
        cbm: round(cbm, 2),
        weight: round(weight, 2),
        palettes: alloc.lclPalettes,
      };
    }
  }

  return {
    containers: finalContainerData,
    rest: lclRest,
  };
};

/**
 * Find the optimal container allocation, optimized by price
 * @param palettes amount of total palettes
 * @param containers extended container information
 * @param breakEvenPoints break even points for all available containers
 * @param paletteData palette dimensions and information
 * @param seaFreightCost sea freight cost of selected seaport
 * @param otherCost other sea freight cost associated with fcl and lcl
 * @param currencies exchange rates
 * @returns {Array<AllocationData>} "best" container allocation optimized by price
 */
function findOptimalContainerAllocation(
  palettes: number,
  containers: ExtendedContainerData,
  breakEvenPoints: BreakEvenPoints,
  paletteData: PaletteData,
  seaFreightCost: SeaportFreightCost,
  otherCost: SeaFreightCostDefinition,
  currencies: Currencies
): Array<AllocationData> {
  const { containerCost } = seaFreightCost.cost;

  let bestPrice = Infinity;
  let bestSeries: Array<AllocationData> = [];
  const visitedSequences: Array<string> = [];
  const containerList = _.orderBy(
    Object.entries(containers).slice() as Array<[key: keyof ExtendedContainerData, value: ContainerInformation]>,
    (c) => containerCost[c[0] as "20" | "40" | "40HC"] / c[1].palettes,
    "desc"
  );

  function getContainerSeries(palettes: number, currentSeries: Array<AllocationData>) {
    const sequence = JSON.stringify(
      currentSeries.map((s) => (s.type === "container" ? s.size : `lcl${s.lclPalettes || 0}`)).sort()
    );
    // Abort if sequence already visited
    if (visitedSequences.some((s) => s === sequence)) {
      return;
    } else visitedSequences.push(sequence);

    if (palettes <= 0) {
      const price = currentSeries.reduce((sum, data) => sum + data.cost, 0);
      if (price < bestPrice) {
        bestPrice = price;
        bestSeries = currentSeries;
      }
      // Abort if price already exceeds the best price
      return;
    }
    for (const [key, container] of containerList) {
      const breakEven = breakEvenPoints[key];
      const series = [...currentSeries];
      if (palettes >= breakEven) {
        // Containers are cheaper
        const rest = palettes - container.palettes;
        const totalContainerCost = getFCLCost(key, seaFreightCost, otherCost.fcl, currencies);
        series.push({ type: "container", size: key as "20" | "40" | "40HC", container, cost: totalContainerCost });
        const price = series.reduce((sum, data) => sum + data.cost, 0);
        // Recursion with leftover palettes
        if (price < bestPrice) {
          getContainerSeries(rest, series);
        }
      } else {
        // LCL is cheaper
        const baseValues = getBaseValuesObject(palettes, paletteData);
        const series = currentSeries.concat({
          type: "lcl",
          lclPalettes: palettes,
          cost: getLCLCost(baseValues, seaFreightCost, otherCost.lcl, currencies),
        });
        const price = series.reduce((sum, data) => sum + data.cost, 0);
        if (price < bestPrice) {
          bestPrice = price;
          bestSeries = series;
        }
      }
    }
  }

  getContainerSeries(palettes, []);

  return bestSeries;
}

/**
 * Determines if the given calculation is sea freight.
 * @param calculation Price calculation that should be checked
 * @returns { boolean } Returns true if the calculation has type SeaFreightCalculation
 */
export function isLCLSeaFreightCalculation(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
): calculation is LCLSeaFreightPriceCalculation {
  return "lclCharges" in calculation;
}

/**
 * Determines if the given calculation is sea freight.
 * @param calculation Price calculation that should be checked
 * @returns { boolean } Returns true if the calculation has type SeaFreightCalculation
 */
export function isAirFreightCalculation(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
): calculation is AirFreightPriceCalculation {
  return "airFreightCost" in calculation;
}

/**
 * Determines if the given calculation is sea freight.
 * @param calculation Price calculation that should be checked
 * @returns { boolean } Returns true if the calculation has type SeaFreightCalculation
 */
export function isSeaFreightCalculation(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
): calculation is SeaFreightPriceCalculation {
  return "fclCost" in calculation;
}

/**
 * Determines if the given calculation is EU stock.
 * @param calculation Price calculation that should be checked
 * @returns { boolean } Returns true if the calculation has type EUStockPriceCalculation
 */
export function isEUStockCalculation(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
): calculation is EUStockPriceCalculation {
  return !("fclCost" in calculation || "airFreightCost" in calculation);
}

/**
 * Determines if the given calculation is a warehouse calc.
 * @param calculation Price calculation that should be checked
 * @returns { boolean } Returns true if the calculation has type WarehousePriceCalculation
 */
export function isWarehouseCalculation(
  calculation:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | EUStockPriceCalculation
    | WarehousePriceCalculation
): calculation is WarehousePriceCalculation {
  return "totalIncludedMargin" in calculation;
}

/**
 * Determines if the given calculationValues values are for sea freight.
 * @param calculationValues Price calculationValues that should be checked
 * @returns { boolean } Returns true if the calculation values have type SeaFreightCalculationValues
 */
export function isLCLSeaFreightValues(
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): calculationValues is LCLSeaFreightCalculationValues {
  return !!calculationValues && "lclCharges" in calculationValues;
}

/**
 * Determines if the given calculationValues values are for sea freight.
 * @param calculationValues Price calculationValues that should be checked
 * @returns { boolean } Returns true if the calculation values have type SeaFreightCalculationValues
 */
export function isSeaFreightValues(
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): calculationValues is SeaFreightCalculationValues {
  return !!calculationValues && "containerValues" in calculationValues;
}

/**
 * Determines if the given calculationValues values are for air freight.
 * @param calculationValues Price calculationValues that should be checked
 * @returns { boolean } Returns true if the calculation values have type AirFreightCalculationValues
 */
export function isAirFreightValues(
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): calculationValues is AirFreightCalculationValues {
  return !!calculationValues && "airFreightCost" in calculationValues;
}

/**
 * Determines if the given calculationValues values are for warehouse.
 * @param calculationValues Price calculationValues that should be checked
 * @returns { boolean } Returns true if the calculation values have type WarehouseCalculationValues
 */
export function isWarehouseValues(
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): calculationValues is WarehouseCalculationValues {
  return !!calculationValues && "totalLogisticCost" in calculationValues;
}

/**
 * Determines if the given calculationValues values are for EU stock.
 * @param calculationValues Price calculationValues that should be checked
 * @returns { boolean } Returns true if the calculation values have type AirFreightCalculationValues
 */
export function isEUStockValues(
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues
    | WarehouseCalculationValues
    | undefined
): calculationValues is EUStockCalculationValues {
  return !(
    (!!calculationValues && "airFreightCost" in calculationValues) ||
    (!!calculationValues && "containerValues" in calculationValues) ||
    (!!calculationValues && "totalIncludedMargin" in calculationValues)
  );
}

/**
 * Recalculate palette data on change event
 * @param calculationValues current calculation values to be overwritten
 * @param totalAmount total commodity amount/weight
 * @param e change event of input element
 * @param weightPerUnit optional, if its a finished product, amount needs to be set to kg
 */
export function recalculatePaletteDataOnChange(
  calculationValues:
    | AirFreightCalculationValues
    | LCLSeaFreightCalculationValues
    | SeaFreightCalculationValues
    | EUStockCalculationValues,
  totalAmount: number,
  e: React.ChangeEvent<HTMLInputElement>,
  weightPerUnit?: number
) {
  const paletteData = calculationValues.paletteData;
  recalculatePaletteOnChange(paletteData, e);
  // Recalculate affected values
  const [cbm, weight, palettes] = calculateCBMAndGrossWeight(
    totalAmount * (weightPerUnit && weightPerUnit > 0 ? weightPerUnit : 1),
    paletteData
  );
  calculationValues.baseValues = {
    cbm,
    weight,
    palettes,
  };
  calculationValues.b2bFollowUpCost = getB2BFollowUpCost(palettes);
}

/**
 * Recalculate palette data on change event
 * @param paletteData current palette data to be overwritten
 * @param e change event of input element
 */
export function recalculatePaletteOnChange(paletteData: PaletteData, e: React.ChangeEvent<HTMLInputElement>) {
  const name = e.target.name as keyof Omit<PaletteData, "stackable">;
  const value = +e.target.value;
  paletteData[name] = value;
  if (name === "netWeight" && value > paletteData.grossWeight) paletteData.grossWeight = value;
  else if (["length", "height", "width"].includes(name)) {
    // Update CBM
    paletteData.cbm =
      Math.round(((paletteData.length * paletteData.width * paletteData.height) / (1000 * 1000)) * 100) / 100;
  } else if (name === "cbm") {
    // Update width, length and height to match CBM
    const width = Math.round(Math.cbrt(value * 1000 * 1000) * 100) / 100;
    paletteData.width = width;
    paletteData.length = width;
    paletteData.height = width;
  }
}

/**
 * Recalculate container allocation with current calculation values
 * @param calculationValues sea freight calculation values
 * @param currencies exchange rates for currencies
 * @param paletteChanged optional, flag if palette changed and palettes per container has to be recalculated
 * @param keepPalettesPerContainer optional, flag to indicate if palettes per container should be copied or recalculated
 * @returns {SeaFreightCalculationValues} sea freight calculation values with recalculated container allocation
 */
export function recalculateContainerAllocation(
  calculationValues: SeaFreightCalculationValues,
  currencies: Currencies,
  paletteChanged?: boolean,
  keepPalettesPerContainer?: boolean
): SeaFreightCalculationValues {
  const updatedValues = _.cloneDeep(calculationValues);
  const { baseValues, seaFreightCost, paletteData, containerValues, otherCost } = updatedValues;
  let containerData = containerValues.containers;
  if (paletteChanged || keepPalettesPerContainer)
    containerData = getExtendedContainerData(paletteData, containerData, keepPalettesPerContainer);

  const breakEvenPoints =
    paletteChanged || keepPalettesPerContainer
      ? getLCLFCLBreakEven(seaFreightCost, containerData, otherCost, paletteData, currencies)
      : calculationValues.breakEvenPoints;

  updatedValues.breakEvenPoints = breakEvenPoints;
  const containerAllocation = findOptimalContainerAllocation(
    baseValues.palettes,
    containerData,
    breakEvenPoints,
    paletteData,
    seaFreightCost,
    otherCost,
    currencies
  );
  updatedValues.containerValues = getContainerValuesForAllocation(
    containerAllocation,
    paletteData,
    containerData,
    baseValues
  );
  return updatedValues;
}

/**
 * Extend the container data with palettes, cbm, weight and amount per container
 * @param paletteData palette dimensions
 * @param containerData container dimensions or already extended data
 * @param keepPalettesPerContainer optional, flag to indicate if palettes per container should be recalculated or copied
 * @returns {ExtendedContainerData} container data with extended information about palettes per container, etc.
 */
export function getExtendedContainerData(
  paletteData: PaletteData,
  containerData: ContainerData | ExtendedContainerData,
  keepPalettesPerContainer?: boolean
): ExtendedContainerData {
  const extendedContainerData = _.cloneDeep(containerData) as ExtendedContainerData;
  for (const [key, container] of Object.entries(containerData) as Array<
    ["20" | "40" | "40HC", SingleContainerData | ContainerInformation]
  >) {
    const palettes =
      keepPalettesPerContainer && "palettes" in container
        ? container.palettes
        : getPalettesPerContainer(paletteData, container);
    extendedContainerData[key]["palettes"] = palettes;
    extendedContainerData[key]["cbm"] = palettes * paletteData.cbm;
    extendedContainerData[key]["weight"] = palettes * paletteData.grossWeight + container.ownWeight;
    extendedContainerData[key]["amount"] = 0;
  }
  return extendedContainerData;
}

/**
 * Calculate the break even points for the available containers 20, 40 and 40HC
 * @param seaFreightCost sea freight cost of selected seaport
 * @param containerData extended container data including palettes per container
 * @param otherCost general sea freight cost definition
 * @param paletteData palette information
 * @param currencies exchange rates
 * @returns {BreakEvenPoints} object with break even points for containers
 */
export function getLCLFCLBreakEven(
  seaFreightCost: SeaportFreightCost,
  containerData: ExtendedContainerData,
  otherCost: SeaFreightCostDefinition,
  paletteData: PaletteData,
  currencies: Currencies
): BreakEvenPoints {
  const { fcl, lcl } = otherCost;

  const breakEven = { "20": Infinity, "40": Infinity, "40HC": Infinity };
  let key: keyof typeof breakEven;
  for (key in breakEven) {
    const fclCost = getFCLCost(key, seaFreightCost, fcl, currencies);
    const cD = containerData[key];
    for (let i = 2; i <= cD.palettes; i++) {
      const baseValues = getBaseValuesObject(i, paletteData);
      const lclCost = getLCLCost(baseValues, seaFreightCost, lcl, currencies);
      // If lcl is higher or the same as fcl we found the break even point and abort the loop
      if (lclCost >= fclCost) {
        breakEven[key] = i;
        break;
      }
    }
  }

  return breakEven;
}

/**
 * Calculate the fcl cost for the given values in € without customs, duty, ...
 * @param container 20, 40 or 40HC container
 * @param seaFreightCost sea freight cost of selected harbor
 * @param fcl the fcl cost definition with freight and followup cost
 * @param currencies exchange rates for currencies
 * @returns {number} resulting fcl price without customs, duty, etc.
 */
const getFCLCost = (
  container: keyof ContainerCost,
  seaFreightCost: SeaportFreightCost,
  fcl: FCLCostDefinition,
  currencies: Currencies
): number => {
  const { cost: freightCost, currency } = seaFreightCost;
  const { containerCost } = freightCost;
  return (
    fcl.additionalFreightCost.reduce(
      (sum, pos) => sum + convertCurrency(pos.costPerUnit, pos.currency, EURO, currencies),
      0
    ) +
    fcl.followUpCost.reduce((sum, pos) => sum + convertCurrency(pos.costPerUnit, pos.currency, EURO, currencies), 0) +
    convertCurrency(containerCost[container], currency, EURO, currencies)
  );
};

/**
 * Calculate the lcl cost for the given values in € without customs, duty, etc.
 * @param baseValues cbm, weight and amount of palettes
 * @param seaFreightCost sea freight cost of selected harbor
 * @param lcl the lcl cost definition with freight and followup cost
 * @param currencies exchange rates for currencies
 * @returns {number} resulting lcl price without customs, duty, etc.
 */
const getLCLCost = (
  baseValues: CalculationBaseValues,
  seaFreightCost: SeaportFreightCost,
  lcl: LCLCostDefinition,
  currencies: Currencies
): number => {
  const { cbm, weight } = baseValues;
  const { cost: freightCost, currency } = seaFreightCost;
  const { cost } = freightCost;
  const seaPortCostWeight = cost * (weight / 1000);
  const seaPortCostCBM = cost * cbm;
  const seaPortCost = seaPortCostCBM > seaPortCostWeight ? seaPortCostCBM : seaPortCostWeight;
  const followUpCost =
    lcl.followUpCost.find((entry) => entry.from < weight && weight <= entry.to)?.cost ||
    lcl.followUpCost[lcl.followUpCost.length - 1].cost;
  return (
    convertCurrency(seaPortCost, currency, EURO, currencies) +
    lcl.additionalFreightCost.reduce((sum, pos) => {
      const costCBM = pos.costPerCBM ? pos.costPerCBM * cbm : 0;
      const costWeight = pos.costPerWeight ? pos.costPerWeight * (weight / (pos.weightUnit === "t" ? 1000 : 1)) : 0;
      const higherCost = costCBM > costWeight ? costCBM : costWeight;
      return (
        sum +
        convertCurrency(
          pos.minimum !== undefined && pos.minimum > higherCost ? pos.minimum : higherCost,
          pos.currency,
          EURO,
          currencies
        )
      );
    }, 0) +
    followUpCost
  );
};

/**
 * Get a base value object for palette data and given amount
 * @param paletteAmount amount of palettes
 * @param paletteData palette data object
 * @returns {CalculationBaseValues} base values with cbm weight and palettes
 */
const getBaseValuesObject = (paletteAmount: number, paletteData: PaletteData): CalculationBaseValues => {
  const [cbm, weight, palettes] = calculateCBMAndGrossWeight(paletteAmount, paletteData, true);
  return { cbm, weight, palettes };
};

/**
 * Get the total transport cost including customs, etc. for a calculation
 * @param calculation any price calculation
 * @returns {number} the total transport cost
 */
export const getTotalTransportCost = (
  calculation: AirFreightPriceCalculation | SeaFreightPriceCalculation | LCLSeaFreightPriceCalculation
): number => {
  let totalTransport = 0;

  if (isSeaFreightCalculation(calculation)) {
    totalTransport =
      calculation.b2bFollowUpCost +
      calculation.fclCost.total +
      calculation.lclCost.total +
      calculation.customsCost.total;
  } else if (isAirFreightCalculation(calculation)) {
    totalTransport =
      calculation.b2bFollowUpCost +
      calculation.followUpCost +
      calculation.airportCost +
      calculation.airFreightCost +
      calculation.duty +
      calculation.additionalFee;
  } else if (isLCLSeaFreightCalculation(calculation)) {
    totalTransport =
      calculation.b2bFollowUpCost +
      calculation.lclCharges.cost +
      calculation.seaFreight.cost +
      calculation.dockage +
      calculation.customsCost.total;
  }

  return totalTransport;
};

/**
 * Get supplier orders for warehouse calculation
 * @param context internal context, specifically including supplier orders and batches
 * @param commodity a commodity document
 * @returns {Array<SelectOption>} list of select options with batches, commodity and supplier order
 */
export const getSupplierOrdersForWarehouseCalculation = (
  context: DataContextInternalType,
  commodity?: Commodity
): Array<SelectOption<WarehouseCalculationRelatedObjects>> => {
  const { supplierOrder, batch, commodity: commodities } = context;
  // Get available batches with a supplier order for the given commodity;
  const batchesForCommodity = batch.filter(
    (b) =>
      b.supplierOrder &&
      (!commodity || b.commodity._id.toString() === commodity._id.toString()) &&
      !b.disabled &&
      getBatchAmount(b) > 0
  );
  const supplierOrders = supplierOrder.filter(
    (sO) => !commodity || sO.commodity._id.toString() === commodity._id.toString()
  );
  return supplierOrders
    .map((sO) => {
      const batches = batchesForCommodity.filter((b) => b.supplierOrder && sO._id.toString() === b.supplierOrder);
      const com = commodity ?? commodities.find((c) => c._id.toString() === sO.commodity._id.toString());
      if (!com) return;
      if (batches.length === 0)
        return {
          value: sO._id.toString(),
          label: `PO ${getOrderNumber(sO)} - No Batch`,
          object: { batches: [], supplierOrder: sO, commodity: com },
        };
      return {
        value: sO._id.toString(),
        label: `PO ${getOrderNumber(sO)} - Batch: ${batches[0].lot} (RB${batches[0].identifier}) ${
          batches.length > 1 ? `and ${batches.length - 1} more` : ""
        }`,
        object: { batches, supplierOrder: sO, commodity: com },
      };
    })
    .filter((sO) => !!sO) as Array<SelectOption<WarehouseCalculationRelatedObjects>>;
};
