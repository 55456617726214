import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { Input } from "../../../common/Input";
import { DataContextSupplierType } from "../../../../context/dataContext";
import { UploadedOfferFile } from "../../../../model/commodityOfferRequest.types";
import userService from "../../../../services/userService";
import { COR_COA, COR_FILES } from "../../../../utils/commodityOfferRequestUtils";
import { uploadAndGetOfferCommodityFileObject } from "../../../../utils/fileUtils";
import { SupplierArticleExtended } from "../../../../utils/productArticleUtils";

interface UploadOfferDocumentModalProps {
  article: SupplierArticleExtended;
  type: COR_FILES;
  onAddFile: (type: COR_FILES, file: UploadedOfferFile) => void;
  context: DataContextSupplierType;
}

interface UploadOfferDocumentModalState {
  show: boolean;
  file?: File;
  saving: boolean;
}

class UploadOfferDocumentModal extends PureComponent<UploadOfferDocumentModalProps, UploadOfferDocumentModalState> {
  fileSelectionRef: React.RefObject<HTMLInputElement>;
  constructor(props: UploadOfferDocumentModalProps) {
    super(props);
    this.fileSelectionRef = React.createRef();
    this.state = {
      show: false,
      saving: false,
    };
  }

  handleShow = () =>
    this.setState({
      show: true,
      file: undefined,
      saving: false,
    });

  handleHide = () => {
    if (this.fileSelectionRef.current) this.fileSelectionRef.current.value = "";
    this.setState({ show: false });
  };

  handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    this.setState({ file: file ? file : undefined });
  };

  handleUpload = () => {
    const { type, article, context, onAddFile } = this.props;
    const { file } = this.state;
    const supplier = context.supplier.find((s) => s._id.toString() === userService.getCompany());
    if (!file || !article || !supplier) return;
    this.setState({ saving: true });
    try {
      const uploadedFile = uploadAndGetOfferCommodityFileObject(
        file,
        `${supplier._id.toString()}_${type}_${article._id.toString()}_${new BSON.ObjectId().toString()}`,
        type,
        supplier
      ) as UploadedOfferFile;
      if (!uploadedFile) return;
      onAddFile(type, uploadedFile);
      this.setState({ show: false });
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { file } = this.state;
    const errors = [];
    if (!file) errors.push("No file selected");
    return errors;
  };

  render() {
    const { type } = this.props;
    const { show, saving } = this.state;
    const errors = this.validateData();
    const label = type === COR_COA ? "COA" : "Specification";

    return (
      <>
        <button className="btn btn-light" onClick={this.handleShow}>
          Upload
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Upload {label}</h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5 ">
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="required fs-5 fw-bold mb-2">File</label>
                <input
                  type="file"
                  ref={this.fileSelectionRef}
                  accept="*"
                  className="form-control custom-form-control"
                  style={{ padding: "0.375rem 0.75rem" }}
                  name="file"
                  onChange={this.handleSelectFile}
                />
              </div>
              <div className="col-md-12 fv-row fv-plugins-icon-container mt-5">
                <label className="fs-5 fw-bold mb-2">Type</label>
                <Input type="text" className="form-control custom-form-control" value={label} disabled={true} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              className={"btn btn-sm btn-outline btn-outline-light "}
              saving={saving}
              buttonText={saving ? "Uploading..." : "Upload Document"}
              onClick={this.handleUpload}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadOfferDocumentModal;
