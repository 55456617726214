import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { SO_T_CHANGEDETA, SO_T_CHANGEDETD, SupplierOrderExtended } from "../../../../../model/supplierOrder.types";
import { Textarea } from "../../../../common/Textarea";
import CalendarWeekSelector from "../../../../common/CalendarWeekSelector";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { getCW, getFullCWString } from "../../../../../utils/dateUtils";
import { getSupplierOrderTimelineEntry } from "../../../../../utils/supplierOrderUtils";
import { getCustomerOrderTimelineEntry, formatDateFromType } from "../../../../../utils/customerOrderUtils";
import {
  CO_T_CHANGEDETA,
  CO_T_CHANGEDTARGETWEEK,
  CustomerOrder,
  CustomerOrderExtended,
} from "../../../../../model/customerOrder.types";
import { Action, callFunction, CUSTOMERORDER, SUPPLIERORDER, transaction } from "../../../../../services/dbService";
import userService from "../../../../../services/userService";
import ChangedETACustomerOrderRows from "../ChangedETACustomerOrderRows";
import {
  ChangeDateAction,
  DateType,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
} from "../../../../../utils/orderUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";

interface ChangeDateCO {
  order: CustomerOrder | CustomerOrderExtended;
  changedDateCO: Date;
  changedDateType?: DateType;
  sendMailToCustomer: boolean;
}

interface ChangeEtaModalProps {
  order: SupplierOrderExtended | CustomerOrderExtended;
  action: ChangeDateAction;
}

interface ChangeEtaModalState {
  show: boolean;
  saving: boolean;
  changedDate?: Date;
  note: string;
  changedDateCOs?: Array<ChangeDateCO>;
}

class ChangeEtaModal extends PureComponent<ChangeEtaModalProps, ChangeEtaModalState> {
  constructor(props: ChangeEtaModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleHide = () => this.setState({ show: false });
  handleShow = () => this.setState(this.getDefaultState(true));

  handleChangeDateSO = (date: Date) => {
    const { action } = this.props;
    switch (action) {
      case ChangeDateAction.CHANGEETA: {
        const changedDateCOs = _.cloneDeep(this.state.changedDateCOs);
        let newChangedDateCOs;
        // set date for COs as well if there are any connected to the SO
        if (changedDateCOs) {
          const newDate = new Date(_.clone(date).setDate(_.clone(date).getDate() + 7));
          newChangedDateCOs = changedDateCOs.map((cEta) => {
            if (cEta.order.changedETA || (!cEta.order.changedETA && cEta.order.targetDate < date)) {
              cEta.changedDateCO = newDate;
            }
            return cEta;
          });
        }
        this.setState({ changedDate: date, changedDateCOs: newChangedDateCOs });
        break;
      }
      case ChangeDateAction.CHANGEETD:
        this.setState({ changedDate: date });
        break;
      default:
        break;
    }
  };

  handleChangeDateCO = (orderId: string, changedDate: Date | React.ChangeEvent<HTMLInputElement>) => {
    const changedDateCOs = _.cloneDeep(this.state.changedDateCOs);
    let date = new Date();
    if (changedDate instanceof Date) {
      date = changedDate;
    } else {
      date = new Date(changedDate.target.value);
    }
    const newChangedDateCOs = changedDateCOs?.map((changedDateObj) => {
      if (changedDateObj.order._id.toString() === orderId) {
        changedDateObj.changedDateCO = date;
      }
      return changedDateObj;
    });
    this.setState({
      changedDateCOs: newChangedDateCOs,
    });
  };

  handleChangeDateType = (orderId: string, dateType: DateType) => {
    const changedDateCOs = _.cloneDeep(this.state.changedDateCOs);
    const newChangedDateCOs = changedDateCOs?.map((changedDateObj) => {
      if (changedDateObj.order._id.toString() === orderId) {
        changedDateObj.changedDateType === dateType
          ? (changedDateObj.changedDateType = undefined)
          : (changedDateObj.changedDateType = dateType);
      }
      return changedDateObj;
    });
    this.setState({
      changedDateCOs: newChangedDateCOs,
    });
  };

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ note: e.target.value });
  };

  handleChangeSendCustomerMail = (orderId: string) => {
    const { changedDateCOs } = this.state;
    const newChangedDateCOs = changedDateCOs?.map((cEta) => {
      if (orderId === cEta.order._id.toString()) {
        cEta.sendMailToCustomer = !cEta.sendMailToCustomer;
      }
      return cEta;
    });
    this.setState({ changedDateCOs: newChangedDateCOs });
  };

  handleSaveChangedDate = async (note: string, changedDate?: Date) => {
    const { order, action } = this.props;
    const { changedDateCOs } = this.state;
    const isSO = isSupplierOrder(order);
    try {
      this.setState({ saving: true });
      const actions: Array<Action> = [];
      // change eta for supplier order
      if (isSO && changedDate && action === ChangeDateAction.CHANGEETA) {
        const timelineEntryTextSO = `${note} - Changed to CW ${getCW(changedDate)}-${changedDate.getFullYear()}}`;
        const timelineEntrySO = getSupplierOrderTimelineEntry(SO_T_CHANGEDETA, { reason: timelineEntryTextSO });
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          update: {
            changedETA: changedDate,
          },
          push: { timeline: timelineEntrySO },
        });
      }
      // change eta for customer order(s)
      if (changedDateCOs && action === ChangeDateAction.CHANGEETA) {
        for (let i = 0; i < changedDateCOs.length; i++) {
          const { changedDateCO, changedDateType } = changedDateCOs[i];
          const timelineEntryTextCO = `${note} - Changed to ${formatDateFromType(changedDateCO, changedDateType)}`;
          const timelineEntryCO = getCustomerOrderTimelineEntry(CO_T_CHANGEDETA, { reason: timelineEntryTextCO });
          const customerOrder = isSO
            ? order.customerOrders.find((co) => co._id.toString() === changedDateCOs[i].order._id.toString())
            : order;
          if (
            (customerOrder && customerOrder.changedETA) ||
            (customerOrder && !customerOrder.changedETA && customerOrder.targetDate !== changedDateCO)
          ) {
            actions.push({
              collection: CUSTOMERORDER,
              filter: { _id: changedDateCOs[i].order._id },
              update: {
                changedETA: changedDateCO,
                changedETAType: changedDateType,
              },
              push: { timeline: timelineEntryCO },
            });
            if (changedDateCOs[i].sendMailToCustomer) {
              await callFunction("sendDelayedOrder", [customerOrder, changedDateCO]);
            }
          }
        }
      }
      // change target date for customer order
      if (changedDateCOs && action === ChangeDateAction.CHANGETARGETWEEK) {
        for (let i = 0; i < changedDateCOs.length; i++) {
          const { changedDateCO, changedDateType } = changedDateCOs[i];
          const timelineEntryTextCO = `${note} - Changed to ${formatDateFromType(changedDateCO, changedDateType)}`;
          const timelineEntryCO = getCustomerOrderTimelineEntry(CO_T_CHANGEDTARGETWEEK, {
            reason: timelineEntryTextCO,
          });
          {
            actions.push({
              collection: CUSTOMERORDER,
              filter: { _id: changedDateCOs[i].order._id },
              update: {
                targetDate: changedDateCO,
                targetDateType: changedDateType,
              },
              push: { timeline: timelineEntryCO },
            });
          }
        }
      }
      // change etd for supplier order
      if (isSO && changedDate && action === ChangeDateAction.CHANGEETD) {
        const timelineEntryTextSO = `${note} - Changed to CW ${getCW(changedDate)}-${changedDate.getFullYear()}`;
        const timelineEntrySO = getSupplierOrderTimelineEntry(SO_T_CHANGEDETD, { reason: timelineEntryTextSO });
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          update: {
            etd: changedDate,
          },
          push: { timeline: timelineEntrySO },
        });
      }
      const res = await transaction(actions);
      if (res) {
        toast.success(
          `${
            action === ChangeDateAction.CHANGETARGETWEEK
              ? "Target Date"
              : action === ChangeDateAction.CHANGEETD
              ? "ETD"
              : "ETA"
          } successfully changed`
        );
        const user = userService.getUserData();
        const targetDateString = `${
          isSO && changedDate
            ? `CW ${getCW(changedDate)}-${changedDate.getFullYear()}`
            : (action === ChangeDateAction.CHANGETARGETWEEK || action === ChangeDateAction.CHANGEETA) &&
              changedDateCOs &&
              changedDateCOs.length > 0
            ? formatDateFromType(changedDateCOs[0].changedDateCO, changedDateCOs[0].changedDateType)
            : ""
        }`;
        const originalTargetDateString = isSO
          ? getFullCWString(order.targetDate)
          : formatDateFromType(order.targetDate, order.targetDateType);
        const message = `${
          action === ChangeDateAction.CHANGETARGETWEEK
            ? "Target Date"
            : action === ChangeDateAction.CHANGEETD
            ? "ETD"
            : "ETA"
        } of order <https://${process.env.REACT_APP_BASE_URL || ""}/${
          isSO ? "supplierOrder" : "customerOrder"
        }/${order._id.toString()}|*${getOrderNumber(order)}*> was changed to ${targetDateString} by ${user.prename} ${
          user.surname
        }, original target date was ${originalTargetDateString}`;
        await sendMessage(getDefaultSlackChannel(false, NotificationType.DELIVERIES), message);
        this.setState({ show: false });
      } else {
        toast.error("Error changing target date");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean) => {
    const { order, action } = this.props;
    const changedDateCOs: Array<ChangeDateCO> = [];
    switch (action) {
      case ChangeDateAction.CHANGETARGETWEEK:
        if (isCustomerOrder(order)) {
          const { targetDate, targetDateType } = order;
          changedDateCOs.push({
            order: order,
            changedDateCO: targetDate,
            changedDateType: targetDateType,
            sendMailToCustomer: false,
          });
        }
        break;
      case ChangeDateAction.CHANGEETA:
        if (isSupplierOrder(order)) {
          for (let i = 0; i < order.customerOrders.length; i++) {
            const date = order.customerOrders[i].changedETA;
            const dateType = order.customerOrders[i].changedETAType;
            changedDateCOs.push({
              order: order.customerOrders[i],
              changedDateCO: date ? date : order.customerOrders[i].targetDate,
              changedDateType: dateType ? dateType : order.customerOrders[i].targetDateType,
              sendMailToCustomer: false,
            });
          }
        } else {
          const { targetDate, targetDateType, changedETA, changedETAType } = order;
          changedDateCOs.push({
            order: order,
            changedDateCO: changedETA ?? targetDate,
            changedDateType: changedETAType ?? targetDateType,
            sendMailToCustomer: false,
          });
        }
        break;
    }
    return {
      show,
      saving: false,
      changedDate:
        action === ChangeDateAction.CHANGETARGETWEEK
          ? order.targetDate
          : action === ChangeDateAction.CHANGEETD && isSupplierOrder(order)
          ? order.etd
          : order.changedETA || order.targetDate,
      note: "",
      changedDateCOs,
    };
  };

  validateData = () => {
    const { changedDate, note, changedDateCOs } = this.state;
    const { order, action } = this.props;
    const errors: Array<string> = [];
    const warnings: Array<string> = [];
    const changeEtaSO = action === ChangeDateAction.CHANGEETA && isSupplierOrder(order);
    const changeEtaCO =
      action === ChangeDateAction.CHANGEETA && isCustomerOrder(order) && changedDateCOs && changedDateCOs.length > 0;
    const changeEtdSO = action === ChangeDateAction.CHANGEETD && isSupplierOrder(order);
    const changeDateCO =
      action === ChangeDateAction.CHANGETARGETWEEK &&
      isCustomerOrder(order) &&
      changedDateCOs &&
      changedDateCOs.length > 0;

    // ERRORS
    if (
      (changeEtaSO && changedDate === order.targetDate) ||
      (changeEtaCO && changedDateCOs[0].changedDateCO === order.targetDate)
    ) {
      errors.push("ETA is still the same as target date");
    }
    if (changeEtdSO && changedDate === order.etd) {
      errors.push("ETD is still the same");
    }
    if (changeDateCO && changedDateCOs[0].changedDateCO === order.targetDate) {
      errors.push("Target date is still the same");
    }
    // ETD > ETA (order would depart after planned arrival)
    if (changeEtaSO && changedDate && order.etd && changedDate < order.etd) {
      errors.push("ETA cannot be before ETD");
    }
    // ETA < ETD (order would depart after planned arrival)
    if (changeEtdSO && changedDate) {
      if (order.changedETA && changedDate > order.changedETA) {
        errors.push("ETD cannot be behind ETA");
      }
    }
    if (note.trim() === "") {
      errors.push("Please add a reason for the change");
    }
    // WARNINGS
    // TargetDate < ETD (order would depart after intended target date) cannot happen because TargetDate in SO cannot be changed atm
    // ETD > Target Date (order would depart after intended target date)
    if (changeEtdSO && changedDate && !order.changedETA && changedDate > order.targetDate) {
      warnings.push("ETD is behind behind target date");
    }
    // ETA < Target Date (order would arrive before intended target date)
    if (changeEtaCO && changedDateCOs[0].changedDateCO < order.targetDate) {
      warnings.push("ETA is before target date");
    }
    // Target Date > ETA (order would arrive before intended target date)
    if (changeDateCO && order.changedETA && changedDateCOs[0].changedDateCO > order.changedETA) {
      warnings.push("Target date is after ETA");
    }
    // ETA > Target Date (order would arrive after intended target date)
    if (changeEtaCO && changedDateCOs[0].changedDateCO > order.targetDate) {
      warnings.push("ETA is after target date");
    }
    // Target Date < ETA (order would arrive after intended target date)
    if (changeDateCO && order.changedETA && changedDateCOs[0].changedDateCO < order.changedETA) {
      warnings.push("Target date is before ETA");
    }
    // add warnings when the type (fixed/CW) for targetWeek or on setting a new ETA differs from the original values chosen by the customer
    if (
      (action === ChangeDateAction.CHANGETARGETWEEK || (action === ChangeDateAction.CHANGEETA && !order.changedETA)) &&
      isCustomerOrder(order) &&
      changedDateCOs &&
      changedDateCOs.length > 0
    ) {
      if (order.targetDateType === DateType.FIX && changedDateCOs[0].changedDateType !== DateType.FIX)
        warnings.push("Make sure changing the date to something else than fixed is intended");
      if (order.targetDateType !== DateType.FIX && changedDateCOs[0].changedDateType === DateType.FIX)
        warnings.push("Make sure changing the date to fixed is intended");
      if (order.targetDateType === DateType.CW && changedDateCOs[0].changedDateType !== DateType.CW)
        warnings.push("Make sure changing the date to something else than CW is intended");
      if (order.targetDateType !== DateType.CW && changedDateCOs[0].changedDateType === DateType.CW)
        warnings.push("Make sure changing the date to  CW is intended");
    }
    // add warnings when the type (fixed/CW) on setting a new ETA differs from the previously set values
    if (
      action === ChangeDateAction.CHANGEETA &&
      order.changedETA &&
      isCustomerOrder(order) &&
      changedDateCOs &&
      changedDateCOs.length > 0
    ) {
      if (order.changedETAType === DateType.FIX && changedDateCOs[0].changedDateType !== DateType.FIX)
        warnings.push("Make sure changing the date to something else than fix is intended");
      if (order.changedETAType !== DateType.FIX && changedDateCOs[0].changedDateType === DateType.FIX)
        warnings.push("Make sure changing the date to fixed is intended");
      if (order.changedETAType === DateType.CW && changedDateCOs[0].changedDateType !== DateType.CW)
        warnings.push("Make sure changing the date to something else than CW is intended");
      if (order.changedETAType !== DateType.CW && changedDateCOs[0].changedDateType === DateType.CW)
        warnings.push("Make sure changing the date to  CW is intended");
    }
    return [errors, warnings];
  };

  render() {
    const { show, saving, changedDate, note, changedDateCOs } = this.state;
    const { order, action } = this.props;
    const changeTW = action === ChangeDateAction.CHANGETARGETWEEK;
    const changeETA = action === ChangeDateAction.CHANGEETA;
    const changeETD = action === ChangeDateAction.CHANGEETD;
    const [errors, warnings] = this.validateData();
    return (
      <>
        <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={this.handleShow}>
          <i className="fa fa-edit text-gray-300 text-hover-white p-0" />
        </button>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} size={"lg"} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Change {changeTW ? "Target Date" : changeETD ? "ETD" : changeETA ? "ETA" : "Date"}
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {isSupplierOrder(order) && (
              <div className="mb-2 p-0">
                <label className="fs-5 fw-bold mb-2">
                  New {changeTW ? `Target Week` : changeETD ? "ETD" : changeETA ? "ETA" : "Date"} of Supplier Order
                </label>
                <CalendarWeekSelector onSelectCalendarWeek={this.handleChangeDateSO} value={changedDate} />
              </div>
            )}
            <div className="mt-5">
              {changedDateCOs?.map((co) => {
                return (
                  <ChangedETACustomerOrderRows
                    key={co.order._id.toString()}
                    action={action}
                    orderId={co.order._id.toString()}
                    order={co.order}
                    changedDate={co.changedDateCO}
                    changedDateType={co.changedDateType}
                    sendMail={co.sendMailToCustomer}
                    onChangeSendMail={this.handleChangeSendCustomerMail}
                    onChangeDate={this.handleChangeDateCO}
                    onChangeDateType={this.handleChangeDateType}
                  />
                );
              })}
              <div className="mb-2">
                <label className="fs-5 fw-bold mb-2">Note</label>
                <Textarea
                  className="fs-6 form-control custom-form-control"
                  placeholder={`Add a reason why ${
                    changeTW ? "target date" : changeETD ? "ETD" : changeETA ? "ETA" : "Date"
                  } is changed`}
                  value={note}
                  onChange={this.handleChangeNote}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-outline-light float-right" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={errors}
              warnings={warnings}
              saving={saving}
              className={"btn btn-sm btn-outline btn-outline-light"}
              buttonText={"Save"}
              onClick={() => this.handleSaveChangedDate(note, changedDate)}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ChangeEtaModal;
