import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PropertyType } from "../../../../utils/propertyUtils";
import PropertiesWidget from "../../../common/PropertiesWidget";
import { DataContextSupplierType } from "../../../../context/dataContext";
import CommodityActiveSubstanceListing from "../../common/CommodityActiveSubstanceListing";
import CommodityPageGeneralInformationBlock from "../../common/CommodityPageGeneralInformationBlock";
import CommodityPageAdvancedInformationBlock from "../../common/CommodityPageAdvancedInformationBlock";
import { C_G_TYPES } from "../../../../utils/commodityUtils";
import userService from "../../../../services/userService";
import {
  COR_APPROVED,
  COR_CANCELED,
  COR_INREVIEW,
  COR_REJECTED,
  COR_REQUESTED,
  COR_T_REJECTED,
} from "../../../../model/commodityOfferRequest.types";
import {
  getCORTimelineEntry,
  resolveCommodityOfferRequestState,
  resolveLatestStateUpdateDate,
} from "../../../../utils/commodityOfferRequestUtils";
import CommodityOfferRequestDetailsModal from "../modals/CommodityOfferRequestDetailsModal";
import OfferCommodityModal from "../modals/OfferCommodityModal";
import { formatDate } from "../../../../utils/baseUtils";
import Search from "../../../common/Search";
import MasterSpecification from "../../../specification/supplier/MasterSpecification";
import { COMMODITY, FINISHEDPRODUCT } from "../../../../services/dbService";
import { isSupplierFinishedProduct } from "../../../../utils/finishedProductUtils";
import { SUPPLIER } from "../../../../utils/userUtils";
import { SupplierArticleExtended } from "../../../../utils/productArticleUtils";

interface CommodityPageGeneralProps extends RouteComponentProps {
  article: SupplierArticleExtended;
  context: DataContextSupplierType;
  onHandleTab: (eventKey: string | null) => void;
}

interface CommodityPageGeneralState {
  search: string;
}

class CommodityPageGeneral extends PureComponent<CommodityPageGeneralProps, CommodityPageGeneralState> {
  constructor(props: CommodityPageGeneralProps) {
    super(props);
    this.state = { search: "" };
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  render() {
    const { article, context, onHandleTab } = this.props;
    const { search } = this.state;
    const cor =
      context.commodityOfferRequest.find(
        (c) =>
          ![COR_CANCELED, COR_REJECTED].includes(c.state) &&
          c.article.id === article._id.toString() &&
          c.supplier === userService.getCompany()
      ) ||
      context.commodityOfferRequest.find(
        (c) => c.article.id === article._id.toString() && c.supplier === userService.getCompany()
      );
    const corUpdate = cor && resolveLatestStateUpdateDate(cor);
    const corState = cor && resolveCommodityOfferRequestState(cor);
    const corNote = cor && cor.state === COR_REJECTED && getCORTimelineEntry(COR_T_REJECTED).payload?.reason; // pls make this data available for suppliers
    const hasAllergens = article.properties.some((p) => p.type === PropertyType.ALLERGEN);

    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">General Information</h2>
              <div className="fs-6 fw-bold text-muted">
                {article.title.en}
                {article && article.subtitle.en.trim() ? `-${article.subtitle.en}` : ""}
              </div>
            </div>
            {cor ? (
              <div className="card bg-light2 mt-10 w-100">
                <div className="card-body fs-6">
                  <span className="fw-bold">
                    <span className="text-white fs-4 fw-bolder">Current request state:</span>
                    <span className="badge badge-success badge-sm ml-3 fw-bolder">{corState}</span>
                  </span>
                  <br />
                  <br />
                  {cor.state === COR_REQUESTED && (
                    <>
                      <div className="fw-bold">
                        Request was submitted on {formatDate(cor.created)} and is waiting for approval. After we have
                        checked your documents and they match our specification, you can offer this raw material.
                        <br />
                        This process usually takes a few days.
                      </div>
                      {corUpdate && <div className="text-muted">Last update: {formatDate(corUpdate)}</div>}
                      <div className="float-right">
                        <CommodityOfferRequestDetailsModal commodityOfferRequest={cor} context={context} />
                      </div>
                    </>
                  )}
                  {cor.state === COR_INREVIEW && (
                    <>
                      <span className=" fw-bold">
                        Request was submitted on {formatDate(cor.created)} and is currently being processed.
                        <br />
                        This process usually takes a few days.
                      </span>
                      <br />
                      {corUpdate && <span className="text-muted">Last update: {formatDate(corUpdate)}</span>}
                      <div className="float-right">
                        <CommodityOfferRequestDetailsModal commodityOfferRequest={cor} context={context} />
                      </div>
                    </>
                  )}
                  {cor.state === COR_REJECTED && (
                    <>
                      <span className=" fw-bold text-danger">
                        Your request was rejected. <br />
                        Please contact your contact person to find out why we could not accept your request.
                      </span>
                      <br />
                      {corNote && <span className="text-muted">Note: {corNote}</span>}
                      {corUpdate && <span className="text-muted">Last update: {formatDate(corUpdate)}</span>}
                      <div className="float-right">
                        <OfferCommodityModal article={article} context={context} outline={true} />
                      </div>
                    </>
                  )}
                  {cor.state === COR_CANCELED && (
                    <>
                      <span className=" fw-bold text-danger">
                        Your request was canceled. <br />
                        Please contact your contact person to find out more or start a new request.
                        <br />
                        {corUpdate && <span className="text-muted">Last update: {formatDate(corUpdate)}</span>}
                      </span>
                      <div className="float-right">
                        <OfferCommodityModal article={article} context={context} outline={true} />
                      </div>
                    </>
                  )}
                  {cor.state === COR_APPROVED && (
                    <>
                      <span className=" fw-bold">
                        Your request is approved! <br />
                        We have checked your documents and you can now offer this raw material. Get started right away
                        and enter your first prices so that your offer is active.
                        <br />
                        {corUpdate && <span className="text-muted">Last update: {formatDate(corUpdate)}</span>}
                      </span>
                      <div className="float-right">
                        <button
                          className="btn w-100 fw-bolder btn-sm btn-outline btn-outline-light"
                          onClick={() => onHandleTab("prices")}
                        >
                          Add Prices
                        </button>
                      </div>
                    </>
                  )}
                  <div className="col-12 col-md-2"></div>
                </div>
              </div>
            ) : (
              <div className="card bg-warning mt-10 w-100">
                <div className="card-body fs-6 row">
                  <span className="text-black fw-bold col-12 col-md-9">
                    You do not currently offer this raw material. If you want to offer the raw material, we have to
                    check your specification and the CoA first.
                  </span>
                  <div className="col-12 col-md-3">
                    <OfferCommodityModal article={article} context={context} />
                  </div>
                  <br />
                </div>
              </div>
            )}
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <CommodityPageGeneralInformationBlock article={article} />
              <div className="border-bottom-dark-gray" />
              <div className="row w-100 align-items-center">
                <div className="col-6">
                  <div className="text-white fs-5 fw-bolder">Advanced Information</div>
                  <div className="text-muted">Detailed Properties</div>
                </div>
                <div className="col-6 my-4">
                  <Search onSearch={this.handleChangeSearch} value={search} placeholder="Search for properties..." />
                </div>
              </div>
              {C_G_TYPES.map((t, idx) => (
                <React.Fragment key={t}>
                  {idx !== 0 && <div className="border-bottom-dark-gray" />}
                  <CommodityPageAdvancedInformationBlock context={context} search={search} article={article} type={t} />
                </React.Fragment>
              ))}
              <div className="border-bottom-dark-gray" />
              {article.activeSubstances.length > 0 && (
                <>
                  <div className="py-0">
                    <div className="d-flex py-6 align-items-center w-100">
                      <div className="me-3">
                        <div className="text-white fs-5 fw-bolder">Active Substances</div>
                        <div className="text-muted">Substances like vitamins and their concentration</div>
                      </div>
                    </div>
                    <CommodityActiveSubstanceListing article={article} />
                  </div>
                  <div className="border-bottom-dark-gray" />
                </>
              )}
              {hasAllergens && (
                <>
                  <div className="py-0">
                    <div className="d-flex py-6 align-items-center w-100">
                      <div className="me-3">
                        <div className="text-white fs-5 fw-bolder">Allergens</div>
                        <div className="text-muted">Substances possibly causing allergic reactions</div>
                      </div>
                    </div>
                    <div className="py-5">
                      <PropertiesWidget properties={article?.properties || []} types={[PropertyType.ALLERGEN]} />
                    </div>
                  </div>
                  <div className="border-bottom-dark-gray" />
                </>
              )}
              <div className="py-0">
                <div className="d-flex py-6 align-items-center w-100">
                  <div className="me-3">
                    <div className="text-white fs-5 fw-bolder">Commodity Tags</div>
                    <div className="text-muted">Keywords &amp; Additional Properties</div>
                  </div>
                </div>
                <div className="py-5">
                  <PropertiesWidget
                    properties={article?.properties || []}
                    types={[PropertyType.TAG, PropertyType.OTHER]}
                  />
                </div>
              </div>
              <div className="border-bottom-dark-gray" />
              <MasterSpecification
                article={
                  article && isSupplierFinishedProduct(article, SUPPLIER)
                    ? { type: FINISHEDPRODUCT, finishedProduct: article }
                    : { type: COMMODITY, commodity: article }
                }
              />
              <div className="border-bottom-dark-gray" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CommodityPageGeneral);
