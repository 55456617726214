import _ from "lodash";
import React, { PureComponent } from "react";
import StatisticCard from "./StatisticCard";
import TurnoverDevelopmentGraph from "./components/TurnoverDevelopmentGraph";
import GeneralStatisticsGraph from "./GeneralStatisticsGraph";
import ActiveCustomersListing from "./ActiveCustomersListing";
import {
  ControllingDashboardFinanceDashboardGraphType,
  ControllingDashboardStatisticCardType,
} from "../../../utils/controllingDashboardUtils";
import { ControllingStatistics } from "../../../model/statistics/controllingStatistics.types";
import SplashScreen from "../../common/SimpleSplashScreen";
import userService from "../../../services/userService";
import { ADMIN, BOM } from "../../../utils/userUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import OrderRentabilityCard from "./components/OrderRentabilityCard";
import CashflowCard from "./components/CashflowCard";
import FinanceDataGraph from "./components/FinanceDataGraph";

interface ControllingDashboardProps {
  context: DataContextInternalType;
}

interface ControllingDashboardState {
  newestStatistics?: ControllingStatistics;
  statistic30daysAgo?: ControllingStatistics;
}

class ControllingDashboard extends PureComponent<ControllingDashboardProps, ControllingDashboardState> {
  constructor(props: ControllingDashboardProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.generateData();
  }

  componentDidUpdate(prevProps: Readonly<ControllingDashboardProps>) {
    if (_.isEqual(prevProps.context.controllingStatistics, this.props.context.controllingStatistics)) {
      this.generateData();
    }
  }

  generateData = () => {
    const { controllingStatistics } = this.props.context;
    if (controllingStatistics.length > 0) {
      const newestStatistics = controllingStatistics[controllingStatistics.length - 1];
      let statistic30daysAgo;
      if (controllingStatistics.length > 30) {
        statistic30daysAgo = controllingStatistics[controllingStatistics.length - 30];
      } else {
        statistic30daysAgo = controllingStatistics[0];
      }
      this.setState({ newestStatistics, statistic30daysAgo });
    }
  };

  render() {
    const { context } = this.props;
    const { newestStatistics, statistic30daysAgo } = this.state;
    if (!newestStatistics || !statistic30daysAgo) return <SplashScreen description="Loading Data" />;
    const { financeData, statisticsData, generalStatistics, mostActiveCustomers, cashflowData, date } =
      newestStatistics;
    const {
      statisticsData: statisticsDataOld,
      mostActiveCustomers: mostActiveCustomersOld,
      date: dateOld,
    } = statistic30daysAgo;
    const currentYear = new Date().getFullYear();
    const turnoverStatistics = context.controllingStatistics
      .filter((conStats) => conStats.date.getFullYear() === currentYear)
      .map((cS) => {
        return { date: cS.date, turnover: cS.turnoverCurrentYear };
      });
    const roles = userService.getRoles();
    const isBOMorAdmin = roles.includes(ADMIN) || roles.includes(BOM);

    return (
      <>
        <div className="d-flex flex-column flex-column-fluid pt-10 pl-2">
          {isBOMorAdmin ? (
            <div className="container-fluid dashboard-container">
              <div className="row">
                <div className="col-12 col-xxl-6">
                  <div className="row h-100">
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKTURNOVER}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENTURNOVER}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WAREHOUSE}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKMARGIN}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENMARGIN}
                      />
                    </div>

                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      {/* Double warehouse is intended since space had to be filled but Burak/Julian had no idea what to put here */}
                      <FinanceDataGraph
                        data={financeData}
                        type={ControllingDashboardFinanceDashboardGraphType.CD_FDG_WAREHOUSE}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xxl-6">
                  <div className="mb-3">
                    <CashflowCard
                      data={cashflowData}
                      warehouseData={{
                        value: financeData.warehouseValueMonths,
                        currency: financeData.warehouseValueCurrency,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xxxl-6">
                  <OrderRentabilityCard />
                </div>
                <div className="col-12 col-xxxl-6">
                  <div className="row h-100">
                    <div className="col-12 mb-3">
                      <TurnoverDevelopmentGraph turnoverStatistics={turnoverStatistics} />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_ORDERS}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_CUSTOMERS}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_REQUESTS}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_SUPPLIERS}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_CONTRACTS}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-3">
                      <StatisticCard
                        statisticsData={{ date, statisticsData }}
                        statisticsDataOld={{ date: dateOld, statisticsData: statisticsDataOld }}
                        type={ControllingDashboardStatisticCardType.CD_SC_COMMODITIES}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 col-xxl-6 mb-3">
                  <ActiveCustomersListing
                    mostActiveCustomers={mostActiveCustomers}
                    mostActiveCustomersOld={mostActiveCustomersOld}
                    context={context}
                  />
                </div>
                <div className="col-12 col-xxl-6 mb-35">
                  <GeneralStatisticsGraph generalStatistics={generalStatistics} />
                </div>
              </div>
            </div>
          ) : (
            <div className="post d-flex flex-column-fluid">
              <div className="container-xxl">
                <div className="card bg-white min-h-100">
                  <div className="card-body">
                    <h3 className="card-label fw-bolder mb-3 fs-3rem">Controlling</h3>
                    <h5 className="mt-20 text-center">
                      <span className="text-muted">You do not have access to this section</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ControllingDashboard;
