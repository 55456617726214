import _ from "lodash";
import { BSON } from "realm-web";
import {
  Commodity,
  CommodityComparisonObject,
  CommodityExtended,
  CommoditySnapshot,
  CommodityTimelineEntry,
  Price,
  SellingPrice,
  SupplierEUStockPrices,
  SupplierPrices,
  SupplierPricesExtended,
  TimelineEntrySupplierPrices,
  TimelineEntryUploadedFile,
  TransportPrices,
  UploadedFileExtended,
} from "../model/commodity.types";
import { doFuseSearch, formatCurrency } from "./baseUtils";
import { callFunction, COMMODITYSTATISTICS, getDb } from "../services/dbService";
import { PropertyType } from "./propertyUtils";
import { Property } from "../model/property.types";
import userService from "../services/userService";
import { SO_ARCHIVED, SO_CANCELED, SO_STATES, SupplierOrder } from "../model/supplierOrder.types";
import { getAllListDifferences, getAllListDifferencesGeneral, getListDifferences } from "./diffUtils";
import { ActiveSubstance } from "../model/activeSubstance.types";
import { BASE_CURRENCY, convertCurrency, Currencies, CUSTOMER_BASE_CURRENCY } from "./currencyUtils";
import {
  CustomerCommodity,
  CustomerCommodityExtended,
  CustomerSupplierPricesExtended,
} from "../model/customer/customerCommodity.types";
import { ANONYMOUS, CUSTOMER, SUPPLIER } from "./userUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { getDaysBetween } from "./dateUtils";
import { CommodityStatistics } from "../model/statistics/commodityStatistics.types";
import { T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE } from "../model/customerOrder.types";
import { Range } from "../model/commonTypes";
import {
  FinishedProduct,
  SupplierPricesFinishedProduct,
  SupplierPricesFinishedProductExtended,
} from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";
import { FP_FINISHEDPRODUCT_PROPERTIES, isFinishedProduct } from "./finishedProductUtils";
import {
  Article,
  ArticleExtended,
  ArticleSnapshot,
  InternalArticleExtended,
  isAnyFinishedProduct,
} from "./productArticleUtils";
import { SupplierSupplierPricesExtended } from "../model/supplier/supplierCommodity.types";

export enum Incoterm {
  FOB = "FOB",
  DDP = "DDP",
  FCA = "FCA",
  EXW = "EXW",
  CIF = "CIF",
}

export const SUPPORTED_INCOTERMS = Object.values(Incoterm).map((i) => i);

export interface ExtendedCommodityTimelineEntry extends CommodityTimelineEntry {
  text: string | JSX.Element;
}

export const D_MASTERSPECIFICATION = "masterSpecification";
export const D_SUPPLIERSPECIFICATION = "supplierSpecification";
export const D_SUPPLIERANALYSIS = "supplierAnalysis";
export const D_COA = "coa";
export const D_OTHER = "other";

export const D_TYPEOPTIONS = [
  { value: D_MASTERSPECIFICATION, label: "Master Specification" },
  { value: D_SUPPLIERSPECIFICATION, label: "Supplier Specification" },
  { value: D_SUPPLIERANALYSIS, label: "Supplier Analysis" },
  { value: D_OTHER, label: "Other" },
];

export const C_UNITS = ["kg", "ltr"];

export const P_DRUMS = "drums";
export const P_BOX = "box";
export const P_BAGS = "bags";
export const P_CARTON = "carton";
export const P_BARREL = "barrel";
export const P_BOTTLE = "bottle";
export const P_CAN = "can";
export const P_DRUMSOPEN = "drumsOpen";

export const C_PACKAGE_OPTIONS = [
  { value: P_DRUMS, label: "Drums" },
  { value: P_BARREL, label: "Barrels" },
  { value: P_BOX, label: "Box" },
  { value: P_BAGS, label: "Bags" },
  { value: P_BOTTLE, label: "Bottle" },
  { value: P_CAN, label: "Cans" },
  { value: P_CARTON, label: "Carton" },
  { value: P_DRUMSOPEN, label: "Drums, open" },
];

export const COMMODITYCREATIONKEYS = ["title", "subtitle", "composition", "category", "organic"];

// timeline types
export const T_COMMODITYCREATED = "commodityCreated";
export const T_COMMODITYEDITED = "commodityEdited";
export const T_FILEUPLOADED = "fileUploaded";
export const T_FILEUPDATED = "fileUpdated";
export const T_FILEDELETED = "fileDeleted";
export const T_PRICEUPDATED = "priceUpdated";
export const T_SUPPLIERPRICEUPDATED = "supplierPriceUpdated";
export const T_PRICESUPDATED = "pricesUpdated";
export const T_COMMODITYDISABLED = "commodityDisabled";
export const T_COMMODITYENABLED = "commodityEnabled";
export const T_COMMODITYAPPROVED = "commodityApproved";
export const T_ORDEREDFROMSUPPLIER = "orderedFromSupplier";
export const T_BATCHCREATED = "batchCreated";
export const T_MASTERSPECCREATED = "masterSpecificationCreated";
export const T_MASTERSPECSIGNED = "masterSpecificationSigned";
export const T_TRANSPORTPRICEUPDATED = "transportPriceUpdate";
export const T_SELLINGPRICESUPDATED = "sellingPricesUpdated";

export type C_TIMELINETYPE =
  | typeof T_COMMODITYCREATED
  | typeof T_COMMODITYEDITED
  | typeof T_FILEUPLOADED
  | typeof T_FILEUPDATED
  | typeof T_FILEDELETED
  | typeof T_PRICEUPDATED
  | typeof T_SUPPLIERPRICEUPDATED
  | typeof T_PRICESUPDATED
  | typeof T_COMMODITYDISABLED
  | typeof T_COMMODITYENABLED
  | typeof T_COMMODITYAPPROVED
  | typeof T_ORDEREDFROMSUPPLIER
  | typeof T_BATCHCREATED
  | typeof T_MASTERSPECCREATED
  | typeof T_MASTERSPECSIGNED
  | typeof T_TRANSPORTPRICEUPDATED
  | typeof T_SELLINGPRICESUPDATED;

// commodity field groups
export const C_G_PHYSICALCHEMICAL = "physicalChemical";
export const C_G_CONTAMINANTS = "heavyMetals";
export const C_G_REGULATORY_STATEMENT = "regulatoryStatement";
export const C_G_PRODUCTINFORMATION = "productInformation";
export const C_G_GRADE = "grade";

export type C_G_TYPE =
  | typeof C_G_PHYSICALCHEMICAL
  | typeof C_G_CONTAMINANTS
  | typeof C_G_REGULATORY_STATEMENT
  | typeof C_G_PRODUCTINFORMATION
  | typeof C_G_GRADE;

export const C_G_TYPES = [
  C_G_PHYSICALCHEMICAL,
  C_G_PRODUCTINFORMATION,
  C_G_GRADE,
  C_G_CONTAMINANTS,
  C_G_REGULATORY_STATEMENT,
] as const;

export enum CustomerPriceRequestResult {
  ALREADY_REQUESTED = "alreadyRequested",
  REQUEST_FAILED = "requestFailed",
  REQUEST_SUCCESSFUL = "requestSuccessful",
}

export const COMMODITY_COMPARISON_KEYS: Array<keyof CommodityComparisonObject> = [
  "title",
  "subtitle",
  "suppliers",
  "organic",
  "note",
  "country",
  "approved",
  "hsCode",
  "btiRefNo",
  "casNumber",
  "activeSubstances",
  "properties",
  "color",
  "density",
  "images",
  "documents",
  "botanicalName",
  "purity",
  "specificRotation",
  "vegetarian",
  "vegan",
  "halal",
  "kosher",
  "part",
  "foodGrade",
  "pharmaceuticalGrade",
  "particleSize",
  "maxAllowedHeavyMetals",
  "maxAllowedMicrobiology",
  "maxAllowedWETO",
  "lossOnDrying",
  "ash",
  "shelfLife",
  "limits",
  "storageConditions",
  "transportConditions",
  "totalResidualOrganicSolvents",
  "aflatoxins",
  "regulatoryData",
  "pah4",
  "benzoypyrene",
  "possibleCrossContamination",
  "sampleSize",
  "acId",
  "referencePrices",
  "appearance",
  "ph",
  "hazardMaterial",
  "feedGrade",
  "cosmeticGrade",
  "uspGrade",
  "medicineGrade",
  "industrialGrade",
  "novelFood",
  "cites",
  "echa",
  "ratioExtract",
  "duty",
];

export type C_COMMODITY_PROPERTIES = keyof CommoditySnapshot & keyof CustomerCommodity & keyof Commodity;

export const C_COMMODITYGROUP_OPTIONS: Array<SelectOption> = [
  { label: "Organic", value: "organic" },
  { label: "Conventional", value: "conventional" },
  { label: "Halal", value: "halal" },
  { label: "Kosher", value: "kosher" },
];

export const C_STATUS_DELIVERABLE = "deliverable";
export const C_STATUS_NOTDELIVERABLE = "notDeliverable";
export const C_STATUS_PARTIALLYINVALID = "partiallyInvalid";
export const C_STATUS_ONSTOCK = "onStock";
export const C_STATUS_FREESTOCK = "freeStock";

export const C_STATUS_OPTIONS: Array<SelectOption> = [
  { label: "Available", value: C_STATUS_DELIVERABLE },
  { label: "Not Available", value: C_STATUS_NOTDELIVERABLE },
  { label: "Warehouse", value: C_STATUS_FREESTOCK },
];

export const C_STATUS_OPTIONS_INTERNAL: Array<SelectOption> = [
  { label: "Deliverable", value: C_STATUS_DELIVERABLE },
  { label: "Not deliverable", value: C_STATUS_NOTDELIVERABLE },
  { label: "Prices partially invalid", value: C_STATUS_PARTIALLYINVALID },
  { label: "On Stock", value: C_STATUS_ONSTOCK },
  { label: "Free Stock", value: C_STATUS_FREESTOCK },
];

export const SUPPORTED_SELLINGPRICE_TYPES = [T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE];

// Backend functions relating to property
const UPSERTCOMMODITY = "upsertCommodity";
export const UPDATECOMMODITYSUPPLIERPRICES = "updateCommoditySupplierPrices";
const INSERTMANYCOMMODITIESANDRELATEDDOCUMENTS = "insertManyCommoditiesAndRelatedDocuments";
const UPDATEMULTIPLECOMMODITIES = "updateMultipleCommodities";
const HANDLECUSTOMERPRICEUPDATEREQUEST = "handleCustomerPriceUpdateRequest";

/**
 * Inserts a new commodity into the database.
 * @param commodity Commodity that should be inserted into the database
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertCommodity(
  commodity: Commodity
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertCommodity(commodity, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false;
}

/**
 * Updates an existing commodity inside the database.
 * @param commodity Commodity that should be updated inside the database
 * @param commodityId Optional id of commodity if it is not contained in commodity object
 * @param timelineEntry optional, timeline entry to push on update
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateCommodity(
  commodity: Partial<Commodity>,
  commodityId?: BSON.ObjectId,
  timelineEntry?: CommodityTimelineEntry
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (commodityId) commodity._id = commodityId;
  return (await callUpsertCommodity(commodity, false, timelineEntry)) as
    | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
    | false;
}

/**
 * Calls the upsert commodity function in backend.
 * @param commodity Commodity that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry optional, timeline entry to push on update
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the function
 */
async function callUpsertCommodity(
  commodity: Partial<Commodity>,
  insert: boolean,
  timelineEntry?: CommodityTimelineEntry
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTCOMMODITY, [commodity, insert, timelineEntry]);
}

/**
 * Handle price update requests from customers
 * @param commodity the requested commodity
 * @param amount the amount that was requested
 * @param targetDate date when the commodity should be delivered
 * @param supplier Optional, ID of the supplier
 * @returns { Promise<CustomerPriceRequestResult> } Result of the function
 */
export async function handleCustomerPriceUpdateRequest(
  commodity: CommodityExtended | CustomerCommodityExtended,
  amount: number,
  targetDate: Date,
  supplier?: string
): Promise<CustomerPriceRequestResult> {
  return callFunction(HANDLECUSTOMERPRICEUPDATEREQUEST, [commodity, amount, targetDate, supplier]);
}

/**
 * Get the amount of valid prices of a commodity or finished product
 * @param article the commodity or finished product
 * @returns {number} the amount of valid prices
 */
export const getValidPricesCount = (
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct
): number => {
  if (article.disabled || !article.approved) return 0;
  return (article.suppliers as Array<SupplierPrices | SupplierPricesFinishedProduct>).reduce(
    (a, sup) => a + (sup.disabled ? 0 : sup.prices.filter((p) => p.price > 0 && p.validUntil >= new Date()).length),
    0
  );
};

/**
 * Check if there are valid fromPrices in commodity or finished product
 * @param article the commodity or finished product
 * @returns {boolean} value if there are any valid from prices
 */
export const checkForValidFromPrice = (
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct
): boolean => {
  if (article.disabled || !article.approved) return false;
  return Boolean(
    (article.fromPrice?.seafreight.price && article.fromPrice.seafreight.price > 0) ||
      (article.fromPrice?.airfreight.price && article.fromPrice.airfreight.price > 0) ||
      (article.fromPrice?.warehouse.price && article.fromPrice.warehouse.price > 0)
  );
};

/**
 * Get the amount of outdated prices of a commodity or finished product
 * @param article the commodity or finished product
 * @returns {number} the amount of outdated prices
 */
export const getOutdatedPricesCount = (
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct
): number => {
  return (article.suppliers as Array<SupplierPrices | SupplierPricesFinishedProduct>).reduce(
    (a, sup) => a + (sup.disabled ? 0 : sup.prices.filter((p) => p.validUntil < new Date()).length),
    0
  );
};

/**
 * Get the amount of prices of a commodity or finished product
 * @param article the commodity or finished product
 * @returns {number} the amount of outdated prices
 */
export const getArticlePricesCount = (article: InternalArticleExtended): number => {
  return article.suppliers.reduce((a, sup) => a + sup.prices.length, 0);
};

/**
 * Get the formatted min and max price of a commodity
 * @param range the price range with currencies
 * @returns {{min: string, max: string} | null}  formatted price range
 */
export const getFormattedPriceRange = (range: {
  min: number;
  minCurrency: string;
  max: number;
  maxCurrency: string;
}): { min: string; max: string } | null => {
  const { min, minCurrency, max, maxCurrency } = range;
  return { min: formatCurrency(min, minCurrency), max: formatCurrency(max, maxCurrency) };
};

/**
 * Get the min and max price and their currency of a commodity or finished product
 * @param article The commodity or finished product
 * @param currencies List of currencies
 * @param targetCurrency optional, target currency prices should be converted to
 * @returns {{min: number, minCurrency: string, max: number, maxCurrency: string} | null}  price range
 */
export const getPriceRange = (
  article: Article | ArticleExtended,
  currencies: Currencies,
  targetCurrency?: string
): { min: number; minCurrency: string; max: number; maxCurrency: string } | null => {
  // Not a one-liner due to typing issues
  const articleSuppliers: Array<SupplierPrices | SupplierPricesExtended | CustomerSupplierPricesExtended> =
    article.suppliers;
  const suppliers = articleSuppliers.filter((s) => !s.disabled);
  if (
    suppliers.length === 0 ||
    suppliers.reduce((a, b) => a + b.prices.filter((p) => p.validUntil >= new Date() && p.price > 0).length, 0) === 0
  )
    return null;
  let minPrice = Infinity;
  let minCurrency = targetCurrency ?? BASE_CURRENCY;
  let maxPrice = 0;
  let maxCurrency = targetCurrency ?? BASE_CURRENCY;
  for (let i = 0; i < suppliers.length; i++) {
    const supplier = suppliers[i];
    const supplierPriceRange = getSupplierPriceRange(supplier.prices, currencies, targetCurrency);
    const { min, max, maxCurrency: maxCurr, minCurrency: minCurr } = supplierPriceRange;
    const maxPriceCur = convertCurrency(max, minCurr, targetCurrency ?? maxCurrency, currencies);
    const minPriceCur = convertCurrency(min, maxCurr, targetCurrency ?? maxCurrency, currencies);
    if (maxPriceCur > maxPrice) {
      maxPrice = max;
      maxCurrency = maxCurr;
    }
    if (minPriceCur < minPrice) {
      minPrice = min;
      minCurrency = minCurr;
    }
  }
  return { min: minPrice, minCurrency, max: maxPrice, maxCurrency };
};

/**
 * Get the min and max price and their currency for a list of supplier prices
 * @param prices Supplier prices
 * @param currencies List of currencies
 * @param targetCurrency optional, target currency prices should be converted to
 * @returns {{min: number, minCurrency: string, max: number, maxCurrency: string}}  price range
 */
export const getSupplierPriceRange = (
  prices: Array<Price>,
  currencies: Currencies,
  targetCurrency?: string
): { min: number; minCurrency: string; max: number; maxCurrency: string } => {
  let minPrice = Infinity;
  let minCurrency = targetCurrency ?? BASE_CURRENCY;
  let maxPrice = 0;
  let maxCurrency = targetCurrency ?? BASE_CURRENCY;
  for (let i = 0; i < prices.length; i++) {
    const price = prices[i];
    if (price.validUntil < new Date()) continue;
    const priceInMaxCur = convertCurrency(price.price, price.currency, targetCurrency ?? maxCurrency, currencies);
    if (priceInMaxCur > maxPrice) {
      maxPrice = targetCurrency ? priceInMaxCur : price.price;
      maxCurrency = targetCurrency ?? price.currency;
    }
    if (priceInMaxCur < minPrice) {
      minPrice = targetCurrency ? priceInMaxCur : price.price;
      minCurrency = targetCurrency ?? price.currency;
    }
  }

  return { min: minPrice, minCurrency, max: maxPrice, maxCurrency };
};

/**
 * Get price range for all articles (commodities or finished products) and prices
 * @param articles All commodities or finished products
 * @param currencies List of currencies
 * @param targetCurrency optional, target currency prices should be converted to
 * @returns {{min: number, minCurrency: string, max: number, maxCurrency: string} | null}  price range
 */
export const getArticlesPriceRange = (
  articles: Array<Article | ArticleExtended>,
  currencies: Currencies,
  targetCurrency?: string
): { min: number; minCurrency: string; max: number; maxCurrency: string } | null => {
  let minPrice = Infinity;
  let minCurr = targetCurrency ?? BASE_CURRENCY;
  let maxPrice = 0;
  let maxCurr = targetCurrency ?? BASE_CURRENCY;
  let pricesFound = false;
  for (let i = 0; i < articles.length; i++) {
    const commodity = articles[i];
    const range = getPriceRange(commodity, currencies);
    if (!range) continue;
    pricesFound = true;
    const { min, minCurrency, max, maxCurrency } = range;
    const convertedMin = convertCurrency(min, minCurrency, targetCurrency ?? minCurr, currencies);
    const convertedMax = convertCurrency(max, maxCurrency, targetCurrency ?? maxCurr, currencies);
    if (convertedMin < minPrice) {
      minPrice = targetCurrency ? convertedMin : min;
      minCurr = targetCurrency ?? minCurrency;
    }
    if (maxPrice < convertedMax) {
      maxPrice = targetCurrency ? convertedMax : max;
      maxCurr = targetCurrency ?? maxCurrency;
    }
  }
  if (!pricesFound) return null;
  return { min: minPrice, minCurrency: minCurr, max: maxPrice, maxCurrency: maxCurr };
};

/**
 * Get the open orders for the given commodity.
 * @param commodity ID of the commodity
 * @param orders Orders that should be filtered
 * @returns { [openOrders: Array<SupplierOrder>, inTimeOrders: Array<SupplierOrder>, lateOrders: Array<SupplierOrder>] } Lists containing all open orders, those that are in time and those that are late
 */
export function getCommodityOpenOrders(
  commodity: BSON.ObjectId | string,
  orders: Array<SupplierOrder>
): [openOrders: Array<SupplierOrder>, inTimeOrders: Array<SupplierOrder>, lateOrders: Array<SupplierOrder>] {
  const openOrders = orders.filter(
    (o) =>
      !([SO_CANCELED, SO_ARCHIVED] as Array<SO_STATES>).includes(o.state) &&
      o.commodity._id.toString() === commodity.toString()
  );
  const [inTimeOrders, lateOrders] = openOrders.reduce(
    (result, oO) => {
      result[oO.targetDate > new Date() ? 0 : 1].push(oO);
      return result;
    },
    [[], []] as Array<Array<SupplierOrder>>
  );
  return [openOrders, inTimeOrders, lateOrders];
}

/**
 * Get a property from a list of properties
 * @param properties List of properties, default value is []
 * @param propertyType the property type to get
 * @param all flag if all flags or only the first appearance should be found
 * @returns { null | undefined | Property | Array<Property>} the matching property/properties or null/undefined
 */
export const getArticleProperty = (
  properties: Array<Property> = [],
  propertyType: string,
  all?: boolean
): null | undefined | Property | Array<Property> => {
  if (properties.length === 0) return null;
  if (all) return properties.filter((p) => p.type === propertyType);
  return properties.find((p) => p.type === propertyType);
};

/**
 * Get a default commodity
 * @param title optional title
 * @param subtitle optional subtitle
 * @param category optional category
 * @param composition optional composition
 * @param organic optional organic
 * @returns {Commodity} empty commodity
 */
export const getDefaultCommodity = (
  title?: string,
  subtitle?: string,
  category?: Property,
  composition?: Property,
  organic?: boolean
): Commodity => {
  return {
    _id: new BSON.ObjectId(),
    suppliers: [],
    supplierEUStocks: [],
    sellingPrices: [],
    title: { en: title || "" },
    subtitle: { en: subtitle || "" },
    unit: "kg",
    botanicalName: "",
    purity: { en: "" },
    specificRotation: { en: "" },
    vegetarian: false,
    vegan: false,
    halal: false,
    kosher: false,
    part: { en: "" },
    foodGrade: false,
    pharmaceuticalGrade: false,
    cosmeticGrade: false,
    uspGrade: false,
    industrialGrade: false,
    medicineGrade: false,
    feedGrade: false,
    particleSize: { en: "" },
    maxAllowedHeavyMetals: { en: "" },
    maxAllowedMicrobiology: { en: "" },
    maxAllowedWETO: { en: "" },
    lossOnDrying: { lessThan: false, amount: 0 },
    ash: { lessThan: false, amount: 0 },
    shelfLife: 0,
    limits: { en: "" },
    storageConditions: { en: "" },
    transportConditions: [],
    totalResidualOrganicSolvents: { en: "" },
    aflatoxins: { en: "" },
    regulatoryData: { en: "" },
    pah4: { en: "" },
    benzoypyrene: { en: "" },
    possibleCrossContamination: { en: "" },
    articleNo: "",
    approved: false,
    disabled: false,
    organic: Boolean(organic),
    hsCode: "",
    casNumber: [],
    activeSubstances: [],
    properties: category && composition ? [category._id.toString(), composition._id.toString()] : [],
    color: { min: "#000000", max: "#ffffff" },
    density: { min: 0, max: 0 },
    images: [],
    country: { code: "cn", name: "China" },
    note: "",
    timeline: [],
    documents: [],
    sampleSize: [],
    hazardMaterial: false,
    appearance: { en: "" },
    cites: false,
    echa: false,
    novelFood: false,
    duty: { percentage: 0 },
    packagingSizes: [{ _id: new BSON.ObjectId(), type: P_DRUMS, packagingSize: 25 }],
    graduatedPrices: { air: [], sea: [] },
    lastUpdate: new Date(),
    vatPercentage: 19,
  };
};

export function getDefaultExtendedCommodity(
  title?: string,
  subtitle?: string,
  category?: Property,
  composition?: Property,
  organic?: boolean
): CommodityExtended {
  const com = getDefaultCommodity(title, subtitle, category, composition, organic);
  const properties: Array<Property> = [];
  if (category) properties.push(category);
  if (composition) properties.push(composition);
  return { ...com, properties, suppliers: [], documents: [], activeSubstances: [] };
}

/**
 * Checks if the given color has a non default value. This means that min and max exist, they are not hex-min and
 * hex-max and they are not "0".
 * @param color Color that should be checked for being non default
 * @returns {boolean} Indicating that the color is non default or not
 */
export function isNonDefaultColor(color?: Range): boolean {
  return Boolean(
    color &&
      color.min &&
      color.max &&
      color.min !== "#000000" &&
      color.max !== "#ffffff" &&
      color.min !== "0" &&
      color.max !== "0"
  );
}

/**
 * Get all similar commodities or finished products
 * @param article the commodity or finished product to get similar ones for
 * @param articles list of all commodities or finished products
 * @returns {Array<Article>} list of similar commodities or finished products
 */
export const getSimilarArticles = (article: ArticleExtended | Article, articles: Array<Article>): Array<Article> => {
  // Threshold 0.2 is always subject to change
  const threshold = 0.2;
  let similarArticles = doFuseSearch(articles, article.title.en, ["title.en"], {
    threshold,
  });
  similarArticles = similarArticles.concat(doFuseSearch(articles, article.subtitle.en, ["subtitle.en"], { threshold }));
  // Remove article itself
  similarArticles = similarArticles.filter((c) => c._id.toString() !== article._id.toString());
  // Remove duplicates
  return Array.from(new Set(similarArticles));
};

/**
 * Get minimum order quantity for list of supplier prices
 * @param prices List of supplier prices
 * @param includeExpired Optional, if set expired prices are also regarded
 * @returns {number} minimum order quantity or -1 for invalid oq
 */
export const getMinOQ = (prices: Array<Price>, includeExpired?: boolean): number => {
  let minOQ = Infinity;
  for (let i = 0; i < prices.length; i++) {
    const price = prices[i];
    if (!includeExpired && price.validUntil < new Date()) continue;
    if (price.minOQ < minOQ) minOQ = price.minOQ;
  }
  return minOQ !== Infinity ? minOQ : -1;
};

/**
 * Get minimum order quantity for list of supplier prices
 * @param prices List of supplier prices
 * @param includeExpired Optional, if set expired prices are also regarded
 * @returns {string | null} minimum order quantity or -1 for invalid oq
 */
export const getBestPrice = (prices: Array<Price>, includeExpired?: boolean): string | null => {
  let bestPrice = Infinity;
  let bestPriceCurrency = BASE_CURRENCY;
  for (let i = 0; i < prices.length; i++) {
    const price = prices[i];
    if (!includeExpired && price.validUntil < new Date()) continue;
    if (price.price < bestPrice) {
      bestPrice = price.price;
      bestPriceCurrency = price.currency;
    }
  }
  return bestPrice !== Infinity ? formatCurrency(bestPrice, bestPriceCurrency) : null;
};

/**
 * Get the best overall price for a commodity from the given supplier prices.
 * @param supplierPrices Prices that should be checked for the best price
 * @param currencies Currencies for conversion
 * @returns { number | null } Best price in base currency or null
 */
export function getBestOverallPrice(supplierPrices: Array<SupplierPrices>, currencies: Currencies): number | null {
  let bestPrice = Infinity;
  const now = new Date();
  for (let i = 0; i < supplierPrices.length; i++) {
    const s = supplierPrices[i];
    if (s.disabled) continue;
    for (let j = 0; j < s.prices.length; j++) {
      const p = s.prices[j];
      if (p.validUntil < now) continue;
      const priceInBase = convertCurrency(p.price, p.currency, BASE_CURRENCY, currencies);
      if (priceInBase < bestPrice) bestPrice = priceInBase;
    }
  }
  return bestPrice !== Infinity ? bestPrice : null;
}

/**
 * Get a default supplier price
 * @param existingPrice Optional, already existing price
 * @param existingLeadTime Optional, already existing lead or preparation time
 * @param currency Optional, preset currency
 * @returns {Price} default supplier price
 */
export const getDefaultPrice = (existingPrice?: Price, existingLeadTime?: number, currency?: string): Price => {
  const today = new Date();
  const validUntil =
    existingPrice && existingPrice.validUntil > today
      ? existingPrice.validUntil
      : new Date(today.setDate(today.getDate() + 90));
  return {
    _id: new BSON.ObjectId(),
    price: 0,
    currency: currency ?? BASE_CURRENCY,
    minOQ: 0,
    date: new Date(),
    validUntil,
    externalArticleNo: "",
    leadTime: existingLeadTime || 5,
  } as Price;
};

/**
 * Get a standard timeline entry without pre and post
 * @param type the timeline type
 * @param subtype optional, additional subtype
 * @param reference optional, additional reference, e.g. for an order
 * @returns {CommodityTimelineEntry} a commodity timeline entry with the given information
 */
export const getCommodityTimelineEntry = (
  type: C_TIMELINETYPE,
  subtype?: string,
  reference?: string
): CommodityTimelineEntry => {
  const timelineEntry: CommodityTimelineEntry = {
    _id: new BSON.ObjectId(),
    person: userService.getUserId(),
    date: new Date(),
    type,
  };
  if (subtype) timelineEntry.subtype = subtype;
  if (reference) timelineEntry.reference = reference;
  return timelineEntry;
};

/**
 * Get a commodity edit timeline entry with differences between origin and update commodity
 * @param commodity the updated commodity object
 * @param originCommodity the origin commodity object
 * @returns {CommodityTimelineEntry} commodity timeline entry
 */
export const getCommodityEditTimelineEntry = (
  commodity: CommodityExtended,
  originCommodity: CommodityExtended
): CommodityTimelineEntry => {
  // Suppliers are changed separately so they do not have to be handled here currently
  const timelineEntry = getCommodityTimelineEntry(T_COMMODITYEDITED);
  const preObject: CommodityComparisonObject = {};
  const postObject: CommodityComparisonObject = {};
  for (const key of COMMODITY_COMPARISON_KEYS) {
    // special handling for lists, etc.
    if (["documents", "suppliers", "properties", "activeSubstances"].includes(key)) continue;
    let preVal;
    let postVal;
    switch (key) {
      default:
        preVal = originCommodity[key];
        postVal = commodity[key];
    }
    if (!_.isEqual(preVal, postVal)) {
      _.set(preObject, key, preVal);
      _.set(postObject, key, postVal);
    }
  }

  // Properties
  const [differentPropsPre, differentPropsPost] = getAllListDifferencesGeneral(
    originCommodity.properties,
    commodity.properties
  );
  if (differentPropsPre.length > 0 || differentPropsPost.length > 0) {
    _.set(preObject, "properties", differentPropsPre);
    _.set(postObject, "properties", differentPropsPost);
  }

  // Active Substances
  const [differentASPre, differentASPost] = getAllListDifferencesGeneral(
    originCommodity.activeSubstances,
    commodity.activeSubstances
  );
  // Ids with percentage
  if (differentASPre.length > 0 || differentASPost.length > 0) {
    _.set(preObject, "activeSubstances", differentASPre);
    _.set(postObject, "activeSubstances", differentASPost);
  }

  // Documents
  const [differentDocsPre, differentDocsPost] = getAllListDifferences(originCommodity.documents, commodity.documents);
  // Save full document
  if (differentDocsPre.length > 0) _.set(preObject, "documents", differentDocsPre);
  if (differentDocsPost.length > 0) _.set(postObject, "documents", differentDocsPost);

  timelineEntry.pre = preObject;
  timelineEntry.post = postObject;
  return timelineEntry;
};

/**
 * Transform list of documents to only contain supplier id instead of full supplier object
 * @param documents list of documents
 * returns { Array<TimelineEntryUploadedFile>} list of transformed documents
 */
export const transformDocumentsForDiff = (documents: Array<UploadedFileExtended>) => {
  return documents.map((d) => {
    return {
      ...d,
      supplier: d.supplier ? d.supplier._id : undefined,
    } as TimelineEntryUploadedFile;
  });
};

/**
 * Transform list of supplier to only contain supplier id instead of full supplier object
 * @param suppliers list of commodity or finished product suppliers with prices
 * returns { Array<TimelineEntrySupplierPrices>} list of transformed documents
 */
export const transformSuppliersForDiff = (
  suppliers: Array<
    SupplierPricesExtended | SupplierPrices | SupplierPricesFinishedProductExtended | SupplierSupplierPricesExtended
  >
) => {
  return suppliers.map((s) => {
    return {
      ...s,
      supplier: s.supplier
        ? typeof s.supplier === "string"
          ? new BSON.ObjectId(s.supplier)
          : s.supplier._id
        : undefined,
    } as TimelineEntrySupplierPrices;
  });
};

/**
 * Get difference between two list of supplier prices
 * @param suppliersPre supplier list pre edit
 * @param supplierPost supplier list post edit
 * @returns {[ Array< TimelineEntrySupplierPrices>, Array< TimelineEntrySupplierPrices>]} tuple with entries that differ
 */
export const getCommoditySupplierDiff = (
  suppliersPre: Array<TimelineEntrySupplierPrices>,
  supplierPost: Array<TimelineEntrySupplierPrices>
): [Array<TimelineEntrySupplierPrices>, Array<TimelineEntrySupplierPrices>] => {
  const differentPre = getSupplierListDifferences(suppliersPre, supplierPost);
  const differentPost = getSupplierListDifferences(supplierPost, suppliersPre);
  return [differentPre, differentPost];
};

/**
 * Get differences between two list of supplier prices by looking especially the price differences
 * @param list list of supplier prices
 * @param list2 list of supplier prices
 * @returns {Array<TimelineEntrySupplierPrices>}
 */
const getSupplierListDifferences = (
  list: Array<TimelineEntrySupplierPrices>,
  list2: Array<TimelineEntrySupplierPrices>
): Array<TimelineEntrySupplierPrices> => {
  const differences = [];
  for (let i = 0; i < list.length; i++) {
    const e = list[i];
    const e2 = list2.find((p2) => e._id.toString() === p2._id.toString());
    if (e2) {
      const priceDifferences = getListDifferences(e.prices ?? [], e2.prices ?? []);
      let hasDiff = false;
      const diffObject: TimelineEntrySupplierPrices = _.pick(e, ["_id", "supplier"]);
      if (priceDifferences.length > 0) {
        // Collect price differences
        diffObject.prices = priceDifferences;
        hasDiff = true;
      }
      if (e.disabled !== e2.disabled) {
        diffObject.disabled = e.disabled;
        hasDiff = true;
      }
      if (e.contingent !== e2.contingent) {
        diffObject.contingent = e.contingent;
        hasDiff = true;
      }
      if (hasDiff) differences.push(diffObject);
    } else {
      // New or removed supplier
      differences.push(e);
    }
  }
  return differences;
};

/**
 * Determines if the given commodity or finished product is a customer commodity or not.
 * @param article Commodity or finished product that should be checked
 * @param view the current view
 * @returns { boolean } Indicating if the commodity is a customer commodity or not
 */
export function isCustomerCommodity(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  view: string
): article is CustomerCommodity | CustomerCommodityExtended {
  return !isAnyFinishedProduct(article) && view === CUSTOMER;
}

/**
 * Determines if the given commodity or finished product is a anonymous view commodity or not.
 * @param article Commodity or finished product that should be checked
 * @param view the current view
 * @returns { boolean } Indicating if the commodity is a customer commodity or not
 */
export function isAnonymousCommodity(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  view: string
): article is CustomerCommodity | CustomerCommodityExtended {
  return !isAnyFinishedProduct(article) && view === ANONYMOUS;
}

/**
 * Determines if the given commodity or finished product is a supplier commodity or not.
 * @param article Commodity or finished product that should be checked
 * @param view the current view
 * @returns { boolean } Indicating if the commodity is a supplier commodity or not
 */
export function isSupplierCommodity(
  article: Article | ArticleExtended | ArticleSnapshot | undefined,
  view: string
): article is Commodity {
  return !isAnyFinishedProduct(article) && view === SUPPLIER;
}

/**
 * Filters the advanced properties of the given article.
 * @param article Article whose properties should be filtered
 * @param search Search query
 * @param type Type of article fields that should be returned
 * @returns { Array<{ label: string; value: string, className?: string }> } List of properties with their values
 */
export function filterAdvancedProperties(
  article: ArticleExtended,
  search: string,
  type: C_G_TYPE
): Array<{ label: string; value: string; className?: string }> {
  const packaging = getArticleProperty(article.properties, PropertyType.PACKAGING) as Property | null;
  const odor = getArticleProperty(article.properties, PropertyType.ODOR) as Property | null;

  let properties: Array<{ label: string; value: string; className?: string }> = [];
  const isFP = isAnyFinishedProduct(article);

  switch (type) {
    case C_G_PHYSICALCHEMICAL:
      if (isFP) {
        properties = [{ label: "Odor", value: odor ? odor.name.en : "-" }];
      } else {
        properties = [
          { label: "Appearance", value: article.appearance?.en ? article.appearance.en : "-" },
          { label: "Odor", value: odor ? odor.name.en : "-" },
          {
            label: "Loss on Drying",
            value: (article.lossOnDrying.lessThan ? "≤" : "") + article.lossOnDrying.amount + " %",
          },
          { label: "Ash", value: (article.ash.lessThan ? "≤" : "") + article.ash.amount + " %" },
          { label: "Particle Size", value: article.particleSize.en ? article.particleSize.en : "-" },
          { label: "Specific Rotation", value: article.specificRotation.en ? article.specificRotation.en : "-" },
          { label: "Purity", value: article.purity.en },
          { label: "pH", value: article.ph ? article.ph.toString() : "Not specified" },
          { label: "Limits", value: article.limits.en ? article.limits.en : "-" },
        ];
      }
      break;
    case C_G_CONTAMINANTS:
      if (isFP) {
        properties = [];
      } else {
        properties = [
          { label: "Aflatoxins", value: article.aflatoxins.en ? article.aflatoxins.en : "-" },
          { label: "PAH 4", value: article.pah4.en ? article.pah4.en : "-" },
          { label: "Benzopyrene", value: article.benzoypyrene.en ? article.benzoypyrene.en : "-" },
          { label: "Max. Allowed ETO", value: article.maxAllowedWETO.en ? article.maxAllowedWETO.en : "-" },
        ];
      }
      break;
    case C_G_REGULATORY_STATEMENT:
      if (isFP) {
        properties = [];
      } else {
        properties = [
          {
            label: "Max. Allowed Heavy Metals",
            value: article.maxAllowedHeavyMetals.en ? article.maxAllowedHeavyMetals.en : "-",
          },
          {
            label: "Total Residual Organic Solvents",
            value: article.totalResidualOrganicSolvents.en ? article.totalResidualOrganicSolvents.en : "-",
          },
          {
            label: "Max. Allowed Microbiology",
            value: article.maxAllowedMicrobiology.en ? article.maxAllowedMicrobiology.en : "-",
          },
          { label: "Regulatory Data", value: article.regulatoryData.en ? article.regulatoryData.en : "-" },
          {
            label: "Possible Cross Contamination",
            value: article.possibleCrossContamination.en ? article.possibleCrossContamination.en : "-",
          },
        ];
      }
      break;
    case C_G_PRODUCTINFORMATION:
      properties = [
        {
          label: "Vegetarian",
          value: article.vegetarian ? "Yes" : "No",
          className: article.vegetarian ? "text-success" : "",
        },
        {
          label: "Vegan",
          value: article.vegan ? "Yes" : "No",
          className: article.vegan ? "text-success" : "",
        },
        {
          label: "Halal",
          value: article.halal ? "Yes" : "No",
          className: article.halal ? "text-success" : "",
        },
        {
          label: "Kosher",
          value: article.kosher ? "Yes" : "No",
          className: article.kosher ? "text-success" : "",
        },
        { label: "Packaging", value: packaging ? packaging.name.en : "-" },
      ];
      if (!isFP) {
        properties = properties.concat([
          {
            label: "Hazard Material",
            value: article.hazardMaterial ? "Yes" : "No",
            className: article.hazardMaterial ? "text-warning" : "",
          },
          {
            label: "Novel Food",
            value: article.novelFood ? "Yes" : "No",
            className: article.novelFood ? "text-warning" : "",
          },
          { label: "CITES", value: article.cites ? "Yes" : "No", className: article.cites ? "text-warning" : "" },
          { label: "ECHA", value: article.echa ? "Yes" : "No", className: article.echa ? "text-warning" : "" },
        ]);
        if (article.ratioExtract) {
          properties.push({ label: "Ratio Extract", value: article.ratioExtract });
        }
      }
      break;
    case C_G_GRADE:
      properties = [
        {
          label: "Food Grade",
          value: article.foodGrade ? "Yes" : "No",
          className: article.foodGrade ? "text-success" : "",
        },
        {
          label: "Pharmaceutical Grade",
          value: article.pharmaceuticalGrade ? "Yes" : "No",
          className: article.pharmaceuticalGrade ? "text-success" : "",
        },
        {
          label: "Feed Grade",
          value: article.feedGrade ? "Yes" : "No",
          className: article.feedGrade ? "text-success" : "",
        },
        {
          label: "Cosmetic Grade",
          value: article.cosmeticGrade ? "Yes" : "No",
          className: article.cosmeticGrade ? "text-success" : "",
        },
        {
          label: "USP Grade",
          value: article.uspGrade ? "Yes" : "No",
          className: article.uspGrade ? "text-success" : "",
        },
        {
          label: "Medicine Grade",
          value: article.medicineGrade ? "Yes" : "No",
          className: article.medicineGrade ? "text-success" : "",
        },
        {
          label: "Industrial Grade",
          value: article.industrialGrade ? "Yes" : "No",
          className: article.industrialGrade ? "text-success" : "",
        },
      ];
      break;
  }
  return search.trim() ? doFuseSearch(properties, search, ["label"]) : _.sortBy(properties, (obj) => obj.label);
}

/**
 * Retrieve all supplier documents from the given article. Also filters by search if given.
 * @param article Article whose supplier documents should be received
 * @param search Optional, filter for the documents
 * @returns { { documents: Array<UploadedFile>, documentsMap: { [id: string]: Array<UploadedFile> } } } Object that contains the commodity documents and the supplier documents
 */
export function getSupplierDocuments(
  article: ArticleExtended,
  search = ""
): {
  documents: Array<UploadedFileExtended>;
  documentsMap: { [id: string]: Array<UploadedFileExtended> };
} {
  let documents = article.documents.slice();
  if (search.trim()) documents = doFuseSearch(documents, search, ["name", "type", "supplier.name"]);
  const documentsMap: { [id: string]: Array<UploadedFileExtended> } = { general: [] };
  for (let i = 0; i < documents.length; i++) {
    const doc = documents[i];
    if (!doc.supplier) {
      documentsMap["general"].push(doc);
      continue;
    }
    if (doc.supplier._id.toString() in documentsMap) {
      documentsMap[doc.supplier._id.toString()].push(doc);
    } else {
      documentsMap[doc.supplier._id.toString()] = [doc];
    }
  }
  return { documents, documentsMap };
}

/**
 * Inserts the given properties, active substances and commodities.
 * @param properties Properties to add
 * @param activeSubstances Active Substances to add
 * @param commodities Commodities to add
 * @returns { Promise<boolean> } Indicating the success of the transaction
 */
export async function insertCommoditiesAndRelatedDocuments(
  properties: Array<Property>,
  activeSubstances: Array<ActiveSubstance>,
  commodities: Array<Commodity>
): Promise<boolean> {
  return callFunction(INSERTMANYCOMMODITIESANDRELATEDDOCUMENTS, [properties, activeSubstances, commodities]);
}

/**
 * Updates the given commodities inside the system.
 * @param commodities Updates that should be performed on the commodities
 * @returns { Promise<boolean> } Indicating the success of the operation
 */
export async function updateMultipleCommodities(commodities: Array<Partial<Commodity>>): Promise<boolean> {
  return callFunction(UPDATEMULTIPLECOMMODITIES, [commodities]);
}

/**
 * Get the average age of prices for a commodity or finished product
 * @param article a commodity or finished product
 * @returns {number | null} average age of prices
 */
export function getAveragePriceAge(article: Commodity | FinishedProduct): number | null {
  const suppliers = article.suppliers.filter((s) => !s.disabled);
  // Check if suppliers with prices exist
  if (suppliers.length === 0 || suppliers.reduce((a, b) => a + b.prices.length, 0) === 0) return null;
  let totalAge = 0;
  let priceCount = 0;
  for (let i = 0; i < suppliers.length; i++) {
    const supplier = suppliers[i];
    for (let j = 0; j < supplier.prices.length; j++) {
      const price = supplier.prices[j];
      const age = getDaysBetween(new Date(), price.date);
      totalAge += age;
      priceCount++;
    }
  }
  if (priceCount === 0) return null;
  return totalAge / priceCount;
}

/**
 * Get commodity statistics for multiple commodities
 * @param commodityIds list of commodity ids to get statistics for
 * @param statistics optional, list of statistic names to get
 * @returns {Promise<Partial<CommodityStatistics> | undefined>} commodity statistics if found for commodity
 */
export async function getCommoditiesStatistics(
  commodityIds: Array<string | BSON.ObjectId>,
  statistics?: Array<string>
): Promise<Array<Partial<CommodityStatistics>> | undefined> {
  const db = getDb();
  const collection = db?.collection(COMMODITYSTATISTICS);
  const projection: { [field: string]: number } = {};
  if (statistics) statistics.forEach((s) => (projection[s] = 1));
  return collection?.find(
    { commodity: { $in: commodityIds.map((id) => id.toString()) } },
    { projection: statistics ? projection : undefined }
  );
}

/**
 * Generates the subtitle of a commodity or finished product with some data that the commodity contains
 * @param article Commodity or finished product a subtitle should be generated for
 * @returns { string } Magic subtitle for the commodity or finished product
 */
export function generateSubtitle(article: InternalArticleExtended): string {
  const subtitle: Array<string> = [];
  const finishedProduct = isFinishedProduct(article);
  const aS = article.activeSubstances
    .map((aS) => Math.ceil(aS.percentage * 100) / 100 + "% " + aS.substance.name.en)
    .join(", ");
  if (aS) subtitle.push(aS);
  if (!finishedProduct && article.ratioExtract?.trim()) subtitle.push(article.ratioExtract.trim());
  if (!finishedProduct && article.botanicalName.trim()) subtitle.push(article.botanicalName.trim());
  if (!finishedProduct && article.part.en.trim()) subtitle.push(article.part.en.trim());
  return subtitle.join("; ");
}

/**
 * Collects the commodities the supplier is offering that are approved and not disabled.
 * @param supplierId ID of the supplier
 * @param articles List of articles, commodities or finished products that should be checked
 * @returns { Array<Commodity> } List of commodities
 */
export function getArticlesForSupplier(
  supplierId: BSON.ObjectId | string,
  articles: Array<Commodity | FinishedProduct>
): Array<Commodity | FinishedProduct> {
  return articles.filter(
    (c) => c.approved && !c.disabled && c.suppliers.some((s) => s.supplier === supplierId.toString())
  );
}

/**
 * Get default list of selling prices for all available transport types
 * @param includePrice optional, flag if default price should be included
 * @returns {Array<TransportPrices>} list of transport price objects for all types
 */
export function getDefaultSellingPrices(includePrice?: boolean): Array<TransportPrices> {
  return [T_SEAFREIGHT, T_AIRFREIGHT, T_WAREHOUSE].map((t) => getDefaultTransportPrices(t, includePrice));
}

/**
 * Get default selling prices for a transport type
 * @param type transport type
 * @param includePrice optional, flag if default price should be included
 * @returns {TransportPrices} transport price object
 */
export function getDefaultTransportPrices(
  type: typeof T_SEAFREIGHT | typeof T_AIRFREIGHT | typeof T_WAREHOUSE,
  includePrice?: boolean
): TransportPrices {
  return {
    _id: new BSON.ObjectId(),
    type,
    prices: includePrice ? [getDefaultSellingPrice()] : [],
  };
}

/**
 * Get default selling price
 * @returns {SellingPrice} default selling price object
 */
export function getDefaultSellingPrice(): SellingPrice {
  return {
    _id: new BSON.ObjectId(),
    price: 0,
    currency: CUSTOMER_BASE_CURRENCY,
    minOQ: 0,
    date: new Date(),
    validUntil: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14), // 14 days as default, adjustable
  };
}

/**
 * Get default EU stock
 * @param supplier Supplier that should be set in the stock
 * @param withPrice Optional, if set an empty price is added
 * @returns { SupplierEUStockPrices } Default EU stock price object
 */
export function getDefaultEUStock(supplier: string, withPrice?: boolean): SupplierEUStockPrices {
  const prices: Array<Price> = [];
  if (withPrice) {
    prices.push(getDefaultPrice());
  }
  return { _id: new BSON.ObjectId(), supplier, prices, amount: 0, disabled: false };
}

/**
 * Check if a commodity or finished product is inactive
 * @param article a commodity or finished product document
 * @param view the view
 * @returns {boolean} true if inactive, else false
 */
export const isArticleInactive = (
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct,
  view: string
): boolean => {
  return (view === CUSTOMER && !article.fromPrice) || article.disabled || !article.approved;
};

/**
 * Checks if given property is part of property types
 * @param property properties including keys of CommoditySnapshot, CustomerCommodity, Commodity and PropertyType
 * @returns {boolean} true if part of PropertyType, false if not
 */
export function isPropertyType(
  property: C_COMMODITY_PROPERTIES | FP_FINISHEDPRODUCT_PROPERTIES | PropertyType
): property is PropertyType {
  if (property === PropertyType.TRANSPORTCONDITIONS) return false;
  return Object.values(PropertyType).includes(property as PropertyType);
}
