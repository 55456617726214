import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import BaseListing, { BaseListingHeaderDefinition } from "../common/BaseListing";
import { paginate, PaginationState } from "../common/Pagination";
import { Commodity } from "../../model/commodity.types";
import { CONFIG, getConfiguration } from "../../utils/configurationUtils";
import { AnonymousConfiguration } from "../../model/configuration/anonymousConfiguration.types";
import { DataContextInternalType } from "../../context/dataContext";
import AddArticleToAnonymousViewModal from "./modals/AddArticleToAnonymousViewModal";
import { FinishedProduct } from "../../model/finishedProduct.types";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { getFullArticleName } from "../../utils/productArticleUtils";
import { isFinishedProduct } from "../../utils/finishedProductUtils";
import userService from "../../services/userService";
import { Action, CONFIGURATION, transaction } from "../../services/dbService";
import { resolveProperties } from "../../utils/propertyUtils";

interface AnonymousViewArticlesProps {
  context: DataContextInternalType;
}

interface AnonymousViewArticlesState extends PaginationState {
  anonymousViewArticles: Array<{ article: Commodity | FinishedProduct; withPrice: boolean }>;
  saving: boolean;
}

class AnonymousViewArticles extends PureComponent<AnonymousViewArticlesProps, AnonymousViewArticlesState> {
  headerDefinition: Array<BaseListingHeaderDefinition> = [
    { title: "Article", style: { width: "40%" } },
    { title: "Type", style: { width: "15%" } },
    { title: "Properties", style: { width: "25%" } },
    { title: "Prices", style: { width: "10%" } },
    { title: "", style: { width: "10%" }, className: "text-right" },
  ];

  constructor(props: AnonymousViewArticlesProps) {
    super(props);
    this.state = { currentPage: 1, pageSize: 25, saving: false, anonymousViewArticles: [] };
  }

  async componentDidMount() {
    await this.generateArticleData();
  }

  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  handleRemoveArticle = async (id: string, type: "commodity" | "finishedProduct") => {
    this.setState({ saving: true });
    try {
      let pre;
      let post;
      let pull;
      if (type === "commodity") {
        pre = { commodities: [id] };
        post = { commodities: [] };
        pull = { "values.commodities": id };
      } else {
        pre = { finishedProducts: [id] };
        post = { finishedProducts: [] };
        pull = { "values.finishedProducts": id };
      }
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre,
        post,
      };
      const action: Action = {
        collection: CONFIGURATION,
        filter: { key: CONFIG.ANONYMOUS },
        update: { lastUpdate: new Date() },
        push: {
          timeline: timelineEntry,
        },
        pull,
      };
      const result = await transaction([action]);
      if (result) {
        await this.generateArticleData();
        toast.success("Article successfully removed from Anonymous View.");
      } else {
        toast.error("Articles could not be removed from Anonymous View. Please try again later.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleToggleWithPriceFlag = async (avArticle: { article: Commodity | FinishedProduct; withPrice: boolean }) => {
    this.setState({ saving: true });
    const isFP = isFinishedProduct(avArticle.article);
    try {
      let pre;
      let post;
      const artId = avArticle.article._id.toString();
      if (isFP) {
        pre = { finishedProducts: [{ article: artId, withPrice: avArticle.withPrice }] };
        post = { finishedProducts: [{ article: artId, withPrice: !avArticle.withPrice }] };
      } else {
        pre = { commodities: [{ article: artId, withPrice: avArticle.withPrice }] };
        post = { commodities: [{ article: artId, withPrice: !avArticle.withPrice }] };
      }
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre,
        post,
      };
      const updateKey = "values." + (isFP ? "finishedProducts" : "commodities") + ".$[art].withPrice";
      const action: Action = {
        collection: CONFIGURATION,
        filter: { key: CONFIG.ANONYMOUS },
        update: { lastUpdate: new Date(), [updateKey]: !avArticle.withPrice },
        push: {
          timeline: timelineEntry,
        },
        arrayFilters: [{ "art.id": artId }],
      };
      const result = await transaction([action]);
      if (result) {
        await this.generateArticleData();
        toast.success(
          `Prices for article successfully ${avArticle.withPrice ? "removed from" : "added to"} Anonymous View.`
        );
      } else {
        toast.error(
          `Prices could not be ${
            avArticle.withPrice ? "removed from" : "added to"
          } Anonymous View. Please try again later.`
        );
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleAddSuccess = async () => {
    await this.generateArticleData();
  };

  generateArticleData = async () => {
    const { context } = this.props;
    const config = await getConfiguration<AnonymousConfiguration>(CONFIG.ANONYMOUS);
    if (config) {
      const anonymousViewArticles: Array<{ article: Commodity | FinishedProduct; withPrice: boolean }> = [];
      for (let i = 0; i < config.values.commodities.length; i++) {
        const c = config.values.commodities[i];
        const commodity = context.commodity.find((com) => com._id.toString() === c.id);
        if (commodity) anonymousViewArticles.push({ article: commodity, withPrice: c.withPrice });
      }
      for (let i = 0; i < config.values.finishedProducts.length; i++) {
        const fp = config.values.finishedProducts[i];
        const finishedProduct = context.finishedProduct.find((finPro) => finPro._id.toString() === fp.id);
        if (finishedProduct) anonymousViewArticles.push({ article: finishedProduct, withPrice: fp.withPrice });
      }
      this.setState({ anonymousViewArticles });
    }
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, anonymousViewArticles, saving } = this.state;

    const articlesPagination = paginate(anonymousViewArticles, currentPage, pageSize);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="card card-xl-stretch bg-white">
              <div className="card-body" style={{ minHeight: "600px" }}>
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Anonymous View Articles</span>
                </h3>
                <BaseListing
                  headerDefinition={this.headerDefinition}
                  bodyContent={
                    <>
                      {articlesPagination.map((a) => {
                        const properties = resolveProperties(a.article.properties, context.property);
                        return (
                          <tr key={a.article._id.toString()}>
                            <td className="text-white align-middle">{getFullArticleName(a.article)}</td>
                            <td className="text-white align-middle">
                              {isFinishedProduct(a.article) ? "Finished Product" : "Commodity"}
                            </td>
                            <td className="text-white align-middle">
                              {properties.map((p) => (
                                <span
                                  key={p._id.toString()}
                                  className="badge badge-gray fw-bolder m-1 px-2 py-2 text-white"
                                >
                                  {p.name.en}
                                </span>
                              ))}
                            </td>
                            <td className="text-white align-middle">
                              <SimpleConfirmationModal.SimpleConfirmationModalButton
                                buttonText={
                                  <i
                                    className={
                                      "fas pr-0 " +
                                      (a.withPrice ? "fa-check-circle text-success" : "fa-times-circle text-danger")
                                    }
                                  />
                                }
                                buttonClasses="btn btn-text btn-sm px-2 py-0"
                                onConfirm={() => this.handleToggleWithPriceFlag(a)}
                                modalTitle={`${a.withPrice ? "Remove" : "Show"} prices of ${getFullArticleName(
                                  a.article
                                )} ${a.withPrice ? "from" : "in"} Anonymous View`}
                                modalDescription={
                                  <span className="text-white">
                                    Do you really want to {a.withPrice ? "hide" : "show"} prices for{" "}
                                    <b>{getFullArticleName(a.article)}</b> inside the Anonymous View? <br />
                                    {!a.withPrice &&
                                      "Users that log into the Anonymous View will be able to see prices for this article like Customers. They will not be able to see Suppliers."}
                                  </span>
                                }
                                disabled={saving}
                                cancelButtonText="Close"
                                confirmButtonText="Confirm"
                                size="md"
                              />
                            </td>
                            <td className="text-white align-middle text-right">
                              <SimpleConfirmationModal.SimpleConfirmationModalButton
                                buttonText={<i className="fa fa-trash fs-7 text-muted text-hover-danger p-0" />}
                                buttonClasses="btn btn-text btn-sm px-2 py-0 float-right"
                                onConfirm={() =>
                                  this.handleRemoveArticle(
                                    a.article._id.toString(),
                                    isFinishedProduct(a.article) ? "finishedProduct" : "commodity"
                                  )
                                }
                                modalTitle={`Remove ${getFullArticleName(a.article)} from Anonymous View`}
                                modalDescription={
                                  <span className="text-white">
                                    Do you really want to remove <b>{getFullArticleName(a.article)}</b> from the
                                    Anonymous View?
                                  </span>
                                }
                                disabled={saving}
                                cancelButtonText="Close"
                                confirmButtonText="Confirm"
                                size="md"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  }
                  documents={anonymousViewArticles}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
              <div className="card-footer text-right border-0">
                <AddArticleToAnonymousViewModal
                  onSuccess={this.handleAddSuccess}
                  context={context}
                  anonymousViewArticles={anonymousViewArticles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnonymousViewArticles;
