import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { DataContextInternalType } from "../../../context/dataContext";
import { CommodityExtended } from "../../../model/commodity.types";
import CommodityPageTabPanel from "./CommodityPageTabPanel";
import CommodityPageSummary from "../common/CommodityPageSummary";
import CommodityPageStats from "./CommodityPageStats";
import { COMMODITYCREATIONKEYS, getDefaultExtendedCommodity } from "../../../utils/commodityUtils";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { COMMODITY, FINISHEDPRODUCT, getDocumentDB } from "../../../services/dbService";
import { FinishedProductExtended } from "../../../model/finishedProduct.types";
import { getDefaultFinishedProductExtended } from "../../../utils/finishedProductUtils";
import { InternalArticleExtended } from "../../../utils/productArticleUtils";
import { extendCommodity, extendFinishedProduct } from "../../../utils/dataTransformationUtils";

interface CommodityParams {
  id: string;
}

interface CommodityProps extends RouteComponentProps<CommodityParams> {
  context: DataContextInternalType;
}

interface CommodityState {
  commodity?: InternalArticleExtended;
}

class CommodityPage extends PureComponent<CommodityProps, CommodityState> {
  _isMounted = false;
  constructor(props: CommodityProps) {
    super(props);
    const finishedProduct = props.match.path === "/finishedProduct" || props.match.path === "/finishedProduct/:id";
    this.state = {
      commodity: finishedProduct ? this.getFinishedProduct(props) : this.getCommodity(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context, location } = this.props;
    const finishedProduct = match.path.includes("finishedProduct");
    const query = new URLSearchParams(location.search);
    if (this.state.commodity || query.get("create") || query.get("clone")) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/articles");
      return;
    }
    this._isMounted = true;
    let commodity;
    if (finishedProduct) {
      commodity = extendFinishedProduct(await getDocumentDB(FINISHEDPRODUCT, id), context);
      if (!commodity) {
        console.error("No finished product could be loaded for id", id);
        toast.error("The requested finished product could not be loaded");
        history.push("/articles");
        return;
      }
      // #TODO - resolve map or so? - context.addDocuments(FINISHEDPRODUCT, [commodity]);
    } else {
      commodity = extendCommodity(await getDocumentDB(COMMODITY, id), context);
      if (!commodity) {
        console.error("No commodity could be loaded for id", id);
        toast.error("The requested commodity could not be loaded");
        history.push("/articles");
        return;
      }
      // #TODO - resolve map or so? - context.addDocuments(COMMODITY, [commodity]);
    }
    if (this._isMounted) this.setState({ commodity });
  };

  componentDidUpdate(prevProps: Readonly<CommodityProps>) {
    const query = new URLSearchParams(location.search);
    const { match } = this.props;
    const finishedProductFlag = match.path.includes("finishedProduct");

    if (query.get("clone")) {
      if (finishedProductFlag) {
        if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
          const finishedProduct = this.getFinishedProduct(this.props);
          // If object is cloned it gets a new id every time, so to prevent endless loop here, finished product in
          // state and cloned finished product are compared without id and get a dummy id
          const id = new BSON.ObjectId();
          const finishedProductNo_id = { ...finishedProduct, _id: id };
          const finishedProductStateNo_id = { ...this.state.commodity, _id: id };
          if (!_.isEqual(finishedProductNo_id, finishedProductStateNo_id)) {
            this.setState({
              commodity: finishedProduct,
            });
          }
        }
      } else {
        if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
          const commodity = this.getCommodity(this.props);
          // If object is cloned it gets a new id every time, so to prevent endless loop here, commodity in
          // state and cloned commodity are compared without id and get a dummy id
          const id = new BSON.ObjectId();
          const commodityNo_id = { ...commodity, _id: id };
          const commodityStateNo_id = { ...this.state.commodity, _id: id };
          if (!_.isEqual(commodityNo_id, commodityStateNo_id)) {
            this.setState({
              commodity,
            });
          }
        }
      }
    } else {
      if (finishedProductFlag) {
        if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
          const finishedProduct = this.getFinishedProduct(this.props);
          if (!_.isEqual(finishedProduct, this.state.commodity)) {
            this.setState({
              commodity: finishedProduct,
            });
          }
        }
      } else {
        if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
          const commodity = this.getCommodity(this.props);
          if (!_.isEqual(commodity, this.state.commodity)) {
            this.setState({
              commodity,
            });
          }
        }
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial commodity data
   * @param props Commodity properties
   * @returns {Commodity | undefined} a commodity object or undefined
   */
  getCommodity = (props: CommodityProps): CommodityExtended | undefined => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("create")) {
      const passedData: any = {};
      COMMODITYCREATIONKEYS.forEach((key) => (passedData[key] = JSON.parse(localStorage.getItem(key) || '""')));
      const category = getDocFromCollection(props.context.property, passedData.category);
      const composition = getDocFromCollection(props.context.property, passedData.composition);
      // check for required data
      if (!passedData.title || !category || !composition) return;
      return getDefaultExtendedCommodity(
        passedData.title,
        passedData.subtitle,
        category,
        composition,
        passedData.organic
      );
    } else if (query.get("clone")) {
      const clonedCommodity = _.cloneDeep(getDocFromCollection(props.context.commodity, props.match.params.id));
      if (clonedCommodity) {
        const clonedComExtended = extendCommodity(clonedCommodity, props.context);
        clonedComExtended._id = new BSON.ObjectId();
        clonedComExtended.suppliers = [];
        clonedComExtended.approved = false;
        clonedComExtended.disabled = false;
        clonedComExtended.articleNo = "";
        clonedComExtended.timeline = [];
        clonedComExtended.documents = [];
        clonedComExtended.sampleSize = [];
        clonedComExtended.images = [];
        clonedComExtended.supplierEUStocks = [];
        clonedComExtended.sellingPrices = [];
        delete clonedComExtended.fromPrice;
        if (clonedComExtended.acId) {
          clonedComExtended.acId = "";
        }
        if (clonedComExtended.btiRefNo) {
          clonedComExtended.btiRefNo = "";
        }
        if (clonedComExtended.referencePrices) {
          clonedComExtended.referencePrices = [];
        }
        return clonedComExtended;
      }
    } else {
      const com = getDocFromCollection(props.context.commodity, props.match.params.id);
      if (com) return extendCommodity(com, props.context);
      return;
    }
  };

  /**
   * Get the initial finished product data
   * @param props FinishedProduct properties
   * @returns {FinishedProduct | undefined} a finished product object or undefined
   */
  getFinishedProduct = (props: CommodityProps): FinishedProductExtended | undefined => {
    const query = new URLSearchParams(props.location.search);
    if (query.get("create")) {
      const passedData: any = {};
      COMMODITYCREATIONKEYS.forEach((key) => (passedData[key] = JSON.parse(localStorage.getItem(key) || '""')));
      const category = getDocFromCollection(props.context.property, passedData.category);
      const composition = getDocFromCollection(props.context.property, passedData.composition);
      // check for required data
      if (!passedData.title || !category || !composition) return;
      return getDefaultFinishedProductExtended(
        passedData.title,
        passedData.subtitle,
        category,
        composition,
        passedData.organic
      );
    } else if (query.get("clone")) {
      const clonedFinishedProduct = _.cloneDeep(
        getDocFromCollection(props.context.finishedProduct, props.match.params.id)
      );
      if (clonedFinishedProduct) {
        const clonedFPExtended = extendFinishedProduct(clonedFinishedProduct, props.context);
        clonedFPExtended._id = new BSON.ObjectId();
        clonedFPExtended.suppliers = [];
        clonedFPExtended.approved = false;
        clonedFPExtended.disabled = false;
        clonedFPExtended.articleNo = "";
        clonedFPExtended.timeline = [];
        clonedFPExtended.documents = [];
        clonedFPExtended.supplierEUStocks = [];
        clonedFPExtended.sellingPrices = [];
        clonedFPExtended.btiRefNo = "";
        return clonedFPExtended;
      }
    } else {
      const fp = getDocFromCollection(props.context.finishedProduct, props.match.params.id);
      if (fp) return extendFinishedProduct(fp, props.context);
      return;
    }
  };

  render() {
    const { context, location } = this.props;
    const { commodity } = this.state;
    if (!commodity) return null;
    const create = !!new URLSearchParams(location.search).get("create");
    const clone = !!new URLSearchParams(location.search).get("clone");
    const createMode = create || clone;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl">
            <div className="d-flex flex-column flex-xl-row">
              {!createMode && (
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                  <CommodityPageSummary article={commodity} />
                  <CommodityPageStats article={commodity} context={context} />
                </div>
              )}
              <CommodityPageTabPanel article={commodity} context={context} create={createMode} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CommodityPage;
