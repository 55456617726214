import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Input } from "../../common/Input";
import { I_CREDITNOTECUSTOMER, I_CUSTOMERINVOICE, I_STATE, Invoice } from "../../../model/invoice.types";
import { formatCurrency, formatDate, getDocFromCollection, toAbsoluteUrl } from "../../../utils/baseUtils";
import { getDaysUntil } from "../../../utils/dateUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { I_REMINDERDESCRIPTION } from "../../../utils/invoiceUtils";
import { getOrderNumber } from "../../../utils/orderUtils";
import { DataContextCustomerType } from "../../../context/dataContext";

interface InvoiceRowProps extends RouteComponentProps {
  onClickCheckbox?: (_id: string) => void;
  checked?: boolean;
  invoice: Invoice;
  isCreditNote: boolean;
  context: DataContextCustomerType;
}

class InvoiceRow extends PureComponent<InvoiceRowProps> {
  render() {
    const { invoice, isCreditNote, checked, onClickCheckbox, context } = this.props;
    const due = new Date(invoice.invoiceDate);
    due.setDate(due.getDate() + invoice.paymentTarget);
    const untilPayment = Math.ceil(getDaysUntil(due));
    const inAdvance = invoice.paymentTarget === -1;
    const dueDate = new Date(invoice.invoiceDate);
    dueDate.setDate(dueDate.getDate() + invoice.paymentTarget);
    const relatedOrder = invoice.relatedOrder
      ? [I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(invoice.type)
        ? getDocFromCollection(context.customerOrder, invoice.relatedOrder)
        : getDocFromCollection(context.sampleOrder, invoice.relatedOrder)
      : undefined;
    return (
      <>
        <tr className="border-bottom-dark-gray">
          {checked !== undefined && onClickCheckbox && (
            <td className="align-middle">
              <div className="form-check form-check-sm form-check-custom form-check-solid">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={checked}
                  onClick={() => onClickCheckbox(invoice._id.toString())}
                />
              </div>
            </td>
          )}
          <td className="text-light align-middle">
            {isCreditNote ? "C" : `INV-${inAdvance ? "A" : ""}`}
            {invoice.invoiceNumber}
          </td>
          <td className="text-light align-middle">{formatCurrency(invoice.total, invoice.currency)}</td>
          <td className="text-light align-middle">
            {relatedOrder && (
              <Link className="custom-link text-white" to={"/order/" + relatedOrder._id.toString()}>
                {getOrderNumber(relatedOrder)}
              </Link>
            )}
          </td>
          <td className="text-light align-middle">
            {relatedOrder && (
              <Link className="custom-link text-white" to={"/commodity/" + relatedOrder.commodity._id.toString()}>
                <span className="fs-8 d-inline-block text-muted text-ellipsis" style={{ maxWidth: "100px" }}>
                  {relatedOrder.commodity.title.en}
                </span>
              </Link>
            )}
          </td>
          {!isCreditNote && (
            <>
              <td className="text-muted align-middle">{formatDate(invoice.invoiceDate)}</td>
              <td className="text-muted align-middle">{inAdvance ? "In Advance" : invoice.paymentTarget + " days"}</td>
              <td
                className={
                  "align-middle " +
                  (inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state)
                    ? "text-muted "
                    : untilPayment > 0
                    ? "text-success"
                    : "text-danger")
                }
              >
                {inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state) ? "-" : untilPayment + " day(s)"}
              </td>
              <td className="align-middle">
                <a href={resolveFilePath(invoice.file)} target="_blank" rel="noopener noreferrer">
                  <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                </a>
              </td>
              <td
                className={
                  "align-middle " +
                  (invoice.state === I_STATE.CANCELED
                    ? "text-danger"
                    : invoice.state === I_STATE.PAID
                    ? "text-white"
                    : "text-warning")
                }
              >
                {invoice.state === I_STATE.CANCELED ? "Canceled" : invoice.state === I_STATE.PAID ? "Paid" : "Open"}
              </td>
            </>
          )}
        </tr>
        {!isCreditNote && (
          <>
            {invoice.reminders.map((r) => {
              const due = new Date(r.date);
              due.setDate(due.getDate() + 14);
              const remaining = Math.ceil(getDaysUntil(due));
              return (
                <tr key={r._id.toString()} className="border-bottom-dark-gray">
                  <td colSpan={2} />
                  <td className="text-light align-middle">
                    {r.dunningFee ? formatCurrency(r.dunningFee, invoice.currency) : ""}
                  </td>
                  <td className="text-warning align-middle">
                    {I_REMINDERDESCRIPTION.find((rd) => rd.value === r.type)?.label}
                  </td>
                  <td />
                  <td className="text-muted align-middle">{formatDate(r.date)}</td>
                  <td className="text-muted align-middle">14 days</td>
                  <td className={"align-middle " + (remaining >= 0 ? "text-success" : "text-danger")}>
                    {remaining} day(s)
                  </td>
                  <td className="align-middle">
                    <a href={resolveFilePath(r.reminderFile)} target="_blank" rel="noopener noreferrer">
                      <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                    </a>
                  </td>
                  <td />
                </tr>
              );
            })}
            {invoice.payments.map((p) => (
              <tr key={p._id.toString()}>
                <td colSpan={2} />
                <td className="text-light align-middle">{formatCurrency(p.amount, invoice.currency)}</td>
                <td className="text-white align-middle">Payment</td>
                <td />
                <td className="text-muted align-middle">{formatDate(p.date)}</td>
                <td colSpan={2} />
                <td>
                  <img
                    src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                    style={{ height: 24 }}
                    className="image-gray"
                    alt="PDF"
                  />
                </td>
                <td />
              </tr>
            ))}
            {invoice.cancelation && (
              <tr key={invoice.cancelation._id.toString()} className={"border-bottom-dark-gray"}>
                <td colSpan={3} />
                <td className="text-danger align-middle">Cancelation</td>
                <td />
                <td className="text-muted align-middle">{formatDate(invoice.cancelation.date)}</td>
                <td colSpan={2} />
                <td className="align-middle">
                  <a
                    href={resolveFilePath(invoice.cancelation.cancelationFile)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                  </a>
                </td>
                <td />
              </tr>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(InvoiceRow);
