import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import SampleOrderWorkflowContent from "./SampleOrderWorkflowContent";
import { DataContextCustomerType, DataContextInternalType } from "../../../../../context/dataContext";
import { SampleOrderExtended } from "../../../../../model/sampleOrder.types";
import { CustomerSampleOrderExtended } from "../../../../../model/customer/customerSampleOrder.types";
import OrderHistory from "../../OrderHistory";

interface SampleOrderWorkflowProps {
  order: SampleOrderExtended | CustomerSampleOrderExtended;
  context: DataContextInternalType | DataContextCustomerType;
}

class SampleOrderWorkflow extends PureComponent<SampleOrderWorkflowProps> {
  render() {
    const { order, context } = this.props;

    return (
      <>
        <div className="card card-bottom-radius-none bg-white">
          <div className="d-flex flex-column px-9 py-4 bg-light2 border-bottom-0">
            <div className="d-flex align-items-center justify-content-between mb-1 w-100">
              <div className="d-flex flex-column">
                <span className="fs-1 text-gray-800 fw-bolder">Sample RB-{order.orderNo}</span>
                <Link to={"/commodity/" + order.commodity._id.toString()} className="fs-4 text-muted">
                  {order.commodity.title.en}
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <SampleOrderWorkflowContent order={order} context={context} />
          </div>
        </div>
        <div className="card card-bottom-radius-none bg-white mt-5">
          <OrderHistory order={order} context={context} />
        </div>
      </>
    );
  }
}

export default SampleOrderWorkflow;
