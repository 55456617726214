import _ from "lodash";
import React, { PureComponent } from "react";
import { CustomerCustomerOrderExtended } from "../../../../model/customer/customerCustomerOrder.types";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../../context/dataContext";
import { getAddressByType } from "../../../../utils/addressUtils";
import { Address, AddressType } from "../../../../model/commonTypes";
import { getCountryNameForCode } from "../../../../utils/baseUtils";
import { getPortCode, getPortNameOrAddress } from "../../../../utils/supplierOrderUtils";
import { getOrderStateRanking } from "../../../../utils/customerOrderUtils";
import { CO_CANCELED, CO_TYPES, T_EUSTOCK, T_WAREHOUSE } from "../../../../model/customerOrder.types";

interface OrderSummaryProps {
  order: CustomerCustomerOrderExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface OrderSummaryState {
  supplierAddress?: Address;
  deliveryCity: string;
  notifyCity: string;
  startCode: string;
  startPort: string;
  destinationCode: string;
  destinationPort: string;
}

class OrderSummary extends PureComponent<OrderSummaryProps, OrderSummaryState> {
  constructor(props: OrderSummaryProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<OrderSummaryProps>) {
    if (
      !_.isEqual(prevProps.order, this.props.order) ||
      !_.isEqual(prevProps.context.supplierOrder, this.props.context.supplierOrder) ||
      !_.isEqual(prevProps.context.notify, this.props.context.notify)
    ) {
      this.setState(this.getDefaultState(this.props));
    }
  }

  getDefaultState = (props: OrderSummaryProps) => {
    const { order, context } = props;
    const { supplier } = order;

    const supplierAddress = supplier ? getAddressByType(supplier.address, AddressType.A_PRIMARY) : undefined;
    const deliveryCity = order.terms?.deliveryCity || "TBD";

    const sO = context.supplierOrder.find((s) => s.customerOrders.includes(order._id.toString()));

    const notify = context.notify.find((n) => n._id.toString() === sO?.terms?.notify);
    const notifyCity = notify ? `${notify.address.city}, ${getCountryNameForCode(notify.address.country)}` : "TBD";

    const shipment = sO?.shipment[0] ?? undefined;
    const startCode = getPortCode(shipment?.shipping.startingPoint || "TBD");
    const startPort = getPortNameOrAddress(shipment?.shipping.startingPoint || "TBD", true);
    const destinationCode = getPortCode(shipment?.shipping.destination || "TBD");
    const destinationPort = getPortNameOrAddress(shipment?.shipping.destination || "TBD", true);

    return {
      supplierAddress,
      deliveryCity,
      notifyCity,
      startCode,
      startPort,
      destinationCode,
      destinationPort,
    };
  };

  getStations = (): Array<{ title: string; subtitle: string; liClassName: string }> => {
    const { order } = this.props;
    const { supplierAddress, deliveryCity, notifyCity, startCode, startPort, destinationCode, destinationPort } =
      this.state;
    const isStockOrder = ([T_WAREHOUSE, T_EUSTOCK] as Array<CO_TYPES>).includes(order.transport);
    const rank = getOrderStateRanking(order);

    if (isStockOrder) {
      const warehouseCity =
        order.transport === T_EUSTOCK
          ? supplierAddress
            ? `${supplierAddress.city}, ${getCountryNameForCode(supplierAddress.country)}`
            : "Supplier Warehouse"
          : "RAWBIDS Warehouse";
      return [
        {
          title: "Requested",
          subtitle: warehouseCity,
          liClassName: rank >= 1 ? "done" : "current",
        },
        {
          title: "Preparing",
          subtitle: warehouseCity,
          liClassName: rank >= 1 ? "done" : "open",
        },
        {
          title: "Processing",
          subtitle: warehouseCity,
          liClassName: rank > 6 ? "done" : rank === 6 ? "current" : "open",
        },
        {
          title: "Quality Check",
          subtitle: warehouseCity,
          liClassName: rank > 6 ? "done" : "open",
        },
        {
          title: "Transit to Customer",
          subtitle: `${warehouseCity} - ${deliveryCity}`,
          liClassName: rank > 7 ? "done" : rank === 7 ? "current" : "open",
        },
        {
          title: "Arrival at Customer",
          subtitle: deliveryCity,
          liClassName: rank > 7 ? "done" : rank === 7 ? "current" : "open",
        },
      ];
    } else {
      return [
        {
          title: "Commissioning",
          subtitle: supplierAddress
            ? `${supplierAddress.city}, ${getCountryNameForCode(supplierAddress.country)}`
            : "Supplier Origin",
          liClassName: rank >= 1 ? "done" : "current",
        },
        {
          title: "Arrived at Starting Port",
          subtitle: startPort,
          liClassName: rank >= 1.5 ? "done" : rank >= 1 ? "current" : "open",
        },
        {
          title: "In Transit",
          subtitle: `${startCode} - ${destinationCode}`,
          liClassName: rank >= 2 ? "done" : rank >= 1.5 ? "current" : "open",
        },
        {
          title: "Arrived at Destination Port",
          subtitle: destinationPort,
          liClassName: rank >= 3 ? "done" : rank >= 2 ? "current" : "open",
        },
        {
          title: "Customs",
          subtitle: destinationPort,
          liClassName: rank >= 3 ? "done" : "open",
        },
        {
          title: "Transit to Central Warehouse",
          subtitle: `${destinationPort} - ${notifyCity}`,
          liClassName: rank >= 4 ? "done" : rank >= 3 ? "current" : "open",
        },
        {
          title: "Arrived at Central Warehouse",
          subtitle: notifyCity,
          liClassName: rank >= 5 ? "done" : rank >= 4 ? "current" : "open",
        },
        {
          title: "Transit to Customer",
          subtitle: `${notifyCity} - ${deliveryCity}`,
          liClassName: rank >= 7 ? "done" : rank >= 5 ? "current" : "open",
        },
        {
          title: "Arrival at Customer",
          subtitle: deliveryCity,
          liClassName: rank > 7 ? "done" : rank === 7 ? "current" : "open",
        },
      ];
    }
  };

  render() {
    const { order } = this.props;

    return (
      <div className="card mb-5 mb-xl-8 bg-white">
        <div className="card-body">
          <span className="fs-2 text-gray-800 fw-bolder">Order Status</span>
          <ul className="delivery-timeline">
            {this.getStations().map(({ title, subtitle, liClassName }, idx) => (
              <li key={idx} className={order.state === CO_CANCELED ? "canceled" : liClassName}>
                <span className="fs-6 text-gray-800 fw-bold d-block">{title}</span>
                <span className="fw-semibold text-gray-500">{subtitle}</span>
              </li>
            ))}
          </ul>
          <div className="bg-light2 text-muted p-5 mt-5">
            Real-time data currently not available. <br />
            Tracking may be delayed up to 24h.
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSummary;
