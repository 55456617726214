import React, { PureComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Cookies, withCookies } from "react-cookie";
import { getAndRemoveQuery } from "../../utils/queryUtils";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../../services/authService";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import { Empty } from "../../model/commonTypes";
import userService from "../../services/userService";
import { ANONYMOUS } from "../../utils/userUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../services/slackService";

interface LocationState {
  from: Location;
}

interface LoginProps extends RouteComponentProps<Empty, Empty, LocationState> {
  cookies: Cookies;
}

interface LoginState {
  email: string;
  password: string;
  error: string;
  loggingIn: boolean;
  fromLogout: boolean;
  resetSuccess: boolean;
}

class Login extends PureComponent<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    const fromLogout = !!getAndRemoveQuery(props.location, props.history, "logout");
    const success = !!getAndRemoveQuery(props.location, props.history, "success");
    this.state = {
      email: "",
      password: "",
      error: "",
      loggingIn: false,
      fromLogout,
      resetSuccess: success,
    };
  }

  /**
   * Handle the login of the user.
   * @param e Event that triggered the login
   */
  handleLogin = async (e: React.FormEvent) => {
    const { email, password } = this.state;
    this.setState({ loggingIn: true, error: "" });
    e.preventDefault();
    try {
      await authService.login(email, password);
      await authService.createAndSetApiKey(this.props.cookies, DEFAULT_SESSION_KEY_NAME);
      if (userService.getUserType() === ANONYMOUS) {
        sendMessage(
          getDefaultSlackChannel(false, NotificationType.ANONYMOUS),
          `New anonymous User login:\nBrowser/OS: ${navigator.userAgent}\nLanguage: ${navigator.language}\nScreenwidth: ${window.innerWidth}px\n`
        );
      }
      // Do full reload of page after login
      const { state } = this.props.location;
      window.location.href = state ? state.from.pathname : "/";
    } catch (ex) {
      console.error(ex);
      this.setState({ error: "Invalid username or password" });
    } finally {
      this.setState({ loggingIn: false });
    }
  };

  render() {
    const { email, password, error, loggingIn, fromLogout, resetSuccess } = this.state;
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
          height: "100%",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <div className="d-flex flex-column flex-column flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
              <h2 className="text-white fw-bold m-0">The biggest virtual marketplace for premium ingredients.</h2>
            </div>
          </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div
              className="bg-body d-flex flex-column align-items-stretch flex-center w-md-600px p-20 border-none"
              style={{ borderRadius: "1.5rem" }}
            >
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" onSubmit={loggingIn ? undefined : this.handleLogin}>
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3 fs-2x">Login</h1>
                    {fromLogout && !error && <p className="text-center text-success">Successfully logged out</p>}
                    {resetSuccess && <p className="text-center text-success">Password successfully reset</p>}
                    {error && <p className="text-center text-danger">{error}</p>}
                  </div>
                  <div className="mb-8">
                    <input
                      name="email"
                      id="login-email"
                      className="form-control form-control-lg form-control-solid"
                      type="email"
                      placeholder="Email"
                      autoComplete={"off"}
                      value={email}
                      required={true}
                      onChange={(e) => this.setState({ email: e.target.value, error: "" })}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      name="password"
                      id="login-password"
                      className="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder="Password"
                      autoComplete={"off"}
                      value={password}
                      required={true}
                      onChange={(e) => this.setState({ password: e.target.value, error: "" })}
                    />
                  </div>
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base mb-8 mt-2">
                    <div />
                    <Link to="/passwordResetRequest" id="login_forgot" className="link-dark">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      id="login-submit"
                      className={"btn btn-dark" + (loggingIn ? " disabled" : "")}
                      disabled={loggingIn}
                    >
                      {!loggingIn ? (
                        <span className="indicator-label">Login</span>
                      ) : (
                        <span className="indicator-progress d-block">
                          Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold ">
                    Not a Member yet?
                    <a href="https://rawbids.com/register" className="link-dark ml-2">
                      Register Now
                    </a>
                  </div>
                </form>
              </div>
              <div className="d-flex flex-stack px-lg-10">
                <div className="me-0">
                  <button className="btn btn-flex btn-link fs-base disabled">
                    <img
                      data-kt-element="current-lang-flag"
                      className="w-20px h-20px rounded me-3"
                      src={toAbsoluteUrl("assets/media/flags/united-states.svg")}
                      alt=""
                    />
                    <span data-kt-element="current-lang-name" className="me-1 link-dark">
                      English
                    </span>
                  </button>
                </div>
                <div className="d-flex text-primary fs-base gap-5">
                  <a
                    href="https://rawbids.com/files/terms_of_purchase.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Purchase
                  </a>
                  <a
                    href="https://rawbids.com/files/terms_of_sale.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Sale
                  </a>
                  <a href="mailto:info@rawbids.com" className="link-dark">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(Login);
