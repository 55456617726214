import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CO_CANCELED, CustomerOrder, CustomerOrderExtended } from "../../model/customerOrder.types";
import {
  calculateArrivalInformation,
  getOrderNumber,
  getOrderStateDescriptions,
  getOrderType,
  INTERNAL_EXTENDED_ORDER_TYPES,
  isCustomerCustomerOrder,
  isCustomerOrder,
  isSampleOrder,
  isSupplierOrder,
} from "../../utils/orderUtils";
import { DataContextInternal, DataContextInternalType } from "../../context/dataContext";
import { I_STATEDESCRIPTION } from "../../utils/invoiceUtils";
import { I_STATE } from "../../model/invoice.types";
import { resolveFilePath, shortenAlias } from "../../utils/fileUtils";
import { getOrderStateRanking } from "../../utils/supplierOrderUtils";
import { formatOrderDate, getOrderStateRanking as getOrderStateRankingCO } from "../../utils/customerOrderUtils";
import { getOrderStateRanking as getOrderStateRankingSamO } from "../../utils/sampleOrderUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../utils/contractUtils";
import { getContractNumber, getContractStateDescriptions, isCustomerContract } from "../../utils/customerContractUtils";
import {
  getAmountForOrderOrContract,
  getLinkForOrderOrContract,
  getTotalPriceForOrderOrContract,
  isAnyContract,
} from "../../utils/contractAndOrderUtils";
import { CC_CANCELED, CustomerContractExtended } from "../../model/customerContract.types";
import { INTERNAL } from "../../utils/userUtils";
import { SO_CANCELED, SO_STATES } from "../../model/supplierOrder.types";
import { SAMO_CANCELED } from "../../model/sampleOrder.types";
import { formatDate, getDocFromCollection } from "../../utils/baseUtils";
import { formatArticleUnit, isAnyFinishedProduct } from "../../utils/productArticleUtils";
import { Company } from "../../model/company.types";
import { CustomerCustomerOrderExtended } from "../../model/customer/customerCustomerOrder.types";

interface OrderWidgetProps {
  order: INTERNAL_EXTENDED_ORDER_TYPES | CustomerCustomerOrderExtended | EXTENDED_CONTRACT_TYPES;
  type: "commodity" | "supplier" | "customer"; // Indicates which value is shown below the order identifier, commodity name for commodity, etc.
  setOpacity?: boolean;
  showServices?: boolean;
  preview?: boolean;
  context?: DataContextInternalType;
}

const OrderWidget: React.FunctionComponent<OrderWidgetProps> = ({ order, type, context, showServices, preview }) => {
  const getDescriptionAndLink = () => {
    const productType = isAnyFinishedProduct(order.commodity) ? "finishedProduct" : "commodity";
    switch (type) {
      case "commodity":
        return [order.commodity.title.en, `/${productType}/${order.commodity._id.toString()}`];
      case "customer":
        if ("company" in order) return [order.company.name, `/customer/${order.company._id.toString()}`];
        return [order.commodity.title.en, `/${productType}/${order.commodity._id.toString()}`];
      case "supplier":
        if (isSupplierOrder(order)) return [order.supplier.name, `/supplier/${order.supplier._id.toString()}`];
        return [order.commodity.title.en, `/${productType}/${order.commodity._id.toString()}`];
      default:
        return [order.commodity.title.en, `/${productType}/${order.commodity._id.toString()}`];
    }
  };

  const getOrderLink = () => {
    return getLinkForOrderOrContract(order);
  };

  const getOpacity = () => {
    if (isSupplierOrder(order)) {
      const rank = getOrderStateRanking(order);
      // invalid, canceled or handled at warehouse
      if (rank === -1 || rank > 4) return 0.5;
    }
    if (isCustomerOrder(order)) {
      const rank = getOrderStateRankingCO(order);
      // invalid, canceled or shipped to customer
      if (rank === -1 || rank > 6) return 0.5;
    }
    if (isSampleOrder(order)) {
      const rank = getOrderStateRankingSamO(order);
      // invalid, canceled/rejected or shipped
      if (rank === -1 || rank > 1) return 0.5;
    }
    return 1;
  };

  const isSample = isSampleOrder(order);
  const isContract = isAnyContract(order);
  const isCanceled =
    order.state === CO_CANCELED ||
    (order.state as SO_STATES) === SO_CANCELED ||
    order.state === SAMO_CANCELED ||
    order.state === CC_CANCELED;
  const arrival = calculateArrivalInformation(
    order,
    isSupplierOrder(order) || isCustomerOrder(order) ? order.changedETA : undefined
  );
  const services =
    isSample || isContract
      ? 0
      : isCustomerOrder(order) || isCustomerCustomerOrder(order)
      ? order.services.length
      : // Unique services for supplier order
        Array.from(new Set(order.customerOrders.map((cO) => cO.services.map((s) => s.service._id.toString())).flat()))
          .length;
  const customers = isSample
    ? 0
    : isSupplierOrder(order)
    ? Array.from(new Set(order.customerOrders.map((cO) => cO.company))).length
    : 0;
  const [description, descLink] = getDescriptionAndLink();

  if (preview) {
    return (
      <div className="align-items-center bg-light no-hover rounded p-5 mb-2" style={{ opacity: getOpacity() }}>
        <div className="d-flex align-items-center justify-content-between">
          {/* Order Number (or Contract Number) - Left Top */}
          <div>
            <Link className="fw-bolder text-gray-800 fs-6 custom-link" to={getOrderLink}>
              {isContract ? `Contract ${getContractNumber(order)}` : `Order ${getOrderNumber(order)}`}
            </Link>
          </div>
          {/* Amount - Right Top */}
          <div className="text-right">
            <div className="fw-bold fs-6 text-gray-400">{getAmountForOrderOrContract(order)}</div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          {/* Order Status - Left Bottom */}
          <div>
            <span className="text-success" style={{ fontSize: ".8rem" }}>
              {isContract ? getContractStateDescriptions(order).title : getOrderStateDescriptions(order).title}
            </span>
          </div>
          {/* Arrival Week - Right Bottom */}
          <div className="text-right text-muted">{arrival.cw > 0 ? `CW ${arrival.cw}-${arrival.year}` : "-"}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="align-items-center bg-light no-hover rounded p-5 mb-7" style={{ opacity: getOpacity() }}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 me-2">
          <div className="d-inline-flex">
            <Link className="fw-bolder text-gray-800 fs-6 custom-link" to={getOrderLink}>
              {isContract ? `Contract ${getContractNumber(order)}` : `Order ${getOrderNumber(order)}`}
            </Link>
            <span className="text-success ml-3 my-auto" style={{ fontSize: ".8rem" }}>
              <span className="d-flex flex-center">
                <span className="bg-success mr-2" style={{ width: "5px", height: "5px", borderRadius: "5px" }}>
                  &nbsp;
                </span>
                {isContract ? getContractStateDescriptions(order).title : getOrderStateDescriptions(order).title}
              </span>
            </span>
          </div>
          <div>
            <Link to={descLink} className="custom-link text-muted fw-bold">
              {description}
            </Link>
          </div>
        </div>
        {isSupplierOrder(order) && (
          <div className="text-right mr-4">
            <span className="text-muted mb-1">
              <SupplierOrderCustomerPopover customerOrders={order.customerOrders} uniqueCustomers={customers} />
            </span>
          </div>
        )}
        <div className="text-right align-self-start mr-4">
          <div className="fw-bold fs-6 text-gray-400">{getAmountForOrderOrContract(order)}</div>
          {!isSample &&
            context?.type === INTERNAL && ( // Context optional, component can also be used by customer view
              <span className="text-muted mb-1">
                {services} {services === 1 ? "Service" : "Services"}
              </span>
            )}
        </div>
        <div className="text-right mr-4">
          <div className="fw-bold fs-6 text-gray-400">
            {isCanceled ? <s>{getTotalPriceForOrderOrContract(order)}</s> : getTotalPriceForOrderOrContract(order)}
          </div>
          <span className="text-muted mb-1">{"transport" in order && getOrderType(order)}</span>
        </div>

        {isCanceled ? (
          <div className="text-right">
            <div className="fw-bold fs-6 text-gray-400">
              <s>{formatOrderDate(order, "CW", true)}</s>
            </div>
            <span className="mb-1 text-danger">Canceled</span>
          </div>
        ) : (
          arrival && (
            <div className="text-right">
              <div className="fw-bold fs-6 text-gray-400">
                {isCustomerOrder(order)
                  ? arrival.dateIsCW && arrival.cw > 0
                    ? `CW ${arrival.cw}-${arrival.year}`
                    : arrival.dateIsFix
                    ? `${formatDate(arrival.arrivalDate)} (fixed)`
                    : `${formatDate(arrival.arrivalDate)}`
                  : arrival.cw > 0
                  ? `CW ${arrival.cw}-${arrival.year}`
                  : "-"}
              </div>
              {arrival.target ? (
                <span className={"mb-1 " + (arrival.late ? "text-danger" : "text-success")}>
                  {isNaN(arrival.weekDiff)
                    ? "no target date"
                    : `${arrival.weekDiff} ${arrival.weekDiff === 1 ? "week" : "weeks"} ${
                        arrival.late ? "overdue" : "remaining"
                      }`}
                </span>
              ) : (
                <span className={"mb-1 text-success"}>delivered</span>
              )}
            </div>
          )
        )}
      </div>
      {(isCustomerOrder(order) || isCustomerContract(order)) && context && (
        <ExtendedOrderData order={order} context={context} showServices={showServices} />
      )}
    </div>
  );
};

export default OrderWidget;

interface ExtendedOrderDataProps {
  order: CustomerOrderExtended | CustomerCustomerOrderExtended | CustomerContractExtended;
  context: DataContextInternalType;
  showServices?: boolean;
}

const ExtendedOrderData: React.FunctionComponent<ExtendedOrderDataProps> = ({ order, context, showServices }) => {
  const relatedBatches = context.batch.filter(
    (b) =>
      (isCustomerOrder(order) && b.customerOrders.some((cO) => cO === order._id.toString())) ||
      (isCustomerContract(order) &&
        b.customerContracts &&
        b.customerContracts.some((cO) => cO === order._id.toString())) ||
      b.packages.some((p) => p.usedOrders && p.usedOrders.includes(order._id.toString()))
  );
  const relatedInvoices = context.invoice.filter(
    (i) =>
      i.relatedOrder === order._id.toString() ||
      (isCustomerContract(order) && order.calls.some((c) => c._id.toString() === i.relatedOrder))
  );
  return (
    <div className="row mt-5">
      <div className="col-md-6">
        <div className="fw-bolder text-white fs-5 my-2">Information</div>
        {isCustomerOrder(order) && (
          <div className="row my-2">
            <span className="col-12 text-white fw-bold">
              Tracking Number:{" "}
              {order.trackingInformation ? (
                order.trackingInformation.trackingLink.trim() ? (
                  <a
                    href={order.trackingInformation.trackingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white fw-bold custom-link"
                  >{`${order.trackingInformation.trackingNumber} (${order.trackingInformation.deliveryCompany})`}</a>
                ) : (
                  <span className="text-white fw-bold">{`${order.trackingInformation.trackingNumber} (${order.trackingInformation.deliveryCompany})`}</span>
                )
              ) : (
                <span className="text-danger">Missing</span>
              )}
            </span>
          </div>
        )}
        <div className="row my-2">
          <span className="col-12 text-white fw-bold ">
            Batch:{" "}
            {relatedBatches.length > 0 ? (
              relatedBatches.map((b) => b.identifier).join(", ")
            ) : (
              <span className="text-danger">Missing</span>
            )}
          </span>
        </div>
        <div className="row my-2">
          {relatedInvoices.length > 0 ? (
            relatedInvoices.map((i) => (
              <span key={i._id.toString()} className="text-white fw-bold">
                Invoice: RBI-{i.invoiceNumber}
                <span className={"ml-2 " + (i.state === I_STATE.PAID ? "text-success" : "text-warning")}>
                  {I_STATEDESCRIPTION.find((s) => s.value === i.state)?.label}
                </span>
              </span>
            ))
          ) : (
            <span className="text-white fw-bold">
              Invoice:
              <span className="ml-1 text-danger">Missing</span>
            </span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="fw-bolder text-white fs-5 my-2">Documents</div>
        {order.files.length > 0 ? (
          order.files.map((f) => (
            <div key={f._id.toString()} className="row my-2">
              <a
                className="text-white fw-bold"
                href={resolveFilePath(f.path)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shortenAlias(f.path)}
              </a>
            </div>
          ))
        ) : (
          <div className="row my-2">
            <span className="text-muted fw-bold">None</span>
          </div>
        )}
        {isCustomerOrder(order) && showServices && order.services.length > 0 && (
          <>
            <div className="fw-bolder text-white fs-5 my-2">Services</div>
            {order.services.map((s) => (
              <div key={s.service._id.toString()} className="row my-2">
                <span className="text-muted fw-bold">
                  <span className="text-white fw-bolder">RBS-{s.service.serviceNo}</span> {s.service.title.en}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

interface SupplierOrderCustomerPopoverProps {
  customerOrders: Array<CustomerOrder>;
  uniqueCustomers: number;
}

const SupplierOrderCustomerPopover: React.FunctionComponent<SupplierOrderCustomerPopoverProps> = ({
  customerOrders,
  uniqueCustomers,
}) => {
  const context = useContext(DataContextInternal);

  const [showPopover, setShowPopover] = useState(false);
  let timer: any = null;

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  });

  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setShowPopover(true);
    }, 0);
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
    setShowPopover(false);
  };

  return (
    <OverlayTrigger
      placement="top"
      show={showPopover}
      overlay={
        <Popover
          style={{ maxWidth: "95vw", border: "none" }}
          id={"customerPopover"}
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={handleMouseLeave}
        >
          <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
            <div className="card-body">
              <div className="text-white">
                {_.orderBy(customerOrders, "company.name", "asc").map((cO, idx) => {
                  const company = getDocFromCollection(context.company, cO.company) as Company;
                  return (
                    <div key={cO._id.toString()} className="row" style={{ minWidth: "450px" }}>
                      <div className="col-5 pl-2 pr-0 my-auto fw-bolder fs-7 text-left">
                        <Link className="custom-link text-white" to={`/customer/${company._id.toString()}`}>
                          {company.name}
                        </Link>
                      </div>
                      <div className="col-4 px-2 my-auto fw-bolder fs-7 text-center">
                        <Link className="custom-link text-white" to={`/customerOrder/${cO._id.toString()}`}>
                          Order {getOrderNumber(cO)}
                        </Link>
                      </div>
                      <div className="col-3 pr-2 pl-0 my-auto fs-7 text-right">
                        {cO.amount + formatArticleUnit(cO.unit)}
                      </div>
                      {idx < customerOrders.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Popover>
      }
    >
      <span className="kt-font-dark kt-link" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <i className={"text-muted"}>
          for {uniqueCustomers} {uniqueCustomers === 1 ? "Customer" : "Customers"}
        </i>
      </span>
    </OverlayTrigger>
  );
};
