import React, { FunctionComponent, PureComponent } from "react";
import { Property } from "../model/property.types";
import { getAnonymousContext, getCustomerContext, getInternalContext, getSupplierContext } from "../services/dbService";
import { ActiveSubstance } from "../model/activeSubstance.types";
import { Commodity } from "../model/commodity.types";
import { Notification } from "../model/notification.types";
import { Invoice } from "../model/invoice.types";
import { Batch } from "../model/batch.types";
import { CustomerOrder } from "../model/customerOrder.types";
import { Service } from "../model/service.types";
import { Supplier } from "../model/supplier.types";
import { Company } from "../model/company.types";
import { UserData } from "../model/userData.types";
import { CustomerCommodity } from "../model/customer/customerCommodity.types";
import { CustomerCustomerOrder } from "../model/customer/customerCustomerOrder.types";
import { SupplierSupplier } from "../model/supplier/supplierSupplier.types";
import userService from "../services/userService";
import { ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "../utils/userUtils";
import { SupplierOrder } from "../model/supplierOrder.types";
import { SupplierSupplierOrder } from "../model/supplier/supplierSupplierOrder.types";
import { Favorites } from "../model/favorites.types";
import { General } from "../model/general.types";
import { VersionHistory } from "../model/versionHistory.types";
import { SampleOrder } from "../model/sampleOrder.types";
import { CustomerSampleOrder } from "../model/customer/customerSampleOrder.types";
import { CommodityOfferRequest } from "../model/commodityOfferRequest.types";
import { Seaport } from "../model/seaport.types";
import { Article } from "../model/article.types";
import { Currencies } from "../utils/currencyUtils";
import { Airport } from "../model/airport.types";
import { Notify } from "../model/notify.types";
import { CustomerContract } from "../model/customerContract.types";
import { Watchlist } from "../model/watchlist.types";
import { CustomerCustomerContract } from "../model/customer/customerCustomerContract.types";
import { CustomerSupplier } from "../model/customer/customerSupplier.types";
import { ControllingStatistics } from "../model/statistics/controllingStatistics.types";
import { CustomerRequest } from "../model/customerRequest.types";
import { CustomerBatch } from "../model/customer/customerBatch.types";
import { CustomerSupplierOrder } from "../model/customer/customerSupplierOrder.types";
import { CustomerNotify } from "../model/customer/customerNotify.types";
import { ForwardingOrder } from "../model/forwardingOrder.types";
import { StorageOrder } from "../model/storageOrder.types";
import { SystemNotification } from "../model/systemNotification.types";
import { FinishedProduct } from "../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../model/customer/customerFinishedProduct.types";
import { AnonymousConfiguration } from "../model/configuration/anonymousConfiguration.types";
import { BaseConfiguration } from "../model/configuration/configuration.types";
import { Forwarder } from "../model/forwarder.types";
import { PriceGraph } from "../model/priceGraph.types";
import { DATA_TYPES, DATABASE_DOCUMENT } from "../utils/dataContextUtils";

export const DefaultDataContextInternal: DataContextInternalType = {
  type: INTERNAL,
  currentView: INTERNAL,
  activeSubstance: [],
  airport: [],
  batch: [],
  configuration: [],
  commodity: [],
  company: [],
  customerContract: [],
  customerOrder: [],
  customerRequest: [],
  finishedProduct: [],
  priceGraph: [],
  forwarder: [],
  forwardingOrder: [],
  property: [],
  service: [],
  storageOrder: [],
  supplierOrder: [],
  sampleOrder: [],
  seaport: [],
  notification: [],
  invoice: [],
  supplier: [],
  userData: [],
  general: [],
  versionHistory: [],
  commodityOfferRequest: [],
  news: [],
  notify: [],
  controllingStatistics: [],
  systemNotification: [],
  currencies: {},
  savedState: {},
  refMap: {},
  innerWidth: window.innerWidth,
  saveComponentState: (key: string, state: any) => undefined,
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => undefined,
  deleteRef: (key: string) => undefined,
  addDocuments: (collection: string, documents: Array<DATABASE_DOCUMENT>) => {},
};

export const DataContextInternal = React.createContext<DataContextInternalType>(DefaultDataContextInternal);

export const DefaultDataContextCustomer: DataContextCustomerType = {
  type: CUSTOMER,
  activeSubstance: [],
  batch: [],
  commodity: [],
  company: [],
  supplier: [],
  customerContract: [],
  customerOrder: [],
  supplierOrder: [],
  customerRequest: [],
  finishedProduct: [],
  priceGraph: [],
  sampleOrder: [],
  property: [],
  service: [],
  notification: [],
  invoice: [],
  news: [],
  userData: [],
  favorites: [],
  notify: [],
  versionHistory: [],
  systemNotification: [],
  currencies: {},
  watchlist: [],
  savedState: {},
  refMap: {},
  innerWidth: window.innerWidth,
  saveComponentState: (key: string, state: any) => undefined,
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => undefined,
  deleteRef: (key: string) => undefined,
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => {},
};

export const DataContextCustomer = React.createContext<DataContextCustomerType>(DefaultDataContextCustomer);

export const DefaultDataContextAnonymous: DataContextAnonymousType = {
  type: ANONYMOUS,
  activeSubstance: [],
  batch: [],
  commodity: [],
  company: [],
  supplier: [],
  customerContract: [],
  customerOrder: [],
  supplierOrder: [],
  customerRequest: [],
  finishedProduct: [],
  priceGraph: [],
  sampleOrder: [],
  property: [],
  service: [],
  notification: [],
  news: [],
  userData: [],
  favorites: [],
  notify: [],
  versionHistory: [],
  systemNotification: [],
  currencies: {},
  watchlist: [],
  savedState: {},
  refMap: {},
  configuration: { values: { commodities: [], finishedProducts: [] } } as unknown as AnonymousConfiguration,
  innerWidth: window.innerWidth,
  saveComponentState: (key: string, state: any) => undefined,
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => undefined,
  deleteRef: (key: string) => undefined,
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => {},
};

export const DataContextAnonymous = React.createContext<DataContextAnonymousType>(DefaultDataContextAnonymous);

export const DefaultDataContextSupplier: DataContextSupplierType = {
  type: SUPPLIER,
  activeSubstance: [],
  airport: [],
  commodity: [],
  finishedProduct: [],
  property: [],
  supplierOrder: [],
  notification: [],
  seaport: [],
  invoice: [],
  supplier: [],
  userData: [],
  favorites: [],
  versionHistory: [],
  commodityOfferRequest: [],
  systemNotification: [],
  currencies: {},
  savedState: {},
  refMap: {},
  innerWidth: window.innerWidth,
  saveComponentState: (key: string, state: any) => undefined,
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => undefined,
  deleteRef: (key: string) => undefined,
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => {},
};

export const DataContextSupplier = React.createContext<DataContextSupplierType>(DefaultDataContextSupplier);

// For easier usage in common components
export type DataContextType = React.ContextType<
  typeof DataContextInternal | typeof DataContextCustomer | typeof DataContextSupplier | typeof DataContextAnonymous
>;

export interface DataContextInternalType {
  type: string;
  currentView: string;
  activeSubstance: Array<ActiveSubstance>;
  airport: Array<Airport>;
  batch: Array<Batch>;
  property: Array<Property>;
  configuration: Array<BaseConfiguration>;
  commodity: Array<Commodity>;
  company: Array<Company>;
  customerContract: Array<CustomerContract>;
  customerOrder: Array<CustomerOrder>;
  customerRequest: Array<CustomerRequest>;
  finishedProduct: Array<FinishedProduct>;
  priceGraph: Array<PriceGraph>;
  forwarder: Array<Forwarder>;
  forwardingOrder: Array<ForwardingOrder>;
  notification: Array<Notification>;
  service: Array<Service>;
  storageOrder: Array<StorageOrder>;
  supplierOrder: Array<SupplierOrder>;
  sampleOrder: Array<SampleOrder>;
  seaport: Array<Seaport>;
  invoice: Array<Invoice>;
  supplier: Array<Supplier>;
  userData: Array<UserData>;
  general: Array<General>;
  versionHistory: Array<VersionHistory>;
  commodityOfferRequest: Array<CommodityOfferRequest>;
  news: Array<Article>;
  notify: Array<Notify>;
  controllingStatistics: Array<ControllingStatistics>;
  systemNotification: Array<SystemNotification>;
  currencies: Currencies;
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: { [key: string]: any };
  saveComponentState: (key: string, state: object) => void;
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => void;
  deleteRef: (key: string) => void;
  addDocuments: (collection: string, documents: Array<DATABASE_DOCUMENT>) => void;
}

export interface DataContextCustomerType {
  type: string;
  activeSubstance: Array<ActiveSubstance>;
  batch: Array<CustomerBatch>;
  property: Array<Property>;
  commodity: Array<CustomerCommodity>;
  company: Array<Company>;
  customerContract: Array<CustomerCustomerContract>;
  customerOrder: Array<CustomerCustomerOrder>;
  finishedProduct: Array<CustomerFinishedProduct>;
  priceGraph: Array<PriceGraph>;
  supplierOrder: Array<CustomerSupplierOrder>;
  customerRequest: Array<CustomerRequest>;
  sampleOrder: Array<CustomerSampleOrder>;
  notification: Array<Notification>;
  service: Array<Service>;
  invoice: Array<Invoice>;
  supplier: Array<CustomerSupplier>;
  news: Array<Article>;
  userData: Array<UserData>;
  favorites: Array<Favorites>;
  notify: Array<CustomerNotify>;
  versionHistory: Array<VersionHistory>;
  systemNotification: Array<SystemNotification>;
  currencies: Currencies;
  watchlist: Array<Watchlist>;
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: { [key: string]: any };
  saveComponentState: (key: string, state: object) => void;
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => void;
  deleteRef: (key: string) => void;
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => void;
}

export interface DataContextAnonymousType {
  type: string;
  activeSubstance: Array<ActiveSubstance>;
  batch: Array<CustomerBatch>;
  property: Array<Property>;
  commodity: Array<CustomerCommodity>;
  company: Array<Company>;
  customerContract: Array<CustomerCustomerContract>;
  customerOrder: Array<CustomerCustomerOrder>;
  finishedProduct: Array<CustomerFinishedProduct>;
  priceGraph: Array<PriceGraph>;
  supplierOrder: Array<CustomerSupplierOrder>;
  customerRequest: Array<CustomerRequest>;
  sampleOrder: Array<CustomerSampleOrder>;
  notification: Array<Notification>;
  service: Array<Service>;
  supplier: Array<CustomerSupplier>;
  news: Array<Article>;
  userData: Array<UserData>;
  favorites: Array<Favorites>;
  notify: Array<CustomerNotify>;
  versionHistory: Array<VersionHistory>;
  systemNotification: Array<SystemNotification>;
  currencies: Currencies;
  watchlist: Array<Watchlist>;
  configuration: AnonymousConfiguration;
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: { [key: string]: any };
  saveComponentState: (key: string, state: object) => void;
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => void;
  deleteRef: (key: string) => void;
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => void;
}

export interface DataContextSupplierType {
  type: string;
  activeSubstance: Array<ActiveSubstance>;
  airport: Array<Airport>;
  property: Array<Property>;
  commodity: Array<Commodity>;
  finishedProduct: Array<FinishedProduct>;
  notification: Array<Notification>;
  seaport: Array<Seaport>;
  supplierOrder: Array<SupplierSupplierOrder>;
  invoice: Array<Invoice>;
  supplier: Array<SupplierSupplier>;
  userData: Array<UserData>;
  favorites: Array<Favorites>;
  versionHistory: Array<VersionHistory>;
  commodityOfferRequest: Array<CommodityOfferRequest>;
  systemNotification: Array<SystemNotification>;
  currencies: Currencies;
  innerWidth: number;
  savedState: { [key: string]: { state: object; date: Date } };
  refMap: { [key: string]: any };
  saveComponentState: (key: string, state: object) => void;
  saveRef: (key: string, ref: PureComponent | FunctionComponent) => void;
  deleteRef: (key: string) => void;
  addDocuments: (collection: string, documents: Array<DATA_TYPES>) => void;
}

export async function getDataContextInternal(): Promise<DataContextInternalType> {
  const context = await getInternalContext();
  return {
    ...context,
  };
}

export async function getDataContextCustomer(): Promise<DataContextCustomerType> {
  const context = await getCustomerContext();
  return {
    ...context,
  };
}

export async function getDataContextAnonymous(): Promise<DataContextAnonymousType> {
  const context = await getAnonymousContext();
  return {
    ...context,
  };
}

export async function getDataContextSupplier(): Promise<DataContextSupplierType> {
  const context = await getSupplierContext();
  return {
    ...context,
  };
}

/**
 * Return the data context definition that matches the type of the active user
 * @returns { React.Context<DataContextInternalType | DataContextCustomerType | DataContextSupplierType> } Context type
 */
export function resolveContextType() {
  const type = userService.getUserType();
  switch (type) {
    case INTERNAL:
      return DataContextInternal;
    case CUSTOMER:
      return DataContextCustomer;
    case SUPPLIER:
      return DataContextSupplier;
    case ANONYMOUS:
      return DataContextAnonymous;
  }
}

/**
 * Check whether the given context is the internal one
 * @param context Context that should be checked
 * @returns { boolean } Indicating if the context is internal or not
 */
export function isInternalContext(context: DataContextType): context is DataContextInternalType {
  return context.type === INTERNAL;
}

/**
 * Check whether the given context is the customer one
 * @param context Context that should be checked
 * @returns { boolean } Indicating if the context is customer or not
 */
export function isCustomerContext(context: DataContextType): context is DataContextCustomerType {
  return context.type === CUSTOMER;
}

/**
 * Check whether the given context is the supplier one
 * @param context Context that should be checked
 * @returns { boolean } Indicating if the context is supplier or not
 */
export function isSupplierContext(context: DataContextType): context is DataContextSupplierType {
  return context.type === SUPPLIER;
}

/**
 * Check whether the given context is the supplier one
 * @param context Context that should be checked
 * @returns { boolean } Indicating if the context is supplier or not
 */
export function isAnonymousContext(context: DataContextType): context is DataContextAnonymousType {
  return context.type === ANONYMOUS;
}
