import { VersionHistory } from "../model/versionHistory.types";
import { callFunction } from "../services/dbService";
import { BSON } from "realm-web";
import { SelectOption } from "../components/common/CustomSelect";

export const TypeOptions: Array<SelectOption> = [
  { value: "feature", label: "Feature" },
  { value: "change", label: "Change" },
  { value: "bugfix", label: "Bugfix" },
  { value: "general", label: "General" },
];

// Function names
const UPSERTVERSIONHISTORY = "upsertVersionHistory";

/**
 * Inserts a new version history into the database.
 * @param versionHistory History object that should be inserted
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> } Result of the call
 */
export async function insertVersionHistory(
  versionHistory: VersionHistory
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return (await upsertVersionHistory(versionHistory, true)) as Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
}

/**
 * Updates a version history object inside the database.
 * @param versionHistory History object that should be updated
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> } Result of the call
 */
export async function updateVersionHistory(
  versionHistory: VersionHistory
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>> {
  return (await upsertVersionHistory(versionHistory, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
}

/**
 * Upserts a version history object inside the database.
 * @param versionHistory History object that should be upserted
 * @param insert Indicated whether insert of update is done
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> } Result of the call
 */
async function upsertVersionHistory(
  versionHistory: VersionHistory,
  insert: boolean
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>> {
  return callFunction(UPSERTVERSIONHISTORY, [versionHistory, insert]);
}
