import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { MostActiveCustomer } from "../../../model/statistics/controllingStatistics.types";
import { getUserName } from "../../../utils/userUtils";
import { formatCurrency, getDocFromCollection, pluralize } from "../../../utils/baseUtils";
import { DataContextInternalType } from "../../../context/dataContext";

interface ActiveCustomersListingProps {
  mostActiveCustomers: Array<MostActiveCustomer>;
  mostActiveCustomersOld: Array<MostActiveCustomer>;
  context: DataContextInternalType;
}

class ActiveCustomersListing extends PureComponent<ActiveCustomersListingProps> {
  /**
   * Resolves the CSS classes for the arrow that indicates the change in activity of the customer
   * @param customerActivity Customer whose current activity should be resolved
   * @param idx Index the customer is in the new most active customer ranking
   * @returns { string } CSS classes for indicating the activity
   */
  getActivityArrow = (customerActivity: MostActiveCustomer, idx: number): string => {
    const { mostActiveCustomersOld } = this.props;
    const cId = customerActivity.customer._id.toString();
    const idxOld = mostActiveCustomersOld.findIndex((mACOld) => mACOld.customer._id.toString() === cId);
    if (idxOld === -1 || idxOld > idx) return "fa-arrow-circle-up text-success"; // Was not in list before or was worse
    if (idxOld === idx) return "fa-arrow-circle-right text-muted"; // Was at the same place
    return "fa-arrow-circle-down text-danger"; // Was at a better place
  };

  render() {
    const { mostActiveCustomers, context } = this.props;

    return (
      <div className="card bg-white rounded h-475px">
        <div className="card-body d-flex justify-content-between flex-column px-0 pb-0" style={{ overflowY: "scroll" }}>
          <div className="px-9">
            <div className="row">
              <span className="fs-1 fw-bolder text-white mb-3">Most Active Customers</span>
              {mostActiveCustomers.map((mAC, idx) => (
                <div key={mAC.customer._id.toString()} className="col-12">
                  <div style={{ lineHeight: "normal" }} className="bg-light p-3 my-2 d-flex">
                    <div style={{ width: "30%" }}>
                      <Link
                        className="text-white fs-5 mb-0 custom-link fw-bold"
                        to={"/customer/" + mAC.customer._id.toString()}
                      >
                        {mAC.customer.name}
                      </Link>
                      <span className="text-muted fw-bold d-block">
                        {getUserName(getDocFromCollection(context.userData, mAC.customer.primaryPerson))}
                      </span>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "10%" }}>
                      <span className={"fs-1rem fa " + this.getActivityArrow(mAC, idx)}></span>
                      <div className="text-muted fs-7">Activity</div>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "10%" }}>
                      <span className="text-white">{pluralize(mAC.contractsAmount, "Contract")}</span>
                      <br />
                      <span className="text-muted fs-7">
                        {formatCurrency(mAC.contractsTurnover, mAC.contractsTurnoverCurrency)}
                      </span>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "10%" }}>
                      <div className="text-success text-nowrap">{pluralize(mAC.activeOrdersAmount, "Order")}</div>
                      <div className="text-muted fs-7 text-nowrap">Active</div>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "10%" }}>
                      <div className="text-white text-nowrap">{pluralize(mAC.totalOrdersAmount, "Order")}</div>
                      <div className="text-muted fs-7 text-nowrap">Total</div>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "15%" }}>
                      <div className="text-white text-nowrap">
                        {formatCurrency(mAC.totalTurnoverLast12Months, mAC.totalTurnoverLast12MonthsCurrency)}
                      </div>
                      <div className="text-muted fs-7 text-nowrap">12m Turnover</div>
                    </div>
                    <div className="fs-6 text-center" style={{ width: "15%" }}>
                      <div className="text-white text-nowrap">
                        {formatCurrency(mAC.totalMarginLast12Months, mAC.totalMarginLast12MonthsCurrency)}
                      </div>
                      <div className="text-muted fs-7 text-nowrap">12m Profit</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActiveCustomersListing;
