import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { ControllingDashboardFinanceDashboardGraphType } from "../../../../utils/controllingDashboardUtils";
import { formatCurrency } from "../../../../utils/baseUtils";
import { EURO } from "../../../../utils/currencyUtils";
import { FinanceData } from "../../../../model/statistics/controllingStatistics.types";

const chartOptions: ApexOptions = {
  chart: {
    type: "area",
    height: 50,
    zoom: { enabled: false },
    sparkline: { enabled: true },
  },
  grid: { show: false },
  dataLabels: { enabled: false },
  stroke: { curve: "straight" },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 0.1,
      opacityFrom: 0.7,
      opacityTo: 0.2,
      stops: [0, 90, 100],
    },
  },
  colors: ["#4d4d4d"],
  xaxis: {
    labels: { show: true },
    categories: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  yaxis: { labels: { show: false } },
  legend: { show: false },
  tooltip: {
    enabled: true,
    theme: "dark",
    y: {
      formatter: function (value: number) {
        return formatCurrency(value, EURO);
      },
      title: { formatter: () => "" },
    },
    marker: { show: false },
  },
};

interface FinanceDataGraphProps {
  data: FinanceData;
  type: ControllingDashboardFinanceDashboardGraphType;
}

const FinanceDataGraph: React.FC<FinanceDataGraphProps> = ({ type, data }) => {
  /**
   * Resolves the data that is needed for building the finance data graph of the given type.
   * @returns { { title: string; financeData: string; series: Array<number> } }
   */
  const resolveData = (): { title: string; badge: string; financeData: string; series: Array<number> } => {
    let title, badge, financeData, series;
    switch (type) {
      case ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENTURNOVER:
        title = "Turnover";
        badge = "Written";
        financeData = formatCurrency(data.writtenTurnover, data.writtenTurnoverCurrency);
        series = data.writtenTurnoverMonths;
        break;
      case ControllingDashboardFinanceDashboardGraphType.CD_FDG_WRITTENMARGIN:
        title = "Margin";
        badge = "Written";
        financeData = formatCurrency(data.writtenMargin, data.writtenMarginCurrency);
        series = data.writtenMarginMonths;
        break;
      case ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKTURNOVER:
        title = "Turnover";
        badge = "Book";
        financeData = formatCurrency(data.bookTurnover, data.bookTurnoverCurrency);
        series = data.bookTurnoverMonths;
        break;
      case ControllingDashboardFinanceDashboardGraphType.CD_FDG_BOOKMARGIN:
        title = "Margin";
        badge = "Book";
        financeData = formatCurrency(data.bookMargin, data.bookMarginCurrency);
        series = data.bookMarginMonths;
        break;
      case ControllingDashboardFinanceDashboardGraphType.CD_FDG_WAREHOUSE:
        title = "Warehouse";
        badge = "Book";
        financeData = formatCurrency(
          data.warehouseValue && data.warehouseValue > 0 ? data.warehouseValue : 0,
          data.warehouseValueCurrency || EURO
        );
        series = data.warehouseValueMonths;
        break;
    }
    return { title, badge, financeData, series };
  };

  const { title, badge, financeData, series } = resolveData();

  const badgeStyle = badge === "Book" ? { backgroundColor: "#ff265a" } : { backgroundColor: "#50CD89" };

  return (
    <div className="card bg-white rounded h-100">
      <div className="pt-3">
        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 2.25rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="text-white">{title}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="badge badge-sm ml-2" style={badgeStyle}>
              {badge}
            </span>
          </div>
        </div>
        <div style={{ padding: "0 2.25rem" }}>
          <h2 className="text-white">{financeData}</h2>
        </div>
        <div id="chart">
          <ReactApexChart options={chartOptions} series={[{ data: series }]} type="area" height={50} width={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default FinanceDataGraph;
