import { BSON } from "realm-web";
import { CommoditySnapshot } from "./commodity.types";
import { Address, Note, OrderFile } from "./commonTypes";
import { UserData } from "./userData.types";
import { Company } from "./company.types";

// States
export const SAMO_REQUESTED = "samoRequested" as const;
export const SAMO_CONFIRMED = "samoConfirmed" as const;
export const SAMO_SHIPPED = "samoShipped" as const;
export const SAMO_ARCHIVED = "samoArchived" as const;
export const SAMO_CANCELED = "samoCanceled" as const;
export const SAMO_REJECTED = "samoRejected" as const;

export type SAMO_STATE =
  | typeof SAMO_REQUESTED
  | typeof SAMO_CONFIRMED
  | typeof SAMO_SHIPPED
  | typeof SAMO_ARCHIVED
  | typeof SAMO_CANCELED
  | typeof SAMO_REJECTED;

// Timeline
export const SAMO_T_CREATED = "samoCreated" as const;
export const SAMO_T_CANCELED = "samoOrderCanceled" as const;
export const SAMO_T_REJECTED = "samoOrderRejected" as const;
export const SAMO_T_CONFIRMED = "samoOrderConfirmed" as const;
export const SAMO_T_SHIPPED = "samoOrderShipped" as const;
export const SAMO_T_ARCHIVED = "samoOrderArchived" as const;
export const SAMO_T_RATED = "samoRatingAdded" as const;
export const SAMO_T_REFERENCEADDED = "samoReference" as const;
export const SAMO_T_DOCUMENTUPLOADED = "samoDocumentUploaded" as const;
export const SAMO_T_DOCUMENTREMOVED = "samoDocumentRemoved" as const;

export const SAMO_TIMELINE_STATE_UPDATES: Array<SAMO_TIMELINETYPE> = [
  SAMO_T_CREATED,
  SAMO_T_CANCELED,
  SAMO_T_REJECTED,
  SAMO_T_CONFIRMED,
  SAMO_T_SHIPPED,
  SAMO_T_ARCHIVED,
  SAMO_T_DOCUMENTUPLOADED,
  SAMO_T_DOCUMENTREMOVED,
];

export type SAMO_TIMELINETYPE =
  | typeof SAMO_T_CREATED
  | typeof SAMO_T_CANCELED
  | typeof SAMO_T_REJECTED
  | typeof SAMO_T_CONFIRMED
  | typeof SAMO_T_SHIPPED
  | typeof SAMO_T_ARCHIVED
  | typeof SAMO_T_RATED
  | typeof SAMO_T_REFERENCEADDED
  | typeof SAMO_T_DOCUMENTUPLOADED
  | typeof SAMO_T_DOCUMENTREMOVED;

export interface SampleOrder {
  _id: BSON.ObjectId;
  orderNo: string;
  state: SAMO_STATE;
  createdAt: Date;
  person: string;
  targetDate: Date;
  deliveryDate?: Date;
  commodity: CommoditySnapshot;
  company: string;
  destination: Address | string; // string for backwards compatibility
  noteCustomer: string;
  supplier?: string; // new
  customerReference?: string; // new
  noteInternal: Array<Note>;
  amount: number;
  unit: "kg" | "ltr" | ""; // Empty string for initializations
  totalPrice: number;
  currency: string;
  files: Array<OrderFile>;
  timeline: Array<SampleOrderTimelineEntry>;
  sampleRating?: {
    ratingText: string;
    rating: number;
    ratingDate: Date;
  };
}

export interface SampleOrderExtended extends Omit<SampleOrder, "person" | "company"> {
  person: UserData;
  company: Company;
}

export interface SampleOrderTimelineEntry {
  _id: BSON.ObjectId;
  date: Date;
  type: SAMO_TIMELINETYPE;
  person: string;
  payload?: SampleOrderTimelineEntryPayload | null;
}

export interface SampleOrderTimelineEntryPayload {
  reason?: string;
  type?: string;
  reference?: string;
}
