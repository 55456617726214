import React, { PureComponent } from "react";
import { DataContextAnonymous, DataContextAnonymousType } from "../../../context/dataContext";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";

interface AnonymousBlurTextProps {
  additionalClasses?: string;
  children: JSX.Element | string;
}

class AnonymousBlurText extends PureComponent<AnonymousBlurTextProps> {
  static contextType = DataContextAnonymous;
  context!: DataContextAnonymousType;

  handleClickOnBlur = () => {
    (this.context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  render() {
    const { additionalClasses, children } = this.props;

    return (
      <span className={"blur-anonymous " + (additionalClasses ?? "")} onClick={this.handleClickOnBlur}>
        {children}
      </span>
    );
  }
}

export default AnonymousBlurText;
