import { BSON } from "realm-web";
import { CustomerCommodityExtended } from "../model/customer/customerCommodity.types";
import {
  CO_STATES,
  CO_T_ARCHIVED,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_CREATED,
  CO_T_CUSTOMS,
  CO_T_ORDERED,
  CO_T_SHIPPED,
  CO_T_SHIPPEDCUSTOMER,
  CO_T_SHIPPEDWAREHOUSE,
  CO_T_WAREHOUSE,
  CO_TRANSPORT,
  T_SEAFREIGHT,
} from "../model/customerOrder.types";
import { CustomerCustomerOrder } from "../model/customer/customerCustomerOrder.types";
import { getDefaultAddress } from "./addressUtils";
import { Company } from "../model/company.types";
import userService from "../services/userService";
import { UserData } from "../model/userData.types";
import { INTERNAL } from "./userUtils";
import { getCustomerOrderTimelineEntry, getOrderStateRanking } from "./customerOrderUtils";
import { CustomerSupplierOrder } from "../model/customer/customerSupplierOrder.types";
import { SO_ARRIVEDATSTARTINGPORT } from "../model/supplierOrder.types";
import { Seaport } from "../model/seaport.types";
import { Airport } from "../model/airport.types";
import { CustomerFinishedProductExtended } from "../model/customer/customerFinishedProduct.types";
import { getArticleSnapshot } from "./productArticleUtils";
import { callFunction } from "../services/dbService";
import { AnonymousConfiguration } from "../model/configuration/anonymousConfiguration.types";

// function names
const GENERATEANDSENDEMAILCONFIRMATION = "generateAndSendEmailConfirmation";
const CHECKCONFIRMATIONCODEANDCREATECOMPANY = "checkConfirmationCodeAndCreateCompany";

/**
 * Generate a customer order for the given article with the given orderNo, transport mode and state.
 * @param article Article that should be ordered
 * @param orderNo Number of the order
 * @param transport Transport mode of the order
 * @param state State the order should be set to
 * @returns {{co: CustomerCustomerOrder, so: CustomerSupplierOrder | undefined}} Generated customer order with related
 *  supplier order if the states requires a supplier order
 */
export function generateCustomerOrder(
  article: CustomerCommodityExtended | CustomerFinishedProductExtended,
  orderNo: number,
  transport: CO_TRANSPORT,
  state: CO_STATES
): { co: CustomerCustomerOrder; so: CustomerSupplierOrder | undefined } {
  const snapshot = getArticleSnapshot(article);
  const orderNoString = orderNo.toString().padStart(6, "0");
  const destination = getDefaultAddress("DE");
  destination.street = "Generic Street";
  destination.houseNo = "42";
  destination.postalCode = "89098";
  destination.city = "Anonymoucity";
  const cco: CustomerCustomerOrder = {
    _id: new BSON.ObjectId("000000000000000000" + orderNoString),
    orderNo: "1" + orderNoString,
    createdAt: new Date(),
    targetDate: new Date(),
    destination,
    noteCustomer: "",
    customerReference:
      "Ord No. " +
      Math.ceil(Math.random() * 10000)
        .toString()
        .padEnd(4, "0"),
    state,
    company: getAnonymousCompany()._id.toString(),
    person: userService.getUserId(),
    commodity: snapshot,
    amount: Math.ceil(Math.random() * 20) * 25,
    unit: "kg",
    priceCommodities: 0,
    services: [],
    priceServices: 0,
    totalPrice: 0,
    currency: "EUR",
    timeline: [],
    files: [],
    transport,
    terms: {
      paymentTerms: "30 days",
      paymentTermConditions: "after delivery",
      deliveryTerms: "DDP",
      deliveryCity: "Berlin",
    },
  };
  const ranking = getOrderStateRanking(cco);
  const createdAt = new Date();
  createdAt.setDate(
    createdAt.getDate() -
      (transport === T_SEAFREIGHT ? Math.random() * 20 + 7 * ranking : Math.random() * 10 + 2 * ranking)
  );
  cco.createdAt = createdAt;
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 7 + (7 - ranking) * (transport === T_SEAFREIGHT ? 14 : 3));
  cco.targetDate = targetDate;

  const tCreated = getCustomerOrderTimelineEntry(CO_T_CREATED);
  tCreated.date = new Date(createdAt);
  cco.timeline.push(tCreated);
  let so;

  if (ranking >= 0.5) {
    const t = getCustomerOrderTimelineEntry(CO_T_ORDERED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 1);
    cco.timeline.push(t);
  }
  if (ranking >= 1.5) {
    const t = getCustomerOrderTimelineEntry(CO_T_ARRIVEDATSTARTINGPORT);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 3);
    cco.timeline.push(t);
    so = generateSupplierOrder(cco._id.toString(), cco.transport, cco.amount);
    const destinationPort: Seaport | Airport =
      transport === T_SEAFREIGHT
        ? { _id: new BSON.ObjectId(), name: "Hamburg", locode: "DEHAM", country: "DE", disabled: false }
        : { _id: new BSON.ObjectId(), name: "Frankfurt", iata: "FRA", icao: "EDDF", country: "DE", disabled: false };
    so.terms = { notify: "000000000000000000000000" };
    so.shipment[0].shipping.destination = destinationPort;
  }
  if (ranking >= 2) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 5);
    cco.timeline.push(t);
  }
  if (ranking >= 3) {
    const t = getCustomerOrderTimelineEntry(CO_T_CUSTOMS);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5));
    cco.timeline.push(t);
  }
  if (ranking >= 4) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPEDWAREHOUSE);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 2);
    cco.timeline.push(t);
  }
  if (ranking >= 5) {
    const t = getCustomerOrderTimelineEntry(CO_T_WAREHOUSE);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 3);
    cco.timeline.push(t);
  }
  if (ranking >= 7) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPEDCUSTOMER);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 4);
    cco.timeline.push(t);
  }
  if (ranking >= 8) {
    const t = getCustomerOrderTimelineEntry(CO_T_ARCHIVED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 5);
    cco.timeline.push(t);
  }

  return { co: cco, so };
}

/**
 * Generates a minimal supplier order that is needed for properly showing data inside the customer view
 * @param customerOrder Related customer order
 * @param transport Transport mode
 * @param amount Amount that is ordered
 * @returns {CustomerSupplierOrder} Prepared supplier order
 */
function generateSupplierOrder(customerOrder: string, transport: CO_TRANSPORT, amount: number): CustomerSupplierOrder {
  const startingPort: Seaport | Airport =
    transport === T_SEAFREIGHT
      ? { _id: new BSON.ObjectId(), name: "Shanghai", locode: "CNSHA", country: "CN", disabled: false }
      : { _id: new BSON.ObjectId(), name: "Beijing", iata: "BJS", icao: "ZBAA", country: "CN", disabled: false };
  return {
    _id: new BSON.ObjectId(),
    customerOrders: [customerOrder],
    transport,
    shipment: [
      {
        _id: new BSON.ObjectId(),
        amount,
        state: SO_ARRIVEDATSTARTINGPORT,
        timeline: [],
        shipping: { trackingNumber: "", startingPoint: startingPort },
      },
    ],
  };
}

/**
 * Generates the company for the anonymous user.
 * @returns {Company} Anonymous Company
 */
export function getAnonymousCompany(): Company {
  return {
    _id: new BSON.ObjectId("000000000000000000000000"),
    name: "Anoymous Company",
    activated: true,
    vat: "",
    mail: "anonymous@example.com",
    address: [],
    creditLimit: 0,
    disabled: false,
    notes: "",
    phone: "",
    rating: 5,
    primaryPerson: userService.getUserId(),
    internalContact: getAnonymousInternalContact()._id.toString(),
    persons: [userService.getUserId()],
  };
}

/**
 * Generate the default internal contact for the anonymous view.
 * @returns {UserData} Internal Contact
 */
export function getAnonymousInternalContact(): UserData {
  return {
    _id: new BSON.ObjectId(),
    files: [],
    company: "internal",
    emails: [{ value: "ic@rawbids.com", description: "" }],
    image: "",
    type: INTERNAL,
    notifications: { language: "DE", settings: [] },
    phones: [],
    onboardingDone: true,
    position: "Anonymous Greeter",
    prename: "Anony",
    surname: "Mous",
    roles: [],
    userId: "",
  };
}

/**
 * Calls generateAndSendEmailConfirmation function in backend with the given user.
 * @param user User for which the email should be confirmed
 * @param resend Optional, if set the mail should be sent again
 * @returns {Promise<boolean>}
 */
export async function generateAndSendEmailConfirmation(user: UserData, resend?: boolean): Promise<boolean> {
  return callFunction(GENERATEANDSENDEMAILCONFIRMATION, [user, resend]);
}

/**
 * Calls checkConfirmationCodeAndCreateCompany function in backend and returns the result of the operation
 * @param user User for which confirmation should be run
 * @param company Company of the user
 * @param code Confirmation code user entered
 * @returns {Promise<{ result: boolean; error: string }>} Contains the result status and an error if there was one
 */
export async function checkConfirmationCodeAndCreateCompany(
  user: UserData,
  company: Company,
  code: string
): Promise<{ result: boolean; error: string }> {
  return callFunction(CHECKCONFIRMATIONCODEANDCREATECOMPANY, [user, company, code]);
}

/**
 * Checks if the given article should have prices in the anonymous view
 * @param article ID of the article
 * @param configuration Anonymous configuration
 * @returns {Boolean} Indicating whether the article has prices or not
 */
export function hasPricesInAnonymousView(article: string, configuration: AnonymousConfiguration): boolean {
  return (
    configuration.values.finishedProducts.some((fp) => fp.id === article && fp.withPrice) ||
    configuration.values.commodities.some((c) => c.id === article && c.withPrice)
  );
}
