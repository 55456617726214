import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { F_SIGNATURE, getUserName, INTERNAL, SelectedSpecificationUserData } from "../../utils/userUtils";
import { SelectOption } from "../common/CustomSelect";
import { uploadPublicFile } from "../../utils/fileUtils";
import { UserFile } from "../../model/userData.types";
import userService from "../../services/userService";
import { Action, COMMODITY, transaction, USERDATA } from "../../services/dbService";
import { createPDF, FOOTER_HTML } from "../../utils/pdfUtils";
import { createMasterSpecificationHTML } from "../../utils/pdf/masterSpecificationGenerationUtils";
import { UploadedFile } from "../../model/commodity.types";
import { D_MASTERSPECIFICATION, getCommodityTimelineEntry, T_MASTERSPECCREATED } from "../../utils/commodityUtils";
import { callPushToArray } from "../../utils/baseUtils";
import { DataContextInternal } from "../../context/dataContext";
import DocumentApprovalUser from "../common/internal/DocumentApprovalUser";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { getDefaultSlackChannel, sendMessage } from "../../services/slackService";
import { extendCommodity } from "../../utils/dataTransformationUtils";

interface MasterSpecificationGenerationToolProps {}

interface MasterSpecificationGenerationToolState {
  generating: boolean;
  originator?: SelectedSpecificationUserData;
  approver?: SelectedSpecificationUserData;
  successCount: number;
  handledCount: number;
}

class MasterSpecificationGenerationTool extends PureComponent<
  MasterSpecificationGenerationToolProps,
  MasterSpecificationGenerationToolState
> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;
  constructor(props: MasterSpecificationGenerationToolProps) {
    super(props);
    this.state = {
      generating: false,
      successCount: 0,
      handledCount: 0,
    };
  }

  handleSelectUser = (type: "approver" | "originator", e: SelectOption<SelectedSpecificationUserData>) => {
    const userData = _.cloneDeep(e.object);
    if (!userData) return; // Should not happen
    userData.signature = userData.files?.find((f) => f.type === F_SIGNATURE);
    userData.date = new Date();
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangePosition = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    userData.position = e.target.value;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangeDate = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    userData.date = targetDate;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleUploadSignature = async (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const file = e.target.files ? e.target.files[0] : null;
    if (!file || !userData) return;
    const alias = uploadPublicFile(file, `Signature-${userData.prename || ""}${userData.surname || ""}`);
    if (!alias) {
      toast.error("File upload failed.");
      return;
    }
    const userFile: UserFile = {
      _id: new BSON.ObjectId(),
      path: alias,
      type: F_SIGNATURE,
      date: new Date(),
      uploadedBy: userService.getUserId(),
      isPublic: true,
    };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      push: { files: userFile },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature uploaded successfully");
      userData.signature = userFile;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error uploading signature image");
    }
  };

  handleRemoveSignature = async (type: "approver" | "originator") => {
    const userData = { ..._.get(this.state, type) };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      pull: { files: userData.signature },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature deleted successfully");
      userData.signature = undefined;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error deleting signature");
    }
  };

  handleCreateMasterSpecifications = async () => {
    const { commodity: commodityList } = this.context;
    const { originator, approver } = this.state;
    if (!originator || !approver) return;
    this.setState({ generating: true, handledCount: 0, successCount: 0 });
    let successCount = 0;
    let count = 0;
    try {
      for (let i = 0; i < commodityList.length; i++) {
        const commodity = commodityList[i];
        const masterSpecification = commodity.documents.find((d) => d.type === D_MASTERSPECIFICATION);
        try {
          const version = masterSpecification && masterSpecification.version ? masterSpecification.version + 1 : 1;
          const path = await createPDF(
            createMasterSpecificationHTML(
              { type: COMMODITY, commodity: extendCommodity(commodity, this.context) },
              originator,
              approver,
              version
            ),
            `MasterSpecification-${commodity.articleNo}`,
            undefined,
            {
              marginLeft: "2cm",
              marginBottom: "4.2cm",
              footerHtml: FOOTER_HTML,
              footerSpacing: 5,
            }
          );
          if (path) {
            const file: UploadedFile = {
              _id: new BSON.ObjectId(),
              name: "Master Specification",
              path,
              type: D_MASTERSPECIFICATION,
              date: new Date(),
              originator: originator._id.toString(),
              approver: approver._id.toString(),
              approvalDate: new Date(),
              signedBy: null,
              version,
            };
            const timelineEntry = getCommodityTimelineEntry(T_MASTERSPECCREATED, D_MASTERSPECIFICATION, path);
            let res;
            if (masterSpecification) {
              const action: Action = {
                collection: COMMODITY,
                filter: { _id: commodity._id },
                update: { "documents.$[filter]": file }, // replace existing master spec
                arrayFilters: [{ "filter._id": masterSpecification._id }],
                push: { timeline: timelineEntry },
              };
              res = await transaction([action]);
            } else {
              res = await callPushToArray(COMMODITY, commodity._id, ["documents", "timeline"], [file, timelineEntry]);
            }
            if (res && (!(typeof res === "object" && "modifiedCount" in res) || res.modifiedCount)) {
              successCount++;
              count++;
              this.setState({ handledCount: count });
            } else {
              console.error(
                `Master specification for ${commodity.title.en} (${commodity._id.toString()}) could not be generated`
              );
              sendMessage(
                getDefaultSlackChannel(),
                `Master specification for ${commodity.title.en} (${commodity._id.toString()}) could not be generated.`
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    } finally {
      toast.success(`Generation finished. ${successCount}/${commodityList.length} successfully generated.`);
      sendMessage(
        getDefaultSlackChannel(),
        `Master spec generation finished. ${successCount}/${commodityList.length} successfully generated.`
      );
      this.setState({ generating: false, successCount });
    }
  };

  validateSpecificationData = () => {
    const { originator, approver } = this.state;
    const errors: Array<string> = [];
    if (originator) {
      if (!originator.signature) errors.push("Signature of originator missing");
    } else errors.push("No originator selected. Please select a user as originator/creator of the commodity");
    if (approver) {
      if (!approver.signature) errors.push("Signature of approver missing");
    } else errors.push("No approver selected. Please select a user that approved the commodity");
    if (originator && approver && originator._id.toString() === approver._id.toString())
      errors.push(
        "Originator and approver cannot be the same person. Please select a different originator or approver"
      );
    return errors;
  };

  render() {
    const { originator, approver, generating, handledCount } = this.state;
    const internalUsers = this.context.userData
      .filter((uD) => uD.type === INTERNAL)
      .map((uD) => {
        return { value: uD._id.toString(), label: getUserName(uD), object: uD as SelectedSpecificationUserData };
      });
    const errors = this.validateSpecificationData();
    if (!userService.isAdmin())
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Master Specification Tool</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <>
        <Modal contentClassName="bg-dark" show={generating} size={"lg"} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Generating Master Specification</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-10">
              <div className="text-white text-center ">
                <div className="splash-screen-relative">
                  <svg className="splash-spinner" viewBox="0 0 50 50" style={{ height: "50px", width: "50px" }}>
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                  <div className="ml-3 h3 text-white">
                    Generating. {handledCount}/{this.context.commodity.length} done.
                  </div>
                </div>
              </div>
              <div className="text-center mt-2">
                <small className="text-white">This may take a while. Please wait</small>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl">
              <div className="card bg-white">
                <div className="card-body">
                  <h3 className="card-title align-items-start flex-column mb-15">
                    <span className="card-label fw-bolder mb-3 fs-3rem">Master Specification Tool</span>
                  </h3>
                  <div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="text-white text-center h6">Originator</div>
                        <DocumentApprovalUser
                          type="originator"
                          disabled={generating}
                          userData={originator}
                          internalUsers={internalUsers}
                          onSelectUser={this.handleSelectUser}
                          onChangePosition={this.handleChangePosition}
                          onChangeDate={this.handleChangeDate}
                          onUploadSignature={this.handleUploadSignature}
                          onRemoveSignature={this.handleRemoveSignature}
                        />
                      </div>
                      <div className="col-xl-6">
                        <div className="text-white text-center h6">Approver</div>
                        <DocumentApprovalUser
                          type="approver"
                          disabled={generating}
                          userData={approver}
                          internalUsers={internalUsers}
                          onSelectUser={this.handleSelectUser}
                          onChangePosition={this.handleChangePosition}
                          onChangeDate={this.handleChangeDate}
                          onUploadSignature={this.handleUploadSignature}
                          onRemoveSignature={this.handleRemoveSignature}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0">
                  <ErrorOverlayButton
                    errors={errors}
                    saving={generating}
                    className="btn btn-sm btn-outline btn-outline-light float-right"
                    buttonText="Generate Specifications"
                    onClick={this.handleCreateMasterSpecifications}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MasterSpecificationGenerationTool;
